import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { types as safetyVoiceScore, IGetsafetyVoiceScoringRequestData } from "./_types";

export const actions = {
  getListSafetyVoice: {
    request: (requestData: IDefaultRequestParams<IGetsafetyVoiceScoringRequestData>) => ({
      ...requestData,
      type: safetyVoiceScore.GET_SAFETY_VOICE_SCORE_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: safetyVoiceScore.GET_SAFETY_VOICE_SCORE_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: safetyVoiceScore.GET_SAFETY_VOICE_SCORE_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getDetailSafetyVoice: {
    request: (requestData: any) => ({
      ...requestData,
      type: safetyVoiceScore.GET_SAFETY_VOICE_SCORE_DETAIL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: safetyVoiceScore.GET_SAFETY_VOICE_SCORE_DETAIL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: safetyVoiceScore.GET_SAFETY_VOICE_SCORE_DETAIL_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};
