import { call, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { IPayloadUpdatePass, types } from "./_types";

function* workerChangePassword({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IPayloadUpdatePass>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_PASS);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

export const watcherChangePassword = () => [
  takeLatest(types.GET_PROFILE_REQUEST, workerChangePassword),
];
