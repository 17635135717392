import { ForgetPass as ForgetPassValues } from "./ForgetPassword/types";
import { DataOTP as DataOTPValues } from "./Otp/types";
import { FormValues as RegisterFormValues } from "./Register/types";
import { UpdatePass as UpdatePassValues } from "./UpdatePassword/types";

export const types = {
  SIGNIN_REQUEST: "auth/SIGNIN_REQUEST",
  SIGNIN_SUCCESS: "auth/SIGNIN_SUCCESS",
  SIGNIN_FAILURE: "auth/SIGNIN_FAILURE",

  REFRESH_TOKEN_REQUEST: "auth/REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_SUCCESS: "auth/REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_FAILURE: "auth/REFRESH_TOKEN_FAILURE",

  REGISTER_REQUEST: "auth/REGISTER_REQUEST",
  REGISTER_SUCCESS: "auth/REGISTER_SUCCESS",
  REGISTER_FAILURE: "auth/REGISTER_FAILURE",

  GET_USERS_REQUEST: "auth/GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "auth/GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "auth/GET_USERS_FAILURE",

  FORGOT_PASS_REQUEST: "auth/FORGOT_PASS_REQUEST",
  FORGOT_PASS_SUCCESS: "auth/FORGOT_PASS_SUCCESS",
  FORGOT_PASS_FAILURE: "auth/FORGOT_PASS_FAILURE",

  OTP_USER_REQUEST: "auth/OTP_USER_REQUEST",
  OTP_USER_SUCCESS: "auth/OTP_USER_SUCCESS",
  OTP_USER_FAILURE: "auth/OTP_USER_FAILURE",

  UPDATE_PASS_REQUEST: "auth/UPDATE_PASS_REQUEST",

  CHECK_TOKEN_REQUEST: "auth/CHECK_TOKEN_REQUEST",
  CHECK_TOKEN_SUCCESS: "auth/CHECK_TOKEN_SUCCESS",
  CHECK_TOKEN_FAILURE: "auth/CHECK_TOKEN_FAILURE",

  SIGNOUT: "auth/SIGNOUT",

  RESET_ACTION_STATUS: "auth/RESET_ACTION_STATUS",
};

export interface ISigninRequestData {
  username: string | null;
  password: string | null;
  grant_type: string;
}

export interface IRegisterRequestData extends RegisterFormValues {}
export interface IForgetPassData extends ForgetPassValues {}
export interface IUserOTPData extends DataOTPValues {}
export interface IUpdatePassData extends UpdatePassValues {}
