import { call, put, takeLatest } from "redux-saga/effects";

import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";

import { actions as userActions } from "./_actions";
import {
  ICreateDetailAttData,
  IGetDetailAttData,
  IGetListDetailAttData,
  IUpdateDetailAttData,
  types as detailTypes,
} from "./_types";

export const watcherDetail = () => [
  takeLatest(detailTypes.GET_ALL_DETAIL_ATT_REQUEST, workerGetDetailAtt),
  takeLatest(detailTypes.GET_DETAIL_ATT_DATA_REQUEST, workerListDetailAttData),
  takeLatest(detailTypes.CREATE_DETAIL_ATT_REQUEST, workerCreateDetailAtt),
  takeLatest(detailTypes.UPDATE_DETAIL_ATT_REQUEST, workerUpdateDetailAtt),
];

function* workerGetDetailAtt({ payload }: IDefaultRequestAction<IGetDetailAttData>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_ALL_DETAIL_ATT,
      method: "post",
      data: {
        attId: payload?.attId,
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    yield put(userActions.getDetailAtt.success(response));
  } catch (err) {
    yield put(userActions.getDetailAtt.failure(err));
  }
}

function* workerListDetailAttData({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetListDetailAttData>): any {
  const http = new HttpService(urlsEndpoint.GET_DETAIL_ATT_DATA);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getDetailAttData.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getDetailAttData.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerCreateDetailAtt({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<ICreateDetailAttData>): any {
  const http = new HttpService(urlsEndpoint.CREATE_DETAIL_ATT);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.createDetailAtt.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.createDetailAtt.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerUpdateDetailAtt({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IUpdateDetailAttData>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_DETAIL_ATT);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.updateDetailAtt.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.updateDetailAtt.failure(err));
    yield callback(onFailure, err);
  }
}
