export const types = {
  GET_BULK_UPLOAD_USER_LIST_REQUEST: "bulkupload/GET_BULK_UPLOAD_USER_LIST_REQUEST",
  GET_BULK_UPLOAD_USER_LIST_SUCCESS: "bulkupload/GET_BULK_UPLOAD_USER_LIST_SUCCESS",
  GET_BULK_UPLOAD_USER_LIST_FAILED: "bulkupload/GET_BULK_UPLOAD_USER_LIST_FAILED",
  GET_BULK_UPLOAD_MEDICINE_LIST_REQUEST: "bulkupload/GET_BULK_UPLOAD_MEDICINE_LIST_REQUEST",
  GET_BULK_UPLOAD_MEDICINE_LIST_SUCCESS: "bulkupload/GET_BULK_UPLOAD_MEDICINE_LIST_SUCCESS",
  GET_BULK_UPLOAD_MEDICINE_LIST_FAILED: "bulkupload/GET_BULK_UPLOAD_MEDICINE_LIST_FAILED",
  BULK_UPLOAD_USER_REQUEST: "bulkupload/BULK_UPLOAD_USER_REQUEST",
  BULK_UPLOAD_MEDICINE_REQUEST: "bulkupload/BULK_UPLOAD_MEDICINE_REQUEST",
  GET_BULK_UPLOAD_USER_FAILED_LIST_REQUEST: "bulkupload/GET_BULK_UPLOAD_USER_FAILED_LIST_REQUEST",
  GET_BULK_UPLOAD_USER_FAILED_LIST_SUCCESS: "bulkupload/GET_BULK_UPLOAD_USER_FAILED_LIST_SUCCESS",
  GET_BULK_UPLOAD_USER_FAILED_LIST_FAILED: "bulkupload/GET_BULK_UPLOAD_USER_FAILED_LIST_FAILED",
  GET_BULK_UPLOAD_MEDICINE_FAILED_LIST_REQUEST:
    "bulkupload/GET_BULK_UPLOAD_MEDICINE_FAILED_LIST_REQUEST",
  GET_BULK_UPLOAD_MEDICINE_FAILED_LIST_SUCCESS:
    "bulkupload/GET_BULK_UPLOAD_MEDICINE_FAILED_LIST_SUCCESS",
  GET_BULK_UPLOAD_MEDICINE_FAILED_LIST_FAILED:
    "bulkupload/GET_BULK_UPLOAD_MEDICINE_FAILED_LIST_FAILED",
};

export interface IGetBulkUploadRequestData {
  page: number;
  limit: number;
  keyword: string;
  orderBy: string | any;
  status: string;
  dateFilter: string;
  startDate: string;
  endDate: string;
  filter?: any;
  asc?: boolean;
  direction?: string;
}

export interface IGetBulkUploadFailedRequestData {
  bulkUploadId: number;
}
