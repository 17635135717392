import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import {
  IGetMedicineRequestData,
  IPayloadUpdateMedicine,
  IPayloadTypeList,
  IPayloadCreateMedicine,
  IGetLogMedicineRequestData,
  IPayloadExportMedicine,
  types as medicineType,
  IGetDetailMedicine,
} from "./_types";

export const actions = {
  getMedicine: {
    request: (requestData: IDefaultRequestParams<IGetMedicineRequestData>) => ({
      ...requestData,
      type: medicineType.GET_MEDICINE_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: medicineType.GET_MEDICINE_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: medicineType.GET_MEDICINE_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getMedicineCode: {
    request: (requestData: IDefaultRequestParams<IGetMedicineRequestData>) => ({
      ...requestData,
      type: medicineType.GET_MEDICINE_CODE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: medicineType.GET_MEDICINE_CODE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: medicineType.GET_DETAIL_MEDICINE_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getLogMedicine: {
    request: (requestData: IDefaultRequestParams<IGetLogMedicineRequestData>) => ({
      ...requestData,
      type: medicineType.GET_MEDICINE_LOG_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: medicineType.GET_MEDICINE_LOG_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: medicineType.GET_MEDICINE_LOG_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getDetailMedicine: {
    request: (requestData: IDefaultRequestParams<IGetDetailMedicine>) => ({
      ...requestData,
      type: medicineType.GET_DETAIL_MEDICINE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: medicineType.GET_DETAIL_MEDICINE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: medicineType.GET_DETAIL_MEDICINE_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  exportMedicine: {
    request: (requestData: IDefaultRequestParams<IPayloadExportMedicine>) => ({
      ...requestData,
      type: medicineType.EXPORT_MEDICINE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: medicineType.EXPORT_MEDICINE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: medicineType.EXPORT_MEDICINE_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};

export async function deleteMedicine(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(`${urlsEndpoint.DELETE_MEDICINE}`);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateStockMedicine(
  payload: IPayloadUpdateMedicine
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.UPDATE_STOCK_MEDICINE);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response?.message,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function showTypeList(
  payload: IPayloadTypeList
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.TYPE_LIST_MEDICINE);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response?.data,
      message: response?.message,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function createMedicine(
  payload: IPayloadCreateMedicine
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.CREATE_MEDICINE);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response?.message,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateMedicine(
  payload: any
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.UPDATE_MEDICINE);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response?.message,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
