import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";

import { actions as generalActions } from "./_actions";
import { IFormDashboard, IGetFormData, types } from "./_types";

export const watcherDashboard = () => [
  takeLatest(types.GET_DASHBOARD_LIST_REQUEST, workerDashboardList),
  takeLatest(types.CREATE_DASHBOARD_REQUEST, workerCreateDashboard),
  takeLatest(types.DETAIL_DASHBOARD_REQUEST, workerDashboardDetail),
  takeLatest(types.UPDATE_DASHBOARD_REQUEST, workerUpdateDashboard),
  takeLatest(types.GET_ALL_DASHBOARD_LIST_REQUEST, workerDashboardListAll),
];

function* workerDashboardList({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetFormData>): any {
  const http = new HttpService(urlsEndpoint.DASHBOARD_LIST);
  try {
    const response = yield call(http.post, payload);
    yield put(generalActions.getDashboard.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(generalActions.getDashboard.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerDashboardListAll({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.DASHBOARD_GET_ALL);
  try {
    const response = yield call(http.post, payload);
    yield put(generalActions.getDashboardAll.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(generalActions.getDashboardAll.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerCreateDashboard({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IFormDashboard>): any {
  const http = new HttpService(urlsEndpoint.CREATE_DASHBOARD);
  try {
    const response = yield call(http.post, payload);
    yield put(generalActions.createDashboard.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(generalActions.createDashboard.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerUpdateDashboard({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IFormDashboard>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_DASHBOARD);
  try {
    const response = yield call(http.post, payload);
    yield put(generalActions.updateDashboard.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(generalActions.updateDashboard.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerDashboardDetail({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(`${urlsEndpoint.DASHBOARD_DETAIL}/${payload?.id}`);
  try {
    const response = yield call(http.custom, {
      url: `${urlsEndpoint.DASHBOARD_DETAIL}/${payload?.id}`,
      method: "get",
    });
    yield put(generalActions.getDetailDashboard.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(generalActions.getDetailDashboard.failure(err));
    yield callback(onFailure, err);
  }
}
