import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { types } from "./_types";

export const actions = {
  // type of work
  getListTypeOfWork: {
    request: (payload: any) => ({
      payload,
      type: types.GET_LIST_TYPE_OF_WORK_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.GET_LIST_TYPE_OF_WORK_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.GET_LIST_TYPE_OF_WORK_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  addTypeOfWork: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.ADD_TYPE_OF_WORK_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.ADD_TYPE_OF_WORK_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.ADD_TYPE_OF_WORK_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  editTypeOfWork: {
    request: (payload: any) => ({
      ...payload,
      type: types.EDIT_TYPE_OF_WORK_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.EDIT_TYPE_OF_WORK_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.EDIT_TYPE_OF_WORK_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  deleteTypeOfWork: {
    request: (payload: any) => ({
      payload,
      type: types.DELETE_TYPE_OF_WORK_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.DELETE_TYPE_OF_WORK_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.DELETE_TYPE_OF_WORK_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  // rank configuration
  getListRankConfiguration: {
    request: (payload: any) => ({
      payload,
      type: types.GET_LIST_RANK_CONFIGURATION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.GET_LIST_RANK_CONFIGURATION_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.GET_LIST_RANK_CONFIGURATION_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  addRankConfiguration: {
    request: (payload: any) => ({
      ...payload,
      type: types.ADD_RANK_CONFIGURATION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.ADD_RANK_CONFIGURATION_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.ADD_RANK_CONFIGURATION_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  editRankConfiguration: {
    request: (payload: any) => ({
      ...payload,
      type: types.EDIT_RANK_CONFIGURATION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.EDIT_RANK_CONFIGURATION_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.EDIT_RANK_CONFIGURATION_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  deleteRankConfiguration: {
    request: (payload: any) => ({
      payload,
      type: types.DELETE_RANK_CONFIGURATION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.DELETE_RANK_CONFIGURATION_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.DELETE_RANK_CONFIGURATION_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  // detail
  getListRankDetail: {
    request: (payload: any) => ({
      payload,
      type: types.GET_LIST_RANK_DETAIL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.GET_LIST_RANK_DETAIL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.GET_LIST_RANK_DETAIL_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  addRankDetail: {
    request: (payload: any) => ({
      ...payload,
      type: types.ADD_RANK_DETAIL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.ADD_RANK_DETAIL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.ADD_RANK_DETAIL_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  editRankDetail: {
    request: (payload: any) => ({
      ...payload,
      type: types.EDIT_RANK_DETAIL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.EDIT_RANK_DETAIL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.EDIT_RANK_DETAIL_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  deleteRankDetail: {
    request: (payload: any) => ({
      payload,
      type: types.DELETE_RANK_DETAIL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.DELETE_RANK_DETAIL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.DELETE_RANK_DETAIL_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};
// non redux
export async function deleteTof(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_TYPE_OF_WORK);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.data?.formFlow,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
export async function deleteRankConfig(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_RANK_CONFIG);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.data?.formFlow,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
export async function deleteRankDetail(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_RANK_DETAIL);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.data?.formFlow,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}