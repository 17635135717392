export const types = {
  GET_PROFILE_REQUEST: "profile/GET_PROFILE_REQUEST",
  GET_PROFILE_SUCCESS: "profile/SIGNIN_SUCCESS",
  GET_PROFILE_FAILED: "profile/GET_PROFILE_FAILED",
};

export interface IPayloadUpdateProfile {
  id: string | number;
  username: string;
  nik: string;
  email: string;
  weight: string | number;
  height: string | number;
  photoProfile: string;
}

export interface IPayloadGetProfile {
  userName: string;
  token: string;
}
