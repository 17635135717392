import { types } from "./_types";

const initialState = {
  userProfile: null,
  actionStatus: "initial",
};

export const profileReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_PROFILE_REQUEST:
      return {
        ...state,
        userProfile: null,
      };
    case types.GET_PROFILE_FAILED:
      return {
        ...state,
        actionStatus: "failure",
        userProfile: null,
      };
    case types.GET_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: action.data?.user,
        actionStatus: "success",
      };
    default:
      return state;
  }
};
