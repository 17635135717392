import { types } from "./_types";

const initialState = {
  result: [],
  roleDetail: {},
  actionStatus: "initial",
  moreActionStatus: {
    getRole: "initial",
    updateRole: "initial",
    addRole: "initial",
  },
  pagination: null,
  data: null,
  archiveData: null,
  isLoading: false,
};

export const roleReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_VIEW_ROLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_VIEW_ROLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_VIEW_ROLE_SUCCESS:
      return {
        ...state,
        // roles: Array.isArray(action.data.data) ? action.data.data : [],
        // result: action.data?.result || [],
        data: action.data.result,
        pagination: action.data.pagination,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_ALL_ARCHIVE_ROLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_ALL_ARCHIVE_ROLE_FAILURE:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_ALL_ARCHIVE_ROLE_SUCCESS:
      return {
        ...state,
        archiveData: action.data.result,
        pagination: action.data.pagination,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_ROLE_BY_ID_REQUEST:
      return {
        ...state,
        roleDetail: {
          ...state.roleDetail,
          [action.data?.result?.id]: action.data?.result,
        },
        moreActionStatus: {
          ...state.moreActionStatus,
          getRole: action.actionStatus,
        },
        actionStatus: action.actionStatus,
        isLoading: true,
      };
    case types.GET_ROLE_BY_ID_FAILURE:
    case types.GET_ROLE_BY_ID_SUCCESS:
      return {
        ...state,
        roleDetail: {
          ...state.roleDetail,
          [action.data?.result?.id]: action.data?.result,
        },
        moreActionStatus: {
          ...state.moreActionStatus,
          getRole: action.actionStatus,
        },
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.CREATE_ROLE_REQUEST:
      return {
        ...state,
        actionStatus: action.actionStatus,
        moreActionStatus: {
          ...state.moreActionStatus,
          addRole: action.actionStatus,
        },
        isLoading: true,
      };
    case types.CREATE_ROLE_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        moreActionStatus: {
          ...state.moreActionStatus,
          addRole: action.actionStatus,
        },
        isLoading: false,
      };
    case types.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        actionStatus: action.actionStatus,
        moreActionStatus: {
          ...state.moreActionStatus,
          addRole: action.actionStatus,
        },
        isLoading: false,
      };
    case types.UPDATE_ROLE_REQUEST:
      return {
        ...state,
        moreActionStatus: {
          ...state.moreActionStatus,
          updateRole: action.actionStatus,
        },
        actionStatus: action.actionStatus,
        isLoading: true,
      };
    case types.UPDATE_ROLE_FAILURE:
      return {
        ...state,
        moreActionStatus: {
          ...state.moreActionStatus,
          updateRole: action.actionStatus,
        },
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        moreActionStatus: {
          ...state.moreActionStatus,
          updateRole: action.actionStatus,
        },
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    default:
      return state;
  }
};
