import { Avatar, notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import styles from "./styles.module.less";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { message } from "antd";
import type { UploadChangeParam } from "antd/es/upload";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useDispatch, useSelector } from "react-redux";
import { selectors } from "../Auth/_selectors";
import View from "./view";
import { profileActions, updateDetailUser, uploadPhotoProfile } from "./_actions";
import { selectors as profileSelectors } from "./_selectors";
import moment from "moment";

interface ModalProfileProps {
  isModalOpen: boolean;
  modalClose: Function;
  modalOpen: Function;
  modalNik: Function;
}

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.onload = function (e) {
    callback(reader.result as string);
  };
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const Profile: React.FC<ModalProfileProps> = ({ isModalOpen, modalOpen, modalClose, modalNik }) => {
  const [loading, setLoading] = useState(false);
  const [loadingPicture, setLoadingPicture] = useState(false);
  const dispatch = useDispatch();
  const [photoForDB, setPhotoForDB] = useState<any>("");
  const [imagePreview, setImagePreview] = useState<any>(null);
  const dataSource = useSelector(selectors.getUserData);
  const dataProfile = useSelector(profileSelectors.getUserProfile);

  const getUserDetailAction = useCallback(async () => {
    setLoading(true);
    dispatch(
      profileActions.getProfile.request({
        payload: { userName: dataSource?.details?.nik, token: dataSource?.access_token },
        onSuccess: (res) => {
          setLoading(false);
          setPhotoForDB(res?.user?.photoProfile ?? "");
        },
        onFailure: (err: any) => {
          setLoading(false);
          notification.error({
            placement: "topRight",
            message: "Error",
            description: "Failed Get Data Profile",
          });
        },
      })
    );
  }, [dataSource?.access_token, dataSource?.details?.nik, dispatch]);

  const uploadPhotoProfileAction = async (e: any, info: any) => {
    const formData = new FormData();
    formData.append("file", e);
    const uploadPhotoProfileResponse = await uploadPhotoProfile(formData);
    if (uploadPhotoProfileResponse.error) {
      setLoadingPicture(false);
      notification.error({
        placement: "topRight",
        message: "Failed get data",
        description: uploadPhotoProfileResponse?.data?.message ?? "Failed get data reservation",
        duration: 3,
      });
      info.onError("failed");
    }
    if (uploadPhotoProfileResponse.success) {
      setLoadingPicture(false);
      info.onSuccess("ok");
      setPhotoForDB(uploadPhotoProfileResponse?.data?.fileData?.url);
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      getUserDetailAction();
    }
  }, [dataSource.access_token, dataSource.details.nik, getUserDetailAction, isModalOpen]);

  const handleChange: UploadProps["onChange"] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === "uploading") {
      setLoadingPicture(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoadingPicture(false);
        setImagePreview(url);
      });
    }
  };

  const uploadButton = (
    <div className={styles.avatarContainer}>
      {loadingPicture ? (
        <LoadingOutlined />
      ) : (
        <div className={styles.img__wrap}>
          <Avatar
            size={130}
            icon={<UserOutlined style={{ display: "inline-block", verticalAlign: "middle" }} />}
          />
          {/* <div className={styles.img__description}>John Doe</div> */}
        </div>
      )}
    </div>
  );

  const onFinish = async (values: any) => {
    setLoading(true);
    const updateDetailResponse = await updateDetailUser({
      ...values,
      id: dataProfile?.id,
      nik: dataProfile?.nik,
      username: dataProfile?.username,
      nikPassport: values?.nikPassport,
      photoProfile: photoForDB ?? "",
      dateOfBirth: values?.dateOfBirth
        ? moment(values.dateOfBirth).format("YYYY-MM-DD")
        : dataProfile?.dateOfBirth,
    });
    if (updateDetailResponse.error) {
      setLoading(false);
      notification.error({
        placement: "topRight",
        message: "Failed to Update Data",
        description: updateDetailResponse?.data?.message ?? "Failed to update",
        duration: 3,
      });
    }
    if (updateDetailResponse.success) {
      setLoading(false);
      getUserDetailAction();
      notification.success({
        placement: "topRight",
        message: "Success",
        description: "Data Updated Successfully",
        duration: 3,
      });
      modalClose();
      modalNik();
    }
  };

  const dummyRequest = (info: any) => {
    uploadPhotoProfileAction(info.file, info);
  };

  return (
    <View
      beforeUpload={beforeUpload}
      dummyRequest={dummyRequest}
      handleChange={handleChange}
      isModalOpen={isModalOpen}
      modalClose={modalClose}
      onFinish={onFinish}
      uploadButton={uploadButton}
      imageUrl={imagePreview ?? photoForDB}
      userData={dataProfile}
      isLoading={loading}
      loadingPicture={loadingPicture}
    />
  );
};

export default Profile;
