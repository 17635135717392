export const types = {
  // Type of Works
  GET_LIST_TYPE_OF_WORK_REQUEST: "rank/GET_LIST_TYPE_OF_WORK_REQUEST",
  GET_LIST_TYPE_OF_WORK_SUCCESS: "rank/GET_LIST_TYPE_OF_WORK_SUCCESS",
  GET_LIST_TYPE_OF_WORK_FAILURE: "rank/GET_LIST_TYPE_OF_WORK_FAILURE",

  ADD_TYPE_OF_WORK_REQUEST: "rank/ADD_TYPE_OF_WORK_REQUEST",
  ADD_TYPE_OF_WORK_SUCCESS: "rank/ADD_TYPE_OF_WORK_SUCCESS",
  ADD_TYPE_OF_WORK_FAILURE: "rank/ADD_TYPE_OF_WORK_FAILURE",

  EDIT_TYPE_OF_WORK_REQUEST: "rank/EDIT_TYPE_OF_WORK_REQUEST",
  EDIT_TYPE_OF_WORK_SUCCESS: "rank/EDIT_TYPE_OF_WORK_SUCCESS",
  EDIT_TYPE_OF_WORK_FAILURE: "rank/EDIT_TYPE_OF_WORK_FAILURE",

  DELETE_TYPE_OF_WORK_REQUEST: "rank/DELETE_TYPE_OF_WORK_REQUEST",
  DELETE_TYPE_OF_WORK_SUCCESS: "rank/DELETE_TYPE_OF_WORK_SUCCESS",
  DELETE_TYPE_OF_WORK_FAILURE: "rank/DELETE_TYPE_OF_WORK_FAILURE",

  // Rank configuration
  GET_LIST_RANK_CONFIGURATION_REQUEST: "rank/GET_LIST_RANK_CONFIGURATION_REQUEST",
  GET_LIST_RANK_CONFIGURATION_SUCCESS: "rank/GET_LIST_RANK_CONFIGURATION_SUCCESS",
  GET_LIST_RANK_CONFIGURATION_FAILURE: "rank/GET_LIST_RANK_CONFIGURATION_FAILURE",

  ADD_RANK_CONFIGURATION_REQUEST: "rank/ADD_RANK_CONFIGURATION_REQUEST",
  ADD_RANK_CONFIGURATION_SUCCESS: "rank/ADD_RANK_CONFIGURATION_SUCCESS",
  ADD_RANK_CONFIGURATION_FAILURE: "rank/ADD_RANK_CONFIGURATION_FAILURE",

  EDIT_RANK_CONFIGURATION_REQUEST: "rank/EDIT_RANK_CONFIGURATION_REQUEST",
  EDIT_RANK_CONFIGURATION_SUCCESS: "rank/EDIT_RANK_CONFIGURATION_SUCCESS",
  EDIT_RANK_CONFIGURATION_FAILURE: "rank/EDIT_RANK_CONFIGURATION_FAILURE",

  DELETE_RANK_CONFIGURATION_REQUEST: "rank/DELETE_RANK_CONFIGURATION_REQUEST",
  DELETE_RANK_CONFIGURATION_SUCCESS: "rank/DELETE_RANK_CONFIGURATION_SUCCESS",
  DELETE_RANK_CONFIGURATION_FAILURE: "rank/DELETE_RANK_CONFIGURATION_FAILURE",

  //  Rank Detail
  GET_LIST_RANK_DETAIL_REQUEST: "rank/GET_LIST_RANK_DETAIL_REQUEST",
  GET_LIST_RANK_DETAIL_SUCCESS: "rank/GET_LIST_RANK_DETAIL_SUCCESS",
  GET_LIST_RANK_DETAIL_FAILURE: "rank/GET_LIST_RANK_DETAIL_FAILURE",

  ADD_RANK_DETAIL_REQUEST: "rank/ADD_RANK_DETAIL_REQUEST",
  ADD_RANK_DETAIL_SUCCESS: "rank/ADD_RANK_DETAIL_SUCCESS",
  ADD_RANK_DETAIL_FAILURE: "rank/ADD_RANK_DETAIL_FAILURE",

  EDIT_RANK_DETAIL_REQUEST: "rank/EDIT_RANK_DETAIL_REQUEST",
  EDIT_RANK_DETAIL_SUCCESS: "rank/EDIT_RANK_DETAIL_SUCCESS",
  EDIT_RANK_DETAIL_FAILURE: "rank/EDIT_RANK_DETAIL_FAILURE",

  DELETE_RANK_DETAIL_REQUEST: "rank/DELETE_RANK_DETAIL_REQUEST",
  DELETE_RANK_DETAIL_SUCCESS: "rank/DELETE_RANK_DETAIL_SUCCESS",
  DELETE_RANK_DETAIL_FAILURE: "rank/DELETE_RANK_DETAIL_FAILURE",
};
