import { types } from "./_types";

const initialState = {
  dashboard: [],
  paginationDashboard: null,
  detailDashboard: null,
  actionStatus: "initial",
  isLoading: true,
  allDashboard: [],
  isLoadingAllDashboard: true,
};

export const dashboardRecuder = (state = initialState, action: any) => {
  switch (action.type) {
    // list dashboard

    case types.GET_DASHBOARD_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_DASHBOARD_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        dashboard: [],
        actionStatus: action.status,
      };
    case types.GET_DASHBOARD_LIST_SUCCESS:
      return {
        ...state,
        dashboard: Array.isArray(action.data.data.list) ? action.data.data.list : [],
        paginationDashboard: action.data.data.pagination,
        actionStatus: action.status,
        isLoading: false,
      };
    //list all
    case types.GET_ALL_DASHBOARD_LIST_REQUEST:
      return {
        ...state,
        isLoadingAllDashboard: true,
      };
    case types.GET_ALL_DASHBOARD_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        allDashboard: [],
        actionStatus: action.status,
      };
    case types.GET_ALL_DASHBOARD_LIST_SUCCESS:
      return {
        ...state,
        allDashboard: Array.isArray(action.data.data.list) ? action.data.data.list : [],
        actionStatus: action.status,
        isLoadingAllDashboard: false,
      };
    //Detail

    case types.DETAIL_DASHBOARD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.DETAIL_DASHBOARD_FAILURE:
      return {
        ...state,
        isLoading: false,
        detailDashboard: null,
        actionStatus: action.status,
      };
    case types.DETAIL_DASHBOARD_SUCCESS:
      return {
        ...state,
        detailDashboard: action.data.data,
        actionStatus: action.status,
        isLoading: false,
      };
    default:
      return state;
  }
};
