const urlsEndpoint = {
  // AUTH
  TOKEN: "auth/oauth/token",
  CHECK_TOKEN: "auth/oauth/check_token",
  REFRESH_TOKEN: "auth/oauth/token?grant_type=refresh_token",
  REGISTER_AUTH: "/auth/user/register",
  REGISTER_BACKEND: "/backend/api/user/register",
  GET_USERS: "/auth/user/list/pagination",
  GET_DETAIL_USERS: "/backend/api/user/detail",
  GET_DETAIL_USER_COMPANY: "/backend/api/user/information/",
  GET_LIST_COMPANY: "/backend/api/company/getall",
  GET_DETAIL_AUTH_USERS: "/auth/user/detail",
  SAVE_USER: "/auth/user/save",
  EDIT_USER: "/auth/user/update",
  DELETE_USER: "/auth/user/delete",
  FORGOT_PASS: "/auth/user/forgetpassword",
  OTP_USER: "/auth/user/otp",
  CREATE_COMPANY_USER: "/backend/api/company/save/name",
  LIST_ARCHIVE_USER: "/auth/user/archives",
  RESTORE_USER: "/auth/user/restore",
  UPDATE_PASS: "/auth/user/resetpassword",
  SAVE_USER_BACKEND: "/backend/api/user/save",
  EDIT_USER_BACKEND: "/backend/api/user/edit",
  LIST_DEPARTMENT: "/backend/api/model/department",
  LIST_GROUP: "/backend/api/model/group",
  LIST_POSITION: "/backend/api/model/position",
  LIST_SECTION: "/backend/api/model/section",
  LIST_MODEL: "/backend/api/model/",
  LIST_DS: "/backend/api/user/username/list",

  // FORM
  GET_FORMS: "/backend/api/form/view/pagination",
  GET_ARCHIVE: "/backend/api/form/view/pagination",
  GET_FORM_DETAIL: "/backend/api/form/get",
  CREATE_FORM: "/backend/api/form/create",
  UPDATE_FORM: "/backend/api/form/edit",
  GET_FORMDATA: "/backend/api/form/getarchive/",
  DELETE_FORM: "/backend/api/form/delete",
  RESTORE_FORM: "/backend/api/form/restore/form",

  //SITE
  GET_ALL_SITE: "/backend/api/site/getall",
  ADD_SITE: "/backend/api/site/add",
  EDIT_SITE: "/backend/api/site/edit",
  DELETE_SITE: "/backend/api/site/delete",

  //HIERARCHY
  GET_ALL_LIST: "/backend/api/model/view",
  ADD_HIERARCHY: "/backend/api/model/save",
  UPDATE_HIERARCHY: "/backend/api/model/edit",
  DELETE_HIERARCHY: "/backend/api/model/delete",
  GET_SUB_ALL_LIST: "/backend/api/model",
  ADD_SUB_HIERARCHY: "/backend/api/model/save/value",
  UPDATE_SUB_HIERARCHY: "/backend/api/model/edit/value",
  DELETE_SUB_HIERARCHY: "/backend/api/model/delete/value",

  //RESERVATION
  GET_MY_TASK: "/safety/api/mytask/list",
  CREATE_MY_TASK: "/safety/api/appointment/update",
  CREATE_MY_TASK_SAFETY: "/backend/api/reservation/update",
  UPDATE_FLOW_PERMIT: "/backend/api/permit/appointment/update",
  GET_HISTORY: "/safety/api/mytask/history",
  GET_FORMFLOW: "/backend/api/formflow/next",
  GET_USER_POSISION: "/backend/api/user/listbypositionid",
  EXPORT_MEDICAL_RECORD: "/safety/api/appointment/record/export",
  SUBMIT_MEDICAL: "/safety/api/appointment/saverecord",
  REJECT_RESERVATION_SAFETY: "/backend/api/reservation/reject",
  REJECT_APPOITMENT_HEALTH: "/safety/api/appointment/reject",
  TIMELINE_STATUS: "/backend/api/formflow/timeline",
  REASSIGN_RESERVATION_TASK: "/backend/api/formflow/reassign",
  TIMELINE_FORMFLOW_LIST: "/backend/api/formflow/timeline",
  SAFETY_REJECTED: "/backend/api/reservation/reject",
  HEALTH_REJECTED: "/safety/api/appointment/reject",
  DUMMY_GET_MY_TASK: "http://localhost:8080/myTask",
  ANOTHER_DUMMY_GET_MY_TASK: "http://localhost:8080/anotherMyTask",

  // FormFlow
  GET_FORM_FLOW: "/backend/api/formflow/getall/pagination",
  DELETE_FORM_FLOW: "/backend/api/formflow/delete",
  RESTORE_FORM_FLOW: "/backend/api/formflow/restore/",
  CREATE_FORM_FLOW: "/backend/api/formflow/create",
  GET_DETAIL_FORM_FLOW: "/backend/api/formflow/getbyid",
  UPDATE_FORM_FLOW: "/backend/api/formflow/update",
  SEARCH_FORM_FLOW: "/backend/api/formflow/getall/pagination",

  GET_DETAIL_MEDICAL: "/safety/api/appointment/medical/detail",
  GET_DETAIL_USER: "/backend/api/user/information",
  GET_USER: "/auth/user/detail/",
  LIST_MEDICAL: "/safety/api/appointment/medical/list",
  LIST_MEDICAL_CHECK_UP: "/safety/api/annualmcu/list",
  DELETE_MEDICAL_CHECK_UP: "/safety/api/annualmcu/delete",
  CREATE_MEDICAL_CHECK_UP: "/safety/api/annualmcu/save",
  DETAIL_MEDICAL: "/safety/api/appointment/medical/detailweb",
  MEDICINE: "/safety/api/appointment/medicationByReservation",
  MEDICINE_SUBMIT: "/safety/api/appointment/medication/confirm",
  MEDICINE_LIST: "/safety/api/medical/medicinelist",
  HEALTH_LETTER: "/safety/api/medicalprint/healthletter",
  SICK_LETTER: "/safety/api/medicalprint/sickletter",
  REFERRAL_LETTER: "/safety/api/medicalprint/referralletter",
  ANTIGEN_RESULT: "/safety/api/medicalprint/antigenresult",
  GUARANTEE_LETTER: "/safety/api/medicalprint/guaranteeletter",
  REIMBURSEMENT: "/safety/api/medicalprint/reimbursementletter",

  //COMPANY
  GET_ALL_COMPANY: "/backend/api/company/getall/pagination",
  GET_COMPANY_BY_ID: "/auth/user/role",
  CREATE_COMPANY: "/backend/api/company/create",
  UPDATE_COMPANY: "/backend/api/company/edit",
  DELETE_COMPANY: "/backend/api/company/delete",

  //ROLE
  GET_ALL_ROLE: "/auth/user/roles",
  GET_ALL_ROLE_LIST: "/auth/user/role/list",
  GET_ALL_ARCHIVE_ROLE: "/auth/user/role/archives",
  GET_ROLE_BY_ID: "/auth/user/role/detail",
  CREATE_ROLE: "/auth/user/role/save",
  UPDATE_ROLE: "/auth/user/role/edit",
  DELETE_ROLE: "/auth/user/role/delete",
  RESTORE_ROLE: "/auth/user/role/restore",
  LIST_ROLE: "/backend/api/user/listbyroleid",

  //Health
  LIST_MENU: "/backend/api/formflow/addhoc/getbyuser",
  LIST_HOME_ADDHOC: "/backend/api/formflow/home/addhoc/getbyuser",
  RESERVATION_LIST: "/safety/api/appointment/list",
  RESERVATION_HISTORY: "/safety/api/appointment/history/list",
  SAFETY_RESERVATION_LIST: "/backend/api/reservation/list",
  SAFETY_RESERVATION_HISTORY: "/backend/api/reservation/history/list",
  RESERVATION_TYPE: "/backend/api/formflow/getListEligible",
  LIST_PATIENT: "/backend/api/user/childuserlist",
  CREATE_RESERVATION: "/safety/api/appointment/save",
  CREATE_SAFETY_RESERVATION: "/backend/api/reservation/save",
  DETAIL_RESERVATION: "/safety/api/appointment/detail",
  DETAIL_SAFETY_RESERVATION: "/backend/api/reservation/detail",
  DETAIL_SAFETY_HISTORY: "/backend/api/reservation/history/detail",
  DETAIL_HISTORY_RESERVATION: "/safety/api/appointment/history/detail",
  LIST_TIME: "/safety/api/appointment/quota",
  LIST_MEDICAL_CENTER: "/safety/api/appointment/listreservation",
  LIST_HISTORY_MEDICAL: "/safety/api/appointment/listdonereservation",
  DETAIL_MEDICAL_CENTER: "/safety/api/appointment/detail",
  // REPORT
  GET_REPORT_FORMDATA: "/backend/api/report/get/formdata",
  DUMMY_RESERVATION_LIST: "http://localhost:8080/assignmentList",
  DUMMY_RESERVATION_DETAIL: "http://localhost:8080/assignmentDetail",

  // RANK CONFIGURATION
  GET_LIST_TYPE_OF_WORK: "/backend/api/rank/list",
  ADD_TYPE_OF_WORK: "/backend/api/rank/savetype",
  EDIT_TYPE_OF_WORK: "/backend/api/rank/edit",
  DELETE_TYPE_OF_WORK: "/backend/api/rank/delete",

  GET_LIST_RANK_CONFIG: "/backend/api/rank/list/rank",
  ADD_RANK_CONFIG: "/backend/api/rank/save/rank",
  EDIT_RANK_CONFIG: "/backend/api/rank/edit/rank",
  DELETE_RANK_CONFIG: "/backend/api/rank/delete/rank",

  GET_LIST_RANK_DETAIL: "/backend/api/rank/list/detail",
  ADD_RANK_DETAIL: "/backend/api/rank/save/detail",
  EDIT_RANK_DETAIL: "/backend/api/rank/edit/detail",
  DELETE_RANK_DETAIL: "/backend/api/rank/delete/detail",

  // MASTER
  GET_MASTER_KEY: "/backend/api/master/view/pagination",
  GET_All_MASTER: "/backend/api/master/getall",
  GET_MASTER_KEYS: "/backend/api/master/view/pagination",
  GET_MASTER_KEYS_CATEGORY: "/backend/api/master/view/subcategory/pagination",
  CREATE_MASTER_KEY: "/backend/api/master/save",
  CREATE_MASTER_KEYS: "/backend/api/master/save/subcategory",
  UPDATE_MASTER_KEY: "/backend/api/master/edit/subcategory",
  GET_MASTER_KEY_DETAIL: "/backend/api/master/get",
  DELETE_MASTER_KEY: "/backend/api/master/delete/subcategory",
  RESTORE_MASTER_KEY: "/backend/api/master/restore/subcategory",
  DELETE_MASTER_KEY_VALID: "/backend/api/master/deletevalid/subcategory",
  // -----
  GET_ALL_MASTER_ATT: "/backend/api/attendance/master/get",
  GET_MASTER_ATT_DETAIL: "/backend/api/attendance/masterget",
  CREATE_MASTER_ATT: "/backend/api/attendance/mastersave",
  UPDATE_MASTER_ATT: "/backend/api/attendance/masteredit",
  DELETE_MASTER_ATT: "/backend/api/attendance/master",
  GET_ALL_DETAIL_ATT: "/backend/api/attendance/detail/get",
  GET_DETAIL_ATT_DATA: "/backend/api/attendance/detailget",
  CREATE_DETAIL_ATT: "/backend/api/attendance/detailsave",
  UPDATE_DETAIL_ATT: "/backend/api/attendance/detailedit",
  DELETE_DETAIL_ATT: "/backend/api/attendance/detail",
  // -----
  GET_ALL_LOCATION: "/backend/api/location/getall",
  GET_LIST_LOC: "/backend/api/location/listLoc",
  GET_ALL_SITES: "/backend/api/site/getall",
  // GET_LOCATION_BY_ID: "/backend/api/location/getbyid",
  GET_LOCATION_BY_ID: "/backend/api/location",
  CREATE_DATA_LOCATION: "/backend/api/location/add",
  UPDATE_DATA_LOCATION: "/backend/api/location/edit",
  DELETE_DATA_LOCATION: "/backend/api/location/delete",
  // -----
  GET_LIST_EQUIP: "/backend/api/master/equipment/listEquip",

  //Tools
  GET_ALL_TOOLS: "/backend/api/tool/getall",
  CREATE_DATA_TOOLS: "/backend/api/tool/add",
  UPDATE_DATA_TOOLS: "/backend/api/tool/edit",
  DELETE_DATA_TOOLS: "/backend/api/tool/delete",

  //Equipment
  GET_ALL_EQUIPMENT: "backend/api/master/equipment/getall",
  GET_CHILD_EQUIPMENT: "/backend/api/master/equipment/detail/list/pagination",
  GET_DETAIL_CHILD_EQUIPMENT: "/backend/api/master/equipment/detail/get",
  GET_DETAIL_EQUIPMENT: "backend/api/master/equipment/get",
  CREATE_DATA_EQUIPMENT: "backend/api/master/equipment/create",
  UPDATE_DATA_EQUIPMENT: "backend/api/master/equipment/update",
  DELETE_DATA_EQUIPMENT: "backend/api/master/equipment/delete",
  LIST_CHILD_EQUIPMENT: "/backend/api/master/equipment/detail/list/pagination",
  LIST_CATEGORY_QUIPMENT: "/backend/api/master/equipment/category/list",
  CREATE_CHILD_EQUIPMENT: "/backend/api/master/equipment/detail/add",
  UPDATE_CHILD_EQUIPMENT: "/backend/api/master/equipment/detail/update",
  DELETE_CHILD_EQUIPMENT: "/backend/api/master/equipment/detail/delete/",
  CREATE_CATEGORY_EQUIPMENT: "/backend/api/master/equipment/category/add",
  DELETE_CATEGORY_EQUIPMENT: "/backend/api/master/equipment/category/delete/",
  GET_LOG_DETAIL_EQUIPMENT: "backend/api/master/equipment/detail/equipmentloglist",

  // Location type
  GET_ALL_LOCATION_TYPE: "backend/api/location/allType",
  CREATE_LOCATION_TYPE: "backend/api/location/type/add",
  DELETE_LOCATION_TYPE: "backend/api/location/delType",
  GET_DETAIL_LOCATION_TYPE: "backend/api/location/type",
  UPDATE_DETAIL_LOCATION_TYPE: "backend/api/location/type/edit",

  //Equipment Type
  GET_EQUIPMENT_TYPE: "/backend/api/master/equipment/getall",
  CREATE_EQUIPMENT_TYPE: "/backend/api/master/equipment/create",
  UPDATE_EQUIPMENT_TYPE: "/backend/api/master/equipment/update",
  DELETE_EQUIPMENT_TYPE: "/backend/api/master/equipment/delete",

  //Sublocation
  GET_ALL_SUBLOCATION: "/backend/api/sublocation/getall/bylocationid",
  GET_BY_ID_SUBLOCATION: "backend/api/sublocation",
  CREATE_SUBLOCATION: "backend/api/sublocation/add",
  DELETE_SUBLOCATION: "backend/api/sublocation/delete",
  UPDATE_SUBLOCATION: "backend/api/sublocation/edit",
  GET_SUBLOCATION_BY_ID: "backend/api/sublocation/getall/bylocationid",

  //Notification
  GET_LIST_TYPE: "backend/api/notification/type/list",
  GET_LIST_NOTIF: "backend/api/notification/list",
  READ_ID_NOTIF: "backend/api/notification/update/read/",
  READ_BADGE_NOTIF: "backend/api/notification/update/badges",

  //Safety
  SAVE_PERMIT: "/backend/api/permit/save",
  UPDATE_PERMIT: "/backend/api/permit/update",
  DELETE_PERMIT: "/backend/api/permit/delete",
  GET_PREQUALIFICATION_DATA: "/backend/api/permit/preQualificationFromContractor",
  GET_DETAIL_TYPE: "/backend/api/rank/get",
  WORK_AGREEMENT: "/backend/api/permit/workAgreement",
  SAFETY_MANDATORY: "/backend/api/permit/safetyMandatoryControl",
  DOCUMENT_LIST: "/backend/api/permit/documentMaster",
  UPLOAD_FILE: "/backend/api/file/upload",
  PERMIT_TO_WORK: "/backend/api/permit/pagination",
  PROJECT_PERMIT_TO_WORK: "/backend/api/permit/project/pagination",
  EXTEND_PERMIT: "/backend/api/permit/extend",
  DELETE_PERMIT_TO_WORK: "/backend/api/permit/project/delete",
  // CREATE_PERMIT_TO_WORK: "/backend/api/permit/pagination",
  UPDATE_PERMIT_TO_WORK: "/backend/api/permit/update",
  GET_DETAIL_PERMIT: "/backend/api/permit/detail",
  GET_SPESIAL_PERMIT_TO_WORK: "/backend/api/permit/special/form/get",
  GET_SPESIAL_PERMIT_CHECKLIST_TO_WORK: "/backend/api/permit/special",
  GET_LIST_SPESIAL_PERMIT_TO_WORK: "/backend/api/permit/special",
  GET_LIST_CONFINED_SPESIAL_PERMIT_TO_WORK: "/backend/api/permit/special/form/hour/list",
  SAVE_SPESIAL_PERMIT: "/backend/api/permit/special/form/update",
  GET_SPESIAL_PERMIT_BY_DAY: "/backend/api/permit/special/form/hour/list",
  CREATE_SPESIAL_PERMIT_BY_DAY: "/backend/api/permit/special/form/hour",
  GET_HOUR_SPESIAL_PERMIT_TO_WORK: "/backend/api/permit/special/form/hour/get",
  GET_BY_DAY_PERMIT_BY_DAY: "/backend/api/permit/special/form/hour/get",
  DELETE_PERMIT_BY_DAY: "/backend/api/permit/special/form/hour",

  //dashboard
  DASHBOARD_SUMMARY: "/safety/api/appointment/summary",
  PATIENT_DEPARTEMENT: "/backend/api/dashboard/patient/department",
  TOP_PATIENT: "/backend/api/dashboard/patient/diagnosa",
  TOP_CASE: "/safety/api/appointment/case",
  SUMMARY_LIST: "/safety/api/appointment/summary/list",
  // detail user
  USER_DETAIL: "/backend/api/user/detail",

  // Medicine
  CREATE_MEDICINE: "/safety/api/medical/save",
  LIST_MEDICINE: "/safety/api/medical/medicineinventorylist",
  UPDATE_MEDICINE: "/safety/api/medical/edit",
  UPDATE_STOCK_MEDICINE: "/safety/api/medical/stock/edit",
  TYPE_LIST_MEDICINE: "/safety/api/medical/medicine/type/list",
  DELETE_MEDICINE: "/safety/api/medical/delete/",
  LOG_MEDICINE: "/safety/api/medical/medicineloglist",
  GET_DETAIL_MEDICINE: "/safety/api/medical/detail",
  //medical inventory
  CREATE_CATEGORY_INVENTORY: "/safety/api/inventory/category/save",
  GET_CATEGORY_INVENTORY: "/safety/api/inventory/category/list",
  DELETE_CATEGORY_INVENTORY: "/safety/api/inventory/category/delete/",
  UPDATE_CATEGORY_INVENTORY: "/safety/api/inventory/category/update",
  GET_INVENTORY_LIST: "/safety/api/medical/inventory/list",
  CREATE_INVENTORY: "/safety/api/medical/inventory/save",
  UPDATE_INVENTORY: "/safety/api/medical/inventory/update",
  DELETE_INVENTORY: "/safety/api/medical/inventory/delete/",
  GET_DETAIL_INVENTORY: "/safety/api/medical/inventory/detail/",
  EXPORT_MEDICINE: "/safety/api/medical/medicine/export",
  // bulk upload
  GET_BULK_UPLOAD_USER_LIST: "/backend/api/user/uploaduser/list",
  GET_BULK_UPLOAD_MEDICINE_LIST: "/safety/api/medical/bulkupload/list",
  BULK_UPLOAD_USER: "/backend/api/user/uploaduser",
  BULK_UPLOAD_MEDICINE: "/safety/api/medical/bulkupload",
  BULK_UPLOAD_USER_FAILED_LIST: "/backend/api/user/uploaduser/detail/list",
  BULK_UPLOAD_MEDICINE_FAILED_LIST: "/safety/api/medical/bulkupload",
  // articles
  CREATE_ARTICLES: "/backend/api/article/add",
  EDIT_ARTICLES: "/backend/api/article/update",
  PIN_ARTICLES: "/backend/api/article/pinned/list",
  UPDATE_PIN_ARTICLES: "/backend/api/article/pinned/update",
  GET_CATEGORY_ARTICLES: "/backend/api/article/category/list",
  GET_LIST_ARTICLES: "/backend/api/article/list/pagination",
  DELETE_LIST_ARTICLES: "/backend/api/article/delete",

  // Home articles
  LIST_ARTICLES: "/backend/api/article/list/pagination",
  GET_DETAIL_ARTICLES: "/backend/api/article/detail/",
  ADD_CATEGORY_ARTICLE: "/backend/api/article/category/add",
  GET_COMMENT: "/backend/api/article/comment/list",
  ADD_COMMENT: "/backend/api/article/comment/save",
  DELETE_COMMENT: "/backend/api/article/comment/delete/",
  UPDATE_COMMENT: "/backend/api/article/comment/edit",
  //Sidebar
  GET_MYTASK_COUNTER: "/safety/api/mytask/counter",

  // Risk assessment
  DETAIL_RISK: "backend/api/assessment/glossary/detail",

  // User SIO
  ADD_SIO: "/backend/api/user/sio/create",
  UPDATE_SIO: "/backend/api/user/sio/update",
  GET_SIO: "/backend/api/user/sio/list",
  DELETE_SIO: "/backend/api/user/sio/delete/",
  // workflow categorization
  CREATE_WORKFLOW_CATEGORIZATION: "/backend/api/formflow/category/save",
  GET_WORKFLOW_CATEGORIZATION: "/backend/api/formflow/category/pagination",
  DELETE_WORKFLOW_CATEGORIZATION: "/backend/api/formflow/category/delete",
  UPDATE_WORKFLOW_CATEGORIZATION: "/backend/api/formflow/category/update",

  // Undo status
  SET_UNDO_STATUS: "/safety/api/appointment/status/undo",

  // Procedure Config
  GET_PROCEDURE_CONFIG_LIST: "/backend/api/procedure/getall",
  DELETE_PROCEDURE_CONFIG_LIST: "/backend/api/procedure/delete/",
  ADD_PROCEDURE_CONFIG: "/backend/api/procedure/create",
  GET_PROCEDURE_CONFIG_DETAIL: "/backend/api/procedure/detail",
  UPDATE_PROCEDURE_CONFIG: "/backend/api/procedure/update",

  // Print ncr
  PRINT_NCR: "/safety/api/safetyprint/ncrletter",

  // safety voice scoring
  SAFETY_VOICE_SCORING_LIST: "/backend/api/reservation/listreservation",
  SAFETY_VOICE_SCORING_DETAIL: "/backend/api/reservation/safetyvoice/detailweb",
  // hiradc
  DUPLICATE_HIRADC: "/backend/api/hiradc/duplicate",
  CREATE_HIRADC: "/backend/api/hiradc/create",
  LIST_HIRADC: "/backend/api/hiradc/list",
  UPDATE_HIRADC: "backend/api/hiradc/update",
  DETAIL_HIRADC: "/backend/api/hiradc/getbyid",
  DELETE_HIRADC: "/backend/api/hiradc/delete",
  HIRADC_ACTIVITY: "/backend/api/model/hiradc_activity",
  HIRADC_AREA: "/backend/api/location/getall",
  POTENTIAL_HIRADC: "/backend/api/model/potential_incident",
  ARCHIVED_HIRADC: "/backend/api/hiradc/archived",
  APPROVE_HIRADC: "/backend/api/hiradc/request/create",
  EXPORT_HIRADC: "/backend/api/hiradc/export",
  HIRADC_LOG: "/backend/api/hiradc/log/all",
  HIRADC_BY_MY_TASK: "/backend/api/hiradc/getbyassignment",
  HIRADC_LIST_APPROVED: "/backend/api/hiradc/approved",
  HIRADC_DUPLICATE: "/backend/api/hiradc/duplicate",
  GET_DETAIL_DEPARTMENT: "/backend/api/user/detail",
  GET_CONTROL_TYPE_HIRADC: "/backend/api/hiradc/controltype/all",
  //Dynamic Dashboard
  DASHBOARD_LIST: "/backend/api/dashboard/list",
  CREATE_DASHBOARD: "/backend/api/dashboard/save",
  DELETE_DASHBOARD: "/backend/api/dashboard/delete/",
  UPDATE_DASHBOARD: "/backend/api/dashboard/update",
  UPDATE_PRIORITY_DASHBOARD: "/backend/api/dashboard/priority/update",
  DASHBOARD_DETAIL: "/backend/api/dashboard/get",
  DASHBOARD_GET_ALL: "/backend/api/dashboard/listall",
};

export default urlsEndpoint;
