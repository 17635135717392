import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { actions as userActions } from "./_actions";
import { IGetAllLocData, types } from "./_types";
import { actions } from "./_actions";
import { debug } from "../../helpers/logger";

export const watcherSafety = () => [
  takeLatest(types.GET_ALL_LOCATION_REQUEST, workerGetAllLoc),
  takeLatest(types.CREATE_PERMIT_REQUEST, workerCreatePermit),
  takeLatest(types.UPDATE_PERMIT_REQUEST, workerUpdatePermit),
  takeLatest(types.DELETE_PERMIT_REQUEST, workerDeletePermit),
  takeLatest(types.GET_PREQUALIFICATION_REQUEST, workerGetPrequalification),
  takeLatest(types.WORK_AGREEMENT_REQUEST, workerWorkAgreement),
  takeLatest(types.GET_DETAIL_TYPE_REQUEST, workerGetTypeDetail),
  takeLatest(types.SAFETY_MANDATORY_REQUEST, workerSafetyMandatory),
  takeLatest(types.DOCUMENT_LIST_REQUEST, workerDocumentList),
  takeLatest(types.UPLOAD_FILE_REQUEST, workerUploadFile),
  takeLatest(types.LIST_PERMIT_REQUEST, workerGetListPtw),
  takeLatest(types.LIST_PROJECT_PERMIT_REQUEST, workerGetListProjectPtw),
  takeLatest(types.EXTEND_REQUEST, workerGetExtendPermit),
  takeLatest(types.GET_DETAIL_PERMIT_REQUEST, workerGetPermitDetail),
  takeLatest(types.CREATE_SPESIAL_PERMIT_REQUEST, workerCreateSpesialPermit),
  takeLatest(types.GET_SPESIAL_PERMIT_REQUEST, workerGetSpesialPermit),
  takeLatest(types.GET_SPESIAL_PERMIT_CHECKLIST_REQUEST, workerGetSpesialChecklist),
  takeLatest(types.GET_BY_DAY_PERMIT_REQUEST, workerGetPermitByDay),
  takeLatest(types.CREATE_BY_DAY_PERMIT_REQUEST, workerCreateSpesialPermitByDay),
];

function* workerGetAllLoc({ payload }: IDefaultRequestAction<IGetAllLocData>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_ALL_LOCATION,
      method: "post",
      data: {
        start: payload?.start,
        limit: payload?.limit,
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });

    yield put(userActions.getAllLoc.success(response));
  } catch (err) {
    yield put(userActions.getAllLoc.failure(err));
  }
}

function* workerCreatePermit({ payload, onSuccess, onFailure }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.SAVE_PERMIT);
  try {
    const response = yield call(http.post, payload);
    if (response.result) {
      yield callback(onSuccess, response);
    } else {
      yield callback(onFailure, response);
    }
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetPermitDetail({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.GET_DETAIL_PERMIT);
  try {
    const response = yield call(http.get, payload);
    if (response.result) {
      yield callback(onSuccess, response);
    } else {
      yield callback(onFailure, response);
    }
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerUpdatePermit({ payload, onSuccess, onFailure }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_PERMIT);
  try {
    const response = yield call(http.post, payload);
    if (response.result) {
      yield callback(onSuccess, response);
    } else {
      yield callback(onFailure, response);
    }
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerDeletePermit({ payload, onSuccess, onFailure }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.DELETE_PERMIT);
  try {
    const response = yield call(http.delete, payload.id);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetPrequalification({ onSuccess, onFailure }: any): any {
  const http = new HttpService(urlsEndpoint.GET_PREQUALIFICATION_DATA);
  try {
    const response = yield call(http.get, "");
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerWorkAgreement({ onSuccess, onFailure }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.WORK_AGREEMENT);
  try {
    const response = yield call(http.getmock);
    if (response.result) {
      yield callback(onSuccess, response);
    } else {
      yield callback(onFailure, response);
    }
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetTypeDetail({ payload, onSuccess, onFailure }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.GET_DETAIL_TYPE);
  try {
    const response = yield call(http.get, payload);
    if (response.result) {
      yield callback(onSuccess, response);
    } else {
      yield callback(onFailure, response);
    }
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerSafetyMandatory({ onSuccess, onFailure }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.SAFETY_MANDATORY);
  try {
    const response = yield call(http.getmock);
    if (response.result) {
      yield callback(onSuccess, response);
    } else {
      yield callback(onFailure, response);
    }
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetExtendPermit({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.EXTEND_PERMIT);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerDocumentList({ onSuccess, onFailure }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.DOCUMENT_LIST);
  try {
    const response = yield call(http.getmock);
    if (response.result) {
      yield callback(onSuccess, response);
    } else {
      yield callback(onFailure, response);
    }
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerUploadFile({ payload, onSuccess, onFailure }: IDefaultRequestAction<any>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.upload, {
      url: urlsEndpoint.UPLOAD_FILE,
      method: "post",
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetListPtw({ payload, onSuccess, onFailure }: any): any {
  const http = new HttpService(urlsEndpoint.PERMIT_TO_WORK);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getListPermit.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListPermit.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetListProjectPtw({ payload, onSuccess, onFailure }: any): any {
  const http = new HttpService(urlsEndpoint.PROJECT_PERMIT_TO_WORK);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getListPermit.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListPermit.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetSpesialPermit({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.GET_SPESIAL_PERMIT_TO_WORK);
  try {
    const response = yield call(http.get, payload);
    yield put(actions.getSpesialWorkPermit.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getSpesialWorkPermit.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetSpesialChecklist({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.GET_SPESIAL_PERMIT_CHECKLIST_TO_WORK);
  try {
    const response = yield call(http.get, payload);
    debug("DATAS SAGA", response);
    yield put(actions.getSpesialWorkPermitChecklist.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getSpesialWorkPermitChecklist.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerCreateSpesialPermit({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.SAVE_SPESIAL_PERMIT);
  try {
    const response = yield call(http.post, payload);
    if (response.result) {
      yield callback(onSuccess, response);
    } else {
      yield callback(onFailure, response);
    }
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetPermitByDay({ payload, onSuccess, onFailure }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.GET_SPESIAL_PERMIT_BY_DAY);
  try {
    const response = yield call(http.get, payload);
    yield put(actions.getByDaySpesialPermit.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerCreateSpesialPermitByDay({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.CREATE_SPESIAL_PERMIT_BY_DAY);
  try {
    const response = yield call(http.post, payload);
    if (response.result) {
      yield callback(onSuccess, response);
    } else {
      yield callback(onFailure, response);
    }
  } catch (err) {
    yield callback(onFailure, err);
  }
}
