import { types } from "./_types";

const initialState = {
  reservation: [],
  reservationHistory: [],
  listReservationType: [],
  detailReservationHistory: [],
  listMedical: [],
  listHistoryMedical: [],
  timeline: [],
  riskDetail: [],
  detailReservation: null,
  auth: {},
  data: null,
  listPatient: null,
  pagination: null,
  paginationHistory: null,
  actionStatus: "initial",
  moreActionStatus: {
    addUser: "initial",
  },
  archiveData: null,
  isLoading: true,
  isLoadingRisk: true,
};

export const healthReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_RESERVATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_RESERVATION_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_RESERVATION_SUCCESS:
      return {
        ...state,
        reservation: action.data.data.Appointment,
        pagination: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_RESERVATION_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_RESERVATION_TYPE_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_RESERVATION_TYPE_SUCCESS:
      return {
        ...state,
        listReservationType: action.data.data.formType,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_RESERVATION_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_RESERVATION_HISTORY_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_RESERVATION_HISTORY_SUCCESS:
      return {
        ...state,
        reservationHistory: action.data.data.reservation,
        paginationHistory: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_RESERVATION_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_RESERVATION_DETAIL_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_RESERVATION_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.data.data.formFlow,
        detailReservation: action.data.data.reservation,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_RESERVATION_DETAIL_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_RESERVATION_DETAIL_HISTORY_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_RESERVATION_DETAIL_HISTORY_SUCCESS:
      return {
        ...state,
        detailReservationHistory: action.data.data.reservation,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_PATIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_PATIENT_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_PATIENT_SUCCESS:
      return {
        ...state,
        listPatient: action.data.data.user,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_MEDICAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_MEDICAL_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_MEDICAL_SUCCESS:
      return {
        ...state,
        listMedical: action.data.data.Appointment,
        actionStatus: action.actionStatus,
        pagination: action.data.pagination,
        isLoading: false,
      };
    case types.GET_HISTORY_MEDICAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_HISTORY_MEDICAL_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_HISTORY_MEDICAL_SUCCESS:
      return {
        ...state,
        listHistoryMedical: action.data.data.Appointment,
        actionStatus: action.actionStatus,
        paginationHistory: action.data.pagination,
        isLoading: false,
      };
    case types.GET_DETAIL_MEDICAL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_DETAIL_MEDICAL_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_DETAIL_MEDICAL_SUCCESS:
      return {
        ...state,
        data: action.data.data.formFlow,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_TIMELINE_STATUS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_TIMELINE_STATUS_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_TIMELINE_STATUS_SUCCESS:
      return {
        ...state,
        timeline: action.data.data.timeline,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_DETAIL_RISK_REQUEST:
      return {
        ...state,
        isLoadingRisk: true,
      };
    case types.GET_DETAIL_RISK_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoadingRisk: false,
      };
    case types.GET_DETAIL_RISK_SUCCESS:
      return {
        ...state,
        riskDetail: action.data.dataList,
        actionStatus: action.actionStatus,
        isLoadingRisk: false,
      };
    default:
      return state;
  }
};
