import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { IGetRoleRequestData, types as roleTypes } from "./_types";

export const actions = {
  getRole: {
    // request: (requestData: IDefaultRequestParams<IGetRoleRequestData>) => ({
    //   ...requestData,
    //   type: roleTypes.GET_ALL_ROLE_REQUEST,
    //   actionStatus: DefaultActionStatus.REQUEST,
    // }),
    request: (payload: IDefaultRequestParams<IGetRoleRequestData>) => ({
      type: roleTypes.GET_VIEW_ROLE_REQUEST,
      ...payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: roleTypes.GET_VIEW_ROLE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: roleTypes.GET_VIEW_ROLE_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getArchiveRole: {
    // request: (requestData: IDefaultRequestParams<IGetRoleRequestData>) => ({
    //   ...requestData,
    //   type: roleTypes.GET_ALL_ROLE_REQUEST,
    //   actionStatus: DefaultActionStatus.REQUEST,
    // }),
    request: (payload: any) => ({
      type: roleTypes.GET_ALL_ARCHIVE_ROLE_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: roleTypes.GET_ALL_ARCHIVE_ROLE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: roleTypes.GET_ALL_ARCHIVE_ROLE_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getRoleDetail: {
    request: (requestData: any) => ({
      ...requestData,
      type: roleTypes.GET_ROLE_BY_ID_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: roleTypes.GET_ROLE_BY_ID_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: roleTypes.GET_ROLE_BY_ID_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createRole: {
    request: (requestData: any) => ({
      ...requestData,
      type: roleTypes.CREATE_ROLE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: roleTypes.CREATE_ROLE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: roleTypes.CREATE_ROLE_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  updateRole: {
    request: (requestData: any) => ({
      ...requestData,
      type: roleTypes.UPDATE_ROLE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: roleTypes.UPDATE_ROLE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: roleTypes.UPDATE_ROLE_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};
export async function deleteRoleAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_ROLE);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.result,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
export async function restoreRoleAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.RESTORE_ROLE);
  try {
    const response = await http.put(id, "");
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
