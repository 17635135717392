import { Popover } from "antd";
import clsx from "clsx";
import { FC, ReactNode } from "react";
import { FORM_ICON } from "../../assets/images";
import LOADING from "../../assets/images/Icon/loading.svg";
import { capitalize } from "../../helpers";
import styles from "./styles.module.less";
import useTranslateShortcut from "../../helpers/hooks/useTranslateShortcut";

interface ButtonPropsComponent {
  onClick?: (values: any) => void;
  title?: string;
  image?: any;
  submit?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  iconButton?: boolean;
  isLoading?: boolean;
  noBorder?: boolean;
  type?: number;
  width?: string;
  dataMenu?: any[];
  style?: React.CSSProperties;
}

const ButtonForm: FC<ButtonPropsComponent> = ({
  onClick,
  title,
  image,
  noBorder = false,
  submit = true,
  disabled = false,
  icon,
  iconButton = false,
  isLoading = false,
  type = 0,
  width = "unset",
  dataMenu,
  ...other
}) => {
  title = title?.toLowerCase();

  const { translate } = useTranslateShortcut({});

  const _withPopover = (child: ReactNode) => {
    if (dataMenu) {
      return (
        <Popover
          placement="bottomRight"
          arrowContent
          trigger="click"
          content={
            dataMenu.length > 0 &&
            dataMenu.map((item: any, i: number) => (
              <div key={i} onClick={() => onClick && onClick(item)} className={styles.btn_list}>
                <div className={styles.icon}>
                  <img className={styles.full} src={item.icon ? item.icon : FORM_ICON} alt="" />
                </div>
                <div>{item.flowName}</div>
              </div>
            ))
          }
        >
          {child}
        </Popover>
      );
    }
    return child;
  };

  return (
    <>
      {_withPopover(
        <button
          type={submit ? "submit" : "button"}
          className={clsx(styles.button_form_wrap, {
            [styles.button_form_wrap_1]: type === 1,
            [styles.button_form_wrap_2]: type === 2,
            [styles.button_form_wrap_3]: type === 3,
            // [styles.button_form_wrap_approved]: title === "Approve",
            [styles.button_form_wrap_reject]: title === "reject",
            [styles.button_form_wrap_close]:
              title === "no" || title === "exit" || title === "cancel",
            [styles.button_form_wrap_save_medical]: title === translate("save").toLowerCase(),
            [styles.button_form_wrap_disabled]: disabled,
            [styles.button_form_wrap_icon]: title === "",
            [styles.button_form_wrap_noBorder]: noBorder === true,
          })}
          onClick={dataMenu ? () => null : disabled || isLoading ? () => null : onClick}
          style={{ width: width }}
          {...other}
        >
          {isLoading && <img src={LOADING} alt="4" style={{ width: 20, height: 20 }} />}
          {iconButton && icon}
          {image && <img src={image} alt="" style={{ marginRight: title ? 4 : 0 }} />}
          {title && capitalize(title)}
        </button>
      )}
    </>
  );
};

export default ButtonForm;
