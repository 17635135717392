import HttpService from "../../../services/http";
import urlsEndpoint from "../../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../../store/defaultTypes";
import {
  IPayloadCategoryInventory,
  IPayloadUpdateInventory,
  types as medicalInventory,
} from "./_types";

export async function createCategoryInventoryAction(
  payload: IPayloadCategoryInventory
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.CREATE_CATEGORY_INVENTORY);
  try {
    const response = await http.post(payload);
    const successResponse = {
      error: true,
      data: undefined,
    };
    if (response.result) {
      const successResponse = {
        success: true,
        data: response?.message,
      };
      return successResponse;
    }
    if (!response.result && response.message === "The name already exists") {
      const successResponse = {
        error: true,
        success: false,
        data: { message: response?.message },
      };
      return successResponse;
    }
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateCategoryInventoryAction(
  payload: IPayloadCategoryInventory
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.UPDATE_CATEGORY_INVENTORY);
  try {
    const response = await http.post(payload);
    const successResponse = {
      error: true,
      data: undefined,
    };
    if (response.result) {
      const successResponse = {
        success: true,
        data: response?.message,
      };
      return successResponse;
    }
    if (!response.result && response.message === "The name already exists") {
      const successResponse = {
        error: true,
        success: false,
        data: { message: response?.message },
      };
      return successResponse;
    }
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function deleteCategoryInventoryAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_CATEGORY_INVENTORY);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.message,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function uploadPhotoProfile(
  payload: any
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await http.upload({
      url: urlsEndpoint.UPLOAD_FILE,
      method: "post",
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const successResponse = {
      success: true,
      data: response?.data?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function createInventoryAction(
  payload: IPayloadUpdateInventory
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.CREATE_INVENTORY);
  try {
    const response = await http.post(payload);
    const successResponse = {
      error: true,
      data: undefined,
    };
    if (response.result) {
      const successResponse = {
        success: true,
        data: response?.message,
      };
      return successResponse;
    }
    if (!response.result && response.message === "The code already exists") {
      const successResponse = {
        error: true,
        success: false,
        data: { message: response?.message },
      };
      return successResponse;
    }
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateInventoryAction(
  payload: IPayloadUpdateInventory
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.UPDATE_INVENTORY);
  try {
    const response = await http.post(payload);
    const successResponse = {
      error: true,
      data: undefined,
    };
    if (response.result) {
      const successResponse = {
        success: true,
        data: response?.message,
      };
      return successResponse;
    }
    if (!response.result && response.message === "The code already exists") {
      const successResponse = {
        error: true,
        success: false,
        data: { message: response?.message },
      };
      return successResponse;
    }
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function deleteInventoryAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_INVENTORY);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.message,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getDetailInventoryAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await http.custom({
      url: `${urlsEndpoint.GET_DETAIL_INVENTORY}/${id}`,
      method: "get",
    });
    const successResponse = {
      success: true,
      data: response.data?.MedicalInventory,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export const medicalInventoryAction = {
  getCategoryInventoryList: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: medicalInventory.GET_CATEGORY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: medicalInventory.GET_CATEGORY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: medicalInventory.GET_CATEGORY_SUCCESS,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getInventoryList: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: medicalInventory.GET_INVENTORY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: medicalInventory.GET_INVENTORY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: medicalInventory.GET_INVENTORY_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};
