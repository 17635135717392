import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { homeArticleActions } from "./_actions";
import { types } from "./_types";

function* workerGetHomeListArticles({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.LIST_ARTICLES);
  try {
    const response = yield call(http.post, payload);
    yield put(homeArticleActions.getListArticles.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(homeArticleActions.getListArticles.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetHomePinArticles({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.PIN_ARTICLES);
  try {
    const response = yield call(http.get, "");
    const getIdsPinArticle = response?.data?.pinnedArticle?.map((item: any) => item.articleId);
    yield put(
      homeArticleActions.getPinArticles.success({ ...response, articleIds: getIdsPinArticle })
    );
    yield callback(onSuccess, response);
    const id = response?.data?.pinnedArticle[0]?.articleId;
    yield put(homeArticleActions.getDetailArticle.request({ payload: id }));
  } catch (err) {
    yield put(homeArticleActions.getPinArticles.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetPinDetailArticles({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.PIN_ARTICLES);
  try {
    const response = yield call(http.get, "");
    const getIdsPinArticle = response?.data?.pinnedArticle?.map((item: any) => item.articleId);
    yield put(
      homeArticleActions.getPinDetailArticles.success({ ...response, articleIds: getIdsPinArticle })
    );
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(homeArticleActions.getPinDetailArticles.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetDetailArticle({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: `${urlsEndpoint.GET_DETAIL_ARTICLES}${payload}`,
      method: "get",
    });
    yield put(homeArticleActions.getDetailArticle.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(homeArticleActions.getDetailArticle.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetCommentList({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_COMMENT);
  try {
    const response = yield call(http.post, payload);
    yield put(homeArticleActions.getCommentList.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(homeArticleActions.getCommentList.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerLoadMoreComment({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_COMMENT);
  try {
    const response = yield call(http.post, payload);
    yield put(homeArticleActions.loadMoreCommentList.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(homeArticleActions.loadMoreCommentList.failure(err));
    yield callback(onFailure, err);
  }
}

export const watcherHomeArticle = () => [
  takeLatest(types.GET_LIST_ARTICLES_REQUEST, workerGetHomeListArticles),
  takeLatest(types.GET_PIN_ARTICLES_REQUEST, workerGetHomePinArticles),
  takeLatest(types.GET_DETAIL_ARTICLE_REQUEST, workerGetDetailArticle),
  takeLatest(types.GET_PIN_DETAIL_ARTICLES_REQUEST, workerGetPinDetailArticles),
  takeLatest(types.GET_COMMENT_LIST_REQUEST, workerGetCommentList),
  takeLatest(types.LOAD_MORE_COMMENT_REQUEST, workerLoadMoreComment),
];
