export const types = {
  GET_CATEGORY_REQUEST: "medicalInventory/GET_CATEGORY_REQUEST",
  GET_CATEGORY_SUCCESS: "medicalInventory/SIGNIN_SUCCESS",
  GET_CATEGORY_FAILED: "medicalInventory/GET_CATEGORY_FAILED",
  GET_INVENTORY_REQUEST: "medicalInventory/GET_INVENTORY_REQUEST",
  GET_INVENTORY_SUCCESS: "medicalInventory/GET_INVENTORY_SUCCESS",
  GET_INVENTORY_FAILED: "medicalInventory/GET_INVENTORY_REQUEST",
};

export interface IPayloadUpdateInventory {
  id?: string | number;
  code: string;
  name: string;
  description: string;
  categoryId: number;
  expiredDate: string;
  condition: string;
  picture?: string | null;
}

export interface IPayloadCategoryInventory {
  id?: string | number;
  name: string;
  description: string;
}

export interface IPayloadGetProfile {
  userName: string;
  token: string;
}
