import { call, put, takeLatest } from "redux-saga/effects";

import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";

import { actions } from "./_actions";
import { types } from "./_types";

export const watcherRankConfig = () => [
  takeLatest(types.GET_LIST_TYPE_OF_WORK_REQUEST, workerListTypeOfWork),
  takeLatest(types.ADD_TYPE_OF_WORK_REQUEST, workerCreateTof),
  takeLatest(types.EDIT_TYPE_OF_WORK_REQUEST, workerUpdateTof),
  takeLatest(types.DELETE_TYPE_OF_WORK_REQUEST, workerDeleteTof),

  takeLatest(types.GET_LIST_RANK_CONFIGURATION_REQUEST, workerListRankConfig),
  takeLatest(types.ADD_RANK_CONFIGURATION_REQUEST, workerCreateRankConfig),
  takeLatest(types.EDIT_RANK_CONFIGURATION_REQUEST, workerUpdateRankConfig),
  takeLatest(types.DELETE_RANK_CONFIGURATION_REQUEST, workerDeleteRankConfig),

  takeLatest(types.GET_LIST_RANK_DETAIL_REQUEST, workerListRankDetail),
  takeLatest(types.ADD_RANK_DETAIL_REQUEST, workerCreateRankDetail),
  takeLatest(types.EDIT_RANK_DETAIL_REQUEST, workerUpdateRankDetail),
  takeLatest(types.DELETE_RANK_DETAIL_REQUEST, workerDeleteRankDetail),
];

// tof
function* workerListTypeOfWork({ payload, onFailure, onSuccess }: any): any {
  const http = new HttpService(urlsEndpoint.GET_LIST_TYPE_OF_WORK);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getListTypeOfWork.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListTypeOfWork.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerCreateTof({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.ADD_TYPE_OF_WORK);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.addTypeOfWork.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.addTypeOfWork.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerUpdateTof({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.EDIT_TYPE_OF_WORK);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.editTypeOfWork.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.editTypeOfWork.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerDeleteTof({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.DELETE_TYPE_OF_WORK);
  try {
    const response = yield call(http.deleteMethodById, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

// rank config
function* workerListRankConfig({ payload, onFailure, onSuccess }: any): any {
  const http = new HttpService(urlsEndpoint.GET_LIST_RANK_CONFIG);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getListRankConfiguration.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListRankConfiguration.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerCreateRankConfig({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.ADD_RANK_CONFIG);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.addRankConfiguration.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.addRankConfiguration.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerUpdateRankConfig({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.EDIT_RANK_CONFIG);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.editRankConfiguration.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.editRankConfiguration.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerDeleteRankConfig({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.DELETE_RANK_CONFIG);
  try {
    const response = yield call(http.deleteMethodById, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

// rank detail
function* workerListRankDetail({ payload, onFailure, onSuccess }: any): any {
  const http = new HttpService(urlsEndpoint.GET_LIST_RANK_DETAIL);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getListRankDetail.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListRankDetail.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerCreateRankDetail({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.ADD_RANK_DETAIL);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.addRankDetail.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.addRankDetail.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerUpdateRankDetail({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.EDIT_RANK_DETAIL);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.editRankDetail.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.editRankDetail.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerDeleteRankDetail({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.DELETE_RANK_DETAIL);
  try {
    const response = yield call(http.deleteMethodById, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}