import {
  IDataPayloadPrequalification,
  IDescriptionOfWork,
  IDocumentList,
  IGeneralInfo,
  IToolsEquipment,
  IWorkingAgreement,
  IWorkingList,
} from "./PermitWork/types";

export const types = {
  GET_ALL_LOCATION_REQUEST: "safety/GET_ALL_LOCATION_REQUEST",
  GET_ALL_LOCATION_SUCCESS: "safety/GET_ALL_LOCATION_SUCCESS",
  GET_ALL_LOCATION_FAILED: "safety/GET_ALL_LOCATION_FAILED",

  LIST_PERMIT_REQUEST: "safety/LIST_PERMIT_REQUEST",
  LIST_PERMIT_SUCCESS: "safety/LIST_PERMIT_SUCCESS",
  LIST_PERMIT_FAILED: "safety/LIST_PERMIT_FAILED",

  GET_DETAIL_PERMIT_REQUEST: "safety/GET_DETAIL_PERMIT_REQUEST",
  GET_DETAIL_PERMIT_SUCCESS: "safety/GET_DETAIL_PERMIT_SUCCESS",
  GET_DETAIL_PERMIT_FAILED: "safety/GET_DETAIL_PERMIT_FAILED",

  LIST_PROJECT_PERMIT_REQUEST: "safety/LIST_PROJECT_PERMIT_REQUEST",
  LIST_PROJECT_PERMIT_SUCCESS: "safety/LIST_PROJECT_PERMIT_SUCCESS",
  LIST_PROJECT_PERMIT_FAILED: "safety/LIST_PROJECT_PERMIT_FAILED",

  DELETE_PERMIT_REQUEST: "safety/DELETE_PERMIT_REQUEST",
  DELETE_PERMIT_SUCCESS: "safety/DELETE_PERMIT_SUCCESS",
  DELETE_PERMIT_FAILED: "safety/DELETE_PERMIT_FAILED",

  CREATE_PERMIT_REQUEST: "safety/CREATE_PERMIT_REQUEST",
  CREATE_PERMIT_SUCCESS: "safety/CREATE_PERMIT_SUCCESS",
  CREATE_PERMIT_FAILED: "safety/CREATE_PERMIT_FAILED",

  UPDATE_PERMIT_REQUEST: "safety/UPDATE_PERMIT_REQUEST",
  UPDATE_PERMIT_SUCCESS: "safety/UPDATE_PERMIT_SUCCESS",
  UPDATE_PERMIT_FAILED: "safety/UPDATE_PERMIT_FAILED",

  // detail type
  GET_DETAIL_TYPE_REQUEST: "safety/GET_DETAIL_TYPE_REQUEST",
  GET_DETAIL_TYPE_SUCCESS: "safety/GET_DETAIL_TYPE_SUCCESS",
  GET_DETAIL_TYPE_FAILED: "safety/GET_DETAIL_TYPE_FAILED",
  // prequalification
  GET_PREQUALIFICATION_REQUEST: "safety/GET_PREQUALIFICATION_REQUEST",
  GET_PREQUALIFICATION_SUCCESS: "safety/GET_PREQUALIFICATION_SUCCESS",
  GET_PREQUALIFICATION_FAILED: "safety/GET_PREQUALIFICATION_FAILED",
  // Work Agreement
  WORK_AGREEMENT_REQUEST: "safety/WORK_AGREEMENT_REQUEST",
  // Safety Mandatory
  SAFETY_MANDATORY_REQUEST: "safety/SAFETY_MANDATORY_REQUEST",
  // Safety Mandatory
  DOCUMENT_LIST_REQUEST: "safety/DOCUMENT_LIST_REQUEST",
  // Upload File
  UPLOAD_FILE_REQUEST: "safety/UPLOAD_FILE_REQUEST",
  //Extend Permit
  EXTEND_REQUEST: "safety/EXTEND_REQUEST",

  GET_SPESIAL_PERMIT_REQUEST: "safety/GET_SPESIAL_PERMIT_REQUEST",
  GET_SPESIAL_PERMIT_SUCCESS: "safety/GET_SPESIAL_PERMIT_SUCCESS",
  GET_SPESIAL_PERMIT_FAILED: "safety/GET_SPESIAL_PERMIT_FAILED",

  GET_SPESIAL_PERMIT_CHECKLIST_REQUEST: "safety/GET_SPESIAL_PERMIT_CHECKLIST_REQUEST",
  GET_SPESIAL_PERMIT_CHECKLIST_SUCCESS: "safety/GET_SPESIAL_PERMIT_CHECKLIST_SUCCESS",
  GET_SPESIAL_PERMIT_CHECKLIST_FAILED: "safety/GET_SPESIAL_PERMIT_CHECKLIST_FAILED",

  CREATE_SPESIAL_PERMIT_REQUEST: "safety/CREATE_SPESIAL_PERMIT_REQUEST",
  CREATE_SPESIAL_PERMIT_SUCCESS: "safety/CREATE_SPESIAL_PERMIT_SUCCESS",
  CREATE_SPESIAL_PERMIT_FAILED: "safety/CREATE_SPESIAL_PERMIT_FAILED",

  GET_BY_DAY_PERMIT_REQUEST: "safety/GET_BY_DAY_PERMIT_REQUEST",
  GET_BY_DAY_PERMIT_SUCCESS: "safety/GET_BY_DAY_PERMIT_SUCCESS",
  GET_BY_DAY_PERMIT_FAILED: "safety/GET_BY_DAY_PERMIT_FAILED",

  CREATE_BY_DAY_PERMIT_REQUEST: "safety/CREATE_BY_DAY_PERMIT_REQUEST",
  CREATE_BY_DAY_PERMIT_SUCCESS: "safety/CREATE_BY_DAY_PERMIT_SUCCESS",
  CREATE_BY_DAY_PERMIT_FAILED: "safety/CREATE_BY_DAY_PERMIT_FAILED",
};

export interface IGetAllLocData {
  token: string;
  start: number;
  limit: number;
}
export interface ICreatePermit {
  status: string;
  generalInformation: IGeneralInfo;
  descriptionOfWork: IDescriptionOfWork;
  workerList: IWorkingList;
  toolsEquipment: IToolsEquipment;
  preQualificationFromContractor: { items: IDataPayloadPrequalification[] };
  workAgreement: IWorkingAgreement;
  documentList: IDocumentList[];
}

export interface IUpdatePermit {
  id: number;
  registryNumber: string | null;
  status: string;
  generalInformation: IGeneralInfo;
  descriptionOfWork: IDescriptionOfWork;
  workerList: IWorkingList;
  toolsEquipment: IToolsEquipment;
  preQualificationFromContractor: { items: IDataPayloadPrequalification[] };
  workAgreement: IWorkingAgreement;
  documentList: IDocumentList[];
}

export interface IDeletePermit {
  id: number;
}
