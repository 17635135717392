import { types } from "./_types";

const initialState = {
  workflowCategorizationList: [],
  pagination: null,
  actionStatus: "initial",
  isLoading: false,
};

export const workflowCategorizationReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_CATEGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_CATEGORY_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        workflowCategorizationList: action.data.data.workflowCategory,
        pagination: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    default:
      return state;
  }
};
