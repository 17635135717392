import { types } from "./_types";
import { uniqBy } from "lodash";
import { SioType } from "./Add/types";

const initialState = {
  users: [],
  listRole: [],
  data: {},
  auth: {},
  listDepartment: [],
  listGroup: [],
  listPosition: [],
  listSection: [],
  listDs: [],
  listCompany: [],
  listSio: [],
  pagination: null,
  dataCompany: null,
  actionStatus: "initial",
  moreActionStatus: {
    addUser: "initial",
  },
  archiveData: null,
  isLoading: true,
  isLoadingDetail: false,
  isLoadingSio: false,
  isErrorSio: false,
  sioFiles: {},
};

export const userReducers = (state = initialState, action: any) => {
  const checkUniqId = (array: any): any => {
    const filtering = uniqBy(array, (item: any) => item.title);
    return filtering;
  };
  switch (action.type) {
    case types.GET_USERS_REQUEST:
      return {
        ...state,
        auth: {},
        data: {},
        dataCompany: null,
        isLoading: true,
      };
    case types.GET_USERS_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        users: Array.isArray(action.data.result) ? action.data.result : [],
        pagination: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.RESET_USER_REQUEST:
      return {
        ...state,
        auth: {},
        data: {},
        dataCompany: null,
      };
    case types.GET_ROLE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_ROLE_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_ROLE_SUCCESS:
      return {
        ...state,
        listRole: Array.isArray(action.data.result) ? action.data.result : [],
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_DEPARTMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_DEPARTMENT_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_DEPARTMENT_SUCCESS:
      return {
        ...state,
        listDepartment: action.data.data.model,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_GROUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_GROUP_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_GROUP_SUCCESS:
      return {
        ...state,
        listGroup: action.data.data.model,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_COMPANY_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_COMPANY_SUCCESS:
      return {
        ...state,
        listCompany: action.data.data.company,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_DIRECT_SUP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_DIRECT_SUP_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_DIRECT_SUP_SUCCESS:
      return {
        ...state,
        listDs: action.data.data.user,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.CREATE_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.CREATE_COMPANY_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        dataCompany: action.data.data.Company,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_POSITION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_POSITION_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_POSITION_SUCCESS:
      return {
        ...state,
        listPosition: action.data.data.model,
        actionStatus: action.actionStatus,
        isLoading: false,
      };

    case types.GET_LIST_SECTION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_SECTION_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_SECTION_SUCCESS:
      return {
        ...state,
        listSection: action.data.data.model,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_DETAIL_USER_REQUEST:
      return {
        ...state,
        isLoadingDetail: true,
      };
    case types.GET_DETAIL_USER_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoadingDetail: false,
      };
    case types.GET_DETAIL_USER_SUCCESS:
      return {
        ...state,
        data: action.data[0].data.user,
        auth: action.data[1].user,
        actionStatus: action.actionStatus,
        isLoadingDetail: false,
      };
    case types.GET_LIST_ARCHIVE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_ARCHIVE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_LIST_ARCHIVE_USER_SUCCESS:
      return {
        ...state,
        archiveData: action.data.result,
        pagination: action.data.pagination,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_SIO_REQUEST:
    case types.DELETE_SIO_REQUEST:
    case types.UPDATE_SIO_REQUEST:
    case types.ADD_SIO_REQUEST:
      return {
        ...state,
        isLoadingSio: true,
        actionStatus: action.actionStatus,
      };
    case types.GET_SIO_SUCCESS:
      return {
        ...state,
        isLoadingSio: false,
        isErrorSio: false,
        listSio: checkUniqId([...action.data, ...state.listSio]),
        actionStatus: action.actionStatus,
      };
    case types.GET_SIO_FAILURE:
    case types.DELETE_SIO_FAILURE:
    case types.UPDATE_SIO_FAILURE:
    case types.ADD_SIO_FAILURE:
      return {
        ...state,
        isLoadingSio: false,
        isErrorSio: true,
        actionStatus: action.actionStatus,
      };
    case types.CLEAR_SIO_FILES:
      return {
        ...state,
        listSio: [],
      };
    case types.UPDATE_SIO_SUCCESS:
      const newListSio = state.listSio.map((sio: SioType) => {
        if (sio.id === action.payload.id) {
          return action.payload;
        }
        return sio;
      });

      return {
        ...state,
        listSio: newListSio,
        isLoadingSio: false,
        isErrorSio: false,
      };
    case types.DELETE_SIO_SUCCESS:
      return {
        ...state,
        isLoadingSio: false,
        listSio: state.listSio.filter((sio: SioType) => sio.id !== action.payload),
      };
    default:
      return state;
  }
};
