import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReservationService } from "../../../components/Reservation/services";
import { actions } from "../_actions";
import { selectors } from "../_selectors";
import View from "./view";
import { actions as masterAction } from "../../MasterData/_actions";
import { useLocation } from "react-router-dom";
import { actions as actioncHiradc } from "../../Hiradc/_actions";

const MyTask = () => {
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState({
    status: "",
    reservation: [],
    date: "",
    startDate: "",
    endDate: "",
  });
  const [listFlow, setListFlow] = useState([]);
  const dataSource = useSelector(selectors.getMytask);
  const dataHistories = useSelector(selectors.getHistoryMyTask);
  const isLoading = useSelector(selectors.isLoading);
  const isLoadingHistory = useSelector(selectors.isLoadingHistory);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const pagination = useSelector(selectors.pagination);
  const [dataLocation, setDataLocation] = useState([]);
  const paginationHistory = useSelector(selectors.paginationHistory);
  const location = useLocation();
  const [dataActivity, setDataActivity] = useState<any>();

  //Result will be either "" | "Safety" | "Health"
  const currentTask = findCurrentTask(location);
  const _keyword = location?.search?.split("search=")[1] ?? "";

  const _fetchData = useCallback(() => {
    dispatch(
      actions.getMyTaskList.request({
        payload: {
          start: 0,
          limit: 10,
          page: 1,
          keyword: _keyword,
          filter: {
            typeFlow: currentTask,
            description: [],
            assignmentNumber: "",
            assignmentDate: "",
            status: "",
            name: "",
          },
          orderBy: ["createdDate"],
          direction: "DESC",
        },
      })
    );
    dispatch(
      actions.getListHistory.request({
        payload: {
          start: 0,
          limit: 10,
          page: 1,
          keyword: _keyword,
          filter: {
            typeFlow: currentTask,
            description: [],
            assignmentNumber: "",
            assignmentDate: "",
            status: "",
            name: "",
          },
          orderBy: ["createdDate"],
          direction: "DESC",
        },
      })
    );
  }, [dispatch, currentTask, _keyword]);

  useEffect(() => {
    dispatch(
      masterAction.getAllLoc.request({
        payload: {
          start: 0,
          limit: 1000,
          page: 1,
          keyword: "",
          filter: {
            locationName: null,
            locationDescription: null,
          },
          orderBy: {
            column: ["id"],
            direction: "ASC",
          },
        },
        onSuccess: (res: any) => {
          setDataLocation(res.data.listLocation);
        },
      })
    );

    dispatch(
      actioncHiradc.getMaster.request({
        payload: {
          start: 0,
          limit: 100,
          search: "",
          categoryId: 30,
          orderBy: {
            column: ["value"],
            direction: "ASC",
          },
        },
        onSuccess: (res: any) => {
          setDataActivity(res?.data?.datamaster);
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    _fetchData();
  }, [_fetchData, location]);

  const _getReservation = async () => {
    const currentReservationService = (() => {
      const currentTypeflow = findCurrentTask(location).toLowerCase();
      return currentTypeflow === "health" || currentTypeflow === "" ? "Medical Center" : "SHE";
    })();
    const res = await getReservationService(currentReservationService);
    if (res.success) {
      if (res.data !== "[]" || res.data !== null) {
        setListFlow(res.data.formFlow);
      }
    } else {
      setListFlow([]);
    }
  };

  useEffect(() => {
    _getReservation();
    // eslint-disable-next-line
  }, [currentTask]);

  const fetchFilter = async (
    tab: string,
    text?: string,
    props?: number,
    size?: number,
    order?: { column: string; direction: string }
  ) => {
    const payload = {
      start: 0,
      limit: size ? size : pageSize,
      page: props ? props : page,
      keyword: text ? text : searchKey,
      filter: {
        typeFlow: currentTask,
        assignmentNumber: "",
        dateFilter: filterData.date,
        assignmentDate: "",
        status: filterData.status,
        startDate:
          filterData.startDate === "" ? "" : moment(filterData.startDate)?.format("YYYY-MM-DD"),
        endDate: filterData.endDate === "" ? "" : moment(filterData.endDate)?.format("YYYY-MM-DD"),
        description: filterData.reservation,
        name: "",
      },
      orderBy: [order?.column ? order.column : "createdDate"],
      direction: order?.direction ? order.direction.toUpperCase() : "DESC",
    };

    if (tab === "Latest") {
      dispatch(actions.getMyTaskList.request({ payload }));
    } else {
      dispatch(actions.getListHistory.request({ payload }));
    }
  };

  const pageChange = (props: number, tab: string, size?: number) => {
    setPage(props);
    setPageSize(size ?? 10);
    fetchFilter(tab, searchKey, props, size);
  };

  const searchReservation = (text: string, segment: string) => {
    setSearchKey(text);
    fetchFilter(segment, text, page, pageSize);
  };

  const getFilterData = (value: string, type: string) => {
    if (type === "clear") {
      setFilterData({
        reservation: [],
        startDate: "",
        endDate: "",
        date: "",
        status: "",
      });
    } else {
      setFilterData({ ...filterData, [type]: value });
    }
  };

  return (
    <View
      searchData={searchReservation}
      dataOutput={dataSource}
      paginationHistory={paginationHistory}
      filterData={filterData}
      isLoading={isLoading || isLoadingHistory}
      listFlow={listFlow}
      dataHistory={dataHistories}
      changeFilter={getFilterData}
      pageSize={pageSize}
      changePage={pageChange}
      fetchFilter={fetchFilter}
      pagination={pagination}
      dataLocation={dataLocation}
      dataActivity={dataActivity}
    />
  );
};

export const findCurrentTask = (location: any) => {
  if (!location.search) return "";
  const splittedParams = location.search.split("&");
  const regex = /^\?*type/i;

  let currentType = "";

  for (let param of splittedParams) {
    const res = regex.exec(param);
    if (res) {
      currentType = res.input.split("=")[res.input.split("=").length - 1];
      break;
    }
  }

  if (!currentType || currentType === "") return "";

  return `${currentType[0].toUpperCase()}${currentType.slice(1)}`;
};

export const TASK_MAPPING = {
  safety: "SHE",
  health: "Medical Center",
} as const;

export default MyTask;
