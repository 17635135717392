import { CheckOutlined, CloseOutlined, ReloadOutlined } from "@ant-design/icons";
import { notification, Popover, Switch } from "antd";
import clsx from "clsx";
import moment from "moment";
import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { BELL } from "../../assets/images";
import useNotification from "../../helpers/hooks/useNotification";
import * as urlsName from "../../helpers/urlsName";
import { actions as actionHealth } from "../../pages/Health/_actions";
import { actions } from "../../pages/Notification/_actions";
import { findCurrentTask } from "../../pages/Reservation/MyTask";
import { actions as actionSafety } from "../../pages/Safety/_actions";
import { MediumLoader } from "../Loader";
import styles from "./styles.module.less";
import { actions as myTaskActions } from "../../pages/Reservation/_actions";

const Notification = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const [unRead, setUnRead] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [isWaiting, setWaiting] = useState(false);
  const [isIndex, setIndex] = useState(0);

  // ref & hooks
  const observer: any = useRef();
  const { isLoading, notifications, badges, hasMore } = useNotification(pageNum);
  const fill = unRead ? notifications.filter((obj: any) => !obj.isRead) : notifications;
  const listPermission = location?.state?.permissions;

  const lastElementRef = useCallback(
    (node) => {
      const id = fill.length - 1;
      if (isLoading) return;
      if (observer.current) observer.current?.disconnect && observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && entries[0].target.id === id.toString() && hasMore) {
          setPageNum((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, fill.length]
  );

  const _reload = useCallback(() => {
    setPageNum(1);
  }, []);

  const _readBadge = () => {
    dispatch(
      actions.readBage.request({
        payload: { id: [] },
        onSuccess: (res: any) => {
          _reload();
        },
      })
    );
  };

  const failedNotification = (assignmentNumber: string) => {
    notification.error({
      placement: "topRight",
      message: "Error!",
      description: `Unable to get assignment: ${assignmentNumber}`,
    });
  };

  const _getDetailPermit = (record: any) => {
    setWaiting(true);
    dispatch(
      actionSafety.getDetailPermit.request({
        payload: `0?registryNumber=${record?.assignmentNumber}`,
        onSuccess: (res: any) => {
          setWaiting(false);
          if (res.message === "Data found") {
            history.push({
              pathname: urlsName.CREATE_PERMIT_TO_WORK,
              search: `id=${res.data.permitToWork.id}&type=${findCurrentTask(
                location
              ).toLowerCase()}`,
              state: {
                extendData: res.data.permitToWork,
                status: "VIEW",
                fieldData: record,
                permissions: listPermission,
              },
            });
          }
        },
        onFailure: (err: any) => {
          setWaiting(false);
          notification.error({
            placement: "topRight",
            message: "Error!",
            description: "Something went wrong",
            duration: 3,
          });
        },
      })
    );
  };

  const _navigate = (data: any, type: string, willUseMyTaskData = true) => {
    const pathname = urlsName.MY_TASK_DETAIL;
    let isPermit, payload, segment;

    // willUseMyTaskData is necessary because the format of data between my task list endpoint and reservation detail enpoint are different.
    // The data from my task list endpoint is more complete than the other one and the same data may have different key in the other endpoint (e.g. formList in my task list and formFlow in reservation detail)

    if (!willUseMyTaskData) {
      // Asumming `data` is in the form of reservation detail

      isPermit = data?.reservation?.assignmentNumber?.includes("SHE/PTW/");
      payload = {
        ...data,
        ...data?.reservation,
        flowName: data?.reservation?.description,
        formList: data?.formFlow ?? [],
        reservationStatus: data?.reservation?.status,
      };
      segment = data?.reservation?.status === "WAITING_APPROVAL" ? "Latest" : "Completed";
    } else {
      // Asumming `data` is in the form of my task list

      isPermit = data?.assignmentNumber?.includes("SHE/PTW/");
      payload = { ...data };
      segment = data?.status === "WAITING_APPROVAL" ? "Latest" : "Completed";
    }

    const state = { fieldData: payload, segment, permissions: listPermission };
    if (isPermit) {
      _getDetailPermit(payload);
    } else {
      history.push({
        pathname,
        state,
        search: `?type=${type}`,
      });
    }
    setWaiting(false);
  };

  const _getDetailSafety = (e: any, type: string) => {
    dispatch(
      actionHealth.getReservationDetailSafety.request({
        payload: { assignmentNumber: e.subject },
        onSuccess(res: any) {
          if (!res || !res?.result || !res?.data) {
            failedNotification(e.subject);
            return;
          }

          if (
            res.data?.reservation?.status === "WAITING_APPROVAL" ||
            res.data?.reservation?.status === "REJECTED"
          ) {
            getData({
              assignmentNumber: e.subject,
              flowType: "Safety",
              successCallback: (res) => {
                if (
                  res?.data &&
                  res?.data?.formFlow &&
                  Array.isArray(res.data.formFlow) &&
                  res.data.formFlow.length > 0
                ) {
                  _navigate(res.data.formFlow[0], type);
                  return;
                }
                setWaiting((prevState) => !prevState);
                failedNotification(e.subject);
              },
              errorCallback: () => {
                setWaiting((prevState) => !prevState);
                failedNotification(e.subject);
              },
            });
            return;
          }

          _navigate(res?.data, type, false);
        },
        onFailure: (err: any) => {
          setWaiting(false);
          failedNotification(e.subject);
        },
      })
    );
  };

  const _getDetailHealth = (e: any, type: string) => {
    dispatch(
      actionHealth.getReservationDetailHealth.request({
        payload: { assignmentNumber: e.subject },
        onSuccess(res: any) {
          if (!res || !res?.result || !res?.data) {
            failedNotification(e.subject);
            return;
          }

          // Check if the the status is waiting approval or not. If it is, get the details from my task list because the data is more complete there to do response actions
          if (
            res.data?.reservation?.status === "WAITING_APPROVAL" ||
            res.data?.reservation?.status === "REJECTED"
          ) {
            getData({
              assignmentNumber: e.subject,
              flowType: "Health",
              successCallback: (res) => {
                if (
                  res?.result ||
                  (res?.data &&
                    res?.data?.formFlow &&
                    Array.isArray(res.data.formFlow) &&
                    res.data.formFlow.length > 0)
                ) {
                  _navigate(res.data.formFlow[0], type);
                  return;
                }
                // Meaning there is a failure
                setWaiting((prevState) => !prevState);
                failedNotification(e.subject);
              },
              errorCallback: () => {
                setWaiting((prevState) => !prevState);
                failedNotification(e.subject);
              },
            });
            return;
          }
          _navigate(res?.data, type, false);
        },
        onFailure: (err: any) => {
          setWaiting(false);
          failedNotification(e.subject);
        },
      })
    );
  };

  interface getDataProps {
    assignmentNumber: string;
    flowType: "Health" | "Safety";
    successCallback: (res: any) => void;
    errorCallback?: (res: any) => void;
  }

  const getData = ({
    assignmentNumber,
    flowType,
    successCallback,
    errorCallback,
  }: getDataProps) => {
    dispatch(
      myTaskActions.getMyTaskList.request({
        payload: {
          start: 0,
          limit: 10,
          page: 1,
          keyword: "",
          filter: {
            typeFlow: flowType,
            description: [],
            assignmentNumber: assignmentNumber,
            assignmentDate: "",
            status: "",
            name: "",
          },
          orderBy: ["createdDate"],
          direction: "DESC",
        },
        onSuccess: successCallback,
        onFailure(err) {
          console.error(err);
          if (errorCallback) {
            errorCallback(err);
          }
        },
      })
    );
  };

  const _read = (e: any, index: number) => {
    setWaiting(true);
    setOpen(!isOpen);
    dispatch(actions.readNotif.request(e.id));
    setIndex(index);
    if (e?.type === "Safety") {
      _getDetailSafety(e, "safety");
    } else {
      _getDetailHealth(e, "health");
    }
  };

  const _item = (e: any, index: number, cRef?: any) => {
    return (
      <div
        key={index}
        id={index.toString()}
        className={styles.list_item}
        onClick={() => _read(e, index)}
        ref={cRef}
      >
        {isWaiting && isIndex === index ? (
          <div className={styles.loading}>
            <MediumLoader />
          </div>
        ) : (
          <>
            <div className={styles.header}>
              <div className={styles.subject}>{e.subject}</div>
              <div className={styles.date}>{moment(e.createdDate).fromNow()}</div>
            </div>
            <div className={styles.title}>
              New Notification for Reservation
              {!e.isRead ? <div className={styles.dot} /> : null}
            </div>
            <div className={styles.description}>{e.body}</div>
          </>
        )}
      </div>
    );
  };

  return (
    <Popover
      ref={observer}
      placement="bottomRight"
      className="custom"
      content={
        <div className={styles.notif_content}>
          <div className={styles.wrap_title}>
            <h2>Notifications</h2>
            <div className={styles.right}>
              Only show unread&nbsp;&nbsp;
              <Switch
                size="small"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(e) => setUnRead(e)}
              />
              &nbsp;&nbsp;
              <ReloadOutlined onClick={() => _reload()} />
            </div>
          </div>
          <div className={styles.wrap_type}></div>
          {fill && fill.length > 0 ? (
            fill.map((e: any, i: number) => {
              if (fill.length === i + 1) {
                return _item(e, i, lastElementRef);
              } else {
                return _item(e, i);
              }
            })
          ) : (
            <div className={styles.list_item_murder}>No data available</div>
          )}
        </div>
      }
      arrowContent
      trigger="click"
    >
      <div
        onClick={() => {
          setOpen(!isOpen);
          if (badges > 0) _readBadge();
        }}
        className={clsx(styles.wrapper_notif, {
          [styles.wrapper_notif_active]: badges > 0,
        })}
      >
        <img
          alt="bell"
          src={BELL}
          style={{ marginRight: 10 }}
          className={badges > 0 ? styles.red_icon : ""}
        />
        <span className={styles.notifTitle}>Notification</span>
        {badges > 0 && (
          <div className={styles.dot} style={{ width: badges > 99 ? 28 : 20 }}>
            <div className={styles.text} style={{ width: badges > 99 ? 26 : 16 }}>
              {badges > 99 ? "99+" : badges}
            </div>
          </div>
        )}
      </div>
    </Popover>
  );
};

export default Notification;
