import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { IPayloadUpdatePass, types } from "./_types";

export const actions = {
  updatePassword: {
    request: (requestData: IDefaultRequestParams<IPayloadUpdatePass>) => ({
      ...requestData,
      type: types.GET_PROFILE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
};
