import clsx from "clsx";
import { FC } from "react";
import { capitalize } from "../../helpers";
import styles from "./styles.module.less";
import useTranslateShortcut from "../../helpers/hooks/useTranslateShortcut";
import { removeSpacesAndSymbols } from "../../helpers/removeSpacesAndSymbols";

interface StatusProps {
  value: string;
  onClick?: () => void;
  dotStatus?: boolean;
}

const Status: FC<StatusProps> = ({ value, onClick, dotStatus = true }) => {
  const { translate } = useTranslateShortcut({ base: "status" });

  const translateStatus = (status: string) => {
    if (!status) return "-";

    const current = removeSpacesAndSymbols(status);
    switch (current) {
      case "DELETED":
        return translate("deleted");
      case "DELETE":
        return translate("delete");
      case "ONPROGRESS":
      case "INPROGRESS":
        return translate("inProgress");
      case "UPDATE":
        return translate("update");
      case "REVIEW":
        return translate("review");
      case "DONE":
        return translate("done");
      case "PUBLISH":
        return translate("publish");
      case "PUBLISHED":
        return translate("published");
      case "ACTIVE":
        return translate("active");
      case "NEW":
        return translate("new");
      case "EXPIRED":
        return translate("expired");
      case "WAITINGAPPROVAL":
        return translate("waitingApproval");
      case "REJECT":
      case "REJECTED":
      case "REJECTEDCLOSE":
        return translate("reject");
      case "DRAFT":
        return translate("draft");
      case "UPDATED":
        return translate("updated");
      default:
        return status;
    }
  };

  return (
    <>
      {value ? (
        <div
          className={clsx(styles.statusWrap, {
            [styles.statusWrap__wait]: value === "DRAFT",
            [styles.statusWrap__onprogress]:
              value === "ON_PROGRESS" ||
              value === "IN PROGRESS" ||
              value === "INPROGRESS" ||
              value === "IN_PROGRESS" ||
              value === "WAITING_APPROVAL" ||
              value === "Review" ||
              value === "UPDATE",
            [styles.statusWrap__rejected]:
              value === "REJECTED" || value === "DELETED" || value === "INACTIVE",
            [styles.statusWrap__reject]:
              value === "REJECT" ||
              value === "REJECTED_CLOSED" ||
              value === "Expired" ||
              value === "DELETE",
            [styles.statusWrap__waiting]: value === "Waiting Approval",
            [styles.statusWrap__done]:
              value === "DONE" ||
              value === "PUBLISH" ||
              value === "PUBLISHED" ||
              value === "Active" ||
              value === "NEW",
          })}
          onClick={onClick}
        >
          <div
            className={clsx(`${dotStatus && styles.statusDot}`, {
              [styles.statusDot__wait]: value === "WAITING_APPROVAL" || value === "DRAFT",
              [styles.statusDot__onprogress]:
                value === "ON_PROGRESS" ||
                value === "IN PROGRESS" ||
                value === "INPROGRESS" ||
                value === "IN_PROGRESS" ||
                value === "WAITING_APPROVAL" ||
                value === "UPDATE" ||
                value === "Waiting Approval",
              [styles.statusDot__rejected]:
                value === "REJECTED" || value === "DELETED" || value === "INACTIVE",
              [styles.statusDot__reject]:
                value === "REJECT" || value === "REJECTED_CLOSED" || value === "DELETE",
              [styles.statusDot__done]:
                value === "DONE" || value === "PUBLISH" || value === "PUBLISHED" || value === "NEW",
            })}
          />
          {capitalize(translateStatus(value), "_")}
        </div>
      ) : (
        "-"
      )}
    </>
  );
};

export default Status;
