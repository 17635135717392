import { UpCircleTwoTone } from "@ant-design/icons";
import { Layout } from "antd";
import { ComponentType, lazy, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import ContentComponent from "../components/Content";
import HeaderComponent from "../components/Header";
import LanguageDropdown from "../components/LanguageDropdown";
import Sidebar from "../components/Sidebar";
import { lazyRetry } from "../helpers";
import { Permission } from "../helpers/getUrlPermission";
import * as urlsName from "../helpers/urlsName";
import { selectors } from "../pages/Auth/_selectors";
import styles from "./styles.module.less";
import useBreakpoints from "../helpers/hooks/useBreakpoints";

// Home
const HomePage = lazy(() => import("../pages/Home/ListArticles"));

const DashboardPIC = lazy(() => import("../pages/Dashboard/PIC"));
const DashboardEmployee = lazy(() => import("../pages/Dashboard/Employee"));
const GreetingIndexPage = lazy(() => import("../pages/Greeting/Index"));
const GreetingAddPage = lazy(() => import("../pages/Greeting/Add"));
const UserIndexPage = lazy(() => import("../pages/User/Index"));
const SiteIndexPage = lazy(() => import("../pages/Site/index"));
const SiteAddPage = lazy(() => import("../pages/Site/Add"));
const UserUpdateaPge = lazy(() => import("../pages/User/Add"));
const ArchiveUser = lazy(() => import("../pages/User/Archive"));
const RoleIndexPage = lazy(() => import("../pages/Role/Index"));
const RoleAddPage = lazy(() => import("../pages/Role/Add"));
const ArchiveRole = lazy(() => import("../pages/Role/Archive"));
const RoleUpdatePage = lazy(() => import("../pages/Role/Update"));
const GeneralCorporateProfilePage = lazy(() => import("../pages/General/GeneralCorporateProfile"));
const GeneralGroupPage = lazy(() => import("../pages/General/GeneralGroup"));
const GeneralRolePage = lazy(() => import("../pages/General/GeneralRole"));
const GeneralThemePage = lazy(() => import("../pages/General/GeneralTheme"));
const GeneralFormIndexPage = lazy(() => import("../pages/General/GeneralForm/Index"));
const GeneralFormAddPage = lazy(() => import("../pages/General/GeneralForm/Add"));
const GeneralFormUpdatePage = lazy(() => import("../pages/General/GeneralForm/Update"));
const GeneralFormDraftIndexPage = lazy(() => import("../pages/General/GeneralFormDraft/Index"));
const GeneralFormArchiveIndexPage = lazy(() => import("../pages/General/GeneralFormArchive/Index"));
const FormFlowPage = lazy(() => import("../pages/General/FormFlow/Index"));
const FormFlowAddPage = lazy(() => import("../pages/General/FormFlow/AddnUpdate"));
const GeneralReminderPage = lazy(() => import("../pages/General/GeneralReminder"));
const MasterKeyPage = lazy(() => import("../pages/MasterData/MasterKey/MasterType"));
const MasterCategoriPage = lazy(() => import("../pages/MasterData/MasterKey/Index"));
const MasterArchivePage = lazy(() => import("../pages/MasterData/MasterKey/CategoryArchive"));
const MasterKeyAddPage = lazy(() => import("../pages/MasterData/MasterKey/Add"));
const MasterKeyAddCategoriPage = lazy(() => import("../pages/MasterData/SubCategory"));
const MasterKeyUpdatePage = lazy(() => import("../pages/MasterData/MasterKey/Update"));
const MasterKeyHealth = lazy(() => import("../pages/MasterData/MasterKey/MasterTypeHealth"));
const MasterKeySafety = lazy(() => import("../pages/MasterData/MasterKey/MasterTypeSafety"));
const MasterAttPage = lazy(() => import("../pages/MasterData/MasterAttendance/Index"));
const MasterAttAddPage = lazy(() => import("../pages/MasterData/MasterAttendance/Add"));
const MasterAttUpdatePage = lazy(() => import("../pages/MasterData/MasterAttendance/Update"));
const DetailAttPage = lazy(() => import("../pages/DetailData/DetailAttendance/Index"));
const DetailAttAddPage = lazy(() => import("../pages/DetailData/DetailAttendance/Add"));
const DetailAttUpdatePage = lazy(() => import("../pages/DetailData/DetailAttendance/Update"));
const MasterLocationPage = lazy(() => import("../pages/MasterData/MasterLocation/Index"));
const MasterLocationAddPage = lazy(() => import("../pages/MasterData/MasterLocation/Add"));
const MasterToolsPage = lazy(() => import("../pages/MasterData/MasterTools/Index"));
const MasterToolsAddPage = lazy(() => import("../pages/MasterData/MasterTools/Add"));
const MasterLocationType = lazy(() => import("../pages/MasterData/MasterLocationType/Index"));
const MasterLocationAdd = lazy(() => import("../pages/MasterData/MasterLocationType/AddNUpdate"));
const MasterLocationUpadate = lazy(
  () => import("../pages/MasterData/MasterLocationType/AddNUpdate")
);
const ReservationList = lazy(() => import("../pages/Health/Reservation/index"));
const MedicalCenterList = lazy(() => import("../pages/Health/MedicalCenter/index/index"));
// const CreateReservation = lazy(() => import("../pages/Health/Reservation/Add"));
const CreateReservation = lazy(
  () =>
    lazyRetry(() => import("../pages/Health/Reservation/Add"), "createReservation") as Promise<{
      default: ComponentType<any>;
    }>
);
const ReservationDetail = lazy(() => import("../pages/Health/Reservation/Detail"));
const MasterSitePage = lazy(() => import("../pages/MasterData/MasterSite"));
const AddNewSitePage = lazy(() => import("../pages/MasterData/MasterSite/AddSite"));
const UpdateSitePage = lazy(() => import("../pages/MasterData/MasterSite/EditSite"));
const MasterRiskPage = lazy(() => import("../pages/MasterData/MasterRisk"));
const AddNewRiskPage = lazy(() => import("../pages/MasterData/MasterRisk/AddRisk"));
const UpdateRiskPage = lazy(() => import("../pages/MasterData/MasterRisk/EditRisk"));
const MasterShiftPage = lazy(() => import("../pages/MasterData/MasterShift"));
const AddNewShiftPage = lazy(() => import("../pages/MasterData/MasterShift/AddShift"));
const UpdateShiftPage = lazy(() => import("../pages/MasterData/MasterShift/EditShift"));
const MasterEquipmentPage = lazy(() => import("../pages/MasterData/MasterEquipment"));
const AddNewEquipmentPage = lazy(() => import("../pages/MasterData/MasterEquipment/AddEquipment"));
const MasterEquipmentCategory = lazy(() => import("../pages/MasterData/MasterEquipmentType/Index"));
const MasterEquipmentType = lazy(
  () => import("../pages/MasterData/MasterEquipmentType/ListEquipmentType")
);
const AddNewEquipmentType = lazy(() => import("../pages/MasterData/MasterEquipmentType/Add"));
const MasterEquipmentChild = lazy(
  () => import("../pages/MasterData/MasterEquipmentType/childEqupment/Index/index")
);
const AddNewEquipmentChild = lazy(
  () => import("../pages/MasterData/MasterEquipmentType/childEqupment/Add/index")
);
const MasterEquipmentDetailLogPage = lazy(
  () => import("../pages/MasterData/MasterEquipmentType/childEqupment/HistoryEquipment")
);
const MasterSubLocationPage = lazy(() => import("../pages/MasterData/MasterSubLocation"));
const AddSubLocationPage = lazy(
  () => import("../pages/MasterData/MasterSubLocation/AddSubLocation")
);
const MasterFireEquipment = lazy(() => import("../pages/MasterData/MasterFireEquipment"));
const MasterFireEquipmentAdd = lazy(() => import("../pages/MasterData/MasterFireEquipment/Add"));
const MasterFireEquipmentChild = lazy(
  () => import("../pages/MasterData/MasterFireEquipment/Child")
);
const MasterFireEquipmentChildAdd = lazy(
  () => import("../pages/MasterData/MasterFireEquipment/Child/Add")
);
const NotFoundPage = lazy(() => import("../pages/NotFound"));
const ReportSubmisionIndexPage = lazy(() => import("../pages/Report/ReportSubmision/Index"));
const ReportSubmisionDetailPage = lazy(() => import("../pages/Report/ReportSubmision/Detail"));
// Rank Configuration
const TypeOfWorkPage = lazy(() => import("../pages/RankConfiguration/TypeOfWork/index"));
const TypeOfWorkAdd = lazy(() => import("../pages/RankConfiguration/Controller"));
const RankConfigurationPage = lazy(() => import("../pages/RankConfiguration/Rank/index"));
const RankDetailPage = lazy(() => import("../pages/RankConfiguration/RankDetail/index"));
// Reservation
const MyTaskPage = lazy(() => import("../pages/Reservation/MyTask/index"));
const MyTaskDetail = lazy(() => import("../pages/Reservation/Details/index"));
// Medical
const MedicalRecord = lazy(() => import("../pages/MedicalRecord/Create/index"));
const MedicalList = lazy(() => import("../pages/MedicalRecord/ListMedical/index"));
const Medicine = lazy(() => import("../pages/MedicalRecord/Medicine/index"));
const CompanyProfileIndexPage = lazy(() => import("../pages/General/CompanyProfile/Index"));
const CompanyProfileAddPage = lazy(() => import("../pages/General/CompanyProfile/AddnUpdate"));
const HierarchyCompanyListPage = lazy(() => import("../pages/HierarchyCompany/Key/Index"));
const HierarchyCompanyAddPage = lazy(() => import("../pages/HierarchyCompany/Key/Add"));
const SubHierarchyCompanyListPage = lazy(() => import("../pages/HierarchyCompany/SubKey/Index"));
const SubHierarchyCompanyAddPage = lazy(() => import("../pages/HierarchyCompany/SubKey/Add"));
// Safety
const PermitWork = lazy(() => import("../pages/Safety/PermitWork/List"));
const CreatePermitWork = lazy(() => import("../pages/Safety/PermitWork/Add"));
const SpesialPermit = lazy(() => import("../pages/Safety/SpesialWorkPermit/List/index"));
const HotPermik = lazy(() => import("../pages/Safety/SpesialWorkPermit/HotPermik/Index"));
const HotConfirmPermik = lazy(
  () => import("../pages/Safety/SpesialWorkPermit/HotPermik/ConfinedSpace/Index")
);
// User Inquiry
const UserInquiry = lazy(() => import("../pages/UserInquiry"));
const BulkUploadReportMedicine = lazy(
  () => import("../pages/BulkUpload/BulkUploadReportMedicine/index")
);
const BulkUploadReportUser = lazy(() => import("../pages/BulkUpload/BulkUploadUser/index"));

const MedicineList = lazy(() => import("../pages/Medicine/Index/index"));
const MedicineLog = lazy(() => import("../pages/Medicine/LogMedicine/index"));
//Medical Inventory
const Inventory = lazy(() => import("../pages/MedicalInvetory/Inventory"));
const DetailInventory = lazy(() => import("../pages/MedicalInvetory/Inventory/ChildInventory"));
// Article
const ArticleList = lazy(() => import("../pages/Articles/ArticleList/index"));
const ListArticles = lazy(() => import("../pages/Articles/Index"));
const AddArticles = lazy(() => import("../pages/Articles/Add"));
const ArticleDetail = lazy(() => import("../pages/Home/ArticleDetail/index"));
// workflow categorization
const WorkflowCategorization = lazy(() => import("../pages/WorkflowCategorization"));
const ProcedureConfig = lazy(() => import("../pages/ProcedureConfig/index"));
const AddProcedureConfig = lazy(() => import("../pages/ProcedureConfig/Add/index"));
// hiradc
const HiradcSubbmission = lazy(() => import("../pages/Hiradc/Index/index"));
const Archived = lazy(() => import("../pages/Hiradc/Archived/index"));
const DetailHiradc = lazy(() => import("../pages/Hiradc/Details/index"));
const addHiradc = lazy(() => import("../pages/Hiradc/add/index"));
const hiradcList = lazy(() => import("../pages/Hiradc/listHiradc"));
const DashboardTable = lazy(() => import("../pages/DynamicDashboard/Table/index"));

// Reassign
const ReassignPage = lazy(() => import("../pages/Reassign"));

// Undo Status
const UndoStatus = lazy(() => import("../pages/UndoStatus"));

// Follow Up Action
const DetailFollowUpAction = lazy(() => import("../pages/Reservation/DetailsFollowUpAction/index"));

// safety voice
const SafetyVoiceScoring = lazy(() => import("../pages/SafetyVoiceScoring/index"));
const SafetyVoiceScoringDetail = lazy(() => import("../pages/SafetyVoiceScoring/Detail/index"));
const DashboardPageMeta = lazy(() => import("../pages/DynamicDashboard/DashboardPage/index"));
const DashboardForm = lazy(() => import("../pages/DynamicDashboard/Form/view"));

const AppAuthenticatedLayout = () => {
  const dataAdmin = useSelector(selectors.getUserData);

  const [collapse, setCollapse] = useState(false);
  const [showTopBtn, setShowTopBtn] = useState(false);
  const breakpoint = useBreakpoints();

  useEffect(() => {
    const scroll = () => {
      if (window.scrollY > 200) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    };
    window.addEventListener("scroll", scroll);

    return () => {
      window.removeEventListener("scroll", scroll);
    };
  }, []);

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getDataPermission = (permissions: any, name: string, category: string) => {
    // digunakan untuk mencari data permission berdasarkan name path dan category menunya
    // param permission merupakan data seluruh menu, param name merupakan menu yang di cari, category merupakan category menunya
    const data = permissions?.find((res: any) => res.name === name && res.category === category);

    return data;
  };

  const getUrl = (permissions: any) => {
    let url = { urlsName: "*", search: "", permissions: "" };
    // fungsi ini digunakan untuk redirect sesuai dengan permission paling atas berdasarkan permission menu
    if (getDataPermission(permissions, "Home", "menu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.HOME_PAGE}`, // url yang diregister di route
        permissions: getDataPermission(permissions, "Home", "menu"), // params digunakan untuk melempar seluruh data yang nantinya bakal di pake untuk melihat access apa saja yang bisa digunakan user
      };
      return url;
    }
    if (getDataPermission(permissions, "Dashboard", "menu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.DASHBOARD}`,
        permissions: getDataPermission(permissions, "Dashboard PIC", "submenu"),
      };
      return url;
    }
    if (
      getDataPermission(permissions, "Dashboard Management", "submenu")?.permissions?.length > 0
    ) {
      url = {
        ...url,
        urlsName: `${urlsName.DASHBOARD_DYNAMIC}`,
        permissions: getDataPermission(permissions, "Dashboard Management", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Dashboard Employee", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.DASHBOARD_EMPLOYEE}`,
        permissions: getDataPermission(permissions, "Dashboard Employee", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Permit to Work", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.PERMIT_TO_WORK}`,
        permissions: getDataPermission(permissions, "Permit to Work", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "My Task", "menu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.MY_TASK}`,
        permissions: getDataPermission(permissions, "My Task", "menu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Medical Center", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.RESERVATION_LIST}`,
        permissions: getDataPermission(permissions, "Medical Center", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Medical Record", "menu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.USER_INQUIRY}`,
        permissions: getDataPermission(permissions, "Medical Record", "menu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Medical Center Doctor", "menu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.MEDICAL_CENTER}`,
        permissions: getDataPermission(permissions, "Medical Center Doctor", "menu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "List Form", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.GENERAL_FORM}`,
        permissions: getDataPermission(permissions, "List Form", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Workflow Form", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.FORM_FLOW_LIST}`,
        permissions: getDataPermission(permissions, "Workflow Form", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "User", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.USER}`,
        permissions: getDataPermission(permissions, "User", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Role", "submenu").permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.ROLE}`,
        permissions: getDataPermission(permissions, "Role", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Company", "submenu").permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.COMPANY_PROFILE}`,
        permissions: getDataPermission(permissions, "Company", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Hierarchy Company", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.HIERARCHY_LIST}`,
        permissions: getDataPermission(permissions, "Hierarchy Company", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Procedure Config", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.PROCEDURE_CONFIG}`,
        permissions: getDataPermission(permissions, "Procedure Config", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Bulk Upload Report", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.BULK_UPLOAD_USER}`,
        permissions: getDataPermission(permissions, "Bulk Upload Report", "submenu"),
      };
      return url;
    }

    if (getDataPermission(permissions, "Medical Center", "submenu")?.permissions?.length > 0) {
      url = {
        urlsName: `${urlsName.MASTER_KEY_HEATH}`,
        search: "id=2&?type=Health",
        permissions: getDataPermission(permissions, "Medical Center", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "SHE", "submenu")?.permissions?.length > 0) {
      url = {
        urlsName: `${urlsName.MASTER_KEY_SAFETY}`,
        search: "id=1&?type=Safety",
        permissions: getDataPermission(permissions, "SHE", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Equipment", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.MASTER_LIST_EQUIPMENT_TYPE}`,
        permissions: getDataPermission(permissions, "Equipment", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Tools", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.TOOLS_LIST}`,
        permissions: getDataPermission(permissions, "Tools", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Rank Configuration", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.TYPE_OF_WORK}`,
        permissions: getDataPermission(permissions, "Rank Configuration", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "Location", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.MASTER_LOCATION}`,
        permissions: getDataPermission(permissions, "Location", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "home", "menu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.HOME_PAGE}`,
        permissions: getDataPermission(permissions, "home", "menu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "HIRADC Submission", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.LIST_HIRADC}`,
        search: "type=hiradcSubbmission",
        permissions: getDataPermission(permissions, "HIRADC Submission", "submenu"),
      };
      return url;
    }
    if (getDataPermission(permissions, "HIRADC List", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.LIST_HIRADC}`,
        search: "type=hiradcList",
        permissions: getDataPermission(permissions, "HIRADC List", "submenu"),
      };
      return url;
    }

    if (getDataPermission(permissions, "Reassign", "submenu")?.permissions?.length > 0) {
      url = {
        ...url,
        urlsName: `${urlsName.REASSIGN}`,
        permissions: getDataPermission(permissions, "Reassign", "submenu"),
      };
      return url;
    }

    return url;
  };

  // Match the permission of "fire tools and equipments" with the "equipments" submenu of master data because originally, "fire tools and equipment" is from that submenu.
  const fireEquipmentPermission = useMemo(() => {
    const lastResort = {
      category: "submenu",
      id: 99,
      name: "Fire Tools & Equipment",
      permissions: ["create", "read", "update", "delete"],
    };
    if (!dataAdmin?.role[0]?.features[0]?.web) return lastResort;

    const list: Permission[] = dataAdmin.role[0].features[0].web;
    const target = list.find((item) => item.name === "Equipment" && item.category === "submenu");

    if (!target) return lastResort;

    const copy = structuredClone(target);
    copy.name = "Fire Tools & Equipment";
    return copy;
  }, [dataAdmin]);

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        {showTopBtn && (
          <div className={styles.floating_btn} onClick={() => goToTop()}>
            <UpCircleTwoTone style={{ fontSize: 25 }} />
          </div>
        )}
        <HeaderComponent key={"header_component"} onCollapse={() => setCollapse(!collapse)} />
        <Sidebar
          collapse={collapse}
          permissions={[...dataAdmin?.role[0]?.features[0]?.web, fireEquipmentPermission]}
          breakpoint={breakpoint}
          setCollapse={setCollapse}
        />
        <Layout
          className="site-layout"
          style={{
            marginLeft: collapse
              ? breakpoint.isBreaking("lg")
                ? 0
                : 55
              : breakpoint.isBreaking("lg")
              ? 0
              : 255,
          }}
        >
          <div style={{ height: 64, backgroundColor: "#fff" }} />
          <ContentComponent>
            <Switch>
              <Route
                path="/"
                render={() => (
                  <Redirect
                    to={{
                      pathname: getUrl(dataAdmin?.role[0]?.features[0]?.web).urlsName,
                      state: {
                        from: "/",
                        permissions: getUrl(dataAdmin?.role[0]?.features[0]?.web).permissions,
                      },
                      search: getUrl(dataAdmin?.role[0]?.features[0]?.web).search,
                    }}
                  />
                )}
                exact={true}
              />
              <Route path={urlsName.HOME_PAGE} exact component={HomePage} />
              <Route path={urlsName.DASHBOARD} exact component={DashboardPageMeta} />
              <Route path={urlsName.DASHBOARD_PIC} exact component={DashboardPIC} />
              <Route path={urlsName.DASHBOARD_EMPLOYEE} exact component={DashboardEmployee} />
              <Route path={urlsName.PERMIT_TO_WORK} exact component={PermitWork} />
              <Route path={urlsName.GREETING} exact component={GreetingIndexPage} />
              <Route path={urlsName.GREETING_ADD} exact component={GreetingAddPage} />
              <Route path={urlsName.USER} exact component={UserIndexPage} />
              <Route path={urlsName.ADD_SITE} exact component={SiteAddPage} />
              <Route path={urlsName.SITE} exact component={SiteIndexPage} />
              <Route path={urlsName.USER_UPDATE} exact component={UserUpdateaPge} />
              <Route path={urlsName.ARCHIVE_USER} exact component={ArchiveUser} />
              <Route path={urlsName.ROLE} exact component={RoleIndexPage} />
              <Route path={urlsName.ARCHIVE_ROLE} exact component={ArchiveRole} />
              <Route path={urlsName.ROLE_ADD} exact component={RoleAddPage} />
              <Route path={urlsName.ROLE_UPDATE} exact component={RoleUpdatePage} />
              <Route
                path={urlsName.GENERAL_CORPORATE}
                exact
                component={GeneralCorporateProfilePage}
              />
              <Route path={urlsName.GENERAL_GROUP} exact component={GeneralGroupPage} />
              <Route path={urlsName.GENERAL_ROLE} exact component={GeneralRolePage} />
              <Route path={urlsName.GENERAL_THEME} exact component={GeneralThemePage} />
              <Route path={urlsName.GENERAL_FORM} exact component={GeneralFormIndexPage} />
              <Route path={urlsName.GENERAL_FORM_ADD} exact component={GeneralFormAddPage} />
              <Route path={urlsName.GENERAL_FORM_UPDATE} exact component={GeneralFormUpdatePage} />
              <Route
                path={urlsName.GENERAL_FORM_DRAFT}
                exact
                component={GeneralFormDraftIndexPage}
              />
              <Route
                path={urlsName.GENERAL_FORM_ARCHIVE}
                exact
                component={GeneralFormArchiveIndexPage}
              />
              <Route path={urlsName.GENERAL_REMINDER} exact component={GeneralReminderPage} />
              <Route path={urlsName.FORM_FLOW_LIST} exact component={FormFlowPage} />
              <Route path={urlsName.FORM_FLOW_ADD} exact component={FormFlowAddPage} />
              <Route path={urlsName.MASTER_KEY} exact component={MasterKeyPage} />
              <Route path={urlsName.MASTER_KEY_CATEGORI} exact component={MasterCategoriPage} />
              <Route path={urlsName.MASTER_KEY_ARCHIVE} exact component={MasterArchivePage} />
              <Route path={urlsName.MASTER_KEY_ADD} exact component={MasterKeyAddPage} />
              <Route
                path={urlsName.MASTER_KEY_ADD_CATEGORY}
                exact
                component={MasterKeyAddCategoriPage}
              />
              <Route path={urlsName.MASTER_KEY_UPDATE} exact component={MasterKeyUpdatePage} />
              <Route path={urlsName.MASTER_KEY_HEATH} exact component={MasterKeyHealth} />
              <Route path={urlsName.MASTER_KEY_SAFETY} exact component={MasterKeySafety} />
              <Route path={urlsName.MASTER_ATT} exact component={MasterAttPage} />
              <Route path={urlsName.MASTER_ATT_ADD} exact component={MasterAttAddPage} />
              <Route path={urlsName.MASTER_ATT_UPDATE} exact component={MasterAttUpdatePage} />
              <Route path={urlsName.DETAIL_ATT} exact component={DetailAttPage} />
              <Route path={urlsName.DETAIL_ATT_ADD} exact component={DetailAttAddPage} />
              <Route path={urlsName.DETAIL_ATT_UPDATE} exact component={DetailAttUpdatePage} />
              <Route path={urlsName.MASTER_LOCATION} exact component={MasterLocationPage} />
              <Route path={urlsName.MASTER_LOCATION_ADD} exact component={MasterLocationAddPage} />
              <Route path={urlsName.MASTER_LIST_SITE} exact component={MasterSitePage} />
              <Route path={urlsName.MASTER_ADD_SITE} exact component={AddNewSitePage} />
              <Route path={urlsName.MASTER_EDIT_SITE} exact component={UpdateSitePage} />
              <Route path={urlsName.MASTER_LIST_RISK} exact component={MasterRiskPage} />
              <Route path={urlsName.MASTER_ADD_RISK} exact component={AddNewRiskPage} />
              <Route path={urlsName.MASTER_EDIT_RISK} exact component={UpdateRiskPage} />
              <Route path={urlsName.MASTER_LIST_SHIFT} exact component={MasterShiftPage} />
              <Route path={urlsName.MASTER_ADD_SHIFT} exact component={AddNewShiftPage} />
              <Route path={urlsName.MASTER_EDIT_SHIFT} exact component={UpdateShiftPage} />
              <Route path={urlsName.MASTER_LIST_EQUIPMENT} exact component={MasterEquipmentPage} />
              <Route path={urlsName.MASTER_LOCATION_TYPE} exact component={MasterLocationType} />
              <Route path={urlsName.MASTER_LOCATION_ADD_TYPE} exact component={MasterLocationAdd} />
              <Route path={urlsName.TOOLS_LIST} exact component={MasterToolsPage} />
              <Route path={urlsName.TOOLS_ADD} exact component={MasterToolsAddPage} />
              <Route
                path={urlsName.MASTER_LOCATION_UPDATE_TYPE}
                exact
                component={MasterLocationUpadate}
              />
              <Route path={urlsName.MASTER_ADD_EQUIPMENT} exact component={AddNewEquipmentPage} />
              <Route
                path={urlsName.MASTER_LIST_EQUIPMENT_CATEGORY}
                exact
                component={MasterEquipmentCategory}
              />
              <Route
                path={urlsName.MASTER_LIST_EQUIPMENT_TYPE}
                exact
                component={MasterEquipmentType}
              />
              <Route
                path={urlsName.MASTER_ADD_EQUIPMENT_TYPE}
                exact
                component={AddNewEquipmentType}
              />
              <Route
                path={urlsName.MASTER_LIST_CHILD_EQUIPMENT}
                exact
                component={MasterEquipmentChild}
              />
              <Route
                path={urlsName.MASTER_ADD_CHILD_EQUIPMENT}
                exact
                component={AddNewEquipmentChild}
              />
              <Route
                path={urlsName.MASTER_LOG_CHILD_EQUIPMENT}
                exact
                component={MasterEquipmentDetailLogPage}
              />
              <Route
                path={urlsName.MASTER_LIST_SUB_LOCATION}
                exact
                component={MasterSubLocationPage}
              />
              <Route path={urlsName.MASTER_FIRE_EQUIPMENT} exact component={MasterFireEquipment} />
              <Route
                path={urlsName.MASTER_FIRE_EUIPMQNT_ADD}
                exact
                component={MasterFireEquipmentAdd}
              />
              <Route
                path={urlsName.MASTER_FIRE_EQUIPMENT_CHILD}
                exact
                component={MasterFireEquipmentChild}
              />
              <Route
                path={urlsName.MASTER_FIRE_EQUIPMENT_CHILD_ADD}
                exact
                component={MasterFireEquipmentChildAdd}
              />
              <Route
                path={urlsName.MASTER_FIRE_EQUIPMENT_CHILD_LOG}
                exact
                component={MasterEquipmentDetailLogPage}
              />
              {/* rank configuration */}
              <Route path={urlsName.TYPE_OF_WORK} exact component={TypeOfWorkPage} />
              <Route path={urlsName.RANK_CONFIGURATION} exact component={RankConfigurationPage} />
              <Route path={urlsName.RANK_DETAIL} exact component={RankDetailPage} />
              <Route path={urlsName.TYPE_OF_WORK_CREATE} exact component={TypeOfWorkAdd} />
              <Route path={urlsName.RESERVATION_LIST} exact component={ReservationList} />
              <Route path={urlsName.MEDICAL_CENTER} exact component={MedicalCenterList} />
              <Route path={urlsName.CREATE_RESERVATION} exact component={CreateReservation} />
              <Route path={urlsName.DETAIL_RESERVATION} exact component={ReservationDetail} />
              <Route path={urlsName.MASTER_ADD_SUB_LOCATION} exact component={AddSubLocationPage} />
              <Route path={urlsName.REPORT_SUBMISION} exact component={ReportSubmisionIndexPage} />
              <Route
                path={urlsName.REPORT_SUBMISION + "/:formId"}
                exact
                component={ReportSubmisionDetailPage}
              />
              <Route path={urlsName.MY_TASK} exact component={MyTaskPage} />
              <Route path={urlsName.MY_TASK_DETAIL} exact component={MyTaskDetail} />
              <Route path={urlsName.MEDICAL_RECORD} exact component={MedicalRecord} />
              <Route path={urlsName.MEDICAL_LIST} exact component={MedicalList} />
              <Route path={urlsName.MEDICINE_LIST} exact component={Medicine} />
              <Route path={urlsName.COMPANY_PROFILE} exact component={CompanyProfileIndexPage} />
              <Route path={urlsName.ADD_COMPANY_PROFILE} exact component={CompanyProfileAddPage} />
              <Route path={urlsName.HIERARCHY_LIST} exact component={HierarchyCompanyListPage} />
              <Route path={urlsName.ADD_HIERARCHY_LIST} exact component={HierarchyCompanyAddPage} />
              <Route
                path={urlsName.SUB_HIERARCHY_LIST}
                exact
                component={SubHierarchyCompanyListPage}
              />
              <Route
                path={urlsName.ADD_SUB_HIERARCHY_LIST}
                exact
                component={SubHierarchyCompanyAddPage}
              />
              {/* Safety */}
              <Route path={urlsName.PERMIT_TO_WORK} exact component={PermitWork} />
              <Route path={urlsName.CREATE_PERMIT_TO_WORK} exact component={CreatePermitWork} />
              <Route path={urlsName.SPESIAL_PERMIT_TO_WORK} exact component={SpesialPermit} />
              <Route path={urlsName.HOT_PERMIK} exact component={HotPermik} />
              <Route path={urlsName.HOT_CONFIRM_PERMIK} exact component={HotConfirmPermik} />
              <Route
                path={urlsName.BULK_UPLOAD_MEDICINE}
                exact
                component={BulkUploadReportMedicine}
              />
              <Route path={urlsName.BULK_UPLOAD_USER} exact component={BulkUploadReportUser} />

              {/* Medicine */}
              <Route path={urlsName.LIST_MEDICINE} exact component={MedicineList} />
              <Route path={urlsName.LOG_MEDICINE} exact component={MedicineLog} />
              {/* User Inquiry */}
              <Route path={urlsName.USER_INQUIRY} exact component={UserInquiry} />
              {/* Medical Inventory */}
              <Route path={urlsName.INVENTORY} exact component={Inventory} />
              <Route path={urlsName.DETAIL_INVENTORY} exact component={DetailInventory} />
              {/* Article */}
              <Route path={urlsName.ARTICLE_LIST} exact component={ArticleList} />
              <Route path={urlsName.LIST_ARTICLES} exact component={ListArticles} />
              <Route path={urlsName.LIST_ARTICLES_CREATE} exact component={AddArticles} />

              <Route path={`${urlsName.ARTICLE_DETAIL}/:slug`} exact component={ArticleDetail} />
              {/* Safety Voice Scoring */}
              <Route path={urlsName.SAFETY_VOICE_SCORING} exact component={SafetyVoiceScoring} />
              <Route
                path={`${urlsName.SAFETY_VOICE_SCORING_DETAIL}`}
                exact
                component={SafetyVoiceScoringDetail}
              />
              {/* Reassign */}
              <Route path={urlsName.REASSIGN} exact component={ReassignPage} />
              <Route
                path={urlsName.DETAIL_FOLLOW_UP_ACTION}
                exact
                component={DetailFollowUpAction}
              />

              <Route
                path={urlsName.WORKFLOW_CATEGORIZATION}
                exact
                component={WorkflowCategorization}
              />
              {/* Undo Status */}
              <Route path={urlsName.UNDO_STATUS} exact component={UndoStatus} />
              {/* Procedure Config */}
              <Route path={urlsName.PROCEDURE_CONFIG} exact component={ProcedureConfig} />
              <Route path={urlsName.ADD_PROCEDURE_CONFIG} exact component={AddProcedureConfig} />

              {/* hiradc */}
              <Route path={urlsName.LIST_HIRADC} exact component={HiradcSubbmission} />
              <Route path={urlsName.HIRADC_LIST} exact component={hiradcList} />
              <Route path={urlsName.ARCHIVED_HIRADC} exact component={Archived} />
              <Route path={urlsName.ADD_HIRADC} exact component={addHiradc} />
              <Route path={urlsName.DETAIL_HIRADC} exact component={DetailHiradc} />

              <Route path={urlsName.DASHBOARD_DYNAMIC} exact component={DashboardTable} />
              <Route path={urlsName.DASHBOARD_PAGE} exact component={DashboardPageMeta} />
              <Route path={urlsName.DASHBOARD_CREATE} exact component={DashboardForm} />
              <Route
                path={`${urlsName.DASHBOARD_EDIT}/:idDashboard`}
                exact
                component={DashboardForm}
              />
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </ContentComponent>
        </Layout>
      </Layout>
      <LanguageDropdown />
    </>
  );
};

export default AppAuthenticatedLayout;
