import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../../services/http";
import urlsEndpoint from "../../../services/urlsEndpoint";
import { callback } from "../../../store/defaultSagas";
import { IDefaultRequestAction } from "../../../store/defaultTypes";
import { medicalInventoryAction } from "./_actions";
import { types } from "./_types";

function* workerGetCategoryInventory({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_CATEGORY_INVENTORY);
  try {
    const response = yield call(http.post, payload);
    yield put(medicalInventoryAction.getCategoryInventoryList.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(medicalInventoryAction.getCategoryInventoryList.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetInventoryList({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_INVENTORY_LIST);
  try {
    const response = yield call(http.post, payload);
    yield put(medicalInventoryAction.getInventoryList.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(medicalInventoryAction.getInventoryList.failure(err));
    yield callback(onFailure, err);
  }
}

export const watcherMedicalInventory = () => [
  takeLatest(types.GET_CATEGORY_REQUEST, workerGetCategoryInventory),
  takeLatest(types.GET_INVENTORY_REQUEST, workerGetInventoryList),
];
