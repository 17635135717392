export const types = {
  GET_MEDICAL_RECORD_LIST_REQUEST: "inquiry/GET_MEDICAL_RECORD_LIST_REQUEST",
  GET_MEDICAL_CHECK_UP_REQUEST: "inquiry/GET_MEDICAL_CHECK_UP_REQUEST",
  CREATE_MEDICAL_CHECK_UP_REQUEST: "inquiry/CREATE_MEDICAL_CHECK_UP_REQUEST",
  DELETE_MEDICAL_CHECK_UP_REQUEST: "inquiry/DELETE_MEDICAL_CHECK_UP_REQUEST",
  EXPORT_MEDICAL_RECORD_REQUEST: "inquiry/EXPORT_MEDICAL_RECORD_REQUEST",
  EXPORT_MEDICAL_RECORD_SUCCESS: "inquiry/EXPORT_MEDICAL_RECORD_SUCCESS",
  EXPORT_MEDICAL_RECORD_FAILED: "inquiry/EXPORT_MEDICAL_RECORD_FAILED",
};

export interface IFilter {
  reservationType: string[];
  date: string;
  time: string;
  dateFilter: string;
  startDate: string;
  endDate: string;
}

export interface IOrderBy {
  column: string[];
  direction: string;
}

export interface IGetList {
  start: number;
  limit: number;
  page: number;
  nik: string;
  keyword: string;
  requestForm: string;
  filter: IFilter;
  orderBy: IOrderBy;
}

export interface IList {
  dateTime: string;
  treatment: string;
  note: string;
  diagnose: string;
  description: string;
  time: string;
  assignmentNumber: string;
  status: string;
}

export interface IPagination {
  currentPage: number;
  limit: number;
  totalItem: number;
  totalPage: number;
}

export interface ITreatment {
  treatment: string;
  diagnose: string;
  description: string;
  assignmentNumber: string;
  treatmentId: number;
}

export interface IReservation {
  dateTime: string;
  reservationType: string;
  reservationCode: string;
  status: string;
}

export interface IVitalSigns {
  bloodPressure: string;
  colorBlind: string;
  pulse: string;
  temperature: string;
  respiration: string;
  visus: string;
}

export interface IMedicine {
  dose: string;
  doseRule: string;
  qty: number;
  medicineName: string;
}

export interface IDetail {
  treatment: ITreatment;
  reservation: IReservation;
  vitalsigns: IVitalSigns;
  medicine: IMedicine[];
}

export interface IExportMedicalRecord {
  username: string;
  filter: object;
  orderBy: {
    column: string[];
    direction: string | null;
  };
}
