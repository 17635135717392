import { AxiosResponse } from "axios";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { SioType } from "./Add/types";
import {
  IAddUserRequestData,
  IGetDetailUserRequestData,
  IGetListArchiveRequestData,
  IGetUserRequestData,
  types as userTypes,
  UploadReturnType,
} from "./_types";

export const actions = {
  getUsers: {
    request: (requestData: IDefaultRequestParams<IGetUserRequestData>) => ({
      ...requestData,
      type: userTypes.GET_USERS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: userTypes.GET_USERS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.GET_USERS_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getRoles: {
    request: (requestData: any) => ({
      ...requestData,
      type: userTypes.GET_ROLE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: userTypes.GET_ROLE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.GET_ROLE_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  resetUser: {
    request: (requestData: IDefaultRequestParams<IAddUserRequestData>) => ({
      ...requestData,
      type: userTypes.RESET_USER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  addUser: {
    request: (requestData: IDefaultRequestParams<IAddUserRequestData>) => ({
      ...requestData,
      type: userTypes.ADD_USER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  createCompany: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: userTypes.CREATE_COMPANY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: userTypes.CREATE_COMPANY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.CREATE_COMPANY_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  editUser: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: userTypes.EDIT_USER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: userTypes.EDIT_USER_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.EDIT_USER_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  deleteUser: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: userTypes.DELETE_USER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  getDetailUser: {
    request: (requestData: IDefaultRequestParams<IGetDetailUserRequestData>) => ({
      ...requestData,
      type: userTypes.GET_DETAIL_USER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: userTypes.GET_DETAIL_USER_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.GET_DETAIL_USER_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListCompany: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: userTypes.GET_LIST_COMPANY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: userTypes.GET_LIST_COMPANY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.GET_LIST_COMPANY_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getDetailUserCompany: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: userTypes.GET_DETAIL_USER_COMPANY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  getListArchive: {
    request: (requestData: IDefaultRequestParams<IGetListArchiveRequestData>) => ({
      ...requestData,
      type: userTypes.GET_LIST_ARCHIVE_USER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: userTypes.GET_LIST_ARCHIVE_USER_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.GET_LIST_ARCHIVE_USER_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListDepartment: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: userTypes.GET_LIST_DEPARTMENT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: userTypes.GET_LIST_DEPARTMENT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.GET_LIST_DEPARTMENT_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListGroup: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: userTypes.GET_LIST_GROUP_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: userTypes.GET_LIST_GROUP_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.GET_LIST_GROUP_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListPosition: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: userTypes.GET_LIST_POSITION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: userTypes.GET_LIST_POSITION_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.GET_LIST_POSITION_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListSection: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: userTypes.GET_LIST_SECTION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: userTypes.GET_LIST_SECTION_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.GET_LIST_SECTION_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListModel: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: userTypes.GET_LIST_MODEL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  getListDirecSuperior: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: userTypes.GET_LIST_DIRECT_SUP_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: userTypes.GET_LIST_DIRECT_SUP_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.GET_LIST_DIRECT_SUP_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListSio: {
    request: (requestData: IDefaultRequestParams<string>) => ({
      ...requestData,
      type: userTypes.GET_SIO_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: SioType[]) => ({
      type: userTypes.GET_SIO_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.GET_SIO_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  clearListSio: () => ({
    type: userTypes.CLEAR_SIO_FILES,
  }),
  deleteSio: {
    request: (requestData: IDefaultRequestParams<number>) => ({
      ...requestData,
      type: userTypes.DELETE_SIO_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (sioId: number) => ({
      payload: sioId,
      type: userTypes.DELETE_SIO_SUCCESS,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.DELETE_SIO_REQUEST,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  updateSio: {
    request: (requestData: IDefaultRequestParams<SioType>) => ({
      ...requestData,
      type: userTypes.UPDATE_SIO_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (sio: SioType) => ({
      payload: sio,
      type: userTypes.UPDATE_SIO_SUCCESS,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.UPDATE_SIO_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  addSio: {
    request: (requestData: IDefaultRequestParams<Omit<SioType, "id" | "status">[]>) => ({
      ...requestData,
      type: userTypes.ADD_SIO_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: SioType[]) => ({
      payload: data,
      type: userTypes.GET_SIO_SUCCESS,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: userTypes.ADD_SIO_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};

export async function restoreRoleAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.RESTORE_USER);
  try {
    const response = await http.put(id, "");
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

interface ManualUploadFileResponseObject {
  success?: boolean;
  error?: boolean;
  data?: string;
}

export async function manualUploadFile(fileData: any): Promise<ManualUploadFileResponseObject> {
  const http = new HttpService("");
  const successResponse: ManualUploadFileResponseObject = {};

  const formData = new FormData();
  formData.append("file", fileData);

  try {
    const response: AxiosResponse<UploadReturnType> = await http.upload({
      url: urlsEndpoint.UPLOAD_FILE,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (typeof response.data.data.fileData.url === "undefined") {
      successResponse.error = true;
      successResponse.data = undefined;
      successResponse.success = false;
      return successResponse;
    }

    successResponse.error = false;
    successResponse.success = true;
    successResponse.data = response.data.data.fileData.url;

    return successResponse;
  } catch (err) {
    console.error(err);
    const errorResponse: ManualUploadFileResponseObject = {};
    errorResponse.error = true;
    errorResponse.success = false;
    errorResponse.data = undefined;
    return errorResponse;
  }
}
