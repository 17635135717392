import { RootState } from "../../store/rootReducers";

export const selectors = {
  isRequestAction: (state: RootState) => state.reservation.actionStatus === "request",
  moreActionStatus: (state: RootState) => state.reservation.moreActionStatus,
  getUserData: (state: RootState) => state.auth.dataUsers,

  getMytask: (state: RootState) => state.reservation.myTask,
  getHistoryMyTask: (state: RootState) => state.reservation.myHistory,
  // getMyDetail: (state: RootState, formId: string) =>
  //   _.get(state.reservation.detailTask, formId, null),
  getMyDetail: (state: RootState) => state.reservation.detailTask,
  pagination: (state: RootState) => state.reservation.pagination,
  paginationHistory: (state: RootState) => state.reservation.paginationHistory,
  isLoading: (state: RootState) => state.reservation.isLoading,
  getTimelinestatus: (state: RootState) => state.reservation.timelineStatus,
  isLoadingTimelineStatus: (state: RootState) => state.reservation.isLoadingTimeline,
  isLoadingHistory: (state: RootState) => state.reservation.isLoadingHistory,
};
