import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { actions } from "./_actions";
import { types, IGetLogMedicineRequestData, IGetMedicineRequestData } from "./_types";

export const watcherMedicine = () => [
  takeLatest(types.GET_MEDICINE_KEYS_REQUEST, workerGetMedicine),
  takeLatest(types.GET_MEDICINE_LOG_REQUEST, workerGetMedicineLog),
  takeLatest(types.GET_MEDICINE_CODE_REQUEST, workerGetMedicineCode),
  takeLatest(types.EXPORT_MEDICINE_REQUEST, workerExportMedicine),
];

function* workerGetMedicine({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetMedicineRequestData>): any {
  const http = new HttpService(urlsEndpoint.LIST_MEDICINE);

  try {
    const response = yield call(http.post, payload);
    yield put(actions.getMedicine.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getMedicine.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerExportMedicine({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.file, {
      url: urlsEndpoint.EXPORT_MEDICINE,
      method: "post",
      data: payload,
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel",
      },
    });
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetMedicineCode({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetMedicineRequestData>): any {
  const http = new HttpService(urlsEndpoint.LIST_MEDICINE);

  try {
    const response = yield call(http.post, payload);
    yield put(actions.getMedicineCode.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getMedicineCode.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetMedicineLog({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetLogMedicineRequestData>): any {
  const http = new HttpService(urlsEndpoint.LOG_MEDICINE);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getLogMedicine.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getLogMedicine.failure(err));
    yield callback(onFailure, err);
  }
}
