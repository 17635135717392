import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { types as articlesType } from "./_types";

export const actions = {
  getPinArticles: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: articlesType.GET_PIN_ARTICLES_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: articlesType.GET_PIN_ARTICLES_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: articlesType.GET_PIN_ARTICLES_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  getArticlePin: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: articlesType.GET_PIN_ARTICLES_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: articlesType.GET_PIN_ARTICLES_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: articlesType.GET_PIN_ARTICLES_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  getCatgeoryArticles: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: articlesType.GET_CATEGORY_ARTICLES_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: articlesType.GET_CATEGORY_ARTICLES_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: articlesType.GET_CATEGORY_ARTICLES_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getCategory: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: articlesType.GET_LIST_CATEGORY_ARTICLES_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: articlesType.GET_LIST_CATEGORY_ARTICLES_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: articlesType.GET_LIST_CATEGORY_ARTICLES_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListArticles: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: articlesType.GET_LIST_ARTICLES_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: articlesType.GET_LIST_ARTICLES_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: articlesType.GET_LIST_ARTICLES_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getUpdatePin: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: articlesType.UPDATE_PIN_ARTICLES_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: articlesType.UPDATE_PIN_ARTICLES_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: articlesType.UPDATE_PIN_ARTICLES_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  // Delete
  deleteArticle: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: articlesType.DELETE_ARTICLES_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  getUpdateArticle: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: articlesType.EDIT_ARTICLES_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: articlesType.EDIT_ARTICLES_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: articlesType.EDIT_ARTICLES_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createArticle: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: articlesType.CREATE_ARTICLES_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: articlesType.CREATE_ARTICLES_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: articlesType.CREATE_ARTICLES_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  // Category
  createCategory: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: articlesType.ADD_CATEGORY_ARTICLES_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
};

export async function getListArticles(): Promise<{
  success?: boolean;
  error?: boolean;
  data: any;
}> {
  const http = new HttpService(urlsEndpoint.PIN_ARTICLES);
  try {
    const response = await http.custom({
      url: `${urlsEndpoint.PIN_ARTICLES}`,
      method: "get",
    });
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
