import { Layout, Menu, Badge } from "antd";
import { FC, useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DASHBOARD_SIDEBAR,
  DATA_MASTER,
  FORM,
  // LOGO_SENTINEL,
  MY_TASK,
  PLUS_CIRCLE,
  RESERVATION,
  SAFETY,
  ARTICLES,
  USER_MANAGEMENT,
  MEDICINE,
  HOME,
  MEDICAL,
  HIRADC,
  NEW_LOGO,
} from "../../assets/images";
import * as urlsName from "../../helpers/urlsName";
import { CloseOutlined } from "@ant-design/icons";
import { IMyTask, taskCounterNameMapping } from "./_types";
import { actions as sidebarActions } from "./_actions";
import { RootState } from "../../store/rootReducers";

import styles from "./styles.module.less";
import useBreakpoints from "../../helpers/hooks/useBreakpoints";
import BlackBanner from "../BlackBanner";

const { Sider } = Layout;
const { SubMenu } = Menu;

interface SidebarPropsComponent {
  collapse?: boolean;
  permissions?: any[];
  breakpoint: ReturnType<typeof useBreakpoints>;
  setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar: FC<SidebarPropsComponent> = ({ collapse, permissions, breakpoint, setCollapse }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { tasksCounter } = useSelector((rootState: RootState) => rootState.sidebar);

  /* Not recommended to push "ALL" if there are other value present */
  const taskCounterType = useSelector((rootState: RootState) => {
    if (rootState.profile.userProfile === null) {
      return [];
    }

    if (rootState.profile.userProfile.userMenu?.length === 0) {
      return ["Medical Center"]; // The default menu to be displayed
    }

    return rootState.profile.userProfile.userMenu?.map((item: any) => item.menuPreferenceName);
  });

  const [isMyTaskBadgeVisible, setIsMyTaskBadgeVisible] = useState(true);

  useEffect(() => {
    dispatch(sidebarActions.getMyTaskCounter.request());
  }, [dispatch, taskCounterType?.length]);

  const findTask = (typeFlow: string | null): IMyTask | undefined => {
    if (!tasksCounter || !typeFlow) return undefined;

    const target = tasksCounter.find((item: any) => item?.typeFlow?.toLowerCase() === typeFlow);
    return target;
  };

  const findAllTaskCounterTotal = (): number | undefined => {
    if (!tasksCounter) return undefined;

    return tasksCounter.reduce((acc: number, item: IMyTask) => acc + item.myTask, 0);
  };

  const shouldMyTaskBeMultiple =
    taskCounterType?.length > 1 || (taskCounterType?.length > 0 && taskCounterType[0] === "ALL");

  const findManagement: any = permissions?.find((res: any) => res.name === "Management Dashboard");
  const pathnameArray = [
    {
      key: "1",
      icon: <img src={HOME} alt="" width={20} />,
      title: "Home",
      path: urlsName.HOME_PAGE,
      type: "menu",
      sub: [],
    },
    {
      [findManagement?.permissions?.length > 0 ? "icons" : "icon"]: (
        <img src={DASHBOARD_SIDEBAR} alt="" width={20} />
      ),
      key: "dashboard",
      title: "Dashboard",
      path: urlsName.DASHBOARD,
      type: findManagement?.permissions?.length > 0 ? "sub" : "menu",
      sub:
        findManagement?.permissions?.length > 0
          ? [
              {
                title: "Dashboard",
                path: urlsName.DASHBOARD,
                search: null,
              },
              { title: "Management Dashboard", path: urlsName.DASHBOARD_DYNAMIC, search: null },
            ]
          : [],
    },
    {
      icon: <img src={SAFETY} alt="" width={20} />,
      title: "Permit to Work",
      path: urlsName.PERMIT_TO_WORK,
      type: "menu",
      sub: [],
    },
    {
      icons: <img src={HIRADC} alt="" width={20} />,
      key: "hiradc",
      title: "HIRADC",
      path: "",
      type: "sub",
      sub: [
        { title: "HIRADC Submission", path: `${urlsName.LIST_HIRADC}` },
        { title: "HIRADC List", path: `${urlsName.HIRADC_LIST}` },
      ],
    },
    {
      [shouldMyTaskBeMultiple ? "icons" : "icon"]: <img src={MY_TASK} alt="" width={20} />,
      // icons: <img src={MY_TASK} alt="" width={20} />,
      title: "My Task",
      path: shouldMyTaskBeMultiple ? "" : `${urlsName.MY_TASK}`,
      // path: "",
      type: shouldMyTaskBeMultiple ? "sub" : "menu",
      // type: "sub",
      sub: taskCounterNameMapping?.map((item: any) => ({
        title: item.title,
        path: item.path,
        search: item.search === null ? null : `type=${item.search}`,
      })),
    },
    {
      icon: <img src={RESERVATION} alt="" width={20} />,
      title: "Reassign",
      path: urlsName.REASSIGN,
      type: "menu",
      sub: [],
    },
    {
      icon: <img src={RESERVATION} alt="" width={20} />,
      title: "Undo Status",
      path: urlsName.UNDO_STATUS,
      type: "menu",
      sub: [],
    },
    {
      key: "37",
      icon: <img src={RESERVATION} alt="" width={20} />,
      title: "Safety Voice Scoring",
      path: urlsName.SAFETY_VOICE_SCORING,
      type: "menu",
      sub: [],
    },
    {
      icon: <img src={RESERVATION} alt="" width={20} />,
      title: "Medical Center",
      path: urlsName.RESERVATION_LIST,
      type: "menu",
      sub: [],
    },
    {
      icon: <img src={MEDICAL} alt="" width={20} />,
      title: "Medical Record",
      path: urlsName.USER_INQUIRY,
      type: "menu",
      sub: [],
    },
    {
      icon: <img src={PLUS_CIRCLE} alt="" width={20} />,
      title: "Medical Center Doctor",
      path: urlsName.MEDICAL_CENTER,
      type: "menu",
      sub: [],
    },
    {
      icons: <img src={MEDICINE} alt="" width={20} />,
      key: "medical_inventory",
      title: "Medical Inventory",
      path: "",
      type: "sub",
      sub: [
        {
          title: "Medicine",
          path: `${urlsName.LIST_MEDICINE}`,
        },
        {
          title: "Bulk Upload Medicine",
          path: `${urlsName.BULK_UPLOAD_MEDICINE}`,
        },
        {
          title: "Inventory",
          path: `${urlsName.INVENTORY}`,
        },
      ],
    },
    {
      icon: <img src={ARTICLES} alt="" width={20} />,
      title: "Articles",
      path: urlsName.LIST_ARTICLES,
      type: "menu",
      sub: [],
    },
    {
      icons: <img src={FORM} alt="" width={20} />,
      key: "forms",
      title: "Forms",
      path: "",
      type: "sub",
      sub: [
        { title: "List Form", path: urlsName.GENERAL_FORM },
        { title: "Workflow Form", path: urlsName.FORM_FLOW_LIST },
      ],
    },
    {
      icons: <img src={USER_MANAGEMENT} alt="" width={20} />,
      key: "user_management",
      title: "User Management",
      path: "",
      type: "sub",
      sub: [
        { title: "User", path: urlsName.USER },
        {
          title: "Bulk Upload User",
          path: `${urlsName.BULK_UPLOAD_USER}`,
        },
        { title: "Role", path: urlsName.ROLE },
        { title: "Company", path: urlsName.COMPANY_PROFILE },
        { title: "Hierarchy Company", path: urlsName.HIERARCHY_LIST },
        { title: "Procedure Config", path: urlsName.PROCEDURE_CONFIG },
      ],
    },
    {
      icons: <img src={DATA_MASTER} alt="" width={20} />,
      key: "master_data",
      title: "Master Data",
      path: "",
      type: "sub",
      sub: [
        {
          title: "Medical Center",
          path: `${urlsName.MASTER_KEY_HEATH}`,
          search: "id=2&?type=Health",
        },
        { title: "SHE", path: `${urlsName.MASTER_KEY_SAFETY}`, search: "id=1&?type=Safety" },
        { title: "Equipment", path: `${urlsName.MASTER_LIST_EQUIPMENT_CATEGORY}` },
        { title: "Fire Tools & Equipment", path: `${urlsName.MASTER_FIRE_EQUIPMENT}` },
        { title: "Tools", path: urlsName.TOOLS_LIST },
        { title: "Rank Configuration", path: `${urlsName.TYPE_OF_WORK}` },
        { title: "Location", path: urlsName.MASTER_LOCATION },
      ],
    },
  ];

  // let count = 0;
  let counts = 0;
  const activeKeys: any[] = [];
  pathnameArray.forEach((item) => {
    if (item.type === "menu") {
      counts++;
      if (pathname.includes(item.path)) {
        activeKeys.push(counts.toString());
      }
    }
    item.sub.forEach((e: any) => {
      counts++;
      if (pathname.includes(e.path)) {
        activeKeys.push(counts.toString());
      }
    });
  });
  const defaultActiveKey = [];
  defaultActiveKey.push(activeKeys.pop());

  const defaultOpenKeys = () => {
    if (location.pathname === "/" || location.pathname === "") {
      return ["home"];
    } else if (
      location.pathname.includes("/dashboard") ||
      location.pathname.includes("/dashboard-list")
    ) {
      return ["sub_dashboard"];
    } else if (
      location.pathname.includes("medicine") ||
      location.pathname.includes("bulk-upload-medicine") ||
      location.pathname.includes("category-inventory")
    ) {
      return ["sub_medical_inventory"];
    } else if (
      location.pathname.includes("general-form") ||
      location.pathname.includes("form-flow")
    ) {
      return ["sub_forms"];
    } else if (
      location.pathname.includes("user") ||
      location.pathname.includes("bulk-upload-user") ||
      location.pathname.includes("role") ||
      location.pathname.includes("company-profile") ||
      location.pathname.includes("hierarchy_list") ||
      location.pathname.includes("procedure-config")
    ) {
      return ["sub_user_management"];
    } else if (
      location.pathname.includes("master-health") ||
      location.pathname.includes("master-safety") ||
      location.pathname.includes("master-equipment-category") ||
      location.pathname.includes("master-fire") ||
      location.pathname.includes("tools") ||
      location.pathname.includes("typeof-work") ||
      location.pathname.includes("master-location") ||
      location.pathname.includes("master-categori") ||
      location.pathname.includes("master-equipment-type") ||
      location.pathname.includes("master-fire/child") ||
      location.pathname.includes("rank-configuration") ||
      location.pathname.includes("rank-detail") ||
      location.pathname.includes("master-sub-location") ||
      location.pathname.includes("detail-hiradc")
    ) {
      return ["sub_master_data"];
    } else if (
      location.pathname.includes("hiradc-submission") ||
      location.pathname.includes("list-hiradc")
    ) {
      return ["sub_hiradc"];
    }
  };

  let location = useLocation();

  const currentLocation = () => {
    if (location.pathname === "/" || location.pathname === "") {
      return "/home";
    } else if (location.pathname === "/master-categori") {
      return "/master-health";
    } else if (location.pathname === "/master-equipment-type") {
      return "/master-equipment-category";
    } else if (location.pathname === "/master-fire/child") {
      return "/master-fire";
    } else if (
      location.pathname === "/rank-configuration" ||
      location.pathname === "/rank-detail"
    ) {
      return "/typeof-work";
    } else if (location.pathname === "/master-sub-location") {
      return "/master-location";
    } else if (location.pathname === "/detail-hiradc") {
      return "/hiradc-submission";
    } else {
      return location.pathname;
    }
  };

  const [current, setCurrent] = useState(currentLocation());

  return (
    <Sider
      theme="light"
      // breakpoint={"lg"} // somehow this does nothing so I'm using custom breakpoint hooks.
      collapsedWidth={breakpoint.isBreaking("lg") ? 0 : 55}
      collapsed={collapse}
      width={275}
      style={{
        backgroundColor: "white",
        paddingInline: collapse && !breakpoint.isBreaking("lg") ? "8px" : "",
        borderRight: "1px solid #e5e7eb",
        zIndex: breakpoint.isBreaking("lg") ? 999 : 3,
        paddingBottom: 100,
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
        isolation: "isolate",
      }}
    >
      <div style={{ backgroundColor: "#ffffff" }}>
        {breakpoint.isBreaking("lg") && (
          <div className={styles.sidebar_header}>
            <img src={NEW_LOGO} alt="Sentinel" />
            <CloseOutlined onClick={() => setCollapse(true)} />
          </div>
        )}
        <Menu
          style={
            !breakpoint.isBreaking("lg")
              ? { paddingBlock: "70px" }
              : { paddingBlock: "0", height: "100vh" }
          }
          selectedKeys={[current]}
          defaultSelectedKeys={defaultActiveKey}
          defaultOpenKeys={defaultOpenKeys()}
          theme="light"
          mode="inline"
          onClick={(e) => {
            setCurrent(e.key);
          }}
        >
          {pathnameArray?.map((e, i) => {
            let hideMenu = [];
            switch (e.title) {
              case "My Task": {
                const findData: any = permissions?.find((item: any) => item?.name === "My Task");
                // const submenusPermissions = permissions
                //   ? permissions.filter((item) =>
                //       SUB_MENUS[e.title as keyof typeof SUB_MENUS].includes(item.id)
                //     )
                //   : [];

                if (findData?.permissions?.length === 0) {
                  // if (!submenusPermissions.some((submenu) => submenu.permissions.length > 0 && submenu.permissions.includes("read"))) {
                  hideMenu.push({ menu: "My Task", hide: true });
                } else {
                  hideMenu.push({ menu: "My Task", hide: false });
                }
                break;
              }
              case "HIRADC": {
                const findData: any = permissions?.find(
                  (res: any) => res.name === "HIRADC Submission"
                );
                const findData2: any = permissions?.find((res: any) => res.name === "HIRADC List");
                if (findData?.permissions?.length === 0 && findData2?.permissions?.length === 0) {
                  hideMenu.push({ menu: "Hiradc", hide: true });
                } else {
                  hideMenu.push({ menu: "Hiradc", hide: false });
                }
                break;
              }
              case "Dashboard": {
                const findData: any = permissions?.find((res: any) => res.name === "Dashboard");
                const findData2: any = permissions?.find(
                  (res: any) => res.name === "Management Dashboard"
                );
                if (findData?.permissions?.length === 0 && findData2?.permissions?.length === 0) {
                  hideMenu.push({ menu: "Dashboard", hide: true });
                } else {
                  hideMenu.push({ menu: "Dashboard", hide: false });
                }
                break;
              }
              case "Forms": {
                const findData: any = permissions?.find((res: any) => res.name === "List Form");
                const findData2: any = permissions?.find(
                  (res: any) => res.name === "Workflow Form"
                );
                if (findData?.permissions?.length === 0 && findData2?.permissions?.length === 0) {
                  hideMenu.push({ menu: "Forms", hide: true });
                } else {
                  hideMenu.push({ menu: "Forms", hide: false });
                }
                break;
              }
              case "Permit to Work": {
                const findData: any = permissions?.find(
                  (res: any) => res.name === "Permit to Work"
                );
                if (findData?.permissions?.length === 0) {
                  hideMenu.push({ menu: "Permit to Work", hide: true });
                } else {
                  hideMenu.push({ menu: "Permit to Work", hide: false });
                }
                break;
              }
              case "User Management": {
                const findData: any = permissions?.find((res: any) => res.name === "User");
                const findData2: any = permissions?.find((res: any) => res.name === "Role");
                const findData3: any = permissions?.find((res: any) => res.name === "Company");
                const findData4: any = permissions?.find(
                  (res: any) => res.name === "Hierarchy Company"
                );
                const findData5: any = permissions?.find(
                  (res: any) => res.name === "Bulk Upload User"
                );
                const findData6: any = permissions?.find(
                  (res: any) => res.name === "Procedure Config"
                );
                if (
                  findData?.permissions?.length === 0 &&
                  findData2?.permissions?.length === 0 &&
                  findData3?.permissions?.length === 0 &&
                  findData4?.permissions?.length === 0 &&
                  findData5?.permissions?.length === 0 &&
                  findData6?.permissions?.length === 0
                ) {
                  hideMenu.push({ menu: "User Management", hide: true });
                } else {
                  hideMenu.push({ menu: "User Management", hide: false });
                }
                break;
              }

              case "Master Data": {
                const findData: any = permissions?.find((res: any) => res.name === "SHE");
                const findData2: any = permissions?.find(
                  (res: any) => res.name === "Medical Center" && res.category === "submenu"
                );
                const findData3: any = permissions?.find((res: any) => res.name === "Equipment");
                const findData4: any = permissions?.find((res: any) => res.name === "Tools");
                const findData5: any = permissions?.find(
                  (res: any) => res.name === "Rank Configuration"
                );
                const findData6: any = permissions?.find((res: any) => res.name === "Location");
                const findData7: any = permissions?.find((res: any) => res.name === "Sub Location");
                if (
                  findData?.permissions?.length === 0 &&
                  findData2?.permissions?.length === 0 &&
                  findData3?.permissions?.length === 0 &&
                  findData4?.permissions?.length === 0 &&
                  findData5?.permissions?.length === 0 &&
                  findData6?.permissions?.length === 0 &&
                  findData7?.permissions?.length === 0
                ) {
                  hideMenu.push({ menu: "Master Data", hide: true });
                } else {
                  hideMenu.push({ menu: "Master Data", hide: false });
                }
                break;
              }
              case "Medical Inventory": {
                const findData: any = permissions?.find((res: any) => res.name === "Medicine");
                const findData2: any = permissions?.find(
                  (res: any) => res.name === "Bulk Upload Medicine" && res.category === "submenu"
                );
                const findData3: any = permissions?.find((res: any) => res.name === "Inventory");
                if (
                  findData?.permissions?.length === 0 &&
                  findData2?.permissions?.length === 0 &&
                  findData3?.permissions?.length === 0
                ) {
                  hideMenu.push({ menu: "Medical Inventory", hide: true });
                } else {
                  hideMenu.push({ menu: "Medical Inventory", hide: false });
                }
                break;
              }
              case "Reassign": {
                const findData = permissions?.find((res) => res.name === "Reassign");
                if (
                  !findData ||
                  findData.permissions.length === 0 ||
                  !findData.permissions.some((item: string) => item === "read")
                ) {
                  hideMenu.push({ menu: "Reassign", hide: true });
                } else {
                  hideMenu.push({ menu: "Reassign", hide: false });
                }
                break;
              }
              case "Undo Status": {
                const findData = permissions?.find((res) => res.name === "Undo Status");
                if (
                  !findData ||
                  findData.permissions.length === 0 ||
                  !findData.permissions.some((item: string) => item === "read")
                ) {
                  hideMenu.push({ menu: "Undo Status", hide: true });
                } else {
                  hideMenu.push({ menu: "Undo Status", hide: false });
                }
              }
            }

            if (e.type === "menu") {
              // count++;
              //* It is unnecessary to check if res.name === "Reassign" or "Undo Status". It's just right now, those menus are categorized as submenus.
              //* We are moving those menus as a normal menu but we'll have to wait for the backend to set it.
              //* Once the backend completed the setup, it is safe to remove these checks (i.e. || res.name === "Reassign" || res.name === "Undo Status")
              const findData: any = permissions?.find(
                (res: any) =>
                  res.name === e.title &&
                  (res.category === "menu" || res.name === "Reassign" || res.name === "Undo Status")
              );
              return (
                findData?.permissions?.includes("read") && (
                  <Menu.Item
                    title={e.title}
                    style={{
                      paddingLeft: 8,
                      borderRadius: e.path === current ? "6px" : "2px",
                      backgroundColor: e.path === current ? "#F3F4F6" : "white",
                    }}
                    key={e.path}
                    onClick={() => {
                      if (e.title === "My Task") {
                        dispatch(sidebarActions.getMyTaskCounter.request());
                        history.push({
                          pathname: e.path,
                          state: { permissions: findData },
                          search: `type=${
                            taskCounterNameMapping?.filter((value: any) => {
                              return value.title === taskCounterType[0];
                            })[0]?.search ?? "health"
                          }`,
                        });
                      } else {
                        history.push({
                          pathname: e.path,
                          state: { permissions: findData },
                        });
                      }
                      localStorage.removeItem("tab");
                      localStorage.removeItem("filter");
                    }}
                  >
                    {e.title === "My Task" ? (
                      <div className={styles.custom_menu_item_title}>
                        <span>
                          <Link to={e.path}>
                            {e.icon}
                            <span style={{ marginLeft: "8px" }}>{e.title}</span>
                          </Link>
                        </span>
                        <Badge
                          count={
                            taskCounterType?.length === 0 &&
                            taskCounterType?.length > 0 &&
                            taskCounterType[0] === "ALL"
                              ? findAllTaskCounterTotal()
                              : taskCounterType?.length > 0
                              ? findTask(
                                  taskCounterNameMapping?.filter((value: any) => {
                                    return value.title === taskCounterType[0];
                                  })[0]?.search
                                )?.myTask
                              : 0
                          }
                          style={{ backgroundColor: "#F9284E" }}
                        />
                      </div>
                    ) : (
                      <>
                        <Link to={e.path}>
                          {e.icon}&nbsp;&nbsp;{!collapse ? e.title : ""}
                        </Link>
                      </>
                    )}
                  </Menu.Item>
                )
              );
            }

            return hideMenu?.map((res, index) => {
              if (res.hide === false && permissions) {
                return (
                  <SubMenu
                    key={e.title === "My Task" ? `${e.type}_mytask` : e.type + "_" + e.key}
                    title={
                      e.title === "My Task" && isMyTaskBadgeVisible ? (
                        <p className={styles.custom_submenu}>
                          {e.title}{" "}
                          <Badge
                            count={findAllTaskCounterTotal()}
                            style={{ backgroundColor: "#F9284E" }}
                          />
                        </p>
                      ) : (
                        e.title
                      )
                    }
                    icon={e.icons}
                    onTitleClick={(e) => {
                      if (e.key === "sub_mytask") {
                        setIsMyTaskBadgeVisible((prevState) => !prevState);
                      }
                    }}
                  >
                    {e.sub.map((val) => {
                      // count++;
                      const findData: any = permissions?.find((res: any) => {
                        const baseCondition = res.name === val.title && res.category === "submenu";
                        if (e.title === "My Task") {
                          // switch (val.title) {
                          //   case "Medical Center":
                          //     return baseCondition && res.id === 41;
                          //   case "SHE":
                          //     return baseCondition && res.id === 40;
                          //   default:
                          //     return baseCondition;
                          // }
                          return res.name === "My Task";
                        }
                        return baseCondition;
                      });
                      return findData?.permissions?.includes("read") ? (
                        <Menu.Item
                          key={
                            val.path === "/my-task" && val.title === "SHE"
                              ? val.path + "?type=safety"
                              : val.path === "/my-task" && val.title === "Medical Center"
                              ? val.path + "?type=health"
                              : val.path
                          }
                          style={{ paddingLeft: 40 }}
                          onClick={() => [
                            e.title === "My Task" &&
                              dispatch(sidebarActions.getMyTaskCounter.request()),
                            history.push({
                              pathname: val.path,
                              search: val?.search ?? "",
                              state: { permissions: findData },
                            }),
                            (localStorage["page"] = JSON.stringify(1)),
                            localStorage.removeItem("tab"),
                            localStorage.removeItem("filter"),
                          ]}
                        >
                          <div
                            className={styles.custom_menu_item_title}
                            style={{ justifyContent: "space-between" }}
                          >
                            <span>
                              <Link
                                to={
                                  val.path === "/my-task" && val.title === "SHE"
                                    ? val.path + "?type=safety"
                                    : val.path === "/my-task" && val.title === "Medical Center"
                                    ? val.path + "?type=health"
                                    : val.path
                                }
                              >
                                {val.title}
                              </Link>
                            </span>{" "}
                            {e.title === "My Task" && (
                              <Badge
                                count={
                                  findTask(
                                    taskCounterNameMapping?.filter((value: any) => {
                                      return value.title === val.title;
                                    })[0]?.search
                                  )?.myTask
                                }
                                style={{ backgroundColor: "#F9284E" }}
                              />
                            )}
                          </div>
                        </Menu.Item>
                      ) : null;
                    })}
                  </SubMenu>
                );
              } else {
                return <div />;
              }
            });
          })}
        </Menu>
        {/* {!collapse ? (
          <div
            style={{
              position: "fixed",
              padding: "5px",
              paddingBottom: "20px",
              bottom: 0,
              left: 10,
              backgroundColor: "white",
              width: "225px",
            }}
          >
            <img alt="logo" style={{ width: 80 }} src={LOGO_SENTINEL} />
          </div>
        ) : (
          " "
        )} */}
      </div>
      <BlackBanner
        isVisible={!!!collapse && breakpoint.isBreaking("lg")}
        onClick={() => setCollapse(true)}
      />
    </Sider>
  );
};

export default Sidebar;
