import { types as healthTypes } from "./_types";

const initialState = {
  myTask: [],
  myHistory: [],
  listReservationType: [],
  data: {},
  auth: {},
  detailTask: [],
  pagination: null,
  paginationHistory: null,
  actionStatus: "initial",
  moreActionStatus: {
    addUser: "initial",
  },
  archiveData: null,
  isLoading: false,
  isLoadingHistory: false,
  timelineStatus: {},
  isLoadingTimeline: false,
};

export const reservationReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case healthTypes.GET_MY_TASK_KEYS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case healthTypes.GET_MY_TASK_KEYS_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case healthTypes.GET_MY_TASK_KEYS_SUCCESS:
      return {
        ...state,
        myTask: action.data.data.formFlow,
        detailTask: action.data.data.formFlow,
        pagination: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case healthTypes.GET_HISTORY_KEYS_REQUEST:
      return {
        ...state,
        isLoadingHistory: true,
      };
    case healthTypes.GET_HISTORY_KEYS_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoadingHistory: false,
      };
    case healthTypes.GET_HISTORY_KEYS_SUCCESS:
      return {
        ...state,
        myHistory: action.data.data.formFlow,
        paginationHistory: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoadingHistory: false,
      };
    case healthTypes.GET_TIMELINE_STATUS:
      return {
        ...state,
        isLoadingTimeline: true,
      };
    case healthTypes.GET_TIMELINE_STATUS_FAILED:
      return {
        ...state,
        isLoadingTimeline: false,
        timelineStatus: {},
        actionStatus: action.status,
      };
    case healthTypes.GET_TIMELINE_STATUS_SUCCESS:
      return {
        ...state,
        timelineStatus: action.data,
        actionStatus: action.status,
        isLoadingTimeline: false,
      };

    default:
      return state;
  }
};
