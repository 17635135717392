import { Checkbox, DatePicker, Form, Modal } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC } from "react";
import ButtonForm from "../../Button";
import Swiper from "../../Swiper";
import styles from "./styles.module.less";
import { useTranslation } from "react-i18next";

interface ModalProps {
  title?: string;
  isCentered?: boolean;
  onOk: () => void;
  onCancel?: () => void;
  visible?: boolean;
  style?: any;
  status?: boolean;
  select?: boolean;
  chip?: boolean;
  filterData?: any;
  listFlow?: any;
  itemStatus?: any;
  itemCondition?: any[];
  condition?: boolean;
  disabled?: boolean;
  onChange: (value: any, type: string) => void;
  today?: boolean;
  customDateTitle?: string;
  isFromSafety?: boolean;
}

const FilterModal: FC<ModalProps> = ({
  isCentered,
  today = true,
  itemStatus,
  itemCondition,
  condition = false,
  onOk,
  filterData,
  onCancel,
  listFlow,
  visible,
  style,
  status,
  select,
  chip,
  disabled = false,
  onChange,
  customDateTitle = "Custom Date",
  isFromSafety,
}) => {
  const { t } = useTranslation();
  const _checkValid = () => {
    let check = true;
    Object.keys(filterData).map((item: any) => {
      if (filterData[item] && filterData[item].length > 0) {
        check = false;
      }
      return check;
    });
    return check;
  };
  const _disabled = disabled ? _checkValid() : false;
  return (
    <Modal
      bodyStyle={{ padding: 24 }}
      width={525}
      visible={visible}
      centered={isCentered}
      onCancel={onCancel}
      closable={false}
      footer={[
        <div className={clsx(styles.footer, styles.footer_margin)}>
          <ButtonForm width="100%" type={1} onClick={onCancel} title={t("myTasks.filter.close")} />
          <ButtonForm
            width="100%"
            disabled={_disabled}
            key="submit"
            onClick={onOk}
            title={t("myTasks.filter.apply")}
          />
        </div>,
      ]}
      style={style}
      className={styles.filter_modal_container}
    >
      <div className={styles.wrap_title}>
        <div className={styles.title}>{t("myTasks.filter.title")}</div>
        <div className={styles.subtitle} onClick={() => onChange("", "clear")}>
          {t("myTasks.filter.reset")}
        </div>
      </div>
      <div className={clsx(styles.top, { [styles.top_active]: !status })}>
        <div className={styles.wrap_status}>
          {itemStatus?.map((item: any, index: number) => {
            return (
              <button
                className={clsx(styles.btn, {
                  [styles.btn_active]:
                    filterData?.status?.toLowerCase() === item.name.toLowerCase(),
                })}
                onClick={() => onChange(item.name.toUpperCase(), "status")}
              >
                {item.name}
              </button>
            );
          })}
        </div>
      </div>
      <div className={clsx(styles.top, { [styles.top_active]: !condition })}>
        <div style={{ marginBottom: 4 }}>{t("myTasks.filter.condition")}</div>
        <div className={styles.wrap_status}>
          {itemCondition?.map((item: any, index: number) => {
            return (
              <button
                className={clsx(styles.btn, {
                  [styles.btn_active]:
                    filterData?.condition?.toLowerCase() === item.value.toLowerCase(),
                })}
                onClick={() => onChange(item.value.toUpperCase(), "condition")}
              >
                {item.name}
              </button>
            );
          })}
        </div>
      </div>
      {listFlow && listFlow.length > 0 ? (
        <div className={clsx(styles.top, { [styles.top_active]: !select })}>
          <div style={{ marginBottom: 8 }}>
            {isFromSafety ? "Assignment Type" : t("myTasks.filter.reservation")}
          </div>
          <div className={styles.listflow_container}>
            {typeof listFlow !== "string" &&
              listFlow
                ?.sort((a: any, b: any) => a.flowName.length - b.flowName.length)
                .map((e: any, i: number) => {
                  const checked =
                    filterData.reservation?.filter((val: string) => val === e.flowName) ?? [];
                  return (
                    <Checkbox
                      style={{ marginLeft: 0, width: "100%", fontWeight: 500, marginBottom: 5 }}
                      checked={checked.length > 0}
                      onChange={(n: any) => {
                        const filterFile = filterData.reservation?.filter(
                          (obj: string) => obj === e.flowName
                        );
                        const arr = filterData.reservation?.filter(
                          (obj: string) => obj !== e.flowName
                        );
                        if (filterFile?.length > 0) {
                          onChange(arr, "reservation");
                        } else {
                          onChange(filterData.reservation.concat(e.flowName), "reservation");
                        }
                      }}
                    >
                      {e.flowName}
                    </Checkbox>
                  );
                })}
          </div>
        </div>
      ) : null}
      <div className={clsx(styles.top, { [styles.top_active]: !chip })}>
        <div style={{ marginBottom: 4 }}>{t("myTasks.filter.date")}</div>
        <Swiper className={styles.wrap_status}>
          {[
            { name: "TODAY", isButton: false },
            { name: "WTD", isButton: false },
            { name: "MTD", isButton: false },
            { name: "YTD", isButton: false },
            { name: customDateTitle, isButton: false },
          ].map((item: any, index: number) => {
            return (
              <button
                className={clsx(styles.btn, {
                  [styles.btn_active]: filterData.date === item.name,
                })}
                onClick={() => onChange(item.name, "date")}
                style={{ display: today === false && item.name === "TODAY" ? "none" : "unset" }}
              >
                <div>{item.name}</div>
              </button>
            );
          })}
        </Swiper>
        <div
          className={clsx(styles.top, {
            [styles.top_active]: filterData?.date !== customDateTitle,
            [styles.top_wrap_custom_date]: filterData?.date === customDateTitle,
          })}
        >
          <Form.Item style={{ height: 42 }} label={t("myTasks.filter.startDate")}>
            <DatePicker
              style={{ width: "100%" }}
              clearIcon={false}
              placeholder={t("myTasks.filter.startDate")}
              value={filterData?.startDate ? filterData.startDate : ""}
              onChange={(e: any) => onChange(moment(e), "startDate")}
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Form.Item style={{ height: 42, marginLeft: 12 }} label={t("myTasks.filter.endDate")}>
            <DatePicker
              style={{ width: "100%" }}
              clearIcon={false}
              placeholder={t("myTasks.filter.endDate")}
              value={filterData?.endDate ? filterData.endDate : ""}
              onChange={(e: any) => onChange(moment(e), "endDate")}
              format="YYYY-MM-DD"
            />
          </Form.Item>
        </div>
      </div>
    </Modal>
  );
};

export default FilterModal;
