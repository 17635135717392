import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { actions as userActions } from "./_action";
import { types as masterTypes } from "./_types";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { callback } from "../../store/defaultSagas";

export const watcherSafetyVoiceScoring = () => [
  takeLatest(masterTypes.GET_SAFETY_VOICE_SCORE_LIST_REQUEST, workerGetListSafetyVoiceScoring),
  takeLatest(masterTypes.GET_SAFETY_VOICE_SCORE_DETAIL_REQUEST, workerSafetyVoiceScoringDetail),
];

function* workerGetListSafetyVoiceScoring({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.SAFETY_VOICE_SCORING_LIST);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getListSafetyVoice.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getListSafetyVoice.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerSafetyVoiceScoringDetail({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.SAFETY_VOICE_SCORING_DETAIL);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getDetailSafetyVoice.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getDetailSafetyVoice.failure(err));
    yield callback(onFailure, err);
  }
}
