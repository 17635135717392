import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { types } from "./_types";

export const actions = {
  getListNotif: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.GET_LIST_NOTIF_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  readBage: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.READ_BADGE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  readNotif: {
    request: (payload: any) => ({
      payload,
      type: types.READ_NOTIF_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.READ_NOTIF_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.READ_NOTIF_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};
