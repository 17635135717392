import { Pagination as PG, Select } from "antd";
import { FC } from "react";
import styles from "./styles.module.less";
import useTranslateShortcut from "../../helpers/hooks/useTranslateShortcut";

interface Props {
  total?: number;
  pageSize?: number;
  current?: number;
  onChange: (page: number, size?: number) => void;
}

const Pagination: FC<Props> = ({ total = 0, pageSize = 5, current = 1, onChange }) => {
  const { translate } = useTranslateShortcut({ base: "pagination." });

  const page = [
    // { value: 5, label: "5 / page" },
    { value: 10, label: translate("currentPage", { pageSize: 10 }) },
    { value: 20, label: translate("currentPage", { pageSize: 20 }) },
    { value: 50, label: translate("currentPage", { pageSize: 50 }) },
    { value: 100, label: translate("currentPage", { pageSize: 100 }) },
  ];
  const checkPage = page.filter((obj: any) => obj.value === pageSize) ?? [];
  if (checkPage.length < 1) {
    // page.unshift({ value: pageSize, label: `${pageSize} / page` });
    page.unshift({ value: pageSize, label: translate("currentPage", { pageSize: pageSize }) });
  }
  return (
    <div className={styles.wrap_pg}>
      <div className={styles.wrap_left}>
        <Select
          defaultValue={pageSize}
          onChange={(e: number) => onChange(current, e)}
          value={pageSize}
          options={page}
        />
        {/* <div className={styles.txt}>{`Total ${total} items`}</div> */}
        <div className={styles.txt}>{translate("totalItems", { total: total })}</div>
      </div>
      <PG
        pageSize={pageSize}
        current={current}
        total={total}
        onChange={onChange}
        showSizeChanger={false}
      />
    </div>
  );
};
export default Pagination;
