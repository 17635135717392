export const types = {
  GET_USERS_REQUEST: "user/GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "user/GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "user/GET_USERS_FAILURE",

  GET_ROLE_REQUEST: "user/GET_ROLE_REQUEST",
  GET_ROLE_SUCCESS: "user/GET_ROLE_SUCCESS",
  GET_ROLE_FAILURE: "user/GET_ROLE_FAILURE",

  GET_DETAIL_USER_REQUEST: "user/GET_DETAIL_USER_REQUEST",
  GET_DETAIL_USER_SUCCESS: "user/GET_DETAIL_USER_SUCCESS",
  GET_DETAIL_USER_FAILURE: "user/GET_DETAIL_USER_FAILURE",

  GET_LIST_COMPANY_REQUEST: "user/GET_LIST_COMPANY_REQUEST",
  GET_LIST_COMPANY_SUCCESS: "user/GET_LIST_COMPANY_SUCCESS",
  GET_LIST_COMPANY_FAILURE: "user/GET_LIST_COMPANY_FAILURE",

  GET_DETAIL_USER_COMPANY_REQUEST: "user/GET_DETAIL_USER_COMPANY_REQUEST",

  GET_LIST_ARCHIVE_USER_REQUEST: "user/GET_LIST_ARCHIVE_USER_REQUEST",
  GET_LIST_ARCHIVE_USER_SUCCESS: "user/GET_LIST_ARCHIVE_USER_SUCCESS",
  GET_LIST_ARCHIVE_USER_FAILURE: "user/GET_DETAIL_USER_FAILURE",

  GET_LIST_DEPARTMENT_REQUEST: "user/GET_LIST_DEPARTMENT_REQUEST",
  GET_LIST_DEPARTMENT_SUCCESS: "user/GET_LIST_DEPARTMENT_SUCCESS",
  GET_LIST_DEPARTMENT_FAILURE: "user/GET_LIST_DEPARTMENT_FAILURE",

  GET_LIST_GROUP_REQUEST: "user/GET_LIST_GROUP_REQUEST",
  GET_LIST_GROUP_SUCCESS: "user/GET_LIST_GROUP_SUCCESS",
  GET_LIST_GROUP_FAILURE: "user/GET_LIST_GROUP_FAILURE",

  GET_LIST_POSITION_REQUEST: "user/GET_LIST_POSITION_REQUEST",
  GET_LIST_POSITION_SUCCESS: "user/GET_LIST_POSITION_SUCCESS",
  GET_LIST_POSITION_FAILURE: "user/GET_LIST_POSITION_FAILURE",

  GET_LIST_SECTION_REQUEST: "user/GET_LIST_SECTION_REQUEST",
  GET_LIST_SECTION_SUCCESS: "user/GET_LIST_SECTION_SUCCESS",
  GET_LIST_SECTION_FAILURE: "user/GET_LIST_SECTION_FAILURE",

  GET_LIST_MODEL_REQUEST: "user/GET_LIST_MODEL_REQUEST",

  GET_LIST_DIRECT_SUP_REQUEST: "user/GET_LIST_DIRECT_SUP_REQUEST",
  GET_LIST_DIRECT_SUP_SUCCESS: "user/GET_LIST_DIRECT_SUP_SUCCESS",
  GET_LIST_DIRECT_SUP_FAILURE: "user/GET_LIST_DIRECT_SUP_FAILURE",

  RESET_USER_REQUEST: "user/RESET_USER_REQUEST",
  CREATE_COMPANY_REQUEST: "user/CREATE_COMPANY_REQUEST",
  CREATE_COMPANY_SUCCESS: "user/CREATE_COMPANY_SUCCESS",
  CREATE_COMPANY_FAILURE: "user/CREATE_COMPANY_FAILURE",

  ADD_USER_REQUEST: "user/ADD_USER_REQUEST",
  ADD_USER_SUCCESS: "user/ADD_USER_SUCCESS",
  ADD_USER_FAILURE: "user/ADD_USER_FAILURE",

  EDIT_USER_REQUEST: "user/EDIT_USER_REQUEST",
  EDIT_USER_SUCCESS: "user/EDIT_USER_SUCCESS",
  EDIT_USER_FAILURE: "user/EDIT_USER_FAILURE",
  DELETE_USER_REQUEST: "user/DELETE_USER_REQUEST",

  GET_SIO_REQUEST: "user/GET_SIO_REQUEST",
  GET_SIO_SUCCESS: "user/GET_SIO_SUCCESS",
  GET_SIO_FAILURE: "user/GET_SIO_FAILURE",

  CLEAR_SIO_FILES: "user/CLEAR_SIO_FILES",

  ADD_SIO_REQUEST: "user/ADD_SIO_REQUEST",
  ADD_SIO_FAILURE: "user/ADD_SIO_FAILURE",

  UPDATE_SIO_REQUEST: "user/UPDATE_SIO_REQUEST",
  UPDATE_SIO_SUCCESS: "user/UPDATE_SIO_SUCCESS",
  UPDATE_SIO_FAILURE: "user/UPDATE_SIO_FAILURE",

  DELETE_SIO_REQUEST: "user/DELETE_SIO_REQUEST",
  DELETE_SIO_SUCCESS: "user/DELETE_SIO_SUCCESS",
  DELETE_SIO_FAILURE: "user/DELETE_SIO_FAILURE",
};

export interface IFormikValues {
  id?: number;
  username: string;
  email: string;
  password: string;
  role: string;
}

export interface IGetDetailUserRequestData {
  username: string;
}

export interface IGetListArchiveRequestData {
  token: string;
  page: number;
  limit: number;
  keyword: string;
  orderBy: {
    field: string;
    sort: string;
  };
}

export interface IAddUserRequestData extends IFormikValues {}

export interface IGetUserRequestData {
  page: number;
  limit: number;
  keyword: string;
  orderBy: {
    field: string;
    sort: string;
  };
  filterBy: object;
}

export interface IGetRoleRequestData {
  token: string;
}

export interface UploadReturnType {
  result: boolean;
  message: string;
  data: Data;
  status: string;
}

export interface Data {
  fileData: FileData;
}

export interface FileData {
  id: number;
  name: string;
  createdBy: string;
  createdDate: string;
  type: string;
  url: string;
}

export interface UserByRole {
  departmentName: string;
  superiorName: string;
  roleId: number;
  groupId: number;
  departmentId: number;
  divisionName: string;
  fullName: string;
  employeeId: string;
  sectionId: number;
  userId: number;
  emailContact: string;
  companyId: number;
  category: string;
  username: string;
}
