import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../pages/Notification/_actions";

function useNotification(pageNum: number) {
  // State
  const [isLoading, setIsLoading] = useState(true);
  const [notifications, setNotifications] = useState<any>([]);
  const [hasMore, setHasMore] = useState(false);
  const [badges, setBadges] = useState<number>(0);
  // dispatch
  const dispatch = useDispatch();

  const _getData = useCallback(
    (page) => {
      setIsLoading(true);
      dispatch(
        actions.getListNotif.request({
          payload: {
            page,
            start: 0,
            limit: 10,
            filter: { type: null },
          },
          onSuccess: (res: any) => {
            const data = res.data?.notificationData?.notification ?? [];
            const totalBadges = res.data?.notificationData?.badgesCountTotal ?? 0;
            setNotifications((prev: any) => {
              const tempData = page > 1 ? [...new Set([...prev, ...data])] : data;
              return tempData;
            });
            setBadges(totalBadges);
            setHasMore(data.length > 0);
            setIsLoading(false);
          },
          onFailure: (err: any) => {
            setIsLoading(false);
          },
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    // get data first with infinite scroll
    _getData(pageNum);
    // get data every 5 minutes
    const _timeInterval: number = parseInt(process.env.REACT_APP_INTERVAL ?? "300000");
    const id = setInterval(() => {
      _getData(1);
    }, _timeInterval);
    // clear interval
    return () => clearInterval(id);
  }, [pageNum, _getData]);

  return { isLoading, notifications, badges, hasMore };
}

export default useNotification;
