import { types } from "./_types";

const initialState = {
  categoryInventoryList: [],
  paginationCategoryInventory: null,
  actionStatus: "initial",
  isLoading: false,
  inventoryList: [],
  paginationInventory: null,
};

export const medicalInventoryReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_CATEGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_CATEGORY_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryInventoryList: action.data.data.category,
        paginationCategoryInventory: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_INVENTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_INVENTORY_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_INVENTORY_SUCCESS:
      return {
        ...state,
        inventoryList: action.data.data.medicalInventory,
        paginationInventory: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    default:
      return state;
  }
};
