import { types as masterTypes } from "./_types";

const initialState = {
  safetyVoiceScoringDetail: {},
  safetyVoiceScoring: [],
  paginationSafetyScoring: null,
  listRole: [],
  pagination: null,
  actionStatus: "initial",
  moreActionStatus: {
    addSafetyVoiceDetail: "initial",
  },
  archiveData: null,
  isLoading: false,
  isLoadingSafetyVoiceList: false,
};

export const safetyVoiceScoreReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case masterTypes.GET_SAFETY_VOICE_SCORE_LIST_REQUEST:
      return {
        ...state,
        isLoadingSafetyVoiceList: true,
      };
    case masterTypes.GET_SAFETY_VOICE_SCORE_LIST_FAILED:
      return {
        ...state,
        isLoadingSafetyVoiceList: false,
        safetyVoiceScoring: [],
        actionStatus: action.status,
      };
    case masterTypes.GET_SAFETY_VOICE_SCORE_LIST_SUCCESS:
      return {
        ...state,
        safetyVoiceScoring: Array.isArray(action.data.data.Appointment)
          ? action.data.data.Appointment
          : [],
        paginationSafetyScoring: action.data.pagination,
        actionStatus: action.status,
        isLoadingSafetyVoiceList: false,
      };
    case masterTypes.GET_SAFETY_VOICE_SCORE_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.GET_SAFETY_VOICE_SCORE_DETAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.GET_SAFETY_VOICE_SCORE_DETAIL_SUCCESS:
      return {
        ...state,
        safetyVoiceScoringDetail: action.data.data,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    default:
      return state;
  }
};
