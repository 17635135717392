import { types } from "./_types";

const initialState: any = {
  listArticles: [],
  pinArticles: [],
  detailArticle: null,
  loadingDetailArticle: false,
  loadingListArticles: false,
  actionStatus: "initial",
  totalArticles: 0,
  articleIds: null,
  loadingPinArticle: false,
  commentList: [],
  paginationComment: null,
  loadingComment: false,
};

export const homeArticleReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_LIST_ARTICLES_REQUEST:
      return {
        ...state,
        listArticles: null,
      };
    case types.GET_LIST_ARTICLES_FAILED:
      return {
        ...state,
        actionStatus: "failure",
        listArticles: [],
        totalArticles: 0,
      };
    case types.GET_LIST_ARTICLES_SUCCESS:
      return {
        ...state,
        listArticles: action?.data?.data?.article,
        actionStatus: "success",
        totalArticles: action?.data?.pagination?.totalItem,
      };
    case types.GET_PIN_ARTICLES_REQUEST:
      return {
        ...state,
        pinArticle: [],
        loadingPinArticle: true,
      };
    case types.GET_PIN_ARTICLES_FAILED:
      return {
        ...state,
        actionStatus: "failure",
        pinArticles: [],
        loadingPinArticle: false,
      };
    case types.GET_PIN_ARTICLES_SUCCESS:
      return {
        ...state,
        pinArticles: action?.data?.data?.pinnedArticle,
        articleIds: action?.data?.articleIds,
        actionStatus: "success",
        loadingPinArticle: false,
      };
    case types.GET_DETAIL_ARTICLE_REQUEST:
      return {
        ...state,
        loadingDetailArticle: true,
      };
    case types.GET_DETAIL_ARTICLE_FAILED:
      return {
        ...state,
        actionStatus: "failure",
        detailArticle: null,
        loadingDetailArticle: false,
      };
    case types.GET_DETAIL_ARTICLE_SUCCESS:
      return {
        ...state,
        detailArticle: action?.data?.data,
        actionStatus: "success",
        loadingDetailArticle: false,
      };
    case types.GET_PIN_DETAIL_ARTICLES_REQUEST:
      return {
        ...state,
        pinArticle: [],
        loadingPinArticle: true,
      };
    case types.GET_PIN_DETAIL_ARTICLES_FAILED:
      return {
        ...state,
        actionStatus: "failure",
        pinArticles: [],
        loadingPinArticle: false,
      };
    case types.GET_PIN_DETAIL_ARTICLES_SUCCESS:
      return {
        ...state,
        pinArticles: action?.data?.data?.pinnedArticle,
        articleIds: action?.data?.articleIds,
        actionStatus: "success",
        loadingPinArticle: false,
      };
    case types.GET_COMMENT_LIST_REQUEST:
      return {
        ...state,
        loadingComment: true,
      };
    case types.GET_COMMENT_LIST_SUCCESS:
      return {
        ...state,
        commentList: action?.data?.data?.comment,
        paginationComment: action?.data?.pagination,
        actionStatus: "success",
        loadingComment: false,
      };
    case types.GET_COMMENT_LIST_FAILED:
      return {
        ...state,
        actionStatus: "failure",
        loadingComment: false,
      };
    case types.LOAD_MORE_COMMENT_REQUEST:
      return {
        ...state,
        loadingComment: true,
      };
    case types.LOAD_MORE_COMMENT_SUCCESS:
      const newCommentList = [...state?.commentList, ...action?.data?.data?.comment];
      return {
        ...state,
        commentList: newCommentList,
        paginationComment: action?.data?.pagination,
        actionStatus: "success",
        loadingComment: false,
      };
    case types.LOAD_MORE_COMMENT_FAILED:
      return {
        ...state,
        actionStatus: "failure",
        loadingComment: false,
      };
    default:
      return state;
  }
};
