import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import {
  ICreateDetailAttData,
  IGetListDetailAttData,
  IUpdateDetailAttData,
  types as detailTypes,
} from "./_types";

export const actions = {
  getDetailAtt: {
    request: (payload: any) => ({
      type: detailTypes.GET_ALL_DETAIL_ATT_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: detailTypes.GET_ALL_DETAIL_ATT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: detailTypes.GET_ALL_DETAIL_ATT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getDetailAttData: {
    request: (requestData: IDefaultRequestParams<IGetListDetailAttData>) => ({
      ...requestData,
      type: detailTypes.GET_DETAIL_ATT_DATA_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: detailTypes.GET_DETAIL_ATT_DATA_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: detailTypes.GET_DETAIL_ATT_DATA_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createDetailAtt: {
    request: (requestData: IDefaultRequestParams<ICreateDetailAttData>) => ({
      ...requestData,
      type: detailTypes.CREATE_DETAIL_ATT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: detailTypes.CREATE_DETAIL_ATT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: detailTypes.CREATE_DETAIL_ATT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  updateDetailAtt: {
    request: (requestData: IDefaultRequestParams<IUpdateDetailAttData>) => ({
      ...requestData,
      type: detailTypes.UPDATE_DETAIL_ATT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: detailTypes.UPDATE_DETAIL_ATT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: detailTypes.UPDATE_DETAIL_ATT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};

export async function deleteDetailAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_DETAIL_ATT);
  try {
    const response = await http.get(id);
    const successResponse = {
      success: true,
      data: response?.data?.attdata,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
