import { IFields } from "../General/GeneralForm/types";

export const types = {
  GET_RESERVATION_REQUEST: "health/GET_RESERVATION_REQUEST",
  GET_RESERVATION_SUCCESS: "health/GET_RESERVATION_SUCCESS",
  GET_RESERVATION_FAILURE: "health/GET_RESERVATION_FAILURE",

  GET_RESERVATION_HISTORY_REQUEST: "health/GET_RESERVATION_HISTORY_REQUEST",
  GET_RESERVATION_HISTORY_SUCCESS: "health/GET_RESERVATION_HISTORY_SUCCESS",
  GET_RESERVATION_HISTORY_FAILURE: "health/GET_RESERVATION_HISTORY_FAILURE",

  GET_RESERVATION_TYPE_REQUEST: "health/GET_RESERVATION_TYPE_REQUEST",
  GET_RESERVATION_TYPE_SUCCESS: "health/GET_RESERVATION_TYPE_SUCCESS",
  GET_RESERVATION_TYPE_FAILURE: "health/GET_RESERVATION_TYPE_FAILURE",

  GET_LIST_PATIENT_REQUEST: "health/GET_LIST_PATIENT_REQUEST",
  GET_LIST_PATIENT_SUCCESS: "health/GET_LIST_PATIENT_SUCCESS",
  GET_LIST_PATIENT_FAILURE: "health/GET_LIST_PATIENT_FAILURE",

  GET_LIST_TIME_REQUEST: "health/GET_LIST_TIME_REQUEST",

  GET_LIST_MEDICAL_REQUEST: "health/GET_LIST_MEDICAL_REQUEST",
  GET_LIST_MEDICAL_SUCCESS: "health/GET_LIST_MEDICAL_SUCCESS",
  GET_LIST_MEDICAL_FAILURE: "health/GET_LIST_MEDICAL_FAILURE",

  GET_DETAIL_MEDICAL_REQUEST: "health/GET_DETAIL_MEDICAL_REQUEST",
  GET_DETAIL_MEDICAL_SUCCESS: "health/GET_DETAIL_MEDICAL_SUCCESS",
  GET_DETAIL_MEDICAL_FAILURE: "health/GET_DETAIL_MEDICAL_FAILURE",

  GET_HISTORY_MEDICAL_REQUEST: "health/GET_HISTORY_MEDICAL_REQUEST",
  GET_HISTORY_MEDICAL_SUCCESS: "health/GET_HISTORY_MEDICAL_SUCCESS",
  GET_HISTORY_MEDICAL_FAILURE: "health/GET_HISTORY_MEDICAL_FAILURE",

  GET_RESERVATION_DETAIL_HISTORY_REQUEST: "health/GET_RESERVATION_DETAIL_HISTORY_REQUEST",
  GET_RESERVATION_DETAIL_HISTORY_SUCCESS: "health/GET_RESERVATION_DETAIL_HISTORY_SUCCESS",
  GET_RESERVATION_DETAIL_HISTORY_FAILURE: "health/GET_RESERVATION_DETAIL_HISTORY_FAILURE",

  GET_RESERVATION_DETAIL_REQUEST: "health/GET_RESERVATION_DETAIL_REQUEST",
  GET_RESERVATION_DETAIL_SUCCESS: "health/GET_RESERVATION_DETAIL_SUCCESS",
  GET_RESERVATION_DETAIL_FAILURE: "health/GET_RESERVATION_DETAIL_FAILURE",

  CREATE_RESERVATION_REQUEST: "health/CREATE_RESERVATION_REQUEST",
  CREATE_RESERVATION_SUCCESS: "health/CREATE_RESERVATION_SUCCESS",
  CREATE_RESERVATION_FAILURE: "health/CREATE_RESERVATION_FAILURE",

  GET_TIMELINE_STATUS_REQUEST: "health/GET_TIMELINE_STATUS_REQUEST",
  GET_TIMELINE_STATUS_SUCCESS: "health/GET_TIMELINE_STATUS_SUCCESS",
  GET_TIMELINE_STATUS_FAILURE: "health/GET_TIMELINE_STATUS_FAILURE",

  GET_DETAIL_RISK_REQUEST: "health/GET_DETAIL_RISK_REQUEST",
  GET_DETAIL_RISK_SUCCESS: "health/GET_DETAIL_RISK_SUCCESS",
  GET_DETAIL_RISK_FAILURE: "health/GET_DETAIL_RISK_FAILURE",

  GET_RISK_LEVEL_REQUEST: "health/GET_RISK_LEVEL_REQUEST",
  GET_RISK_LEVEL_SUCCESS: "health/GET_RISK_LEVEL_SUCCESS",
  GET_RISK_LEVEL_FAILURE: "health/GET_RISK_LEVEL_FAILURE",

  GET_LIST_INVENTORY_REQUEST: "health/GET_LIST_INVENTORY_REQUEST",
  GET_LIST_INVENTORY_SUCCESS: "health/GET_LIST_INVENTORY_SUCCESS",
  GET_LIST_INVENTORY_FAILURE: "health/GET_LIST_INVENTORY_FAILURE",

  GET_RESERVATION_DETAIL_SHE_REQUEST: "health/GET_RESERVATION_DETAIL_SHE_REQUEST",
  GET_RESERVATION_DETAIL_HEALTH_REQUEST: "health/GET_RESERVATION_DETAIL_HEALTH_REQUEST",
};

// export interface IFormikValues {
//   id?: number;
//   username: string;
//   email: string;
//   password: string;
//   role: string;
// }

export interface IGetDetailUserRequestData {
  username: string;
}

export interface IGetListReservationRequestData {
  token: string;
  page: number;
  limit: number;
  orderBy: {
    field: string;
    sort: string;
  };
}

export interface PayloadReasign {
  assignmentNumber: any;
  username: string;
  comment?: string;
  fromFlow?: any;
}

export interface AssignmentInputs {
  id: number;
  flowName: string;
  description: string;
  userName: string;
  isSubordinateExist: boolean;
  detailId: number;
  formVersionId: number;
  formFlowId: number;
  formList: FormFlow[];
  category: any;
  icon: string;
  typeFlow: string;
  isReassign: boolean;
}

export interface Tab {
  tabNumber: number;
  tabDescription: string;
  field: IFields[];
}

export interface AssignmentDetail {
  formFlow: FormFlow[];
  formFlowId: number;
  approvedBy: string;
  reservation: Reservation;
  waitingApprovalFrom: string[];
  comment: string;
}

export interface Score {
  [key: string]: {
    [key: string]: any;
    totalScore: number;
  };
}

export interface FormFlow {
  formId: number;
  formVersionId: number;
  formName: string;
  formDescription: string;
  stepRule: any;
  status: string;
  formType: string;
  category: string;
  locationTypeId: number;
  locationId: number;
  equipmentTypeId: number;
  formCode: string;
  summaryTab: any;
  formField: Tab[];
  formStatus: any;
  isReassign: boolean;
  score?: Score;
}

export interface Reservation {
  date: string;
  updatedBy: string;
  reservationId: string;
  patientId: string;
  createdBy: string;
  created: string;
  description: string;
  time: any;
  assignmentNumber: string;
  updated: any;
  status: string;
  submitterSection?: string;
  submitterDirectSuperior?: string;
  submitterPosition?: string;
  submitterDepartment?: string;
  submitterGroup?: string;
}

export interface DeviationController {
  severity: number;
  isOpen: boolean;
  riskLevel: number;
  isParent: boolean;
  userCorrector: null;
  risklocation: number;
  riskType: string;
  probability: number;
  parentAssignmentNumber: string;
  correctiveAction: null;
  riskSubLocation: number;
}

export interface Deviation {
  isOpen: boolean;
  userCorrector: null;
  parentAssignmentNumber: string;
  dueDate: string;
  deviationForm: DeviationFormObject;
  correctiveAction: null;
  location: null;
  assignmentNumber: string;
  flowName: string;
  username: string;
  status: string;
  fieldId: string;
  deviationTitle: string;
  deviationDescription: string;
}

export interface MyTaskDetail {
  formFlowId: number;
  assignmentNumber: string;
  isDraft: boolean;
  leadAuditor: null;
  submitionDate: string;
  location: null;
  flowName: string;
  description: string;
  userName: string;
  assignmentDate: string;
  equipmentName: null;
  locationName: null;
  level: number;
  assignmentId: number;
  status: string;
  formList: FormFlow[];
  typeFlow: string;
  approvedBy: null;
  isDirectsuperior: null;
  accessByPosition: null;
  accessType: null;
  accessByUser: null;
  isOpen: null;
  correctiveAction: null;
  userCorrector: null;
  probability: number;
  severity: number;
  riskLevel: number;
  risklocation: number;
  riskSubLocation: number;
  riskType: null;
  isParent: boolean;
  parentAssignmentNumber: null;
  deviation: DeviationController;
  listDeviation: Deviation[];
  patientId: string;
  patientName: string;
  type: string;
  reservationStatus: string;
  dateTime: string;
  date: string;
  time: null;
  deviationForm: DeviationFormObject;
  fieldId: string | null;
  parentData?: ParentData;
}

export interface FormList {
  formId: number;
  formVersionId: number;
  formName: string;
  formDescription: string;
  stepRule: null;
  status: string;
  formType: string;
  category: string;
  locationTypeId: number;
  locationId: number;
  equipmentTypeId: number;
  formCode: string;
  summaryTab: null;
  formField: FormField[];
  formStatus: null;
  isReassign: boolean;
}

export interface FormField {
  tabNumber: number;
  tabDescription: string;
  field: IFields[];
}

export interface DeviationFormObject {
  formId: number;
  formVersionId: number;
  locationTypeId: number;
  locationId: number;
  equipmentTypeId: number;
  formName: string;
  formDescription: string;
  status: string;
  formType: string;
  category: string;
  formCode: string;
  stepRule: null;
  summaryTab: null;
  formStatus: string;
  isReassign: boolean;
  formField: FormField[];
}

export interface ParentData {
  dueDate: null;
  deviationForm: DeviationFormObject;
  fullName: string;
  flowName: string;
  isOpen: null;
  userCorrector: string;
  parentAssignmentNumber: null;
  deviationTitle: null;
  correctiveAction: null;
  location: null;
  assignmentNumber: string;
  deviationDescription: null;
  username: string;
  status: string;
  fieldId: null;
}

export interface SafetyReservation {
  listDeviation: any[];
  parentData: ParentData;
  formFlow: FormFlow[];
  formFlowId: number;
  approvedBy: string;
  reservation: Reservation;
  deviationForm: DeviationFormObject;
  deviation: Deviation;
  waitingApprovalFrom: any[];
  comment: string;
  fieldId: null;
}
