import { generateXls } from "./generateXls";
import { generatePDF } from "./generatePdf";

// NORMAL CASE
const capitalize = (text: string, separator: string = " ") => {
  if (typeof text === "string" && text?.length === 0) {
    return "";
  }
  const splitData: string[] = text?.split(separator);
  let arrayData: string[] = [];
  for (let i: number = 0; i < splitData?.length; i++) {
    arrayData.push(splitData[i][0].toUpperCase() + splitData[i].substring(1).toLowerCase());
  }
  return arrayData.join(" ");
};

// ROMAWI CASE
const romanize = (num: number) => {
  const roman: any = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let str = "";
  for (let i of Object.keys(roman)) {
    let q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }
  return str;
};

const validateSize = (file: any, callback: any, maxSize: number = 10e6) => {
  if (file && !file.name) {
    window.alert("Please select a file");
    return false;
  } else if (file.size > maxSize) {
    window.alert(`Please upload a file smaller than ${maxSize / 1000000} MB`);
    return false;
  } else {
    callback();
    return true;
  }
};

const generateColor = () => {
  let randomColorString = "#";
  const arrayOfColorFunctions = "0123456789abcdef";
  for (let x = 0; x < 6; x++) {
    let index = Math.floor(Math.random() * 16);
    let value = arrayOfColorFunctions[index];

    randomColorString += value;
  }
  return randomColorString;
};

const removeDuplicates = (array: any, key: string) => {
  return array.reduce((arr: any, item: any) => {
    const removed = arr.filter((i: any) => i[key] !== item[key]);
    return [...removed, item];
  }, []);
};

const countWorkers = (array: []) => {
  let current = null;
  let cnt = 0;
  let countArray = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== current) {
      if (cnt > 0) {
        countArray.push(`${current} (${cnt})`);
      }
      current = array[i];
      cnt = 1;
    } else {
      cnt++;
    }
  }
  if (cnt > 0) {
    countArray.push(`${current} (${cnt})`);
  }
  return countArray;
};

const checkField = (nameKey: string, myArray: any[], type: string) => {
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i][type] === nameKey) {
      return true;
    }
  }
};

const normalizeDataIDAndValue = (value: string): any => {
  const see: string = value?.includes(" ;") ? " ;" : ";";
  const valuesField = value?.split(see) ?? [];
  const newValue = valuesField
    .map((item: any) => {
      if (item.includes("###")) {
        const newValueArray = item?.split("###")[1];
        return newValueArray;
      } else {
        return item;
      }
    })
    .join(", ");

  /* fungsi ini untuk membuat data dari <id>###<value>;<id>###<value>;<id>###<value>; 
  menjadi <id>###<value>, <id>###<value>, <id>###<value> */
  return newValue;
};

const checkPrevValueEquipment = (e: any, data: any, callback: (e: any) => void) => {
  let check = false;
  let _temp = data;
  const resp = data;
  let field = e?.formList[0].formField[0].field;
  e?.formList[0].formField[0].field.map((item: any, i: number) => {
    const val = field[i].value ?? "";
    if (item.type === "equipment" && item.options === "info" && resp) {
      Object.keys(resp).map((_item: string) => {
        if (_item !== "locationId" && _item !== "sublocationId") {
          if (item.optionsType === _item) {
            if (val !== resp[_item]) {
              _temp[_item] = val;
              check = true;
            }
          }
        }
        return check;
      });
      if (item.optionsType === "locationId") {
        const id = val?.split("###")[0] === "undefined" ? 0 : parseInt(val?.split("###")[0] ?? 0);
        if (id !== resp["locationId"]) {
          _temp["locationId"] = id;
          check = true;
        }
      }
      if (item.optionsType === "sublocationId") {
        const id = val?.split("###")[0] === "undefined" ? 0 : parseInt(val?.split("###")[0] ?? 0);
        if (id !== resp["sublocationId"]) {
          _temp["sublocationId"] = id;
          check = true;
        }
      }
      return check;
    }
    return field;
  });
  callback(_temp);
  return check;
};

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = (componentImport: any, name: string) => {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false"
    );
    // try to import the component
    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error: any) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

export {
  capitalize,
  romanize,
  validateSize,
  generateColor,
  generatePDF,
  generateXls,
  removeDuplicates,
  countWorkers,
  normalizeDataIDAndValue,
  checkField,
  checkPrevValueEquipment,
  lazyRetry,
};
