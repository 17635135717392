import { types } from "./_types";

const initialState = {
  procedureConfigList: [],
  pagination: null,
  actionStatus: "initial",
  isLoading: false,
};

export const procedureConfigurationReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_PROCEDURE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_PROCEDURE_LIST_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
        procedureConfigList: [],
        pagination: action?.data?.pagination,
      };
    case types.GET_PROCEDURE_LIST_SUCCESS:
      return {
        ...state,
        procedureConfigList: action?.data?.data?.listTool,
        pagination: action?.data?.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    default:
      return state;
  }
};
