import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { actions as actionForm } from "./_actions";
import {
  ICreateSubmitMedicineKeyData,
  IGetDetailMedicine,
  IGetDetailUserRequestData,
  IGetMedicalDetailRequestData,
  IGetMedicalList,
  IGETMedicalRequest,
  IGetListMedical,
  types as masterTypes,
} from "./_types";

export const watcherMedical = () => [
  takeLatest(masterTypes.GET_MEDICAL_RECORD_KEYS_REQUEST, workerDetailPreview),
  takeLatest(masterTypes.SUBMIT_MEDICAL_KEYS_REQUEST, workerCreateMedical),
  takeLatest(masterTypes.GET_USERS_KEYS_REQUEST, workerGetDetailUsers),
  takeLatest(masterTypes.LIST_MEDICAL_KEYS_REQUEST, workerGetMedicalList),
  takeLatest(masterTypes.LIST_MEDICINE_KEYS_REQUEST, workerGetMedicalMedicine),
  takeLatest(masterTypes.MEDICAL_DETAIL_KEYS_REQUEST, workerGetDetailList),
  takeLatest(masterTypes.GET_DETAIL_USER_KEYS_REQUEST, workerGetDetailDataUserNew),
  takeLatest(masterTypes.SUBMIT_MEDICINE_KEYS_REQUEST, workerSubmit),
  takeLatest(masterTypes.MEDICAL_LIST_REQUEST, workerMedicineList),
  takeLatest(masterTypes.HEALTH_LETTER_REQUEST, workerHealthLetter),
  takeLatest(masterTypes.SICK_LETTER_REQUEST, workerSickLetter),
  takeLatest(masterTypes.REFERRAL_LETTER_REQUEST, workerReferralLetter),
  takeLatest(masterTypes.ANTIGEN_RESULT_REQUEST, workerAntigenResult),
  takeLatest(masterTypes.GUARANTEE_LETTER_REQUEST, workerGuaranteeLetter),
  takeLatest(masterTypes.REIMBURSEMENT_REQUEST, workerReimbursement),
];

function* workerDetailPreview({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetMedicalDetailRequestData>): any {
  const http = new HttpService(urlsEndpoint.GET_DETAIL_MEDICAL);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.getMedicalRecord.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getMedicalRecord.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetMedicalList({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<IGetMedicalList>): any {
  const http = new HttpService(urlsEndpoint.LIST_MEDICAL);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.getList.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getList.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetDetailList({ payload, onSuccess, onFailure }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.DETAIL_MEDICAL);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.getDetail.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getDetail.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerMedicineList({ payload }: IDefaultRequestAction<IGetListMedical>): any {
  const http = new HttpService(urlsEndpoint.MEDICINE_LIST);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.getMedicalList.success(response));
  } catch (err) {
    yield put(actionForm.getMedicalList.failure(err));
  }
}

function* workerHealthLetter({ payload, onSuccess, onFailure }: IDefaultRequestAction<null>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.file, {
      url: urlsEndpoint.HEALTH_LETTER,
      method: "post",
      data: payload,
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerSickLetter({ payload, onSuccess, onFailure }: IDefaultRequestAction<null>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.file, {
      url: urlsEndpoint.SICK_LETTER,
      method: "post",
      data: payload,
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerReferralLetter({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.file, {
      url: urlsEndpoint.REFERRAL_LETTER,
      method: "post",
      data: payload,
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerAntigenResult({ payload, onSuccess, onFailure }: IDefaultRequestAction<null>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.file, {
      url: urlsEndpoint.ANTIGEN_RESULT,
      method: "post",
      data: payload,
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGuaranteeLetter({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.file, {
      url: urlsEndpoint.GUARANTEE_LETTER,
      method: "post",
      data: payload,
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerReimbursement({ payload, onSuccess, onFailure }: IDefaultRequestAction<null>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.file, {
      url: urlsEndpoint.REIMBURSEMENT,
      method: "post",
      data: payload,
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetDetailUsers({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetDetailUserRequestData>): any {
  const http = new HttpService(`${urlsEndpoint.GET_DETAIL_AUTH_USERS}/${payload?.username}`);
  try {
    const response = yield call(http.custom, {
      url: `${urlsEndpoint.GET_DETAIL_AUTH_USERS}/${payload?.username}`,
      method: "get",
    });
    yield put(actionForm.getUser.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getUser.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerGetDetailDataUserNew({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetDetailUserRequestData>): any {
  const http = new HttpService(`${urlsEndpoint.GET_USER}/${payload?.username}`);
  try {
    const response = yield call(http.custom, {
      url: `${urlsEndpoint.GET_USER}/${payload?.username}`,
      method: "get",
    });
    yield put(actionForm.getUserDetail.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getUserDetail.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerGetMedicalMedicine({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetDetailMedicine>): any {
  const http = new HttpService(`${urlsEndpoint.MEDICINE}/${payload?.id}`);
  try {
    const response = yield call(http.custom, {
      url: `${urlsEndpoint.MEDICINE}/${payload?.id}`,
      method: "get",
    });
    yield put(actionForm.getMedicine.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getMedicine.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerSubmit({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<ICreateSubmitMedicineKeyData>): any {
  const http = new HttpService(urlsEndpoint.MEDICINE_SUBMIT);
  try {
    let response = yield call(http.custom, {
      url: urlsEndpoint.MEDICINE_SUBMIT,
      method: "post",
      data: {
        treatmentId: payload?.treatmentId,
        treatment: payload?.treatment,
        diagnose: payload?.diagnose,
        note: payload?.note,
        reservationId: payload?.reservationId,
        isConfirm: payload?.isConfirm,
        medicine: payload?.medicine ?? [],
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    yield put(actionForm.submitMedicine.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.submitMedicine.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerCreateMedical({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGETMedicalRequest>): any {
  const url = payload?.flowData?.assigmentNumber
    ? urlsEndpoint.CREATE_MY_TASK
    : urlsEndpoint.SUBMIT_MEDICAL;
  const http = new HttpService(url);
  try {
    let response = yield call(http.post, payload);
    yield put(actionForm.createMedical.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.createMedical.failure(err));
    yield callback(onFailure, err);
  }
}
