export const types = {
  GET_LIST_ARTICLES_REQUEST: "homeArticles/GET_LIST_ARTICLES_REQUEST",
  GET_LIST_ARTICLES_SUCCESS: "homeArticles/GET_LIST_ARTICLES_SUCCESS",
  GET_LIST_ARTICLES_FAILED: "homeArticles/GET_LIST_ARTICLES_FAILED",
  GET_PIN_ARTICLES_REQUEST: "homeArticles/GET_PIN_ARTICLES_REQUEST",
  GET_PIN_ARTICLES_SUCCESS: "homeArticles/GET_PIN_ARTICLES_SUCCESS",
  GET_PIN_ARTICLES_FAILED: "homeArticles/GET_PIN_ARTICLES_FAILED",
  GET_DETAIL_ARTICLE_REQUEST: "homeArticles/GET_DETAIL_ARTICLE_REQUEST",
  GET_DETAIL_ARTICLE_SUCCESS: "homeArticles/GET_DETAIL_ARTICLE_SUCCESS",
  GET_DETAIL_ARTICLE_FAILED: "homeArticles/GET_DETAIL_ARTICLE_FAILED",
  GET_PIN_DETAIL_ARTICLES_REQUEST: "homeArticles/GET_PIN_DETAIL_ARTICLES_REQUEST",
  GET_PIN_DETAIL_ARTICLES_SUCCESS: "homeArticles/GET_PIN_DETAIL_ARTICLES_SUCCESS",
  GET_PIN_DETAIL_ARTICLES_FAILED: "homeArticles/GET_PIN_DETAIL_ARTICLES_FAILED",
  GET_COMMENT_LIST_REQUEST: "homeArticles/GET_COMMENT_LIST_REQUEST",
  GET_COMMENT_LIST_SUCCESS: "homeArticles/GET_COMMENT_LIST_SUCCESS",
  GET_COMMENT_LIST_FAILED: "homeArticles/GET_COMMENT_LIST_SUCCESS",
  LOAD_MORE_COMMENT_REQUEST: "homeArticles/LOAD_MORE_COMMENT_REQUEST",
  LOAD_MORE_COMMENT_SUCCESS: "homeArticles/LOAD_MORE_COMMENT_SUCCESS",
  LOAD_MORE_COMMENT_FAILED: "homeArticles/LOAD_MORE_COMMENT_FAILED",
};

export interface IPayloadComment {
  articleId?: string | number;
  comment: string;
}

export interface IPayloadUpdateComment {
  commentId?: number | null;
  articleId: number;
  comment: string;
}

export interface editComment {
  commentId: number | null;
  comment: string;
  toEdit?: boolean;
  toDelete?: boolean;
}
