import { types as masterTypes } from "./_types";

const initialState = {
  datamaster: [],
  dataDetail: {},
  attdata: [],
  attMasterDetail: {},
  location: [],
  locDetail: {},
  dataLocs: [],
  site: [],
  actionStatus: "initial",
  moreActionStatus: {
    getForm: "initial",
    addData: "initial",
    updateForm: "initial",
    getMasterAtt: "initial",
    addMasterAtt: "initial",
    updateMasterAtt: "initial",
    getLoc: "initial",
    getSite: "initial",
    addLoc: "initial",
    updateLoc: "initial",
  },
  data: null,
  pagination: null,
  paginationTools: null,
  locationType: [],
  masterdata: [],
  masterTypesDataList: [],
  isLoading: true,
  equipmentType: [],
  paginationEquipment: null,
  paginationEquipmentChild: null,
  listTools: [],
  listChildEquipment: [],
  subLocationById: null,
  paginationCategory: null,
  listCategoryEquipmentType: [],
  equipmentCategoryPagination: null,
  listEquipmentDetailLog: [],
  paginationEquipmentDetailLog: null,
};

export const masterReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case masterTypes.GET_MASTER_KEYS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.GET_MASTER_KEYS_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.GET_MASTER_KEYS_SUCCESS:
      return {
        ...state,
        pagination: action.data.pagination,
        data: action.data.data.datamaster,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.GET_MASTER_KEYS_CATEGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.GET_MASTER_KEYS_CATEGORY_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.GET_MASTER_KEYS_CATEGORY_SUCCESS:
      return {
        ...state,
        data: action.data.data.datamaster,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.GET_MASTER_KEYS_ARCHIVE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.GET_MASTER_KEYS_ARCHIVE_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.GET_MASTER_KEYS_ARCHIVE_SUCCESS:
      return {
        ...state,
        // datamaster: action.data?.data?.datamaster || [],
        data: action.data.data.datamaster,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.GET_MASTER_KEY_DETAIL_REQUEST:
    case masterTypes.GET_MASTER_KEY_DETAIL_FAILED:
    case masterTypes.GET_MASTER_KEY_DETAIL_SUCCESS:
      return {
        ...state,
        dataDetail: {
          ...state.dataDetail,
          [action.data?.data?.datamaster[0]?.subCategoryId]: action.data?.data?.datamaster[0],
        },
        moreActionStatus: {
          ...state.moreActionStatus,
          getForm: action.actionStatus,
        },
        actionStatus: action.actionStatus,
      };

    case masterTypes.CREATE_MASTER_KEY_REQUEST:
    case masterTypes.CREATE_MASTER_KEY_FAILED:
    case masterTypes.CREATE_MASTER_KEY_SUCCESS:
      return {
        ...state,
        actionStatus: action.actionStatus,
        moreActionStatus: {
          ...state.moreActionStatus,
          addData: action.actionStatus,
        },
      };
    case masterTypes.CREATE_MASTER_CATEGORY_REQUEST:
    case masterTypes.CREATE_MASTER_CATEGORY_FAILED:
    case masterTypes.CREATE_MASTER_CATEGORY_SUCCESS:
      return {
        ...state,
        actionStatus: action.actionStatus,
        moreActionStatus: {
          ...state.moreActionStatus,
          addData: action.actionStatus,
        },
      };
    case masterTypes.UPDATE_MASTER_KEY_REQUEST:
    case masterTypes.UPDATE_MASTER_KEY_FAILED:
    case masterTypes.UPDATE_MASTER_KEY_SUCCESS:
      return {
        ...state,
        moreActionStatus: {
          ...state.moreActionStatus,
          updateForm: action.actionStatus,
        },
        actionStatus: action.actionStatus,
      };
    //--------------------
    //batas
    //--------------------
    case masterTypes.GET_ALL_MASTER_ATT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.GET_ALL_MASTER_ATT_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.GET_ALL_MASTER_ATT_SUCCESS:
      return {
        ...state,
        // attdata: action.data?.data?.attdata || [],
        data: action.data.data.attdata,
        isLoading: false,
        actionStatus: action.actionStatus,
        // moreActionStatus: {
        //   ...state.moreActionStatus,
        //   getDetailAtt: action.actionStatus,
        // },
      };
    case masterTypes.GET_MASTER_ATT_DETAIL_REQUEST:
    case masterTypes.GET_MASTER_ATT_DETAIL_FAILED:
    case masterTypes.GET_MASTER_ATT_DETAIL_SUCCESS:
      return {
        ...state,
        attMasterDetail: {
          ...state.attMasterDetail,
          [action.data?.data?.attdata?.attId]: action.data?.data?.attdata,
        },
        moreActionStatus: {
          ...state.moreActionStatus,
          getDetailAtt: action.actionStatus,
        },
        actionStatus: action.actionStatus,
      };
    case masterTypes.CREATE_MASTER_ATT_REQUEST:
    case masterTypes.CREATE_MASTER_ATT_FAILED:
    case masterTypes.CREATE_MASTER_ATT_SUCCESS:
      return {
        ...state,
        actionStatus: action.actionStatus,
        moreActionStatus: {
          ...state.moreActionStatus,
          addMasterAtt: action.actionStatus,
        },
      };
    case masterTypes.UPDATE_MASTER_ATT_REQUEST:
    case masterTypes.UPDATE_MASTER_ATT_FAILED:
    case masterTypes.UPDATE_MASTER_ATT_SUCCESS:
      return {
        ...state,
        moreActionStatus: {
          ...state.moreActionStatus,
          updateMasterAtt: action.actionStatus,
        },
        actionStatus: action.actionStatus,
      };
    //--------------------
    //batas
    //--------------------
    case masterTypes.GET_SITE_SUCCESS:
      return {
        ...state,
        site: action.data.data.site,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.GET_SITE_FAILED:
    case masterTypes.GET_SITE_REQUEST:
    case masterTypes.GET_LOCATION_BY_ID_REQUEST:
    case masterTypes.GET_LOCATION_BY_ID_FAILED:
    case masterTypes.GET_LOCATION_BY_ID_SUCCESS:
      return {
        ...state,
        locDetail: {
          ...state.locDetail,
          [action.data?.data?.location?.id]: action.data?.data?.location,
        },
        moreActionStatus: {
          ...state.moreActionStatus,
          getLoc: action.actionStatus,
        },
        actionStatus: action.actionStatus,
      };
    case masterTypes.GET_LIST_LOC_REQUEST:
    case masterTypes.GET_LIST_LOC_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
      };
    case masterTypes.GET_LIST_LOC_SUCCESS:
      return {
        ...state,
        dataLocs:
          action.data.data?.location?.map((item: any) => ({
            label: item.locationName,
            value: item.id,
          })) || [],
        actionStatus: action.actionStatus,
      };
    case masterTypes.CREATE_DATA_LOCATION_REQUEST:
    case masterTypes.CREATE_DATA_LOCATION_FAILED:
    case masterTypes.CREATE_DATA_LOCATION_SUCCESS:
      return {
        ...state,
        actionStatus: action.actionStatus,
        moreActionStatus: {
          ...state.moreActionStatus,
          addLoc: action.actionStatus,
        },
      };
    case masterTypes.UPDATE_DATA_LOCATION_REQUEST:
    case masterTypes.UPDATE_DATA_LOCATION_FAILED:
    case masterTypes.UPDATE_DATA_LOCATION_SUCCESS:
      return {
        ...state,
        moreActionStatus: {
          ...state.moreActionStatus,
          updateLoc: action.actionStatus,
        },
        actionStatus: action.actionStatus,
      };

    // site
    case masterTypes.SITE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.SITE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.SITE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data.OutputSchema,
        actionStatus: action.actionStatus,
      };
    // risk
    case masterTypes.RISK_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.RISK_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.RISK_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data.OutputSchema,
        actionStatus: action.actionStatus,
      };
    // shift
    case masterTypes.SHIFT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.SHIFT_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    // beda sendiri
    case masterTypes.SHIFT_LIST_SUCCESS:
      return {
        ...state,
        data: action.data.data,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    // equipment
    case masterTypes.EQUIPMENT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.EQUIPMENT_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.EQUIPMENT_LIST_SUCCESS:
      return {
        ...state,
        data: action.data.data.listEquipment,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    // equipmentType
    case masterTypes.EQUIPMENT_TYPE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.EQUIPMENT_TYPE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.EQUIPMENT_TYPE_LIST_SUCCESS:
      return {
        ...state,
        equipmentType: action.data.data !== null ? action.data.data.listEquipment : [],
        paginationEquipment: action.data.pagination,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    // equipment
    case masterTypes.LOCATION_TYPE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.LOCATION_TYPE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.LOCATION_TYPE_LIST_SUCCESS:
      return {
        ...state,
        locationType: action.data.data.locationType,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.MASTER_TOOLS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.MASTER_TOOLS_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.MASTER_TOOLS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listTools: action.data.data !== null ? action.data.data.listTool : [],
        paginationTools: action.data.pagination,
        actionStatus: action.actionStatus,
      };

    // sublocation by id
    case masterTypes.SUBLOCATION_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.SUBLOCATION_BY_ID_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.SUBLOCATION_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subLocationById: action.data.data?.dataMaster,
        actionStatus: action.actionStatus,
      };
    case masterTypes.CREATE_SUBLOCATION_REQUEST:
    case masterTypes.UPDATE_SUBLOCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.CREATE_SUBLOCATION_FAILED:
    case masterTypes.CREATE_SUBLOCATION_SUCCESS:
    case masterTypes.UPDATE_SUBLOCATION_FAILED:
    case masterTypes.UPDATE_SUBLOCATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case masterTypes.MASTER_TYPE_DATAS_LIST_REQUEST:
    case masterTypes.MASTER_TYPE_DATAS_LIST_FAILURE:
    case masterTypes.MASTER_TYPE_DATAS_LIST_SUCCESS:
      return {
        ...state,
        masterTypesDataList: action?.data?.data?.dataMaster,
        actionStatus: action.actionStatus,
      };
    case masterTypes.EQUIPMENT_CHILD_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.EQUIPMENT_CHILD_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.EQUIPMENT_CHILD_LIST_SUCCESS:
      return {
        ...state,
        listChildEquipment: action.data.data?.equipmentDetail,
        isLoading: false,
        paginationEquipmentChild: action?.data?.pagination,
        actionStatus: action.actionStatus,
      };
    case masterTypes.EQUIPMENT_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.EQUIPMENT_CATEGORY_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.EQUIPMENT_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        listCategoryEquipmentType: action.data?.data?.equipmentCategory ?? [],
        isLoading: false,
        paginationCategory: action?.data?.pagination ?? null,
        actionStatus: action.actionStatus,
        equipmentCategoryPagination: action.data.pagination,
      };

    case masterTypes.HISTORY_EQUIPMENT_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.HISTORY_EQUIPMENT_DETAIL_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.HISTORY_EQUIPMENT_DETAIL_SUCCESS:
      return {
        ...state,
        listEquipmentDetailLog: action.data.data?.equipmentDetailLog,
        isLoading: false,
        paginationEquipmentDetailLog: action?.data?.pagination,
        actionStatus: action.actionStatus,
      };
    default:
      return state;
  }
};
