import { Card, Form, Input } from "antd";
import { FastField, FormikProps } from "formik";
import { FC } from "react";
import ButtonForm from "../../components/Button";
import FormItem from "../../components/Form/FormItem";
import styles from "./styles.module.less";

interface ViewProps {
  onClick: Function;
  isLoading: boolean;
}

const View: FC<ViewProps & FormikProps<any>> = ({ onClick, isLoading, ...formikProps }) => {
  const { handleSubmit } = formikProps;
  return (
    <Card className={styles.container}>
      <h1 className={styles.headTitle}>Change Password</h1>
      <Form onFinish={handleSubmit} layout="vertical">
        <FastField name="currentPassword">
          {(props: any) => (
            <FormItem required label="Current Password" name="currentPassword">
              <Input.Password placeholder="********" {...props.field} className={styles.form} />
            </FormItem>
          )}
        </FastField>
        <FastField name="newPassword">
          {(props: any) => (
            <FormItem label="New Password" name="newPassword">
              <Input.Password placeholder="********" {...props.field} className={styles.form} />
            </FormItem>
          )}
        </FastField>
        <FastField name="confirmPassword">
          {(props: any) => (
            <FormItem label="Confirm Password" name="confirmPassword">
              <Input.Password placeholder="********" {...props.field} className={styles.form} />
            </FormItem>
          )}
        </FastField>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <ButtonForm submit={false} onClick={() => onClick()} title="Cancel" type={1} />
          <ButtonForm isLoading={isLoading} title="Submit" />
        </div>
      </Form>
    </Card>
  );
};

export default View;
