import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
// import { MERGER } from "../../assets/images";

export default function Loader() {
  const divStyle = {
    minHeight: "100vh",
    minWidth: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div style={divStyle}>
      <Spin size="large" />
      {/* <img src={MERGER} alt="powered by sentinel" /> */}
    </div>
  );
}

export function MediumLoader() {
  const divStyle = { display: "flex", alignItems: "center", justifyContent: "center" };
  const loaderStyle = { fontSize: "48px" };

  return (
    <div style={divStyle}>
      <LoadingOutlined spin style={loaderStyle} />
    </div>
  );
}

export function ContentLoader() {
  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  };

  return (
    <div style={divStyle}>
      <Spin size="large" />
    </div>
  );
}
