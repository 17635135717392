import { takeLatest, call, put } from "redux-saga/effects";

import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";

import { actions as reportActions } from "./_actions";
import { IGetFormDetailRequestData, IGetFormsRequestData, types } from "./_types";

export const watcherReport = () => [
  takeLatest(types.GET_FORMS_REQUEST, workerGetForms),
  takeLatest(types.GET_FORM_DETAIL_REQUEST, workerGetFormDetail),
];

function* workerGetForms({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetFormsRequestData>): any {
  const http = new HttpService(urlsEndpoint.GET_FORMDATA);
  try {
    const response = yield call(http.post, payload);
    yield put(reportActions.getForms.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(reportActions.getForms.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetFormDetail({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetFormDetailRequestData>): any {
  const http = new HttpService(urlsEndpoint.GET_REPORT_FORMDATA);
  try {
    const response = yield call(http.post, payload);
    yield put(reportActions.getFormDetail.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(reportActions.getFormDetail.failure(err));
    yield callback(onFailure, err);
  }
}
