import createSagaMiddleware from "@redux-saga/core";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";

import rootReducers from "./rootReducers";
import { rootSagas } from "./rootSagas";

const reduxPersistConfig = {
  key: "root",
  version: 1,
  storage: storage,
  whitelist: ["auth"],
  debug: true,
};

const history = createBrowserHistory();
const reactRouterMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

/* ------------- Redux Configuration ------------- */
const middlewares = [];

/* ------------- Saga Middleware ------------- */
middlewares.push(reactRouterMiddleware);
middlewares.push(sagaMiddleware);

const persistedReducer = persistReducer(reduxPersistConfig, rootReducers);

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));
const persistor = persistStore(store);
sagaMiddleware.run(rootSagas);

export { store, persistor };
