import { useState, useEffect } from "react";

export type Breakpoints = "sm" | "md" | "lg" | "xl" | "2xl";
const XL2 = 1536;
const XL = 1280;
const LG = 1024;
const MD = 768;
const BREAKPOINTS = {
  "2xl": XL2,
  xl: XL,
  lg: LG,
  md: MD,
};

const useBreakpoints = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<Breakpoints>(
    processWidth(window.innerWidth)
  );

  useEffect(() => {
    const breakpointListener = (ev: any) => {
      const currentWidth = ev?.target?.innerWidth;
      if (currentWidth) {
        setCurrentBreakpoint(processWidth(currentWidth));
      }
    };

    window.addEventListener("resize", breakpointListener);

    return () => {
      window.removeEventListener("resize", breakpointListener);
    };
  }, []);

  const takeFrom = (breakpoint: Breakpoints) => {
    const index = Object.keys(BREAKPOINTS).indexOf(breakpoint);
    if (index === 0) return [Object.keys(BREAKPOINTS)[0]];

    return Object.keys(BREAKPOINTS).slice(0, index + 1) as Breakpoints[];
  };

  const isBreaking = (breakpoint: Breakpoints) => {
    if (breakpoint === "sm") {
      return window.innerWidth < MD;
    }
    return BREAKPOINTS[breakpoint] > window.innerWidth;
  };

  function processWidth(width: number) {
    if (width >= XL2) {
      return "2xl";
    } else if (width >= XL) {
      return "xl";
    } else if (width >= LG) {
      return "lg";
    } else if (width >= MD) {
      return "md";
    }
    return "sm";
  }

  return {
    currentBreakpoint,
    takeFrom,
    isBreaking,
  };
};

export default useBreakpoints;
