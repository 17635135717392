import { types } from "./_types";

const initialState = {
  exportMedicalRecord: [],
  isLoading: true,
};

export const inquiryReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_MEDICAL_RECORD_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case types.EXPORT_MEDICAL_RECORD_REQUEST:
      return {
        ...state,
        isLoading: false,
      };
    case types.EXPORT_MEDICAL_RECORD_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.EXPORT_MEDICAL_RECORD_SUCCESS:
      return {
        ...state,
        exportMedicalRecord: action.data.data.medicine,
        paginationLog: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    default:
      return state;
  }
};
