import * as urlsName from "./urlsName";

const breadcrumbNameTags: { [key: string]: string } = {
  [urlsName.DASHBOARD]: "Dashboard",
  [urlsName.GREETING]: "Greeting",
  [urlsName.GREETING_ADD]: "Add New Greeting",
  [urlsName.USER]: "User",
  [urlsName.ROLE]: "Role",
  [urlsName.GENERAL_CORPORATE]: "General Corporate",
  [urlsName.GENERAL_GROUP]: "General Group",
  [urlsName.GENERAL_THEME]: "General Theme",
  [urlsName.GENERAL_FORM]: "General Form",
  [urlsName.GENERAL_FORM_DRAFT]: "General Form Draft",
  [urlsName.ARTICLE_LIST]: "Article List",
};

export default breadcrumbNameTags;
