import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { ICreatePermit, IDeletePermit, IUpdatePermit, types } from "./_types";

export const actions = {
  getAllLoc: {
    request: (payload: any) => ({
      type: types.GET_ALL_LOCATION_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.GET_ALL_LOCATION_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.GET_ALL_LOCATION_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createPermit: {
    request: (payload: IDefaultRequestParams<ICreatePermit>) => ({
      ...payload,
      type: types.CREATE_PERMIT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.CREATE_PERMIT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.CREATE_PERMIT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  updatePermit: {
    request: (payload: IDefaultRequestParams<IUpdatePermit>) => ({
      ...payload,
      type: types.UPDATE_PERMIT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.UPDATE_PERMIT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.UPDATE_PERMIT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  deletePermit: {
    request: (payload: IDefaultRequestParams<IDeletePermit>) => ({
      ...payload,
      type: types.DELETE_PERMIT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  getListPermit: {
    request: (payload: any) => ({
      ...payload,
      type: types.LIST_PERMIT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.LIST_PERMIT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.LIST_PERMIT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  getListProjectPermit: {
    request: (payload: any) => ({
      ...payload,
      type: types.LIST_PROJECT_PERMIT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.LIST_PROJECT_PERMIT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.LIST_PROJECT_PERMIT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  getDetailPermit: {
    request: (payload: any) => ({
      ...payload,
      type: types.GET_DETAIL_PERMIT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.GET_DETAIL_PERMIT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.GET_DETAIL_PERMIT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  // get detail tow
  detDetailTypeOfWork: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.GET_DETAIL_TYPE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.GET_DETAIL_TYPE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.GET_DETAIL_TYPE_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  // get data prequalification
  getPrequalification: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.GET_PREQUALIFICATION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.GET_PREQUALIFICATION_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.GET_PREQUALIFICATION_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  workAgreement: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.WORK_AGREEMENT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  safetyMandatory: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.SAFETY_MANDATORY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  extendPermit: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.EXTEND_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  documentList: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.DOCUMENT_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  uploadFile: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.UPLOAD_FILE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },

  getSpesialWorkPermit: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.GET_SPESIAL_PERMIT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.GET_SPESIAL_PERMIT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.GET_SPESIAL_PERMIT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  getSpesialWorkPermitChecklist: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.GET_SPESIAL_PERMIT_CHECKLIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.GET_SPESIAL_PERMIT_CHECKLIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.GET_SPESIAL_PERMIT_CHECKLIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createSpesialPermit: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.CREATE_SPESIAL_PERMIT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.CREATE_SPESIAL_PERMIT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.CREATE_SPESIAL_PERMIT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  getByDaySpesialPermit: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.GET_BY_DAY_PERMIT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.GET_BY_DAY_PERMIT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.GET_BY_DAY_PERMIT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  createSpesialPermitByDay: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: types.CREATE_BY_DAY_PERMIT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.CREATE_BY_DAY_PERMIT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.CREATE_BY_DAY_PERMIT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};

// non redux
export async function deletePtw(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_PERMIT_TO_WORK);
  try {
    const response = await http.delete(id);
    if (response.result) {
      const successResponse = {
        success: true,
        data: response?.data,
      };
      return successResponse;
    } else {
      const errorResponse = {
        error: true,
        data: response?.data,
      };
      return errorResponse;
    }
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function deleteLocAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_DATA_LOCATION);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.data?.location,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getSpesialWorkPermit(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_SPESIAL_PERMIT_TO_WORK);
  try {
    const response = await http.custom({
      url: `${urlsEndpoint.GET_SPESIAL_PERMIT_TO_WORK}/${id}`,
      method: "get",
    });
    const successResponse = {
      success: true,
      data: response?.dataList,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function deleteSpesial(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_PERMIT_BY_DAY);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getListSpesialWorkPermit(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_LIST_SPESIAL_PERMIT_TO_WORK);
  try {
    const response = await http.custom({
      url: `${urlsEndpoint.GET_LIST_SPESIAL_PERMIT_TO_WORK}/${id}`,
      method: "get",
    });
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getSpesialWorkPermitByHours(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_HOUR_SPESIAL_PERMIT_TO_WORK);
  try {
    const response = await http.custom({
      url: `${urlsEndpoint.GET_HOUR_SPESIAL_PERMIT_TO_WORK}/${id}`,
      method: "get",
    });
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getSpesialWorkPermitByDays(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_SPESIAL_PERMIT_BY_DAY);
  try {
    const response = await http.custom({
      url: `${urlsEndpoint.GET_SPESIAL_PERMIT_BY_DAY}/${id}`,
      method: "get",
    });
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getRequestToInspect(): Promise<{
  success?: boolean;
  error?: boolean;
  data: any;
  status?: number | any;
}> {
  const http = new HttpService(urlsEndpoint.LIST_MENU);
  try {
    const response = await http.post({
      start: 0,
      limit: 1,
      page: 1,
      filter: {
        assignmentNumber: "",
        assigmentDate: "",
        flowName: "request to inspect",
        typeFlow: "safety",
        locationTypeName: "",
        equipmentTypeName: "",
      },
    });

    const successResponse = {
      success: true,
      data: response?.data,
      status: response?.status,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      success: false,
      error: true,
      data: null,
    };
    return errorResponse;
  }
}
