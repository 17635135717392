import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import {
  IGetHiradcListRequest,
  IGetHiradcRequestData,
  IPayloadExportHiradc,
  IPayloadLogHiradc,
  types as hiradcType,
} from "./_types";

export const actions = {
  getMaster: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: hiradcType.GET_MASTER_ACTION_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: hiradcType.GET_MASTER_ACTION_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: hiradcType.GET_MASTER_ACTION_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListHiradc: {
    request: (requestData: IDefaultRequestParams<IGetHiradcRequestData>) => ({
      ...requestData,
      type: hiradcType.GET_LIST_HIRADC_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: hiradcType.GET_LIST_HIRADC_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: hiradcType.GET_LIST_HIRADC_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getApproved: {
    request: (requestData: IDefaultRequestParams<IGetHiradcListRequest>) => ({
      ...requestData,
      type: hiradcType.LIST_APROVED_HIRADC_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: hiradcType.LIST_APROVED_HIRADC_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: hiradcType.LIST_APROVED_HIRADC_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getLogHiradc: {
    request: (requestData: IDefaultRequestParams<IPayloadLogHiradc>) => ({
      ...requestData,
      type: hiradcType.LOG_HIRADC_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: hiradcType.LOG_HIRADC_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: hiradcType.LOG_HIRADC_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getArchivedHiradc: {
    request: (requestData: IDefaultRequestParams<IGetHiradcRequestData>) => ({
      ...requestData,
      type: hiradcType.GET_ARCHIVED_HIRADC_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: hiradcType.GET_ARCHIVED_HIRADC_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: hiradcType.GET_ARCHIVED_HIRADC_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  exportHiradc: {
    request: (requestData: IDefaultRequestParams<IPayloadExportHiradc>) => ({
      ...requestData,
      type: hiradcType.EXPORT_HIRADC_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: hiradcType.EXPORT_HIRADC_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: hiradcType.EXPORT_HIRADC_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};

export async function addHiradc(
  payload: object
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.CREATE_HIRADC);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function addApproved(
  payload: object
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.APPROVE_HIRADC);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function deleteHiradc(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_HIRADC);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getDetailHiradc(
  id: string
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DETAIL_HIRADC);
  try {
    const response = await http.get(id || "");
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getDepartment(
  id: string
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_DETAIL_DEPARTMENT);
  try {
    const response = await http.get(id || "");
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
export async function getDuplicateHiradc(
  id: string
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(`${urlsEndpoint.HIRADC_DUPLICATE}/${id}`);
  try {
    const response = await http.post();
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getDetailMyTask(
  assignmentNumber: string
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.HIRADC_BY_MY_TASK);
  try {
    const response = await http.post({
      assignmentNumber: assignmentNumber,
    });
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getAreaHiradc(
  payload: object
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.HIRADC_AREA);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getHiradcActivity(payload: object): Promise<{
  success?: boolean;
  error?: boolean;
  data: any;
}> {
  const http = new HttpService(urlsEndpoint.GET_MASTER_KEYS_CATEGORY);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getControlType(): Promise<{
  success?: boolean;
  error?: boolean;
  data: any;
}> {
  const http = new HttpService(urlsEndpoint.GET_CONTROL_TYPE_HIRADC);
  try {
    const response = await http.get("");
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateHiradc(
  payload: object
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.UPDATE_HIRADC);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function listApproval(
  payload: object
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.LIST_HIRADC);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
