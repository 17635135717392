import { ReloadOutlined } from "@ant-design/icons";
import { Table as TB } from "antd";
import { FC } from "react";
import styles from "./styles.module.less";

const Table: FC<any> = (props) => {
  const { dataSource, onReload, loading } = props;
  const data = dataSource ? dataSource : [];

  return (
    <div className={styles.wrap_tb}>
      {data && data.length < 1 ? (
        loading && loading ? null : (
          <div className={styles.wrap_reload}>
            <div className={styles.pad}>
              <ReloadOutlined title="Reload" onClick={() => onReload && onReload()} />
            </div>
          </div>
        )
      ) : null}
      <TB pagination={false} scroll={{ x: 1000 }} {...props} />
    </div>
  );
};
export default Table;
