export const types = {
  GET_PIN_ARTICLES_REQUEST: "articles/GET_PIN_ARTICLES_REQUEST",
  GET_PIN_ARTICLES_SUCCESS: "articles/GET_PIN_ARTICLES_SUCCESS",
  GET_PIN_ARTICLES_FAILED: "articles/GET_PIN_ARTICLES_FAILED",

  GET_PIN_ARTICLES_LIST_REQUEST: "articles/GET_PIN_ARTICLES_LIST_REQUEST",
  GET_PIN_ARTICLES_LIST_SUCCESS: "articles/GET_PIN_ARTICLES_LIST_SUCCESS",
  GET_PIN_ARTICLES_LIST_FAILED: "articles/GET_PIN_ARTICLES_LIST_FAILED",

  GET_LIST_ARTICLES_REQUEST: "articles/GET_LIST_ARTICLES_REQUEST",
  GET_LIST_ARTICLES_SUCCESS: "articles/GET_LIST_ARTICLES_SUCCESS",
  GET_LIST_ARTICLES_FAILED: "articles/GET_LIST_ARTICLES_FAILED",

  GET_CATEGORY_ARTICLES_REQUEST: "articles/GET_CATEGORY_ARTICLES_REQUEST",
  GET_CATEGORY_ARTICLES_SUCCESS: "articles/GET_CATEGORY_ARTICLES_SUCCESS",
  GET_CATEGORY_ARTICLES_FAILED: "articles/GET_CATEGORY_ARTICLES_FAILED",

  GET_LIST_CATEGORY_ARTICLES_REQUEST: "articles/GET_LIST_CATEGORY_ARTICLES_REQUEST",
  GET_LIST_CATEGORY_ARTICLES_SUCCESS: "articles/GET_LIST_CATEGORY_ARTICLES_SUCCESS",
  GET_LIST_CATEGORY_ARTICLES_FAILED: "articles/GET_LIST_CATEGORY_ARTICLES_FAILED",

  UPDATE_PIN_ARTICLES_REQUEST: "articles/UPDATE_PIN_ARTICLES_REQUEST",
  UPDATE_PIN_ARTICLES_SUCCESS: "articles/UPDATE_PIN_ARTICLES_SUCCESS",
  UPDATE_PIN_ARTICLES_FAILED: "articles/UPDATE_PIN_ARTICLES_FAILED",

  // Request
  DELETE_ARTICLES_REQUEST: "articles/DELETE_ARTICLES_REQUEST",
  CREATE_ARTICLES_REQUEST: "articles/CREATE_ARTICLES_REQUEST",
  CREATE_ARTICLES_SUCCESS: "articles/CREATE_ARTICLES_SUCCESS",
  CREATE_ARTICLES_FAILED: "articles/CREATE_ARTICLES_FAILED",

  EDIT_ARTICLES_REQUEST: "articles/EDIT_ARTICLES_REQUEST",
  EDIT_ARTICLES_SUCCESS: "articles/EDIT_ARTICLES_SUCCESS",
  EDIT_ARTICLES_FAILED: "articles/EDIT_ARTICLES_FAILED",

  // Category
  ADD_CATEGORY_ARTICLES_REQUEST: "articles/ADD_CATEGORY_ARTICLES_REQUEST",
};
