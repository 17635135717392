import _ from "lodash";

export const toURLEncodedParams = (payload: any = null) => {
  const params = new URLSearchParams();

  _.forIn(payload, (value, key) => {
    params.append(key, value);
  });

  return params;
};

export const toFormData = (payload: any = null) => {
  const params = new FormData();

  _.forIn(payload, (value, key) => {
    params.append(key, value);
  });

  return params;
};
