import { call, put, takeLatest } from "redux-saga/effects";

import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { IMyTaskReqParams, types } from "./_types";

import { callback } from "../../store/defaultSagas";
import { actions as sidebarActions } from "./_actions";

export const watcherSidebar = () => [
  takeLatest(types.GET_MYTASK_COUNTER_REQUEST, workerGetMyTaskCounter),
];

function* workerGetMyTaskCounter({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<IMyTaskReqParams>): any {
  const http = new HttpService(urlsEndpoint.GET_MYTASK_COUNTER);
  try {
    const response = yield call(http.find);

    if (response.data === null) {
      throw new Error("My task counter data not found");
    }

    yield put(sidebarActions.getMyTaskCounter.success(response.data.counter));
    yield callback(onSuccess, response);
  } catch (err) {
    console.error(err);
    yield put(sidebarActions.getMyTaskCounter.failure(err));
    yield callback(onFailure, err);
  }
}
