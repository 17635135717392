import { debug } from "../../helpers/logger";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { PayloadReasign, types as healthTypes } from "./_types";

export const actions = {
  getReservationList: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_RESERVATION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_RESERVATION_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_RESERVATION_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createReservation: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.CREATE_RESERVATION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.CREATE_RESERVATION_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.CREATE_RESERVATION_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getReservationHistory: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_RESERVATION_HISTORY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_RESERVATION_HISTORY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_RESERVATION_HISTORY_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getReservationType: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_RESERVATION_TYPE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_RESERVATION_TYPE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_RESERVATION_TYPE_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getReservationDetailHealth: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_RESERVATION_DETAIL_HEALTH_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  getReservationDetailSafety: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_RESERVATION_DETAIL_SHE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  getReservationDetail: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_RESERVATION_DETAIL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_RESERVATION_DETAIL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_RESERVATION_DETAIL_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getReservationDetailHistory: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_RESERVATION_DETAIL_HISTORY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_RESERVATION_DETAIL_HISTORY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_RESERVATION_DETAIL_HISTORY_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListPatient: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_LIST_PATIENT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_LIST_PATIENT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_LIST_PATIENT_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListTime: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_LIST_TIME_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  getListMedicalCenter: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_LIST_MEDICAL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_LIST_MEDICAL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_LIST_MEDICAL_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getHistoryMedicalCenter: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_HISTORY_MEDICAL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_HISTORY_MEDICAL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_HISTORY_MEDICAL_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getMedicalCenterDetail: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_DETAIL_MEDICAL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_DETAIL_MEDICAL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_DETAIL_MEDICAL_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getTimelineStatus: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_TIMELINE_STATUS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_TIMELINE_STATUS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_TIMELINE_STATUS_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getDetailRisk: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_DETAIL_RISK_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_DETAIL_RISK_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_DETAIL_RISK_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListInventory: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_LIST_INVENTORY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_LIST_INVENTORY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_LIST_INVENTORY_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};

export async function getFormFlow(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_FORMFLOW);
  try {
    const response = await http.custom({
      url: `${urlsEndpoint.GET_FORMFLOW}/${id}`,
      method: "get",
    });
    const successResponse = {
      success: true,
      data: response?.data,
    };
    debug("response", response);
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getListByPosision(
  positionId: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_USER_POSISION);
  try {
    const response = await http.post({
      positionId,
    });
    const successResponse = {
      success: true,
      data: response?.data,
      status: response?.status,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getListUsers(
  payload: any
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_USERS);
  try {
    const response = await http.post({ ...payload });
    const successResponse = {
      success: true,
      data: response?.result,
      status: response?.status,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getListByRole(
  roleId: string | number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  if (typeof roleId !== "number" && !roleId) return { error: true, data: undefined };

  if (typeof roleId === "string") {
    roleId = parseInt(roleId);
  }

  const http = new HttpService(urlsEndpoint.LIST_ROLE);
  try {
    const response = await http.post({ roleId });
    const successResponse = {
      success: true,
      error: false,
      data: response.data,
      status: response.status,
    };
    return successResponse;
  } catch (err) {
    console.error(err);
    const errorResponse = {
      error: true,
      success: false,
      data: err,
    };
    return errorResponse;
  }
}

export async function getListRisk(
  name: string
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DETAIL_RISK);
  try {
    const response = await http.post({
      name,
    });
    const successResponse = {
      success: true,
      data: response,
      status: response?.status,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getDetailUser(
  userName: string
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_USER);
  try {
    const response = await http.get(userName);
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getUsersList(): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_USERS);
  try {
    const response = await http.post();
    const successResponse = {
      success: true,
      data: response?.data,
      status: response?.status,
    };

    return successResponse;
  } catch (err) {
    console.error(err);
    return {
      error: true,
      data: err,
    };
  }
}

export async function ReassignTask(
  payload: PayloadReasign
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.REASSIGN_RESERVATION_TASK);
  try {
    const response = await http.post({
      payload,
    });
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function RejectedHealth(
  payload: PayloadReasign
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.REJECT_APPOITMENT_HEALTH);
  try {
    const response = await http.post({
      payload,
    });
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function RejectedSafety(
  payload: PayloadReasign
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.REJECT_RESERVATION_SAFETY);
  try {
    const response = await http.post({
      payload,
    });
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function timelineFormFlow(
  assignmentNumber: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.TIMELINE_FORMFLOW_LIST);
  try {
    const response = await http.post({
      assignmentNumber,
    });
    const successResponse = {
      success: true,
      data: response?.data,
      status: response?.status,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
