import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { IPayloadComment, IPayloadUpdateComment, types as homeArticleTypes } from "./_types";

export async function createCommentArticleAction(
  payload: IPayloadComment
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.ADD_COMMENT);
  try {
    const response = await http.post(payload);
    const successResponse = {
      error: true,
      data: undefined,
    };
    if (response.result) {
      const successResponse = {
        success: true,
        data: response?.message,
      };
      return successResponse;
    }
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function deleteCommentAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_COMMENT);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.message,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateCommentarAction(
  payload: IPayloadUpdateComment
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.UPDATE_COMMENT);
  try {
    const response = await http.post(payload);
    const successResponse = {
      error: true,
      data: undefined,
    };
    if (response.result) {
      const successResponse = {
        success: true,
        data: response?.message,
      };
      return successResponse;
    }
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export const homeArticleActions = {
  getListArticles: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: homeArticleTypes.GET_LIST_ARTICLES_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: homeArticleTypes.GET_LIST_ARTICLES_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: homeArticleTypes.GET_LIST_ARTICLES_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getPinArticles: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: homeArticleTypes.GET_PIN_ARTICLES_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: homeArticleTypes.GET_PIN_ARTICLES_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: homeArticleTypes.GET_PIN_ARTICLES_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getPinDetailArticles: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: homeArticleTypes.GET_PIN_DETAIL_ARTICLES_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: homeArticleTypes.GET_PIN_DETAIL_ARTICLES_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: homeArticleTypes.GET_PIN_DETAIL_ARTICLES_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getDetailArticle: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: homeArticleTypes.GET_DETAIL_ARTICLE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: homeArticleTypes.GET_DETAIL_ARTICLE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: homeArticleTypes.GET_DETAIL_ARTICLE_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getCommentList: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: homeArticleTypes.GET_COMMENT_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: homeArticleTypes.GET_COMMENT_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: homeArticleTypes.GET_COMMENT_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  loadMoreCommentList: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: homeArticleTypes.LOAD_MORE_COMMENT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: homeArticleTypes.LOAD_MORE_COMMENT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: homeArticleTypes.LOAD_MORE_COMMENT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};
