import { types } from "./_types";

const initialState = {
  listTypeOfWork: [],
  listRankConfiguration: [],
  listRankDetail: [],
  isLoading: true,
  pagination: null,
};

export const rankConfigurationReducers = (state = initialState, action: any) => {
  switch (action.type) {
    // type of work
    case types.GET_LIST_TYPE_OF_WORK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_TYPE_OF_WORK_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.GET_LIST_TYPE_OF_WORK_SUCCESS:
      return {
        ...state,
        listTypeOfWork: action.data.data.listType,
        pagination: action.data.pagination,
        isLoading: false,
      };
    // add
    case types.ADD_TYPE_OF_WORK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.ADD_TYPE_OF_WORK_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.ADD_TYPE_OF_WORK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    // edit
    case types.EDIT_TYPE_OF_WORK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.EDIT_TYPE_OF_WORK_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.EDIT_TYPE_OF_WORK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    // delete
    case types.DELETE_TYPE_OF_WORK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.DELETE_TYPE_OF_WORK_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.DELETE_TYPE_OF_WORK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    // rank
    case types.GET_LIST_RANK_CONFIGURATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_RANK_CONFIGURATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.GET_LIST_RANK_CONFIGURATION_SUCCESS:
      return {
        ...state,
        listRankConfiguration: action.data.data.listRank,
        pagination: action.data.pagination,
        isLoading: false,
      };
    // add
    case types.ADD_RANK_CONFIGURATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.ADD_RANK_CONFIGURATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.ADD_RANK_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    // edit
    case types.EDIT_RANK_CONFIGURATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.EDIT_RANK_CONFIGURATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.EDIT_RANK_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    // delete
    case types.DELETE_RANK_CONFIGURATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.DELETE_RANK_CONFIGURATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.DELETE_RANK_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    // detail
    case types.GET_LIST_RANK_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_RANK_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.GET_LIST_RANK_DETAIL_SUCCESS:
      return {
        ...state,
        listRankDetail: action.data.data ? action.data.data.listDetail : [],
        pagination: action.data.pagination,
        isLoading: false,
      };
    // add
    case types.ADD_RANK_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.ADD_RANK_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.ADD_RANK_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    // edit
    case types.EDIT_RANK_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.EDIT_RANK_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.EDIT_RANK_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    // delete
    case types.DELETE_RANK_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.DELETE_RANK_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.DELETE_RANK_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
