import { AxiosRequestConfig } from "axios";
import axiosInstance from "./axiosInstance";

export default class HttpService {
  url: string;

  constructor(url: string) {
    this.url = url;
  }

  find = async (params?: any): Promise<any> => {
    try {
      const res = await axiosInstance.get(this.url, {
        params,
      });
      return {
        ...(res.data as any),
        status: res.status,
        statusText: res.statusText,
      };
    } catch (error: any) {
      if (error.response) {
        throw error.response;
      }
      throw error;
    }
  };

  get = async (id: number | string): Promise<any> => {
    try {
      const res = await axiosInstance.get(`${this.url}/${id}`);
      return {
        ...(res.data as any),
        status: res.status,
        statusText: res.statusText,
      };
    } catch (error: any) {
      if (error.response) {
        throw error.response;
      }
      throw error;
    }
  };

  post = async (payload?: any): Promise<any> => {
    try {
      const res = await axiosInstance.post(this.url, payload);
      return {
        ...(res.data as any),
        status: res.status,
        statusText: res.statusText,
      };
    } catch (error: any) {
      if (error.response) {
        throw error.response;
      }
      throw error;
    }
  };

  download = async (config: AxiosRequestConfig<any>): Promise<any> => {
    try {
      const res = await axiosInstance(config);
      return res;
    } catch (error: any) {
      if (error.response) {
        throw error.response;
      }
      throw error;
    }
  };

  put = async (id: number, payload: any): Promise<any> => {
    try {
      const res = await axiosInstance.put(`${this.url}/${id}`, payload);
      return {
        ...(res.data as any),
        status: res.status,
        statusText: res.statusText,
      };
    } catch (error: any) {
      if (error.response) {
        throw error.response;
      }
      throw error;
    }
  };

  delete = async (id: number): Promise<any> => {
    try {
      const res = await axiosInstance.delete(`${this.url}/${id}`);
      return {
        ...(res.data as any),
        status: res.status,
        statusText: res.statusText,
      };
    } catch (error: any) {
      if (error.response) {
        throw error.response;
      }
      throw error;
    }
  };

  deleteById = async (id: number): Promise<any> => {
    try {
      const res = await axiosInstance.post(`${this.url}/id=${id}`);
      return {
        ...(res.data as any),
        status: res.status,
        statusText: res.statusText,
      };
    } catch (error: any) {
      if (error.response) {
        throw error.response;
      }
      throw error;
    }
  };

  // DELETE BY ID BUT ALSO WITH DELETE METHOD
  deleteMethodById = async (id: number): Promise<any> => {
    try {
      const res = await axiosInstance.delete(`${this.url}/id=${id}`);
      return {
        ...(res.data as any),
        status: res.status,
        statusText: res.statusText,
      };
    } catch (error: any) {
      if (error.response) {
        throw error.response;
      }
      throw error;
    }
  };

  custom = async (config: AxiosRequestConfig<any>): Promise<any> => {
    try {
      const res = await axiosInstance(config);
      return {
        ...(res.data as any),
        status: res.status,
        statusText: res.statusText,
      };
    } catch (error: any) {
      if (error.response) {
        throw error.response;
      }
      throw error;
    }
  };

  getmock = async (): Promise<any> => {
    const res = await axiosInstance.get(`${this.url}`);
    return res.data;
  };

  file = async (config: AxiosRequestConfig<any>): Promise<any> => {
    try {
      const res = await axiosInstance(config);
      return {
        data: res.data,
        status: res.status,
        statusText: res.statusText,
      };
    } catch (error: any) {
      if (error.response) {
        throw error.response;
      }
      throw error;
    }
  };

  upload = async (config: AxiosRequestConfig<any>): Promise<any> => {
    try {
      const res = await axiosInstance(config);
      return {
        data: res.data,
        status: res.status,
        statusText: res.statusText,
      };
    } catch (error: any) {
      if (error.response) {
        throw error.response;
      }
      throw error;
    }
  };

  uploadXlsx = async (config: AxiosRequestConfig<any>): Promise<any> => {
    try {
      const res = await axiosInstance(config);
      return {
        data: res.data,
        status: res.status,
        statusText: res.statusText,
      };
    } catch (error: any) {
      if (error.response) {
        throw error.response;
      }
      throw error;
    }
  };
}
