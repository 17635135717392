import { types } from "./_types";

const initialState = {
  medicineList: [],
  medicineDetails: [],
  exportMedicine: [],
  exportMedicalRecord: [],
  logMedicine: [],
  medicineCode: [],
  pagination: null,
  paginationLog: null,
  paginationHistory: null,
  actionStatus: "initial",
  moreActionStatus: {
    addUser: "initial",
  },
  isLoading: true,
};

export const medicineReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_MEDICINE_KEYS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_MEDICINE_KEYS_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_MEDICINE_KEYS_SUCCESS:
      return {
        ...state,
        medicineList: action.data.data.medicine,
        pagination: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_MEDICINE_CODE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_MEDICINE_CODE_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_MEDICINE_CODE_SUCCESS:
      return {
        ...state,
        medicineCode: action.data.data.medicine,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_MEDICINE_LOG_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_MEDICINE_LOG_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_MEDICINE_LOG_SUCCESS:
      return {
        ...state,
        logMedicine: action.data.data.medicine,
        paginationLog: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };

    case types.GET_DETAIL_MEDICINE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_DETAIL_MEDICINE_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_DETAIL_MEDICINE_SUCCESS:
      return {
        ...state,
        medicineDetails: action.data.data.medicine,
        paginationLog: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };

    case types.EXPORT_MEDICINE_REQUEST:
      return {
        ...state,
        isLoading: false,
      };
    case types.EXPORT_MEDICINE_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.EXPORT_MEDICINE_SUCCESS:
      return {
        ...state,
        exportMedicine: action.data.data.medicine,
        paginationLog: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };

    default:
      return state;
  }
};
