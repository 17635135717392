import { call, put, takeLatest } from "redux-saga/effects";
import { toFormData } from "../../helpers/formHelpers";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { actions as userActions } from "./_actions";
import {
  IForgetPassData,
  IRegisterRequestData,
  ISigninRequestData,
  IUpdatePassData,
  IUserOTPData,
  types,
} from "./_types";

export const watcherAuth = () => [
  takeLatest(types.SIGNIN_REQUEST, workerSignin),
  takeLatest(types.REGISTER_REQUEST, workerRegister),
  takeLatest(types.GET_USERS_REQUEST, workerGetUsers),
  takeLatest(types.FORGOT_PASS_REQUEST, workerForgotPass),
  takeLatest(types.OTP_USER_REQUEST, workerUserOTP),
  takeLatest(types.UPDATE_PASS_REQUEST, workerUpdatePass),
  takeLatest(types.CHECK_TOKEN_REQUEST, workerCheckTokenUser),
  takeLatest(types.REFRESH_TOKEN_REQUEST, workerCheckTokenRefresh),
];

function* workerSignin({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<ISigninRequestData>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      method: "post",
      url: urlsEndpoint.TOKEN,
      data: toFormData(payload),
      auth: {
        username: process.env.REACT_APP_API_USERNAME || "",
        password: process.env.REACT_APP_API_PASSWORD || "",
      },
    });

    const lastResortFireEquipmentPermission = {
      category: "submenu",
      id: 99,
      name: "Fire Tools & Equipment",
      permissions: ["create", "read", "update", "delete"],
    };

    try {
      const list = response?.role[0]?.features[0]?.web;
      if (list) {
        const target = list.find(
          (item: any) => item.name === "Equipment" && item.category === "submenu"
        );

        if (target) {
          response.role[0].features[0].web.push(lastResortFireEquipmentPermission);
        }

        const copy = structuredClone(target);
        copy.name = "Fire Tools & Equipment";

        response.role[0].features[0].web.push(copy);
      }
    } catch (err) {
      console.error("Could not get the permission of fire equipment");
      console.error(err);
    }

    yield put(userActions.signin.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.signin.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerForgotPass({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IForgetPassData>): any {
  const http = new HttpService(urlsEndpoint.FORGOT_PASS);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.forgotPass.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.forgotPass.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerUserOTP({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IUserOTPData>): any {
  const http = new HttpService(urlsEndpoint.OTP_USER);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.OTPUser.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.OTPUser.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerUpdatePass({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IUpdatePassData>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_PASS);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerRegister({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IRegisterRequestData>): any {
  const http = new HttpService("");
  try {
    const registerAuthRes = yield call(http.custom, {
      method: "post",
      url: urlsEndpoint.REGISTER_AUTH,
      data: {
        username: payload?.username,
        email: payload?.email,
        password: payload?.password,
        companyEmailDomain: payload?.companyEmailDomain,
      },
      auth: {
        username: process.env.REACT_APP_API_USERNAME || "",
        password: process.env.REACT_APP_API_PASSWORD || "",
      },
    });
    const tokenRes = yield call(http.custom, {
      method: "post",
      url: urlsEndpoint.TOKEN,
      data: toFormData({
        username: payload?.username,
        password: payload?.password,
        grant_type: "password",
      }),
      auth: {
        username: process.env.REACT_APP_API_USERNAME || "",
        password: process.env.REACT_APP_API_PASSWORD || "",
      },
    });
    const registerBackendRes = yield call(http.custom, {
      url: urlsEndpoint.REGISTER_BACKEND,
      method: "post",
      data: {
        companyName: payload?.companyName,
        companyEmailDomain: payload?.companyEmailDomain,
        contactEmail: payload?.contactEmail,
        operationAddress: payload?.operationAddress,
        phone: payload?.phone,
        businesType: payload?.businesType,
        password: payload?.password,
        username: payload?.username,
      },
      auth: undefined,
      headers: {
        Authorization: `Bearer ${tokenRes.access_token}`,
      },
    });

    yield put(userActions.register.success([registerAuthRes, tokenRes, registerBackendRes]));
    yield callback(onSuccess, [registerAuthRes, tokenRes, registerBackendRes]);
  } catch (err) {
    yield put(userActions.register.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetUsers({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_USERS);
  try {
    const response = yield call(http.find, payload);
    yield put(userActions.getUsers.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getUsers.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerCheckTokenRefresh({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<string>): any {
  const http = new HttpService(`${urlsEndpoint.REFRESH_TOKEN}`);
  try {
    const tokenRes = yield call(http.custom, {
      url: urlsEndpoint.REFRESH_TOKEN,
      method: "post",
      params: { refresh_token: payload },
      auth: {
        username: process.env.REACT_APP_API_USERNAME || "",
        password: process.env.REACT_APP_API_PASSWORD || "",
      },
    });
    yield put(userActions.refreshToken.success(tokenRes));
    yield callback(onSuccess, tokenRes);
  } catch (err) {
    yield put(userActions.refreshToken.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerCheckTokenUser({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<string>): any {
  const http = new HttpService(urlsEndpoint.CHECK_TOKEN);
  try {
    const tokenRes = yield call(http.custom, {
      url: urlsEndpoint.CHECK_TOKEN,
      method: "get",
      params: { token: payload },
      auth: {
        username: process.env.REACT_APP_API_USERNAME || "",
        password: process.env.REACT_APP_API_PASSWORD || "",
      },
    });

    yield put(userActions.checkToken.success(tokenRes));
    yield callback(onSuccess, tokenRes);
  } catch (err) {
    yield put(userActions.checkToken.failure(err));
    yield callback(onFailure, err);
  }
}
