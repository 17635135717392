import { types } from "./_types";

const initialState = {
  isLoading: true,
};

export const notificationReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.READ_NOTIF_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.READ_NOTIF_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.READ_NOTIF_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
