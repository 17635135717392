import { useState } from "react";
import i18n from "../../_i18n";
import styles from "./styles.module.less";

const LanguageDropdown = () => {
  const [currentLanguage, setCurrentLanguage] = useState("en");
  const [currentPosition, setCurrentPosition] = useState("right");
  const positionStyle = (() => {
    switch (currentPosition) {
      case "right":
        return {
          right: "5px",
        };
      case "center":
        return {
          right: "50%",
          transform: "translateX(50%)",
        };
      case "left":
        return {
          right: "100%",
          transform: "translateX(100%)",
        };
      default:
        return {
          right: "5px",
        };
    }
  })();

  const handleLanguageChange = (ev: any) => {
    setCurrentLanguage(ev.target.value);
    i18n.changeLanguage(ev.target.value);
  };

  const handlePositionChange = (ev: any) => {
    if (["left", "center", "right"].includes(ev.target.value)) {
      setCurrentPosition(ev.target.value);
      return;
    }
    setCurrentPosition("right");
  };

  return (
    <div className={styles.language_select_container} style={positionStyle}>
      <p className={styles.toast_message}>Development-only feature</p>
      <label htmlFor="#select-language">Select Language</label>
      <select value={currentLanguage} onChange={handleLanguageChange} id="select-language">
        <option value="en">EN</option>
        <option value="id">ID</option>
      </select>
      <label htmlFor="position">Choose Position</label>
      <select id="position" value={currentPosition} onChange={handlePositionChange}>
        <option value="left">Left</option>
        <option value="center">Center</option>
        <option value="right">Right</option>
      </select>
    </div>
  );
};

export default LanguageDropdown;
