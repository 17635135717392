import * as urlsName from "./urlsName";

export interface Permission {
  permissions: string[];
  name: string;
  id: number;
  category: string;
}

const DEFAULT_PERMISSION_OBJECT = {
  permissions: [],
  name: "",
  id: -1,
  category: "",
};

export const getDataPermission = (
  permissions: Permission[] | undefined,
  name: string | number,
  category: string
) => {
  // digunakan untuk mencari data permission berdasarkan name path dan category menunya
  // param permission merupakan data seluruh menu, param name merupakan menu yang di cari, category merupakan category menunya
  let data;

  if (typeof name === "string") {
    data = permissions?.find((res) => res.name === name && res.category === category);
  } else {
    data = permissions?.find((res) => res.id === name && res.category === category);
  }

  if (!data) return DEFAULT_PERMISSION_OBJECT;

  return data;
};

export const getUrl = (permissions: any) => {
  let url: { urlsName: string; search: string; permissions: Permission } = {
    urlsName: "*",
    search: "",
    permissions: DEFAULT_PERMISSION_OBJECT,
  };
  // fungsi ini digunakan untuk redirect sesuai dengan permission paling atas berdasarkan permission menu
  if (getDataPermission(permissions, "Home", "menu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.HOME_PAGE}`, // url yang diregister di route
      permissions: getDataPermission(permissions, "Home", "menu"), // params digunakan untuk melempar seluruh data yang nantinya bakal di pake untuk melihat access apa saja yang bisa digunakan user
    };
    return url;
  }
  if (getDataPermission(permissions, "Dashboard", "menu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.DASHBOARD}`,
      permissions: getDataPermission(permissions, "Dashboard PIC", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Dashboard Management", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.DASHBOARD_DYNAMIC}`,
      permissions: getDataPermission(permissions, "Dashboard Management", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Dashboard Employee", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.DASHBOARD_EMPLOYEE}`,
      permissions: getDataPermission(permissions, "Dashboard Employee", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Permit to Work", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.PERMIT_TO_WORK}`,
      permissions: getDataPermission(permissions, "Permit to Work", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "My Task", "menu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.MY_TASK}`,
      permissions: getDataPermission(permissions, "My Task", "menu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Medical Center", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.RESERVATION_LIST}`,
      permissions: getDataPermission(permissions, "Medical Center", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Medical Record", "menu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.USER_INQUIRY}`,
      permissions: getDataPermission(permissions, "Medical Record", "menu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Medical Center Doctor", "menu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.MEDICAL_CENTER}`,
      permissions: getDataPermission(permissions, "Medical Center Doctor", "menu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "List Form", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.GENERAL_FORM}`,
      permissions: getDataPermission(permissions, "List Form", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Workflow Form", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.FORM_FLOW_LIST}`,
      permissions: getDataPermission(permissions, "Workflow Form", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "User", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.USER}`,
      permissions: getDataPermission(permissions, "User", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Role", "submenu").permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.ROLE}`,
      permissions: getDataPermission(permissions, "Role", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Company", "submenu").permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.COMPANY_PROFILE}`,
      permissions: getDataPermission(permissions, "Company", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Hierarchy Company", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.HIERARCHY_LIST}`,
      permissions: getDataPermission(permissions, "Hierarchy Company", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Procedure Config", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.PROCEDURE_CONFIG}`,
      permissions: getDataPermission(permissions, "Procedure Config", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Bulk Upload Report", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.BULK_UPLOAD_USER}`,
      permissions: getDataPermission(permissions, "Bulk Upload Report", "submenu"),
    };
    return url;
  }

  if (getDataPermission(permissions, "Medical Center", "submenu")?.permissions?.length > 0) {
    url = {
      urlsName: `${urlsName.MASTER_KEY_HEATH}`,
      search: "id=2&?type=Health",
      permissions: getDataPermission(permissions, "Medical Center", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "SHE", "submenu")?.permissions?.length > 0) {
    url = {
      urlsName: `${urlsName.MASTER_KEY_SAFETY}`,
      search: "id=1&?type=Safety",
      permissions: getDataPermission(permissions, "SHE", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Equipment", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.MASTER_LIST_EQUIPMENT_TYPE}`,
      permissions: getDataPermission(permissions, "Equipment", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Tools", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.TOOLS_LIST}`,
      permissions: getDataPermission(permissions, "Tools", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Rank Configuration", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.TYPE_OF_WORK}`,
      permissions: getDataPermission(permissions, "Rank Configuration", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "Location", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.MASTER_LOCATION}`,
      permissions: getDataPermission(permissions, "Location", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "home", "menu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.HOME_PAGE}`,
      permissions: getDataPermission(permissions, "home", "menu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "HIRADC Submission", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.LIST_HIRADC}`,
      search: "type=hiradcSubbmission",
      permissions: getDataPermission(permissions, "HIRADC Submission", "submenu"),
    };
    return url;
  }
  if (getDataPermission(permissions, "HIRADC List", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.LIST_HIRADC}`,
      search: "type=hiradcList",
      permissions: getDataPermission(permissions, "HIRADC List", "submenu"),
    };
    return url;
  }

  if (getDataPermission(permissions, "Reassign", "submenu")?.permissions?.length > 0) {
    url = {
      ...url,
      urlsName: `${urlsName.REASSIGN}`,
      permissions: getDataPermission(permissions, "Reassign", "submenu"),
    };
    return url;
  }

  return url;
};
