import { FC, HTMLProps } from "react";
import { SEARCH } from "../../assets/images";
import useTranslateShortcut from "../../helpers/hooks/useTranslateShortcut";
import styles from "./styles.module.less";
interface SearchPropsComponent {
  search?: string;
}

const SearchForm: FC<SearchPropsComponent & HTMLProps<HTMLInputElement>> = ({
  search,
  ...rest
}) => {
  const { translate } = useTranslateShortcut({});

  return (
    <div className={styles.inputWrap}>
      <img src={SEARCH} alt="" style={{ marginRight: 8, marginLeft: 12 }} />
      <input
        placeholder={translate("search")}
        value={search}
        style={{ border: "none", outline: "none", color: "#6B7280", width: "80%" }}
        type="text"
        {...rest}
      />
    </div>
  );
};

export default SearchForm;
