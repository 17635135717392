import { all } from "@redux-saga/core/effects";

import { watcherAuth } from "../pages/Auth/_sagas";
import { watcherGeneral } from "../pages/General/_sagas";
import { watcherMaster } from "../pages/MasterData/_sagas";
import { watcherReservasion } from "../pages/Reservation/_sagas";
import { watcherUser } from "../pages/User/_sagas";
import { watcherSite } from "../pages/Site/_sagas";
import { watcherReport } from "../pages/Report/_sagas";
import { watcherDetail } from "../pages/DetailData/_sagas";
import { watcherRole } from "../pages/Role/_sagas";
import { watcherHealth } from "../pages/Health/_sagas";
import { watcherMedical } from "../pages/MedicalRecord/_sagas";
import { watcherNotification } from "../pages/Notification/_sagas";
import { watcherRankConfig } from "../pages/RankConfiguration/_sagas";
import { watcherHierarchy } from "../pages/HierarchyCompany/_sagas";
import { watcherSafety } from "../pages/Safety/_sagas";
import { watcherProfile } from "../pages/Profile/_sagas";
import { watcherChangePassword } from "../pages/ChangePassword/_sagas";
import { watcherMedicine } from "../pages/Medicine/_sagas";
import { watcherUserInquiry } from "../pages/UserInquiry/_sagas";
import { watcherMedicalInventory } from "../pages/MedicalInvetory/Inventory/_sagas";
import { watcherBulkUser } from "../pages/BulkUpload/_sagas";
import { watcherArticles } from "../pages/Articles/_sagas";
import { watcherHomeArticle } from "../pages/Home/_sagas";
import { watcherSidebar } from "../components/Sidebar/_sagas";
import { watcherWorkflowCategorization } from "../pages/WorkflowCategorization/_sagas";
import { watcherProcedureConfig } from "../pages/ProcedureConfig/_sagas";
import { watcherSafetyVoiceScoring } from "../pages/SafetyVoiceScoring/_sagas";
import { watcherHiradc } from "../pages/Hiradc/_sagas";
import { watcherDashboard } from "../pages/DynamicDashboard/_sagas";

export function* rootSagas() {
  yield all([
    ...watcherAuth(),
    ...watcherReservasion(),
    ...watcherGeneral(),
    ...watcherMaster(),
    ...watcherUser(),
    ...watcherReport(),
    ...watcherDetail(),
    ...watcherRole(),
    ...watcherSite(),
    ...watcherHealth(),
    ...watcherRankConfig(),
    ...watcherHierarchy(),
    ...watcherMedical(),
    ...watcherNotification(),
    ...watcherSafety(),
    ...watcherProfile(),
    ...watcherChangePassword(),
    ...watcherMedicine(),
    ...watcherUserInquiry(),
    ...watcherMedicalInventory(),
    ...watcherBulkUser(),
    ...watcherArticles(),
    ...watcherHomeArticle(),
    ...watcherSidebar(),
    ...watcherWorkflowCategorization(),
    ...watcherProcedureConfig(),
    ...watcherSafetyVoiceScoring(),
    ...watcherHiradc(),
    ...watcherDashboard(),
  ]);
}
