import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { actions } from "./_actions";
import { types, IGetHiradcRequestData, IPayloadLogHiradc, IGetHiradcListRequest } from "./_types";

export const watcherHiradc = () => [
  takeLatest(types.GET_LIST_HIRADC_KEYS_REQUEST, workerGetHiradcList),
  takeLatest(types.GET_ARCHIVED_HIRADC_KEYS_REQUEST, workerGetHiradcArchived),
  takeLatest(types.GET_MASTER_ACTION_KEYS_REQUEST, workerGetMaster),
  takeLatest(types.EXPORT_HIRADC_REQUEST, workerExportHiradc),
  takeLatest(types.LOG_HIRADC_REQUEST, workerGetHiradcLog),
  takeLatest(types.LIST_APROVED_HIRADC_REQUEST, workerGetHiradcListApproved),
];

function* workerGetMaster({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_MASTER_KEYS_CATEGORY);

  try {
    const response = yield call(http.post, payload);

    yield put(actions.getMaster.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getMaster.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetHiradcList({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetHiradcRequestData>): any {
  const http = new HttpService(urlsEndpoint.LIST_HIRADC);

  try {
    const response = yield call(http.post, payload);
    yield put(actions.getListHiradc.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListHiradc.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetHiradcListApproved({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetHiradcListRequest>): any {
  const http = new HttpService(urlsEndpoint.HIRADC_LIST_APPROVED);

  try {
    const response = yield call(http.post, payload);
    yield put(actions.getApproved.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getApproved.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetHiradcArchived({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetHiradcRequestData>): any {
  const http = new HttpService(urlsEndpoint.ARCHIVED_HIRADC);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getArchivedHiradc.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getArchivedHiradc.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetHiradcLog({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IPayloadLogHiradc>): any {
  const http = new HttpService(urlsEndpoint.HIRADC_LOG);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getLogHiradc.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getLogHiradc.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerExportHiradc({ payload, onSuccess, onFailure }: IDefaultRequestAction<null>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.file, {
      url: urlsEndpoint.EXPORT_HIRADC,
      method: "post",
      data: payload,
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel",
      },
    });
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}
