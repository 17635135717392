import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { IFormDashboard, IGetFormData, types as generalTypes } from "./_types";

export const actions = {
  getDashboard: {
    request: (requestData: IDefaultRequestParams<IGetFormData>) => ({
      ...requestData,
      type: generalTypes.GET_DASHBOARD_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_DASHBOARD_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_DASHBOARD_LIST_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getDashboardAll: {
    request: (requestData: IDefaultRequestParams<IGetFormData>) => ({
      ...requestData,
      type: generalTypes.GET_ALL_DASHBOARD_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_ALL_DASHBOARD_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_ALL_DASHBOARD_LIST_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createDashboard: {
    request: (requestData: IDefaultRequestParams<IFormDashboard>) => ({
      type: generalTypes.CREATE_DASHBOARD_REQUEST,
      ...requestData,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.CREATE_DASHBOARD_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.CREATE_DASHBOARD_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getDetailDashboard: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: generalTypes.DETAIL_DASHBOARD_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.DETAIL_DASHBOARD_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.DETAIL_DASHBOARD_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  updateDashboard: {
    request: (requestData: IDefaultRequestParams<IFormDashboard>) => ({
      type: generalTypes.UPDATE_DASHBOARD_REQUEST,
      ...requestData,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.UPDATE_DASHBOARD_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.UPDATE_DASHBOARD_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  updatePriorityDashboard: {
    request: (requestData: IDefaultRequestParams<IFormDashboard>) => ({
      type: generalTypes.UPDATE_PRIORITY_DASHBOARD_REQUEST,
      ...requestData,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.UPDATE_PRIORITY_DASHBOARD_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.UPDATE_PRIORITY_DASHBOARD_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};

export async function deleteDashboard(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(`${urlsEndpoint.DELETE_DASHBOARD}/${id}`);
  try {
    const response = await http.custom({
      url: `${urlsEndpoint.DELETE_DASHBOARD}/${id}`,
      method: "delete",
    });
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
