import { Breadcrumb as BaseBreadcrumb } from "antd";
import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import breadcrumbNameTags from "../../helpers/breadcrumbNameTags";
import HOME from "../../assets/images/sidebar/home.svg";

interface BreadcrumbProps {}

const Breadcrumb: FC<BreadcrumbProps> = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/");
  const breadcrumbItems = pathSnippets.map((_: any, index: number) => {
    const url = `/${pathSnippets.slice(1, index + 1).join("/")}`;
    if (index === 0) {
      return null;
    }
    return (
      <BaseBreadcrumb.Item>
        {index === 1 ? (
          <img src={HOME} alt="" style={{ width: "22px", marginTop: -6, marginRight: 5 }} />
        ) : (
          <></>
        )}
        <Link
          style={{
            fontWeight: index + 1 === pathSnippets.length ? "bold" : "normal",
            color: index + 1 === pathSnippets.length ? "#003984" : "unset",
            fontSize: "16px",
          }}
          to={url}
        >
          {breadcrumbNameTags[url]}
        </Link>
      </BaseBreadcrumb.Item>
    );
  });
  return (
    <div style={{ marginBottom: "1rem", marginLeft: 25 }}>
      <BaseBreadcrumb>{breadcrumbItems}</BaseBreadcrumb>
    </div>
  );
};

export default Breadcrumb;
