import { types } from "./_types";

const initialState = {
  listReservationType: [],
  categoryList: [],
  categories: [],
  listArticles: [],
  pinArticles: [],
  listPinArticles: [],
  data: {},
  auth: {},
  detailTask: [],
  articles: [],
  paginationList: null,
  pagination: null,
  paginationHistory: null,
  actionStatus: "initial",
  moreActionStatus: {
    addUser: "initial",
  },
  archiveData: null,
  isLoading: true,
  loadingPin: true,
};

export const articlesReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_PIN_ARTICLES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_PIN_ARTICLES_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_PIN_ARTICLES_SUCCESS:
      return {
        ...state,
        pinArticles: action.data.data.article,
        pagination: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_CATEGORY_ARTICLES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_CATEGORY_ARTICLES_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_CATEGORY_ARTICLES_SUCCESS:
      return {
        ...state,
        categoryList: action.data.data.articleCategory,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_CATEGORY_ARTICLES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_CATEGORY_ARTICLES_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_CATEGORY_ARTICLES_SUCCESS:
      return {
        ...state,
        categories: action.data.data.articleCategory,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_ARTICLES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_ARTICLES_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_ARTICLES_SUCCESS:
      return {
        ...state,
        listArticles: action.data.data.article,
        paginationList: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_PIN_ARTICLES_LIST_REQUEST:
      return {
        ...state,
        loadingPin: true,
      };
    case types.GET_PIN_ARTICLES_LIST_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        loadingPin: false,
      };
    case types.GET_PIN_ARTICLES_LIST_SUCCESS:
      return {
        ...state,
        listPinArticles: action.data.data.pinnedArticle,
        actionStatus: action.actionStatus,
        loadingPin: false,
      };
    default:
      return state;
  }
};
