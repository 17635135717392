export enum DefaultActionStatus {
  REQUEST = "request",
  SUCCESS = "success",
  FAILURE = "failure",
}

export interface IDefaultRequestParams<T> {
  payload?: T;
  onSuccess?: (res: any) => void;
  onFailure?: (err: any) => void;
}

export interface IDefaultRequestAction<T> extends IDefaultRequestParams<T> {
  type: string;
  actionStatus: DefaultActionStatus;
}

export interface IDefaultSuccessAction<T> {
  type: string;
  data: T;
  actionStatus: DefaultActionStatus;
}

export interface IDefaultFailureAction<T> {
  type: string;
  error: T;
  actionStatus: DefaultActionStatus;
}

export interface IDropDownTypes {
  label: string;
  value: string;
}

export const MY_PIN = "1a1dc91c907325c69271ddf0c944bc72";
export const FIRST_LOGIN = "0784b630ceb6f604c4f4a3ce34e64045";
export const USE_NIK = "90b8760d2b9dd6169c3bd6b4450b410c";
