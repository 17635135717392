import { call, put, takeLatest } from "redux-saga/effects";
// import { debug } from "../../helpers/logger";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";

import { actions as generalActions } from "./_actions";
import {
  ICreateFormRequestData,
  IGetFlowDataSearch,
  IGetFormDataArchive,
  IGetFormDataCompany,
  IGetFormDataMasters,
  IGetFormDataSearch,
  IGetFormDetailRequestData,
  IGETFormFlowRequest,
  IGetFormsRequestData,
  IUpdateFormRequestData,
  types,
} from "./_types";

export const watcherGeneral = () => [
  takeLatest(types.GET_FORMS_MASTER_REQUEST, workerGetFormMaster),
  takeLatest(types.GET_FORMS_REQUEST, workerGetForms),
  takeLatest(types.GET_FORMS_SEARCH_REQUEST, workerGetFormSearch),
  takeLatest(types.GET_FLOW_SEARCH_REQUEST, workerGetFlowSearch),
  takeLatest(types.GET_FORMS_DRAFT_REQUEST, workerGetFormDraft),
  takeLatest(types.GET_LIST_EQUIP_REQUEST, workerGetListEquip),
  takeLatest(types.GET_FORM_DETAIL_REQUEST, workerGetFormDetail),
  takeLatest(types.CREATE_FORM_REQUEST, workerCreateForm),
  takeLatest(types.UPDATE_FORM_REQUEST, workerUpdateForm),
  takeLatest(types.GET_LIST_FORM_FLOW_REQUEST, workerFormFlowList),
  takeLatest(types.GET_FORMS_ARCHIVE, workerGetFormArchive),
  takeLatest(types.GET_COMPANY_REQUEST, workerGetCompany),
  takeLatest(types.GET_MASTER_DATAS_REQUEST, workerGetMasterDatas),
  takeLatest(types.GET_MASTER_SAFETY_REQUEST, workerGetMasterDataSafety),
  takeLatest(types.GET_ALL_FORMS_MASTER_REQUEST, workerGetAllFormMaster),
];

function* workerGetForms({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetFormsRequestData>): any {
  const http = new HttpService(urlsEndpoint.GET_FORMS);
  try {
    const response = yield call(http.post, payload);
    yield put(generalActions.getForms.success({ ...response, formStatus: payload?.status }));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(generalActions.getForms.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetFormSearch({ payload }: IDefaultRequestAction<IGetFormDataSearch>): any {
  const http = new HttpService(urlsEndpoint.GET_FORMS);
  try {
    const response = yield call(http.post, payload);
    yield put(generalActions.getFormSearch.success(response));
  } catch (err) {
    yield put(generalActions.getFormSearch.failure(err));
  }
}

function* workerGetFlowSearch({ payload }: IDefaultRequestAction<IGetFlowDataSearch>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.SEARCH_FORM_FLOW,
      method: "post",
      data: {
        start: payload?.start,
        limit: payload?.limit,
        keyword: payload?.keyword,
        requestForm: "mobile",
        orderBy: {
          column: ["flowName", "description"],
          direction: payload?.orderBy.direction,
        },
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    yield put(generalActions.getFormPublish.success(response));
  } catch (err) {
    yield put(generalActions.getFormPublish.failure(err));
  }
}

function* workerGetFormArchive({ payload }: IDefaultRequestAction<IGetFormDataArchive>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_ARCHIVE,
      method: "post",
      data: {
        start: payload?.start,
        limit: payload?.limit,
        status: "DELETED",
        orderBy: {
          column: payload?.orderBy.column,
          direction: payload?.orderBy.direction,
        },
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    yield put(generalActions.getFormPublish.success(response));
  } catch (err) {
    yield put(generalActions.getFormPublish.failure(err));
  }
}

function* workerGetFormDraft({ payload }: IDefaultRequestAction<IGetFormDataArchive>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_ARCHIVE,
      method: "post",
      data: {
        start: payload?.start,
        limit: payload?.limit,
        keyword: payload?.keyword,
        status: "DELETED",
        orderBy: {
          column: payload?.orderBy.column,
          direction: payload?.orderBy.direction,
        },
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });

    yield put(generalActions.getFormDraft.success(response));
  } catch (err) {
    yield put(generalActions.getFormDraft.failure(err));
  }
}

function* workerGetFormMaster({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetFormsRequestData>): any {
  const http = new HttpService(urlsEndpoint.GET_FORMS);
  try {
    const response = yield call(http.post, payload);

    yield put(generalActions.getFormMaster.success({ ...response, formStatus: payload?.status }));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(generalActions.getFormMaster.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetAllFormMaster({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetFormsRequestData>): any {
  const http = new HttpService(urlsEndpoint.GET_FORMS);
  try {
    const response = yield call(http.post, payload);

    yield put(
      generalActions.getAllFormMaster.success({ ...response, formStatus: payload?.status })
    );
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(generalActions.getAllFormMaster.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetMasterDatas({ payload }: IDefaultRequestAction<IGetFormDataMasters>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_MASTER_KEYS,
      method: "post",
      data: {
        start: payload?.start,
        limit: payload?.limit,
        typeId: payload?.typeId,
        requestForm: "mobile",
        orderBy: {
          column: ["name"],
          direction: "ASC",
        },
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });

    yield put(generalActions.getMasterDatas.success(response));
  } catch (err) {
    yield put(generalActions.getMasterDatas.failure(err));
  }
}
function* workerGetMasterDataSafety({ payload }: IDefaultRequestAction<IGetFormDataMasters>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_MASTER_KEYS,
      method: "post",
      data: {
        start: payload?.start,
        limit: payload?.limit,
        typeId: payload?.typeId,
        requestForm: "mobile",
        orderBy: {
          column: ["name"],
          direction: "ASC",
        },
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });

    yield put(generalActions.getMasterDataSafety.success(response));
  } catch (err) {
    yield put(generalActions.getMasterDataSafety.failure(err));
  }
}

function* workerGetFormDetail({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetFormDetailRequestData>): any {
  const http = new HttpService(urlsEndpoint.GET_FORM_DETAIL);
  try {
    const response = yield call(http.post, payload);
    yield put(generalActions.getFormDetail.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(generalActions.getFormDetail.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetListEquip({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_LIST_EQUIP,
      method: "post",
      data: {
        start: 0,
        limit: 100,
      },
    });
    yield put(generalActions.getListEquip.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(generalActions.getListEquip.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerCreateForm({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<ICreateFormRequestData>): any {
  const http = new HttpService(urlsEndpoint.CREATE_FORM);
  try {
    const response = yield call(http.post, payload);
    yield put(generalActions.createForm.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(generalActions.createForm.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetCompany({ payload }: IDefaultRequestAction<IGetFormDataCompany>): any {
  const http = new HttpService(urlsEndpoint.GET_ALL_COMPANY);
  try {
    const response = yield call(http.post, payload);
    yield put(generalActions.getListCompany.success(response));
  } catch (err) {
    yield put(generalActions.getListCompany.failure(err));
  }
}

function* workerUpdateForm({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IUpdateFormRequestData>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_FORM);
  try {
    const response = yield call(http.post, payload);

    yield put(generalActions.updateForm.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(generalActions.updateForm.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerFormFlowList({ payload }: IDefaultRequestAction<IGETFormFlowRequest>): any {
  const http = new HttpService(urlsEndpoint.GET_FORM_FLOW);
  try {
    const response = yield call(http.post, { ...payload, requestForm: "mobile" });
    yield put(generalActions.getListFormFlow.success(response));
  } catch (err) {
    yield put(generalActions.getListFormFlow.failure(err));
  }
}
