import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { actions as actionForm } from "./_actions";
import {
  IGETMyTaskRequest,
  IGetReservasiKeyData,
  PayloadReasign,
  types as masterTypes,
  PayloadGetMyTask,
} from "./_types";

export const watcherReservasion = () => [
  takeLatest(masterTypes.GET_MY_TASK_KEYS_REQUEST, workerGetMyTask),
  takeLatest(masterTypes.CREATE_MY_TASK_REQUEST, workerCreateMyTask),
  takeLatest(masterTypes.GET_HISTORY_KEYS_REQUEST, workerHistoryMyTask),
  takeLatest(masterTypes.GET_TIMELINE_STATUS, workerGetMyTimelineStatus),
  takeLatest(masterTypes.REASSIGN_TASK_REQUEST, workerReassignTask),
  takeLatest(masterTypes.REJECTED_HEALTH_REQUEST, workerRejectedHealth),
  takeLatest(masterTypes.REJECTED_SAFETY_REQUEST, workerRejectedSafety),
];

function* workerGetMyTask({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<PayloadGetMyTask>): any {
  //* DEVIATION MARK SWITCHER
  const http = new HttpService(urlsEndpoint.GET_MY_TASK);
  // const http = new HttpService(urlsEndpoint.DUMMY_GET_MY_TASK);
  // const http = new HttpService(urlsEndpoint.ANOTHER_DUMMY_GET_MY_TASK);
  try {
    const response = yield call(http.post, payload);
    // const response = yield call(http.find);

    // if (payload?.filter.assignmentNumber) {
    //   response.data.formFlow = response.data.formFlow.filter(
    //     (item: any) => item.assignmentNumber === payload.filter.assignmentNumber
    //   );
    // }

    yield put(actionForm.getMyTaskList.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getMyTaskList.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerHistoryMyTask({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetReservasiKeyData>): any {
  const http = new HttpService(urlsEndpoint.GET_HISTORY);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.getListHistory.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getListHistory.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerCreateMyTask({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGETMyTaskRequest>): any {
  const isPermit = payload?.flowData?.assigmentNumber?.includes("SHE/PTW/");
  // const url = isPermit ? urlsEndpoint.UPDATE_FLOW_PERMIT : urlsEndpoint.CREATE_MY_TASK;

  const url = (() => {
    if (isPermit) return urlsEndpoint.UPDATE_FLOW_PERMIT;

    if (payload?.flowData?.formFlow?.typeFlow === "Health") {
      return urlsEndpoint.CREATE_MY_TASK;
    }

    return urlsEndpoint.CREATE_MY_TASK_SAFETY;
  })();

  const http = new HttpService(url);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.createMytask.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.createMytask.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetMyTimelineStatus({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.TIMELINE_STATUS);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.getMyTimelineStatus.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getMyTimelineStatus.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerReassignTask({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<PayloadReasign>): any {
  const http = new HttpService(urlsEndpoint.REASSIGN_RESERVATION_TASK);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.reassignTask.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.reassignTask.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerRejectedHealth({ payload, onFailure, onSuccess }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.HEALTH_REJECTED);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.rejectedHealth.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.rejectedHealth.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerRejectedSafety({ payload, onFailure, onSuccess }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.SAFETY_REJECTED);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.rejectedSafety.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.rejectedSafety.failure(err));
    yield callback(onFailure, err);
  }
}
