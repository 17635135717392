import { types } from "./_types";

let result: any;

const initialState = {
  data: result,
  dataUsers: [],
  actionStatus: "initial",
  moreActionStatus: {
    register: "initial",
    forget: "initial",
    update: "initial",
    otp: "initial",
  },
};

export const authReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SIGNIN_REQUEST:
    case types.SIGNIN_FAILURE:
    case types.SIGNIN_SUCCESS:
      return {
        ...state,
        data: action.data,
        actionStatus: action.actionStatus,
      };
    case types.FORGOT_PASS_REQUEST:
    case types.FORGOT_PASS_FAILURE:
    case types.FORGOT_PASS_SUCCESS:
      return {
        ...state,
        moreActionStatus: {
          ...state.moreActionStatus,
          forget: action.actionStatus,
        },
      };
    case types.OTP_USER_REQUEST:
    case types.OTP_USER_FAILURE:
    case types.OTP_USER_SUCCESS:
      return {
        ...state,
        moreActionStatus: {
          ...state.moreActionStatus,
          otp: action.actionStatus,
        },
      };
    case types.GET_USERS_REQUEST:
    case types.GET_USERS_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
      };
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        dataUsers: Array.isArray(action.data.data)
          ? action.data.data.map((item: any) => ({
              label: item.username,
              value: String(item.username),
            }))
          : [],
        actionStatus: action.actionStatus,
      };
    case types.REGISTER_REQUEST:
    case types.REGISTER_FAILURE:
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        moreActionStatus: {
          ...state.moreActionStatus,
          register: action.actionStatus,
        },
      };
    case types.CHECK_TOKEN_REQUEST:
    case types.CHECK_TOKEN_FAILURE:
    case types.CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        data: Object.assign(state.data, action.data),
        actionStatus: action.actionStatus,
      };
    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        data: action.data,
        actionStatus: action.actionStatus,
      };
    case types.RESET_ACTION_STATUS:
      return {
        ...state,
        actionStatus: "initial",
      };
    default:
      return state;
  }
};
