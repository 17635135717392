import { takeLatest, call, put } from "redux-saga/effects";

import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";

import { actions } from "./_actions";
import {
  IGetRoleDetailRequestData,
  IGetRoleRequestData,
  ICreateRoleData,
  IUpdateRoleData,
  types,
} from "./_types";

export const watcherRole = () => [
  takeLatest(types.GET_VIEW_ROLE_REQUEST, workerGetAllRole),
  takeLatest(types.GET_ALL_ARCHIVE_ROLE_REQUEST, workerGetArchiveRole),
  takeLatest(types.GET_ROLE_BY_ID_REQUEST, workerGetRoleDetail),
  takeLatest(types.CREATE_ROLE_REQUEST, workerCreateRole),
  takeLatest(types.UPDATE_ROLE_REQUEST, workerUpdateRole),
];

function* workerGetAllRole({ payload }: IDefaultRequestAction<IGetRoleRequestData>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_ALL_ROLE,
      method: "post",
      data: {
        limit: payload?.limit,
        page: payload?.page,
        keyword: payload?.keyword,
        orderBy: {
          field: payload?.orderBy?.field,
          sort: payload?.orderBy?.sort,
        },
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });

    // const data: any[] = []
    // response.result.map((res: any, index: number)=>{
    //   const map = {
    //     name: res.name,
    //     description: res.description,
    //     isActive: JSON.stringify(res.isActive),
    //     id: res.id
    //   }
    //   data.push(map)
    // })
    // response.result = data

    yield put(actions.getRole.success(response));
  } catch (err) {
    yield put(actions.getRole.failure(err));
  }
}

function* workerGetArchiveRole({ payload }: IDefaultRequestAction<IGetRoleRequestData>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_ALL_ARCHIVE_ROLE,
      method: "post",
      data: {
        limit: payload?.limit,
        page: payload?.page,
        keyword: payload?.keyword,
        orderBy: {
          field: payload?.orderBy?.field,
          sort: payload?.orderBy?.sort,
        },
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });

    yield put(actions.getArchiveRole.success(response));
  } catch (err) {
    yield put(actions.getArchiveRole.failure(err));
  }
}

function* workerGetRoleDetail({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetRoleDetailRequestData>): any {
  const http = new HttpService(urlsEndpoint.GET_ROLE_BY_ID);
  try {
    const response = yield call(http.get, payload?.id || "");
    yield put(actions.getRoleDetail.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getRoleDetail.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerCreateRole({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<ICreateRoleData>): any {
  const http = new HttpService(urlsEndpoint.CREATE_ROLE);
  try {
    const response = yield call(http.post, payload);

    yield put(actions.createRole.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.createRole.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerUpdateRole({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IUpdateRoleData>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_ROLE);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.updateRole.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.updateRole.failure(err));
    yield callback(onFailure, err);
  }
}
