import { types } from "./_types";

const initialState = {
  hiradcList: [],
  archived: [],
  hiradcLog: [],
  hiradcApproved: [],
  pagination: null,
  paginationLog: null,
  paginationHistory: null,
  paginationApproved: null,
  actionStatus: "initial",
  moreActionStatus: {
    addUser: "initial",
  },
  isLoading: true,
  isLoadingLog: true,
};

export const hiradcReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_LIST_HIRADC_KEYS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_HIRADC_KEYS_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_LIST_HIRADC_KEYS_SUCCESS:
      return {
        ...state,
        hiradcList: action.data.data.hiradc,
        pagination: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.LIST_APROVED_HIRADC_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.LIST_APROVED_HIRADC_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.LIST_APROVED_HIRADC_SUCCESS:
      return {
        ...state,
        hiradcApproved: action.data.data.hiradc,
        paginationApproved: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.LOG_HIRADC_REQUEST:
      return {
        ...state,
        isLoadingLog: true,
      };
    case types.LOG_HIRADC_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoadingLog: false,
      };
    case types.LOG_HIRADC_SUCCESS:
      return {
        ...state,
        hiradcLog: action.data.data.hiradc,
        paginationLog: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoadingLog: false,
      };
    case types.GET_ARCHIVED_HIRADC_KEYS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_ARCHIVED_HIRADC_KEYS_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_ARCHIVED_HIRADC_KEYS_SUCCESS:
      return {
        ...state,
        archived: action.data.data.hiradc,
        pagination: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    default:
      return state;
  }
};
