import { notification } from "antd";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FILTER } from "../../../assets/images";
import BaseTemplate from "../../../components/Base";
import ButtonForm from "../../../components/Button";
import FilterModal from "../../../components/Modal/FilterModal";
// import ModalResponse from "../../../components/Modal/GeneralFormPreviewModal/MyTask";
import Pagination from "../../../components/Pagination";
import SearchForm from "../../../components/Search";
import Segmented from "../../../components/Segmented";
import Status from "../../../components/Status";
import Table from "../../../components/Table";
import * as urlsName from "../../../helpers/urlsName";
import { actions } from "../../Safety/_actions";
import { findCurrentTask } from ".";
import { useTranslation } from "react-i18next";
// import { TASK_MAPPING } from ".";
import { useQuery } from "../../../helpers/useQuery";
import { selectors as authSelector } from "../../Auth/_selectors";
// import { RootState } from "../../../store/rootReducers";
import { getDataPermission } from "../../../helpers/getUrlPermission";
// import { decrypt } from "../../../helpers/aes";
import useTranslateShortcut from "../../../helpers/hooks/useTranslateShortcut";

import styles from "./styles.module.less";

interface ViewProps {
  dataOutput: [];
  dataActivity?: [];
  dataHistory: any;
  isLoading?: boolean;
  filterData: any;
  changeFilter: (value: any, type: string) => void;
  searchData: Function;
  fetchFilter: Function;
  pagination: any;
  pageSize: number;
  changePage: (value: number, segment: string, size?: number) => void;
  paginationHistory: any;
  listFlow: any;
  dataLocation?: any;
}

// enum ModalType {
//   NONE = "",
//   PREVIEW_MODAL = "PREVIEW_MODAL",
// }

const View: FC<ViewProps> = ({
  dataOutput,
  isLoading,
  changeFilter,
  paginationHistory,
  dataHistory,
  listFlow,
  fetchFilter,
  filterData,
  searchData,
  pagination,
  changePage,
  pageSize,
  dataLocation,
  dataActivity,
}) => {
  const { t } = useTranslation();
  const { translate: generalTranslate } = useTranslateShortcut({});
  const dataSource = dataOutput;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  // const listPermission = location.state?.permissions;
  const dataAdmin = useSelector(authSelector.getUserData);
  // const myTaskMenus = useSelector(
  //   (rootState: RootState) => rootState.profile.userProfile?.userMenu
  // );
  // const isEnterFromSubMenu = myTaskMenus && Array.isArray(myTaskMenus) && myTaskMenus.length > 1;
  // const currentPageName =
  //   TASK_MAPPING[findCurrentTask(location).toLowerCase() as keyof typeof TASK_MAPPING];

  // const currentPageIdentifier = getCurrentPageId(currentPageName);

  // const listPermission = getDataPermission(
  //   dataAdmin.role[0].features[0].web,
  //   currentPageIdentifier ?? currentPageName,
  //   "submenu"
  // );
  const listPermission = getDataPermission(dataAdmin.role[0].features[0].web, "My Task", "menu");

  const [open, setOpen] = useState(false);
  const [isWaiting, setWaiting] = useState(false);
  const [isSearch, setSearch] = useState("");
  const showModal = () => setOpen(true);

  const _keyword = location?.search?.split("search=")[1] ?? "";

  useEffect(() => {
    setSearch(_keyword);
  }, [_keyword]);

  const handleClose = () => {
    setOpen(false);
  };

  const query = useQuery();
  const typeTask = query.get("type") || "";

  // const [visibleModal, setVisibleModal] = useState(ModalType.NONE);
  // const hideModal = () => setVisibleModal(ModalType.NONE);
  const tab = localStorage.getItem("tab");
  const [segment, setSegment] = useState(tab === null ? "Latest" : tab);

  // function getCurrentPageId(pageName: string) {
  //   switch (pageName) {
  //     case "Medical Center":
  //       return 41;
  //     case "SHE":
  //       return 40;
  //     default:
  //       return undefined;
  //   }
  // }

  const _getDetailPermit = (record: any) => {
    setWaiting(true);
    dispatch(
      actions.getDetailPermit.request({
        payload: `0?registryNumber=${record?.assignmentNumber}`,
        onSuccess: (res: any) => {
          setWaiting(false);
          if (res.message === "Data found") {
            history.push({
              pathname: urlsName.CREATE_PERMIT_TO_WORK,
              search: `id=${res.data.permitToWork.id}&type=${findCurrentTask(
                location
              ).toLowerCase()}`,
              state: {
                extendData: res.data.permitToWork,
                status: "VIEW",
                fieldData: record,
                permissions: listPermission,
              },
            });
          }
        },
        onFailure: (err: any) => {
          setWaiting(false);
          notification.error({
            placement: "topRight",
            message: "Error!",
            description: t("myTasks.alert.errorWentWrong"),
            duration: 3,
          });
        },
      })
    );
  };

  const _detail = (record: any, segment: string) => {
    const isPermit = record?.assignmentNumber.includes("SHE/PTW/");
    const pathname = urlsName.MY_TASK_DETAIL;
    const hiradc = record?.flowName;

    const state = { fieldData: record, segment: segment, permissions: listPermission };
    return {
      onClick: () => {
        hiradc === "Hiradc"
          ? history.push({
              pathname: urlsName.DETAIL_HIRADC,
              state: {
                dataDetail: record?.assignmentNumber,
                type: "Edit",
                dataLocation: dataLocation,
                code: record?.code,
                typePage: "myTask",
                listPermission: "update",
                dataActivity: dataActivity,
              },
            })
          : isPermit
          ? _getDetailPermit(record)
          : history.push({
              pathname,
              state,
              search: `?type=${findCurrentTask(location).toLowerCase()}`,
            });
      },
    };
  };

  // Sorter
  let count = "";
  const _sortList = (direction: string, column: string) => {
    if (count !== direction) {
      count = direction;
      fetchFilter(segment, isSearch, null, null, { column, direction });
    }
  };

  const columns = [
    {
      title:
        typeTask === "safety"
          ? t("myTasks.tableLabel.assignmentNo")
          : t("myTasks.tableLabel.reservationNo"),
      dataIndex: "assignmentNumber",
      key: "assignmentNumber",
      // onCell: (record: any) => {
      //   return _detail(record, segment);
      // },
      sorter: (a: any, b: any, sort: any) => _sortList(sort.split("end")[0], "assignmentNumber"),
      render: (value: any, record: any) => {
        if (!value) {
          return "-";
        }
        return record?.assignmentNumber;
      },
    },
    {
      title:
        typeTask === "safety"
          ? t("myTasks.tableLabel.submitter")
          : t("myTasks.tableLabel.patientName"),
      dataIndex: "patientName",
      key: "patientName",
      // onCell: (record: any) => {
      //   return _detail(record, segment);
      // },
      sorter: (a: any, b: any, sort: any) => _sortList(sort.split("end")[0], "patientName"),
      render: (value: string, record: any) => {
        if (!value) {
          return "-";
        }
        return record?.patientName;
      },
    },
    {
      title: t("myTasks.tableLabel.department"),
      dataIndex: "submitterDepartment",
      key: "submitterDepartment",
      // onCell: (record: any) => {
      //   return _detail(record, segment);
      // },
      sorter: (a: any, b: any, sort: any) => _sortList(sort.split("end")[0], "submitterDepartment"),
      render: (value: string, record: any) => {
        if (!value) {
          return "-";
        }
        return record?.submitterDepartment;
      },
    },
    {
      title: t("myTasks.tableLabel.type"),
      dataIndex: "flowName",
      key: "flowName",
      // onCell: (record: any) => {
      //   return _detail(record, segment);
      // },
      sorter: (a: any, b: any, sort: any) => _sortList(sort.split("end")[0], "description"),
      render: (value: string, record: any) => {
        if (!value) {
          return "-";
        }
        return record?.flowName;
      },
    },
    {
      title: t("myTasks.tableLabel.date"),
      dataIndex: "dateTime",
      key: "dateTime",
      width: "15%",
      // onCell: (record: any) => {
      //   return _detail(record, segment);
      // },
      sorter: (a: any, b: any, sort: any) => _sortList(sort.split("end")[0], "createdDate"),
      render: (value: string, record: any) => {
        if (!value) {
          return "-";
        }
        if (record?.time !== null) {
          return `${record?.date}, ${record?.time}`;
        } else if (record?.date !== null && record?.time === null) {
          return `${record?.date}`;
        } else {
          return `${record?.time}`;
        }
      },
    },
    {
      title: t("myTasks.tableLabel.status"),
      dataIndex: "reservationStatus",
      key: "reservationStatus",
      width: "16%",
      // onCell: (record: any) => {
      //   return _detail(record, segment);
      // },
      sorter: (a: any, b: any, sort: any) => _sortList(sort.split("end")[0], "status"),
      render: (_: any, record: any) => {
        return (
          <Status
            value={segment === "Latest" ? record?.reservationStatus : record?.reservationStatus}
          />
        );
      },
    },
  ];

  if (segment !== "Completed") {
    columns.push({
      title: t("myTasks.tableLabel.action"),
      dataIndex: "id",
      key: "id",
      width: "16%",
      // onCell: (record: any) => {
      //   return _detail(record, segment);
      // },
      sorter: () => null,
      render: (data: any, record: any) => {
        return (
          <>
            {listPermission?.permissions?.includes("update") ? (
              <Link
                to={{
                  pathname:
                    location.pathname + `/detail?type=${findCurrentTask(location).toLowerCase()}`,
                  state: { fieldData: record, permissions: listPermission },
                }}
              >
                <div
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: "#FFF",
                    backgroundColor: "#003984",
                    paddingTop: 8,
                    paddingBottom: 8,
                    width: 110,
                    textAlign: "center",
                    borderRadius: 6,
                  }}
                  onClick={() => {
                    // setVisibleModal(ModalType.PREVIEW_MODAL);
                    // history.push({
                    //   path: location.pathname,
                    //   search: `?type=${findCurrentTask(location).toLowerCase()}`,
                    //   state: { fieldData: record, permissions: listPermission },
                    // });
                    // if (record?.reservationStatus === "FOLLOW_UP_ACTION") {
                    //   history.push({
                    //     path: urlsName.DETAIL_FOLLOW_UP_ACTION,
                    //     state: { fieldData: record, permissions: listPermission },
                    //   });
                    // } else {
                    //   history.push({
                    //     path: location.pathname,
                    //     search: `?type=${findCurrentTask(location).toLowerCase()}`,
                    //     state: { fieldData: record, permissions: listPermission },
                    //   });
                    // }
                    // if (record?.formList.length < 1) {
                    //   Swal.fire({
                    //     icon: "info",
                    //     text: "Please configure approval form!",
                    //     confirmButtonColor: "#003984",
                    //   });
                    // }
                  }}
                >
                  {t("myTasks.label.response")}
                </div>
              </Link>
            ) : (
              <div />
            )}
          </>
        );
      },
    });
  }

  return (
    <>
      <BaseTemplate
        title={t("myTasks.myTasks")}
        isParent={true}
        loading={isWaiting}
        content={
          <>
            <div className={styles.search_container}>
              <Segmented
                data={[t("myTasks.latest"), t("myTasks.completed")]}
                value={segment}
                onSelect={(value: any) => [
                  setSegment(value),
                  changeFilter("", "clear"),
                  localStorage.setItem("tab", value),
                ]}
              />
              <div className={styles.search_section}>
                <SearchForm
                  search={isSearch}
                  onChange={(e: any) => {
                    searchData(e.target.value, segment);
                    setSearch(e.target.value);
                  }}
                />
                <ButtonForm
                  onClick={() => showModal()}
                  title={t("myTasks.filter.title")}
                  type={2}
                  image={FILTER}
                />
              </div>
            </div>
            <Table
              scroll={{ x: 1000 }}
              onReload={() => searchData("", segment)}
              loading={isLoading}
              dataSource={segment === "Latest" ? dataSource : dataHistory}
              columns={columns}
              style={{ cursor: "pointer" }}
              pagination={false}
              onRow={(record: any) => _detail(record, segment)}
              locale={{
                triggerDesc: generalTranslate("sortDescending"),
                triggerAsc: generalTranslate("sortAscending"),
                cancelSort: generalTranslate("cancelSort"),
              }}
            />
            <Pagination
              total={segment === "Latest" ? pagination?.totalItem : paginationHistory?.totalItem}
              pageSize={pageSize}
              current={
                segment === "Latest" ? pagination?.currentPage : paginationHistory?.currentPage
              }
              onChange={(value: number, size?: number) => changePage(value, segment, size)}
            />
          </>
        }
      />
      <FilterModal
        isCentered={false}
        filterData={filterData}
        visible={open}
        onCancel={handleClose}
        onOk={() => [setOpen(false), fetchFilter(segment, isSearch)]}
        listFlow={listFlow}
        itemStatus={[
          { name: "Approved", isButton: false },
          { name: "Rejected", isButton: false },
          { name: "Done", isButton: false },
        ]}
        status={segment !== "Latest" ? true : false}
        chip
        select={segment === "Latest" ? true : false}
        onChange={changeFilter}
        customDateTitle="CUSTOM"
        isFromSafety={findCurrentTask(location).toLowerCase() === "safety"}
      />
      {/* <ModalResponse
        onClose={hideModal}
        closable={false}
        refreshData={() => fetchData()}
        keyboard={false}
        visible={visibleModal === ModalType.PREVIEW_MODAL}
      /> */}
    </>
  );
};

export default View;
