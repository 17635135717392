import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { procedureConfigAction } from "./_actions";
import { types } from "./_types";

function* workerProcedureConfigList({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_PROCEDURE_CONFIG_LIST);
  try {
    const response = yield call(http.post, payload);
    yield put(procedureConfigAction.getProcedureConfigList.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(procedureConfigAction.getProcedureConfigList.failure(err));
    yield callback(onFailure, err);
  }
}

export const watcherProcedureConfig = () => [
  takeLatest(types.GET_PROCEDURE_LIST_REQUEST, workerProcedureConfigList),
];
