import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import {
  IRegisterRequestData,
  ISigninRequestData,
  IForgetPassData,
  IUserOTPData,
  IUpdatePassData,
  types as authTypes,
} from "./_types";

export const actions = {
  signin: {
    request: (requestData: IDefaultRequestParams<ISigninRequestData>) => ({
      ...requestData,
      type: authTypes.SIGNIN_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: authTypes.SIGNIN_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: authTypes.SIGNIN_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  register: {
    request: (requestData: IDefaultRequestParams<IRegisterRequestData>) => ({
      ...requestData,
      type: authTypes.REGISTER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: authTypes.REGISTER_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: authTypes.REGISTER_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  forgotPass: {
    request: (requestData: IDefaultRequestParams<IForgetPassData>) => ({
      ...requestData,
      type: authTypes.FORGOT_PASS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: authTypes.FORGOT_PASS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: authTypes.FORGOT_PASS_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  OTPUser: {
    request: (requestData: IDefaultRequestParams<IUserOTPData>) => ({
      ...requestData,
      type: authTypes.OTP_USER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: authTypes.OTP_USER_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: authTypes.OTP_USER_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  updatePass: {
    request: (requestData: IDefaultRequestParams<IUpdatePassData>) => ({
      ...requestData,
      type: authTypes.UPDATE_PASS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  getUsers: {
    request: (requestData: IDefaultRequestParams<null>) => ({
      ...requestData,
      type: authTypes.GET_USERS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: authTypes.GET_USERS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: authTypes.GET_USERS_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  checkToken: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: authTypes.CHECK_TOKEN_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: authTypes.CHECK_TOKEN_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: authTypes.CHECK_TOKEN_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  refreshToken: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: authTypes.REFRESH_TOKEN_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: authTypes.REFRESH_TOKEN_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: authTypes.REFRESH_TOKEN_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  signout: () => ({
    type: authTypes.SIGNOUT,
  }),
  resetActionStatus: () => ({
    type: authTypes.RESET_ACTION_STATUS,
  }),
};
