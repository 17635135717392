export const types = {
  GET_PROCEDURE_LIST_REQUEST: "workflowCategorization/GET_PROCEDURE_LIST_REQUEST",
  GET_PROCEDURE_LIST_SUCCESS: "workflowCategorization/GET_PROCEDURE_LIST_SUCCESS",
  GET_PROCEDURE_LIST_FAILED: "workflowCategorization/GET_PROCEDURE_LIST_FAILED",
};

export interface IPayloadUpdateCategory {
  id?: string | number | null;
  documentNumber: string;
  formFlow: number | null | undefined;
  revision: string;
  isActive: boolean;
  releaseDate: any;
}
