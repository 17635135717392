import { useTranslation } from "react-i18next";

interface useTranslateShortcutProps {
  base?: string;
}

const useTranslateShortcut = ({ base }: useTranslateShortcutProps) => {
  const { t } = useTranslation();

  const translate = (keyword: string, args?: Record<string, any>) => {
    let current = keyword;
    if (base) {
      if (base.at(-1) !== ".") {
        current = `${base}.${keyword}`;
      } else {
        current = `${base}${keyword}`;
      }
    }

    if (args) {
      return t(current, args);
    }
    return t(current);
  };

  return {
    translate,
  };
};

export default useTranslateShortcut;
