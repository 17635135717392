import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { IGetFormDetailRequestData, IGetFormsRequestData, types as reportTypes } from "./_types";

export const actions = {
  getForms: {
    request: (requestData: IDefaultRequestParams<IGetFormsRequestData>) => ({
      ...requestData,
      type: reportTypes.GET_FORMS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: reportTypes.GET_FORMS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: reportTypes.GET_FORMS_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getFormDetail: {
    request: (requestData: IDefaultRequestParams<IGetFormDetailRequestData>) => ({
      ...requestData,
      type: reportTypes.GET_FORM_DETAIL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: reportTypes.GET_FORM_DETAIL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: reportTypes.GET_FORM_DETAIL_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};
