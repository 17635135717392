import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { actions as userActions } from "./_actions";
import { types as masterTypes } from "./_types";
import { IDefaultRequestAction } from "../../store/defaultTypes";

export const watcherHierarchy = () => [
  takeLatest(masterTypes.GET_LIST_HIERARCHY_REQUEST, workerGetListHierarchy),
  takeLatest(masterTypes.ADD_HIERARCHY_REQUEST, workerAddHierarchy),
  takeLatest(masterTypes.DELETE_HIERARCHY_REQUEST, workerDeleteHierarchy),
  takeLatest(masterTypes.UPDATE_HIERARCHY_REQUEST, workerUpdateHierarchy),
  takeLatest(masterTypes.SUB_HIERARCHY_REQUEST, workerGetSubListHierarchy),
  takeLatest(masterTypes.ADD_SUB_HIERARCHY_REQUEST, workerAddSubHierarchy),
  takeLatest(masterTypes.UPDATE_SUB_HIERARCHY_REQUEST, workerUpdateSubHierarchy),
  takeLatest(masterTypes.DELETE_SUB_HIERARCHY_REQUEST, workerDeleteSubHierarchy),
];

function* workerGetListHierarchy({ payload }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.GET_ALL_LIST);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getList.success(response));
  } catch (err) {
    yield put(userActions.getList.failure(err));
  }
}

function* workerAddHierarchy({ payload, onSuccess, onFailure }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.ADD_HIERARCHY);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerDeleteHierarchy({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.DELETE_HIERARCHY);
  try {
    const response = yield call(http.delete, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerUpdateHierarchy({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_HIERARCHY);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetSubListHierarchy({ payload }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.GET_SUB_ALL_LIST);
  try {
    const response = yield call(http.get, payload?.header);
    yield put(userActions.getSubList.success(response));
  } catch (err) {
    yield put(userActions.getSubList.failure(err));
  }
}

function* workerAddSubHierarchy({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.ADD_SUB_HIERARCHY);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerDeleteSubHierarchy({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.DELETE_SUB_HIERARCHY);
  try {
    const response = yield call(http.delete, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerUpdateSubHierarchy({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_SUB_HIERARCHY);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}
