import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { IGETMyTaskRequest, PayloadReasign, types as healthTypes } from "./_types";
import { makeMyTaskPayload } from "../../helpers/hooks/useGetMyTask";

export const actions = {
  getMyTaskList: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_MY_TASK_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_MY_TASK_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_MY_TASK_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getMyTimelineStatus: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_TIMELINE_STATUS,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_TIMELINE_STATUS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_TIMELINE_STATUS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListHistory: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_HISTORY_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_HISTORY_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_HISTORY_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createMytask: {
    request: (requestData: IDefaultRequestParams<IGETMyTaskRequest>) => ({
      ...requestData,
      type: healthTypes.CREATE_MY_TASK_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.CREATE_MY_TASK_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.CREATE_MY_TASK_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  reassignTask: {
    request: (requestData: IDefaultRequestParams<PayloadReasign>) => ({
      ...requestData,
      type: healthTypes.REASSIGN_TASK_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.REASSIGN_TASK_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.REASSIGN_TASK_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  rejectedHealth: {
    request: (requestData: IDefaultRequestParams<PayloadReasign>) => ({
      ...requestData,
      type: healthTypes.REJECTED_HEALTH_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.REJECTED_HEALTH_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.REJECTED_HEALTH_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  rejectedSafety: {
    request: (requestData: IDefaultRequestParams<PayloadReasign>) => ({
      ...requestData,
      type: healthTypes.REJECTED_SAFETY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.REJECTED_SAFETY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.REJECTED_SAFETY_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};

export async function updateMyTask(
  payload: IGETMyTaskRequest
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await http.custom({
      url: urlsEndpoint.CREATE_MY_TASK,
      method: "post",
      data: {
        patientId: payload?.patientId,
        time: "22:00",
        flowData: {
          leadAuditor: payload?.flowData.leadAuditor,
          assigmentNumber: payload?.flowData?.assigmentNumber,
          submitionDate: payload?.flowData?.submitionDate,
          location: payload?.flowData?.location,
          generalInfo: payload?.flowData?.generalInfo,
          action: payload?.flowData?.action,
          formFlow: {
            flowName: payload?.flowData.formFlow?.flowName,
            description: payload?.flowData.formFlow?.description,
            formVersionId: payload?.flowData.formFlow?.formVersionId,
            accessByUser: null,
            accessByRole: null,
            id: payload?.flowData.formFlow?.formFlowId,
            formFlowId: payload?.flowData.formFlow?.formFlowId,
            isDraft: payload?.flowData.formFlow?.isDraft,
            formStatus: payload?.flowData.formFlow?.formStatus,
            formList: [payload?.flowData.formFlow?.formList ?? []],
          },
          // forms: payload?.datas ?? [],
        },
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function createMyTaskSafety(
  payload: IGETMyTaskRequest,
  isForPermit?: boolean
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await http.custom({
      url:
        isForPermit !== undefined && isForPermit
          ? urlsEndpoint.UPDATE_FLOW_PERMIT
          : urlsEndpoint.CREATE_MY_TASK_SAFETY,
      method: "post",
      data: payload,
    });
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getFormMyTask(
  flowId: number,
  assignmentNumber: any
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_FORMFLOW);
  try {
    const response = await http.post({ flowId, assignmentNumber });
    // const response = await http.custom({
    //   url: `${urlsEndpoint.GET_FORMFLOW}/${id}/${assignmentNumber}`,
    //   method: "post",
    // });

    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getFormDetail(
  formId: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_FORM_DETAIL);
  try {
    const response = await http.post({ formId });
    const successResponse = {
      success: true,
      data: response.data?.form,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function manualGetMyTask(assignmentNumber: string) {
  //* DEVIATION MARK SWITCHER
  const http = new HttpService(urlsEndpoint.GET_MY_TASK);
  // const http = new HttpService(urlsEndpoint.DUMMY_GET_MY_TASK);
  // const http = new HttpService(urlsEndpoint.ANOTHER_DUMMY_GET_MY_TASK);
  try {
    const response = await http.post(makeMyTaskPayload(assignmentNumber));
    // const response = await http.find();
    // response.data.formFlow = response.data.formFlow.filter(
    //   (item: any) => item.assignmentNumber === assignmentNumber
    // );
    const successResponse = {
      error: false,
      data: response.data,
    };

    return successResponse;
  } catch (err) {
    console.error(err);
    const errorResponse = {
      error: true,
      data: undefined,
    };
    return errorResponse;
  }
}

export async function getPrintNCRAction(
  assignmentNumber: any
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await http.file({
      url: urlsEndpoint.PRINT_NCR,
      method: "post",
      data: assignmentNumber,
      responseType: "blob",
      headers: {
        Accept: "application/pdf",
      },
    });
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
