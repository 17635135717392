/* eslint-disable react-hooks/rules-of-hooks */
import { Form, FormItemProps } from "antd";
import { useFormikContext } from "formik";
import _ from "lodash";
import { FC } from "react";

interface OtherProps {
  name?: string;
  withFormik?: boolean;
}

const FormItem: FC<OtherProps & FormItemProps> = ({
  name = "",
  withFormik = true,
  ...otherProps
}) => {
  if (!withFormik) {
    return <Form.Item {...otherProps} />;
  }
  const { errors, touched } = useFormikContext<any>();
  const error = _.get(errors, name);
  const touch = _.get(touched, name);
  return (
    <Form.Item
      validateStatus={error && touch ? "error" : ""}
      help={error && touch && error}
      {...otherProps}
    />
  );
};

export default FormItem;
