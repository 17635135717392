import { MenuOutlined, PoweroffOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Dropdown, Layout, Menu, notification, Row, Space, Typography } from "antd";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { NEW_LOGO, PROFILE } from "../../assets/images";
import * as urlsName from "../../helpers/urlsName";
import { actions as authActions } from "../../pages/Auth/_actions";
import Profile from "../../pages/Profile";
import Notification from "./Notification";
import styles from "./styles.module.less";
import { selectors as profileSelectors } from "../../pages/Profile/_selectors";
import { selectors } from "../../pages/Auth/_selectors";
import { profileActions } from "../../pages/Profile/_actions";
import ChangePassword from "../../pages/ChangePassword";
import { FIRST_LOGIN } from "../../store/defaultTypes";
import { decrypt } from "../../helpers/aes";

const { Header } = Layout;
const { Text } = Typography;

interface HeaderPropsComponent {
  onCollapse: () => void;
  // photoProfile?: string;
}

const HeaderComponent: FC<HeaderPropsComponent> = ({ onCollapse }) => {
  type Profile = {
    photoProfile: string;
    fullName: string;
    position: string;
  };
  const dataProfile: Profile = useSelector(profileSelectors.getUserProfile);
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalUpdateProfile, setModalUpadteProfile] = useState(false);
  const [isChange, setChange] = useState(false);
  const dataSource = useSelector(selectors.getUserData);
  const firstLogin = decrypt(localStorage[FIRST_LOGIN]) === "true";

  useEffect(() => {
    if (firstLogin) setTimeout(() => setChange(true), 1000);
  }, [firstLogin]);

  // Profile
  const modal = useMemo(
    () => (
      <Profile
        isModalOpen={modalUpdateProfile}
        modalClose={() => setModalUpadteProfile(false)}
        modalOpen={() => setModalUpadteProfile(true)}
        modalNik={() => {}}
      />
    ),
    [modalUpdateProfile]
  );

  // Change Password
  const changePassword = useMemo(
    () => <ChangePassword isModalOpen={isChange} onClick={() => setChange(false)} />,
    [isChange]
  );

  const getUserDetailAction = useCallback(async () => {
    dispatch(
      profileActions.getProfile.request({
        payload: { userName: dataSource?.details?.nik, token: dataSource?.access_token },
        onSuccess: (res) => {},
        onFailure: (err: any) => {
          notification.error({
            placement: "topRight",
            message: "Error",
            description: "Failed Get Data Profile",
          });
        },
      })
    );
  }, [dataSource?.access_token, dataSource?.details?.nik, dispatch]);

  useEffect(() => {
    getUserDetailAction();
  }, [getUserDetailAction]);

  return (
    <>
      <Header
        style={{
          padding: 0,
          background: "#fff",
          color: "black",
          position: "fixed",
          width: "100vw",
          zIndex: 99,
          // top: 0,
          // marginBottom:100
        }}
        className="site-layout-background"
      >
        <Row align="middle" justify="space-between">
          <Row align="middle">
            <MenuOutlined
              onClick={onCollapse}
              style={{ fontSize: 16, marginLeft: 21, color: "#4b5563" }}
            />
            <img alt="Sentinel" className={styles.logo} src={NEW_LOGO} />
          </Row>
          <Col style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
            <Notification />
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="18" onClick={() => setModalUpadteProfile(true)}>
                    <UserOutlined /> Profile
                  </Menu.Item>
                  <Menu.Item key="18" onClick={() => setChange(true)}>
                    <UserOutlined /> Change Password
                  </Menu.Item>
                  <Menu.Item
                    key="16"
                    onClick={() => {
                      dispatch(authActions.signout());
                      localStorage.clear();
                      history.push(urlsName.ROOT);
                    }}
                  >
                    <PoweroffOutlined /> Logout
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <div className="ant-dropdown-link" onClick={(e: any) => e.preventDefault()}>
                <Space
                  align="center"
                  style={{ display: "flex", flexDirection: "row", marginRight: 32 }}
                >
                  <Avatar
                    size={32}
                    icon={<img src={PROFILE} alt="" />}
                    src={dataProfile?.photoProfile}
                  />
                  <div className={styles.profile_summary}>
                    <Text style={{ textTransform: "capitalize", fontSize: 12, fontWeight: 600 }}>
                      {dataProfile?.fullName}
                    </Text>
                    <Text style={{ textTransform: "capitalize", fontSize: 12, fontWeight: 400 }}>
                      {dataProfile?.position}
                    </Text>
                  </div>
                </Space>
              </div>
            </Dropdown>
          </Col>
        </Row>
      </Header>
      {modalUpdateProfile && modal}
      {isChange && changePassword}
    </>
  );
};

export default HeaderComponent;
