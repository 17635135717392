import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { actions as userActions } from "./_action";
import { types as masterTypes } from "./_types";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { callback } from "../../store/defaultSagas";

export const watcherBulkUser = () => [
  takeLatest(masterTypes.GET_BULK_UPLOAD_MEDICINE_LIST_REQUEST, workerGetListBulkUploadMedicine),
  takeLatest(masterTypes.GET_BULK_UPLOAD_USER_LIST_REQUEST, workerGetListBulkUploadUser),
  takeLatest(masterTypes.BULK_UPLOAD_MEDICINE_REQUEST, workerUploadMedicineFile),
  takeLatest(masterTypes.BULK_UPLOAD_USER_REQUEST, workerUploadUserFile),
  takeLatest(
    masterTypes.GET_BULK_UPLOAD_MEDICINE_FAILED_LIST_REQUEST,
    workerGetFailedListBulkUploadMedicine
  ),
  takeLatest(
    masterTypes.GET_BULK_UPLOAD_USER_FAILED_LIST_REQUEST,
    workerGetFailedListBulkUploadUser
  ),
];

function* workerGetListBulkUploadMedicine({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.GET_BULK_UPLOAD_MEDICINE_LIST);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getListMedicine.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getListMedicine.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetListBulkUploadUser({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.GET_BULK_UPLOAD_USER_LIST);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getListUser.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getListUser.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerUploadUserFile({ payload, onSuccess, onFailure }: IDefaultRequestAction<any>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.uploadXlsx, {
      url: urlsEndpoint.BULK_UPLOAD_USER,
      method: "post",
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerUploadMedicineFile({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.uploadXlsx, {
      url: urlsEndpoint.BULK_UPLOAD_MEDICINE,
      method: "post",
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetFailedListBulkUploadMedicine({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(
    `${urlsEndpoint.BULK_UPLOAD_MEDICINE_FAILED_LIST}/${payload?.bulkUploadId}`
  );
  try {
    const response = yield call(http.custom, {
      url: `${urlsEndpoint.BULK_UPLOAD_MEDICINE_FAILED_LIST}/${payload?.bulkUploadId}`,
      method: "get",
    });
    yield put(userActions.getListFailedMedicine.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getListFailedMedicine.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerGetFailedListBulkUploadUser({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.BULK_UPLOAD_USER_FAILED_LIST);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getListFailedUser.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getListFailedUser.failure(err));
    yield callback(onFailure, err);
  }
}
