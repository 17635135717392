import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";

export async function getReservationService(typePgae?: string): Promise<{
  success?: boolean;
  error?: boolean;
  data: any;
  status?: number | any;
}> {
  const http = new HttpService(urlsEndpoint.LIST_HOME_ADDHOC);
  try {
    const response = await http.post({
      start: 0,
      limit: 1000,
      page: 1,
      filter: {
        uncategorizedFirst: false,
        assignmentNumber: "",
        assigmentDate: "",
        flowName: "",
        typeFlow: typePgae === "Medical Center" ? "health" : "safety",
        locationTypeName: "",
        equipmentTypeName: "",
      },
    });

    const formFlow = response?.data?.formFlow?.map(function (item: any) {
      const newItem = {
        ...item,
        isCategory: false,
      };
      return newItem;
    });

    let data = [...formFlow];
    if (response?.data?.formFlowCategory) {
      const formFlowCategory = response?.data?.formFlowCategory.map((item: any) => {
        const data = {
          id: item.id,
          flowName: item?.name,
          description: item?.description,
          userName: null,
          isSubordinateExist: null,
          detailId: null,
          formVersionId: null,
          formFlowId: null,
          formList: null,
          category: { id: item.id },
          icon: item?.image ?? "",
          typeFlow: "Health",
          isReassign: false,
          isCategory: true,
        };
        return data;
      });

      data.push(...formFlowCategory);
    }

    const successResponse = {
      success: true,
      data: {
        formFlow: data,
      },
      status: response?.status,
    };

    return successResponse;
  } catch (err) {
    const errorResponse = {
      success: false,
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getReservationByCategoryId(payload: {
  categoryId: number;
  flowName: string;
}): Promise<{
  success?: boolean;
  error?: boolean;
  data: any;
  status?: number | any;
}> {
  const http = new HttpService(urlsEndpoint.LIST_MENU);
  try {
    const response = await http.post({
      start: 0,
      limit: 10,
      page: 1,
      filter: {
        flowName: payload?.flowName ?? "",
        categoryId: payload?.categoryId,
        typeFlow: localStorage.getItem("CURRENT_TYPEFLOW") ?? "health",
        assignmentNumber: "",
        assigmentDate: "",
        locationTypeName: "",
        equipmentTypeName: "",
      },
    });
    const successResponse = {
      success: true,
      data: response?.data,
      status: response?.status,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      success: false,
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
