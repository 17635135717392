import { call, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { IGetList, types } from "./_types";

export const watcherUserInquiry = () => [
  takeLatest(types.GET_MEDICAL_RECORD_LIST_REQUEST, workerMedicalRecord),
  takeLatest(types.GET_MEDICAL_CHECK_UP_REQUEST, workerMedicalCheckUp),
  takeLatest(types.DELETE_MEDICAL_CHECK_UP_REQUEST, workerDeleteCheckUp),
  takeLatest(types.CREATE_MEDICAL_CHECK_UP_REQUEST, workerCreateCheckUp),
  takeLatest(types.EXPORT_MEDICAL_RECORD_REQUEST, workerExportMedicalRecord),
];

function* workerMedicalRecord({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetList>): any {
  const http = new HttpService(urlsEndpoint.LIST_MEDICAL);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerMedicalCheckUp({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetList>): any {
  const http = new HttpService(urlsEndpoint.LIST_MEDICAL_CHECK_UP);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerDeleteCheckUp({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetList>): any {
  const http = new HttpService(urlsEndpoint.DELETE_MEDICAL_CHECK_UP);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerCreateCheckUp({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetList>): any {
  const http = new HttpService(urlsEndpoint.CREATE_MEDICAL_CHECK_UP);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerExportMedicalRecord({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.file, {
      url: urlsEndpoint.EXPORT_MEDICAL_RECORD,
      method: "post",
      data: payload,
      responseType: "blob",
      headers: {
        Accept: "application/vnd.ms-excel",
      },
    });
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}
