// import { debug } from "../../helpers/logger";
import { types as healthTypes } from "./_types";

const initialState = {
  previewMedical: {},
  medical: [],
  listMedicine: [],
  dataDetail: {},
  myHistory: [],
  listReservationType: [],
  data: {},
  medicine: {},
  reservation: {},
  medicalDetail: {},
  auth: {},
  users: {},
  userDetail: {},
  detailTask: [],
  pdf: null,
  pagination: null,
  actionStatus: "initial",
  moreActionStatus: {
    addUser: "initial",
  },
  archiveData: null,
  isLoading: true,
};

export const medicalReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case healthTypes.GET_MEDICAL_RECORD_KEYS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case healthTypes.GET_MEDICAL_RECORD_KEYS_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: true,
      };
    case healthTypes.GET_MEDICAL_RECORD_KEYS_SUCCESS:
      return {
        ...state,
        dataDetail: {
          ...state.dataDetail,
          [action.data?.data?.assignmentNumber]: action.data?.data,
        },
        previewMedical: action.data.data,
        // moreActionStatus: {
        //   ...state.moreActionStatus,
        //   getForm: action.actionStatus,
        // },
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case healthTypes.LIST_MEDICAL_KEYS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case healthTypes.LIST_MEDICAL_KEYS_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: true,
      };
    case healthTypes.LIST_MEDICAL_KEYS_SUCCESS:
      return {
        ...state,
        medical: action.data.data.reservation,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case healthTypes.MEDICAL_DETAIL_KEYS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case healthTypes.MEDICAL_DETAIL_KEYS_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: true,
      };
    case healthTypes.MEDICAL_DETAIL_KEYS_SUCCESS:
      return {
        ...state,
        medicalDetail: action.data.data.formFlow,
        reservation: action.data.data.reservation,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case healthTypes.GET_USERS_KEYS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case healthTypes.GET_USERS_KEYS_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case healthTypes.GET_USERS_KEYS_SUCCESS:
      return {
        ...state,
        users: action?.data?.user,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case healthTypes.GET_DETAIL_USER_KEYS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case healthTypes.GET_DETAIL_USER_KEYS_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case healthTypes.GET_DETAIL_USER_KEYS_SUCCESS:
      return {
        ...state,
        userDetail: action?.data?.user,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case healthTypes.LIST_MEDICINE_KEYS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case healthTypes.LIST_MEDICINE_KEYS_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case healthTypes.LIST_MEDICINE_KEYS_SUCCESS:
      return {
        ...state,
        medicine: action?.data?.data?.medication,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case healthTypes.MEDICAL_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case healthTypes.MEDICAL_LIST_FAILED:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case healthTypes.MEDICAL_LIST_SUCCESS:
      return {
        ...state,
        // listMedicine: action?.data?.data?.medicine,
        listMedicine:
          action.data.data?.medicine?.map((item: any) => ({
            label: item.medicineName,
            value: item.medicineName,
            qty: item.qty,
          })) || [],
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    default:
      return state;
  }
};
