import { IFormFlow } from "./FormFlow/types";
import { IFormikValues } from "./GeneralForm/types";

export const types = {
  GET_FORMS_REQUEST: "general/GET_FORMS_REQUEST",
  GET_FORMS_ARCHIVE: "general/GET_FORMS_ARCHIVE",
  GET_FORMS_SUCCESS: "general/GET_FORMS_SUCCESS",
  GET_FORMS_FAILURE: "general/GET_FORMS_FAILURE",

  GET_FORMS_MASTER_REQUEST: "general/GET_FORMS_MASTER_REQUEST",
  GET_FORMS_MASTER_SUCCESS: "general/GET_FORMS_MASTER_SUCCESS",
  GET_FORMS_MASTER_FAILURE: "general/GET_FORMS_MASTER_FAILURE",

  GET_FORMS_PUBLISH_REQUEST: "general/GET_FORMS_PUBLISH_REQUEST",
  GET_FORMS_PUBLISH_SUCCESS: "general/GET_FORMS_PUBLISH_SUCCESS",
  GET_FORMS_PUBLISH_FAILURE: "general/GET_FORMS_PUBLISH_FAILURE",

  GET_FORMS_SEARCH_REQUEST: "general/GET_FORMS_SEARCH_REQUEST",
  GET_FORMS_SEARCH_SUCCESS: "general/GET_FORMS_SEARCH_SUCCESS",
  GET_FORMS_SEARCH_FAILURE: "general/GET_FORMS_SEARCH_FAILURE",

  GET_FLOW_SEARCH_REQUEST: "general/GET_FLOW_SEARCH_REQUEST",
  GET_FLOW_SEARCH_SUCCESS: "general/GET_FLOW_SEARCH_SUCCESS",
  GET_FLOW_SEARCH_FAILURE: "general/GET_FLOW_SEARCH_FAILURE",

  GET_FORMS_DRAFT_REQUEST: "general/GET_FORMS_DRAFT_REQUEST",
  GET_FORMS_DRAFT_SUCCESS: "general/GET_FORMS_DRAFT_SUCCESS",
  GET_FORMS_DRAFT_FAILURE: "general/GET_FORMS_DRAFT_FAILURE",

  GET_MASTER_DATAS_REQUEST: "general/GET_MASTER_DATAS_REQUEST",
  GET_MASTER_DATAS_SUCCESS: "general/GET_MASTER_DATAS_SUCCESS",
  GET_MASTER_DATAS_FAILURE: "general/GET_MASTER_DATAS_FAILURE",

  GET_MASTER_SAFETY_REQUEST: "general/GET_MASTER_SAFETY_REQUEST",
  GET_MASTER_SAFETY_SUCCESS: "general/GET_MASTER_SAFETY_SUCCESS",
  GET_MASTER_SAFETY_FAILURE: "general/GET_MASTER_SAFETY_FAILURE",

  GET_LIST_EQUIP_REQUEST: "general/GET_LIST_EQUIP_REQUEST",
  GET_LIST_EQUIP_SUCCESS: "general/GET_LIST_EQUIP_SUCCESS",
  GET_LIST_EQUIP_FAILURE: "general/GET_LIST_EQUIP_FAILURE",

  GET_FORM_DETAIL_REQUEST: "general/GET_FORM_DETAIL_REQUEST",
  GET_FORM_DETAIL_SUCCESS: "general/GET_FORM_DETAIL_SUCCESS",
  GET_FORM_DETAIL_FAILURE: "general/GET_FORM_DETAIL_FAILURE",

  CREATE_FORM_REQUEST: "general/CREATE_FORM_REQUEST",
  CREATE_FORM_SUCCESS: "general/CREATE_FORM_SUCCESS",
  CREATE_FORM_FAILURE: "general/CREATE_FORM_FAILURE",

  UPDATE_FORM_REQUEST: "general/UPDATE_FORM_REQUEST",
  UPDATE_FORM_SUCCESS: "general/UPDATE_FORM_SUCCESS",
  UPDATE_FORM_FAILURE: "general/UPDATE_FORM_FAILURE",

  GET_LIST_FORM_FLOW_REQUEST: "general/GET_LIST_FORM_FLOW_REQUEST",
  GET_LIST_FORM_FLOW_SUCCESS: "general/GET_LIST_FORM_FLOW_SUCCESS",
  GET_LIST_FORM_FLOW_FAILURE: "general/GET_LIST_FORM_FLOW_FAILURE",

  GET_COMPANY_REQUEST: "general/GET_COMPANY_REQUEST",
  GET_COMPANY_SUCCESS: "general/GET_COMPANY_SUCCESS",
  GET_COMPANY_FAILURE: "general/GET_COMPANY_FAILURE",

  GET_ALL_FORMS_MASTER_REQUEST: "general/GET_ALL_FORMS_MASTER_REQUEST",
  GET_ALL_FORMS_MASTER_SUCCESS: "general/GET_ALL_FORMS_MASTER_SUCCESS",
  GET_ALL_FORMS_MASTER_FAILURE: "general/GET_ALL_FORMS_MASTER_FAILURE",
};

export interface IGetFormData {
  token: string;
  // keyword: string ;
  // status: string ;
  start: number;
  limit: number;
  orderBy: {
    column: string[];
    direction: string;
  };
}

export interface IGetFormDataMasters {
  token: string;
  start: number;
  typeId: number;
  limit: number;
}
export interface IGetRoleRequestData {
  token: string;
  limit: number;
}

export interface IGetFormDataSearch {
  page: number;
  keyword: string;
  start: number;
  limit: number;
  orderBy: {
    column: string[];
    direction: string;
  };
}
export interface IGetFlowDataSearch {
  token: string;
  keyword: string;
  requestForm: string;
  start: number;
  limit: number;
  orderBy: {
    column: string[];
    direction: string;
  };
}

export interface IGetFormDataArchive {
  token: string;
  start: number;
  limit: number;
  status: string;
  keyword: string;
  orderBy: {
    column: string[];
    direction: string;
  };
}
export interface IGetFormDataMasters {
  token: string;
  start: number;
  typeId: number;
  limit: number;
}
export interface IGetFormDataCompany {
  start: number;
  limit: number;
  page: number;
  keyword: string;
}

export interface IGetFormsRequestData {
  start: number;
  limit: number;
  status?: string;
  order: object;
}

export interface IGetFormDetailRequestData {
  formId: number;
  formVersionId: number;
}

export interface ICreateFormRequestData extends IFormikValues {
  formField: {
    tabDescription: string;
    tabNumber: number;
    field: {
      id: string;
      type: string;
      title?: string;
      required: string;
      options?: string;
    }[];
  }[];
}

export interface IUpdateFormRequestData extends IFormikValues {
  formId: number;
  // formVersionId: number;
  formField: {
    tabDescription: string;
    tabNumber: number;
    field: {
      id: string;
      type: string;
      title?: string;
      required: string;
      options?: string;
    }[];
  }[];
}

export interface IGETFormFlowRequest {
  start?: number;
  limit?: number;
  status?: string;
  token?: string | null;
  page?: number;
  icon?: string;
  typeFlow?: string;
  keyword?: string;
  flowName?: string;
  description?: string;
  equipmentId?: number | null;
  locationId?: number | null;
  forms?: IFormFlow[];
  id?: number;
  datas?: any[];
  detailId?: number;
  isRepetitiveFlow?: string;
  flowFrequency?: number | null;
  flowRepetition?: string | null;
  flowDate?: string | Date;
  isActive?: string;
  orderBy: {
    column: string[];
    direction: string | null;
  };
  code?: string;
  requestForm?: string;
  categoryId?: number | null;

  filter?: object;
}
