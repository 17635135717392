import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { profileActions } from "./_actions";
import { IPayloadGetProfile, types } from "./_types";

function* workerGetProfile({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IPayloadGetProfile>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: `${urlsEndpoint.GET_DETAIL_AUTH_USERS}/${payload?.userName}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    yield put(profileActions.getProfile.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(profileActions.getProfile.failure(err));
    yield callback(onFailure, err);
  }
}

export const watcherProfile = () => [takeLatest(types.GET_PROFILE_REQUEST, workerGetProfile)];
