import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { IPayloadUpdateCategory, types as workflowCategorization } from "./_types";

export async function createCategoryWorkflowAction(
  payload: IPayloadUpdateCategory
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.CREATE_WORKFLOW_CATEGORIZATION);
  try {
    const response = await http.post(payload);
    const successResponse = {
      error: true,
      data: undefined,
    };
    if (response.result) {
      const successResponse = {
        success: true,
        data: response?.message,
      };
      return successResponse;
    }
    if (!response.result && response.message === "The name already exists") {
      const successResponse = {
        error: true,
        success: false,
        data: { message: response?.message },
      };
      return successResponse;
    }
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateCategoryInventoryAction(
  payload: IPayloadUpdateCategory
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.UPDATE_CATEGORY_INVENTORY);
  try {
    const response = await http.post(payload);
    const successResponse = {
      error: true,
      data: undefined,
    };
    if (response.result) {
      const successResponse = {
        success: true,
        data: response?.message,
      };
      return successResponse;
    }
    if (!response.result && response.message === "The name already exists") {
      const successResponse = {
        error: true,
        success: false,
        data: { message: response?.message },
      };
      return successResponse;
    }
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function deleteCategoryInventoryAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_CATEGORY_INVENTORY);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.message,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function uploadPhotoProfile(
  payload: any
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await http.upload({
      url: urlsEndpoint.UPLOAD_FILE,
      method: "post",
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const successResponse = {
      success: true,
      data: response?.data?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateWorkflowCategoryAction(
  payload: IPayloadUpdateCategory
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.UPDATE_WORKFLOW_CATEGORIZATION);
  try {
    const response = await http.post(payload);
    const successResponse = {
      error: true,
      data: undefined,
    };
    if (response.result) {
      const successResponse = {
        success: true,
        data: response?.message,
      };
      return successResponse;
    }
    if (!response.result && response.message === "The code already exists") {
      const successResponse = {
        error: true,
        success: false,
        data: { message: response?.message },
      };
      return successResponse;
    }
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function deleteWorkflowCategoryAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_WORKFLOW_CATEGORIZATION);
  try {
    const response = await http.delete(id);
    const successResponse = {
      error: true,
      data: undefined,
    };

    if (
      response.result ||
      (response?.message === "Successfully deleted." && response?.status === 200)
    ) {
      const successResponse = {
        success: true,
        data: response?.message,
      };
      return successResponse;
    }
    if (!response.result && response.message === "Category is already used") {
      const successResponse = {
        error: true,
        success: false,
        data: { message: response?.message },
      };
      return successResponse;
    }
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export const workflowCategorizationAction = {
  getCategoryList: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: workflowCategorization.GET_CATEGORY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: workflowCategorization.GET_CATEGORY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: workflowCategorization.GET_CATEGORY_SUCCESS,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};
