import moment from "moment";
import { call, put, takeLatest } from "redux-saga/effects";
import { debug } from "../../helpers/logger";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { SioType } from "./Add/types";

import { actions } from "./_actions";
import { IGetDetailUserRequestData, IGetListArchiveRequestData, types } from "./_types";

export const watcherUser = () => [
  takeLatest(types.GET_USERS_REQUEST, workerGetUsers),
  takeLatest(types.ADD_USER_REQUEST, workerAddUser),
  takeLatest(types.GET_DETAIL_USER_REQUEST, workerGetDetailUsers),
  takeLatest(types.GET_DETAIL_USER_COMPANY_REQUEST, workerGetDetailUserCompany),
  takeLatest(types.GET_ROLE_REQUEST, workerGetRole),
  takeLatest(types.EDIT_USER_REQUEST, workerEditUser),
  takeLatest(types.DELETE_USER_REQUEST, workerDeleteUser),
  takeLatest(types.GET_LIST_ARCHIVE_USER_REQUEST, workerGetArchiveUser),
  takeLatest(types.GET_LIST_DEPARTMENT_REQUEST, workerGetDepartment),
  takeLatest(types.GET_LIST_GROUP_REQUEST, workerGetGroup),
  takeLatest(types.GET_LIST_POSITION_REQUEST, workerGetPosition),
  takeLatest(types.GET_LIST_SECTION_REQUEST, workerGetSection),
  takeLatest(types.GET_LIST_MODEL_REQUEST, workerGetModel),
  takeLatest(types.GET_LIST_DIRECT_SUP_REQUEST, workerGetDirectSup),
  takeLatest(types.CREATE_COMPANY_REQUEST, workerCreateCompany),
  takeLatest(types.GET_LIST_COMPANY_REQUEST, workerGetListCompany),
  takeLatest(types.GET_SIO_REQUEST, workerGetSio),
  takeLatest(types.DELETE_SIO_REQUEST, workerDeleteSio),
  takeLatest(types.UPDATE_SIO_REQUEST, workerUpdateSio),
  takeLatest(types.ADD_SIO_REQUEST, workerAddSio),
];

function* workerAddSio({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<Omit<SioType[], "id" | "status">>): any {
  if (!payload || payload.length === 0) return;
  const http = new HttpService(urlsEndpoint.ADD_SIO);

  try {
    const result = yield call(http.post, payload);

    if (result) {
      yield put(actions.getListSio.success(result.data.data));
      yield callback(onSuccess, result);
    }
  } catch (err) {
    console.error(err);
    yield put(actions.addSio.failure(err));
    yield callback(onFailure);
  }
}

function* workerUpdateSio({ payload, onFailure, onSuccess }: IDefaultRequestAction<SioType>): any {
  if (!payload) return;
  const http = new HttpService(urlsEndpoint.UPDATE_SIO);

  try {
    const result = yield call(http.post, payload);

    if (result) {
      yield put(actions.updateSio.success(result.data.data));
      yield callback(onSuccess, result);
    }
  } catch (err) {
    console.error(err);
    yield put(actions.updateSio.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerDeleteSio({ payload, onFailure, onSuccess }: IDefaultRequestAction<number>): any {
  if (!payload) return;
  const http = new HttpService(urlsEndpoint.DELETE_SIO);

  try {
    const result = yield call(http.delete, payload);

    if (result) {
      yield put(actions.deleteSio.success(payload));
      yield callback(onSuccess, result);
    }
  } catch (err) {
    console.error(err);
    yield put(actions.deleteSio.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetSio({ payload, onFailure, onSuccess }: IDefaultRequestAction<string>): any {
  const http = new HttpService(`${urlsEndpoint.GET_SIO}`);

  const finalPayload = {
    start: 0,
    limit: 999,
    page: 1,
    keyword: "", // title or certificateName
    filter: {
      title: "",
      certificateNumber: "",
      username: payload,
    },
    orderBy: ["createdDate"],
    direction: "DESC",
  };

  try {
    const response = yield call(http.post, finalPayload);

    yield put(actions.getListSio.success(response.data.userSioList));
    yield callback(onSuccess, response);
  } catch (err) {
    console.error(err);
    yield put(actions.getListSio.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetUsers({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_USERS);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getUsers.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getUsers.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetDetailUsers({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetDetailUserRequestData>): any {
  const http = new HttpService(urlsEndpoint.GET_DETAIL_USERS);
  const http2 = new HttpService(urlsEndpoint.GET_DETAIL_AUTH_USERS);
  try {
    const response = yield call(http.get, payload?.username || "");
    const response2 = yield call(http2.get, payload?.username || "");
    yield put(actions.getDetailUser.success([response, response2]));
    yield callback(onSuccess, [response, response2]);
  } catch (err) {
    yield put(actions.getUsers.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetDetailUserCompany({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetDetailUserRequestData>): any {
  const http = new HttpService(urlsEndpoint.GET_DETAIL_USER_COMPANY);
  try {
    const response = yield call(http.get, payload?.username || "");
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetListCompany({ payload, onFailure, onSuccess }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.GET_LIST_COMPANY);
  try {
    const response = yield call(http.get, payload ?? "");
    yield put(actions.getListCompany.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListCompany.success(err));
    yield callback(onFailure, err);
  }
}

function* workerGetRole({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_ALL_ROLE_LIST);
  try {
    const response = yield call(http.find, payload);
    yield put(actions.getRoles.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getRoles.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetDepartment({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.LIST_DEPARTMENT);
  try {
    const response = yield call(http.get, payload || "");
    yield put(actions.getListDepartment.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListDepartment.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetGroup({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.LIST_GROUP);
  try {
    const response = yield call(http.find, payload);
    yield put(actions.getListGroup.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListGroup.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetPosition({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.LIST_POSITION);
  try {
    const response = yield call(http.get, payload || "");
    yield put(actions.getListPosition.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListPosition.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetSection({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.LIST_SECTION);
  try {
    const response = yield call(http.get, payload || "");
    yield put(actions.getListSection.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListSection.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetModel({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.LIST_MODEL);
  try {
    const response = yield call(http.get, payload || "");
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetDirectSup({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.LIST_DS);
  try {
    const response = yield call(http.find, payload);
    yield put(actions.getListDirecSuperior.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListDirecSuperior.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetArchiveUser({
  payload,
}: IDefaultRequestAction<IGetListArchiveRequestData>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.LIST_ARCHIVE_USER,
      method: "post",
      data: {
        page: payload?.page,
        limit: payload?.limit,
        keyword: payload?.keyword,
        orderBy: {
          field: "",
          sort: "",
        },
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });

    yield put(actions.getListArchive.success(response));
  } catch (err) {
    yield put(actions.getListArchive.failure(err));
  }
}

function* workerAddUser({ payload, onFailure, onSuccess }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.SAVE_USER);
  const http2 = new HttpService(urlsEndpoint.SAVE_USER_BACKEND);
  try {
    const fullName = (payload?.fullName ?? "").split(" ");
    const lastName = fullName.length > 1 ? fullName[1] : "";
    const payload1 = {
      nik: payload?.nik,
      nikPassport: payload?.nikPassport,
      username: payload?.username,
      email: payload?.email,
      password: payload?.password ? payload?.password : "Sentinel01!",
      fullName: payload?.fullName,
      dateOfBirth: moment(payload?.dateOfBirth).format("YYYY-MM-DD"),
      gender: payload?.gender,
      phone: payload?.phone,
      role: payload?.role,
      consultationLink: payload?.link,
      bloodType: payload?.bloodType,
      userMenu: payload?.userMenu,
      firstName: fullName[0],
      lastName: lastName,
      height: 0,
      weight: 0,
      photoProfile: payload?.photoProfile,
    };
    const payload2 = {
      username: payload?.nik,
      email: payload?.email,
      password: payload?.password ? payload?.password : "Sentinel01!",
      divisionName: payload?.userDivision,
      departmentName: payload?.departmentName,
      roleId: payload?.roleAccess,
      superiorName: payload?.superiorName,
      employeeId: payload?.idEmployee,
      category: payload?.category,
      companyId: payload?.company,
      groupId: payload?.group,
      positionId: payload?.position,
      departmentId: payload?.departmentId,
      sectionId: payload?.section,
      photoProfile: payload?.photoProfile,
    };
    const response = yield call(http.post, payload1);
    debug(response);
    const response2 = yield call(http2.post, payload2);
    debug(response2);
    yield callback(onSuccess, [response, response2]);
  } catch (err) {
    console.error(err);
    yield callback(onFailure, err);
  }
}

function* workerEditUser({ payload, onFailure, onSuccess }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.EDIT_USER);
  const http2 = new HttpService(urlsEndpoint.EDIT_USER_BACKEND);
  try {
    const fullName = payload?.fullName.split(" ");
    const lastName = fullName.length > 1 ? fullName[1] : "";
    const payload1 = {
      id: payload?.id,
      nik: payload?.nik,
      nikPassport: payload?.nikPassport,
      username: payload?.username,
      email: payload?.email,
      password: payload?.password,
      fullName: payload?.fullName,
      dateOfBirth: moment(payload?.dateOfBirth).format("YYYY-MM-DD"),
      gender: payload?.gender,
      phone: payload?.phone,
      role: payload?.role,
      consultationLink: payload?.link,
      bloodType: payload?.bloodType,
      userMenu: payload?.userMenu,
      firstName: fullName[0],
      lastName: lastName,
      height: 0,
      weight: 0,
      photoProfile: payload?.photoProfile,
    };
    const payload2 = {
      username: payload?.username,
      email: payload?.email,
      password: payload?.password,
      divisionName: payload?.userDivision,
      departmentName: payload?.departmentName,
      roleId: payload?.roleAccess,
      superiorName: payload?.superiorName,
      employeeId: payload?.idEmployee,
      category: payload?.category,
      companyId: payload?.company,
      groupId: payload?.group,
      positionId: payload?.position,
      departmentId: payload?.departmentId,
      sectionId: payload?.section,
    };
    const response = yield call(http.post, payload1);
    debug(response);
    const response2 = yield call(http2.post, payload2);
    debug(response2);
    yield callback(onSuccess, [response, response2]);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerDeleteUser({ payload, onFailure, onSuccess }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.DELETE_USER);
  try {
    const response = yield call(http.delete, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerCreateCompany({ payload, onFailure, onSuccess }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.CREATE_COMPANY_USER);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.createCompany.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.createCompany.success(err));
    yield callback(onFailure, err);
  }
}
