import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { types } from "./_types";

export const actions = {
  getList: {
    request: (payload: any) => ({
      type: types.GET_LIST_HIERARCHY_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.GET_LIST_HIERARCHY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.GET_LIST_HIERARCHY_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createModel: {
    request: (payload: IDefaultRequestParams<any>) => ({
      type: types.ADD_HIERARCHY_REQUEST,
      ...payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  updateModel: {
    request: (payload: IDefaultRequestParams<any>) => ({
      type: types.UPDATE_HIERARCHY_REQUEST,
      ...payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  deleteModel: {
    request: (payload: IDefaultRequestParams<any>) => ({
      type: types.DELETE_HIERARCHY_REQUEST,
      ...payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  getSubList: {
    request: (payload: any) => ({
      type: types.SUB_HIERARCHY_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: types.SUB_HIERARCHY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: types.SUB_HIERARCHY_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createSubModel: {
    request: (payload: IDefaultRequestParams<any>) => ({
      type: types.ADD_SUB_HIERARCHY_REQUEST,
      ...payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  updateSubModel: {
    request: (payload: IDefaultRequestParams<any>) => ({
      type: types.UPDATE_SUB_HIERARCHY_REQUEST,
      ...payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  deleteSubModel: {
    request: (payload: IDefaultRequestParams<any>) => ({
      type: types.DELETE_SUB_HIERARCHY_REQUEST,
      ...payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
};
