export const types = {
    GET_SITE_REQUEST: "site/GET_SITE_REQUEST",
    GET_SITE_SUCCESS: "site/GET_SITE_SUCCESS",
    GET_SITE_FAILURE: "site/GET_SITE_FAILURE",
  
    ADD_SITE_REQUEST: "site/ADD_SITE_REQUEST",
    EDIT_SITE_REQUEST: "site/EDIT_SITE_REQUEST",
    DELETE_SITE_REQUEST: "site/DELETE_SITE_REQUEST",
  };
  
  export interface IFormikValues {
      id?: number;
      siteName: String,
      siteDescription: String,
      latitude: String,
      longitude: String
  }
  
  export interface IAddSiteRequestData extends IFormikValues {}
  