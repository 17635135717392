import React from "react";

import styles from "./styles.module.less";

interface BlackBannerProps {
  isVisible: boolean;
  onClick?: () => void;
}

const BlackBanner: React.FC<BlackBannerProps> = ({ isVisible, onClick }) => {
  if (!isVisible) return <></>;
  return (
    <div className={styles.black_banner_container} onClick={onClick ? onClick : undefined}></div>
  );
};

export default BlackBanner;
