import clsx from "clsx";
import { FC, ReactNode } from "react";
import FadeIn from "react-fade-in";
import { useHistory } from "react-router-dom";
import { BACK } from "../../assets/images";

import Breadcrumb from "../Breadcrumb";
import { ContentLoader } from "../Loader";

import styles from "./styles.module.less";

interface BaseTemplateProps {
  title: string | ReactNode;
  showBreadcrumb?: boolean;
  content: ReactNode;
  titleMenu?: ReactNode;
  footer?: ReactNode;
  isParent?: boolean;
  loading?: boolean;
  goBack?: () => void;
}

const BaseTemplate: FC<BaseTemplateProps> = ({
  title,
  titleMenu,
  content,
  footer,
  showBreadcrumb = false,
  isParent = false,
  loading = false,
  goBack,
}) => {
  const history = useHistory();
  return (
    <section
      className={clsx(styles.root, {
        [styles.root_parent]: isParent,
      })}
    >
      {loading && (
        <div className={styles.bg_opacity}>
          <ContentLoader />
        </div>
      )}
      <div className={styles.content}>
        <FadeIn>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{ display: "flex", alignItems: "center", marginBottom: 16, flexBasis: "100%" }}
            >
              {isParent ? (
                ""
              ) : (
                <img
                  src={BACK}
                  alt=""
                  onClick={() => {
                    if (goBack) {
                      goBack();
                    } else {
                      history.goBack();
                      // localStorage.setItem("isTab", "");
                    }
                  }}
                  style={{ cursor: "pointer" }}
                />
              )}
              <div
                style={{
                  marginLeft: isParent ? 0 : 10,
                  fontSize: 20,
                  fontWeight: 600,
                  flexBasis: "100%",
                }}
              >
                {title}
              </div>
            </div>
            {titleMenu}
          </div>
          <div className={styles.contentSub}>
            {showBreadcrumb && <Breadcrumb />}
            {content}
          </div>
        </FadeIn>
      </div>
      <div className={styles.footer}>{footer}</div>
    </section>
  );
};

export default BaseTemplate;
