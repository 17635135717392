import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import {
  ICreateMasterAttData,
  ICreateMasterKeyData,
  ICreateMasterLocData,
  IGetLocationDetailData,
  IGetMasterAttDetailData,
  IGetMasterKeyDetailData,
  IMasterLocationTypeRequestData,
  IUpdateMasterAttData,
  IUpdateMasterKeyData,
  IUpdateMasterLocData,
  ICreateMasterKeyCategory,
  types as masterTypes,
} from "./_types";

export const actions = {
  getForms: {
    request: (payload: any) => ({
      type: masterTypes.GET_MASTER_KEYS_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.GET_MASTER_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.GET_MASTER_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getFormsCategori: {
    request: (payload: any) => ({
      type: masterTypes.GET_MASTER_KEYS_CATEGORY_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.GET_MASTER_KEYS_CATEGORY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.GET_MASTER_KEYS_CATEGORY_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getFormsArchive: {
    request: (payload: any) => ({
      type: masterTypes.GET_MASTER_KEYS_ARCHIVE_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.GET_MASTER_KEYS_ARCHIVE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.GET_MASTER_KEYS_ARCHIVE_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createForm: {
    request: (requestData: IDefaultRequestParams<ICreateMasterKeyData>) => ({
      ...requestData,
      type: masterTypes.CREATE_MASTER_KEY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    failure: (error: any) => ({
      type: masterTypes.CREATE_MASTER_KEY_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
    success: (data: any) => ({
      type: masterTypes.CREATE_MASTER_KEY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
  },
  createCategory: {
    request: (requestData: IDefaultRequestParams<ICreateMasterKeyCategory>) => ({
      ...requestData,
      type: masterTypes.CREATE_MASTER_CATEGORY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    failure: (error: any) => ({
      type: masterTypes.CREATE_MASTER_CATEGORY_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
    success: (data: any) => ({
      type: masterTypes.CREATE_MASTER_CATEGORY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
  },
  updateForm: {
    request: (requestData: IDefaultRequestParams<IUpdateMasterKeyData>) => ({
      ...requestData,
      type: masterTypes.UPDATE_MASTER_KEY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.UPDATE_MASTER_KEY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.UPDATE_MASTER_KEY_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getFormDetail: {
    request: (requestData: IDefaultRequestParams<IGetMasterKeyDetailData>) => ({
      ...requestData,
      type: masterTypes.GET_MASTER_KEY_DETAIL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.GET_MASTER_KEY_DETAIL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.GET_MASTER_KEY_DETAIL_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  //--------------------
  //batas
  //--------------------
  getMasterAtt: {
    request: (payload: any) => ({
      type: masterTypes.GET_ALL_MASTER_ATT_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.GET_ALL_MASTER_ATT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.GET_ALL_MASTER_ATT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getMasterSite: {
    request: (payload: any) => ({
      type: masterTypes.GET_SITE_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.GET_SITE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.GET_SITE_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getMasterAttDetail: {
    request: (requestData: IDefaultRequestParams<IGetMasterAttDetailData>) => ({
      ...requestData,
      type: masterTypes.GET_MASTER_ATT_DETAIL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.GET_MASTER_ATT_DETAIL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.GET_MASTER_ATT_DETAIL_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createMasterAtt: {
    request: (requestData: IDefaultRequestParams<ICreateMasterAttData>) => ({
      ...requestData,
      type: masterTypes.CREATE_MASTER_ATT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.CREATE_MASTER_ATT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.CREATE_MASTER_ATT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  updateMasterAtt: {
    request: (requestData: IDefaultRequestParams<IUpdateMasterAttData>) => ({
      ...requestData,
      type: masterTypes.UPDATE_MASTER_ATT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.UPDATE_MASTER_ATT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.UPDATE_MASTER_ATT_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  //--------------------
  //batas
  //--------------------
  getAllLoc: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: masterTypes.GET_ALL_LOCATION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  getLocDetail: {
    request: (requestData: IDefaultRequestParams<IGetLocationDetailData>) => ({
      ...requestData,
      type: masterTypes.GET_LOCATION_BY_ID_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.GET_LOCATION_BY_ID_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.GET_LOCATION_BY_ID_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createLoc: {
    request: (requestData: IDefaultRequestParams<ICreateMasterLocData>) => ({
      ...requestData,
      type: masterTypes.CREATE_DATA_LOCATION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.CREATE_DATA_LOCATION_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.CREATE_DATA_LOCATION_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  updateLoc: {
    request: (requestData: IDefaultRequestParams<IUpdateMasterLocData>) => ({
      ...requestData,
      type: masterTypes.UPDATE_DATA_LOCATION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.UPDATE_DATA_LOCATION_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.UPDATE_DATA_LOCATION_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListLoc: {
    request: (requestData: IDefaultRequestParams<null>) => ({
      ...requestData,
      type: masterTypes.GET_LIST_LOC_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.GET_LIST_LOC_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.GET_LIST_LOC_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  // site
  getSiteList: {
    request: (payload: any) => ({
      type: masterTypes.SITE_LIST_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.SITE_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.SITE_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  // risk
  getRiskList: {
    request: (payload: any) => ({
      type: masterTypes.RISK_LIST_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.RISK_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.RISK_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  // shift
  getShiftList: {
    request: (payload: any) => ({
      type: masterTypes.SHIFT_LIST_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.SHIFT_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.SHIFT_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  // equipment
  getEquipmentList: {
    request: (payload: any) => ({
      type: masterTypes.EQUIPMENT_LIST_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.EQUIPMENT_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.EQUIPMENT_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  // equipment
  getEquipmentType: {
    request: (payload: any) => ({
      type: masterTypes.EQUIPMENT_TYPE_LIST_REQUEST,
      ...payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.EQUIPMENT_TYPE_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.EQUIPMENT_TYPE_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  addEquipmentType: {
    request: (payload: any) => ({
      type: masterTypes.EQUIPMENT_ADD_REQUEST,
      ...payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  updateEquipmentType: {
    request: (payload: any) => ({
      type: masterTypes.EQUIPMENT_UPDATE_REQUEST,
      ...payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  deleteEquipmentType: {
    request: (payload: any) => ({
      type: masterTypes.EQUIPMENT_DELETE_REQUEST,
      ...payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  detailEquipment: {
    request: (payload: any) => ({
      type: masterTypes.EQUIPMENT_DETAIL_REQUEST,
      ...payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  childEquipment: {
    request: (payload: any) => ({
      type: masterTypes.EQUIPMENT_CHILD_REQUEST,
      ...payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },

  // location
  getLocationList: {
    request: (payload: any) => ({
      type: masterTypes.LOCATION_LIST_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.LOCATION_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.LOCATION_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  //location type
  getLocationTypeList: {
    request: (payload: any) => ({
      type: masterTypes.LOCATION_TYPE_LIST_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.LOCATION_TYPE_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.LOCATION_TYPE_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  // sublocation
  getSubLocationList: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: masterTypes.SUBLOCATION_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  // sublocation by id
  getSubLocationById: {
    request: (payload: any) => ({
      type: masterTypes.SUBLOCATION_BY_ID_REQUEST,
      payload,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.SUBLOCATION_BY_ID_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.SUBLOCATION_BY_ID_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  // sublocation create
  createSubLocation: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: masterTypes.CREATE_SUBLOCATION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.CREATE_SUBLOCATION_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.CREATE_SUBLOCATION_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  deleteSubLocation: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: masterTypes.DELETE_SUBLOCATION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  updateSubLocation: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: masterTypes.UPDATE_SUBLOCATION_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },

  // master types site
  getMasterTypesDataList: {
    request: (requestData: IDefaultRequestParams<null>) => ({
      ...requestData,
      type: masterTypes.MASTER_TYPE_DATAS_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.MASTER_TYPE_DATAS_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.MASTER_TYPE_DATAS_LIST_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  //master tool
  getListTools: {
    request: (requestData: any) => ({
      ...requestData,
      type: masterTypes.MASTER_TOOLS_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.MASTER_TOOLS_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.MASTER_TOOLS_LIST_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getAddTools: {
    request: (requestData: any) => ({
      ...requestData,
      type: masterTypes.MASTER_TOOLS_ADD_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  getEditTools: {
    request: (requestData: any) => ({
      ...requestData,
      type: masterTypes.MASTER_TOOLS_EDIT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  deleteTools: {
    request: (requestData: any) => ({
      ...requestData,
      type: masterTypes.MASTER_TOOLS_DELETE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },

  // child equipment
  getChildEquipment: {
    request: (requestData: any) => ({
      ...requestData,
      type: masterTypes.EQUIPMENT_CHILD_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.EQUIPMENT_CHILD_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.EQUIPMENT_CHILD_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getCategory: {
    request: (requestData: any) => ({
      ...requestData,
      type: masterTypes.EQUIPMENT_CATEGORY_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.EQUIPMENT_CATEGORY_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.EQUIPMENT_CATEGORY_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },

  getChildCreate: {
    request: (requestData: any) => ({
      ...requestData,
      type: masterTypes.MASTER_TOOLS_ADD_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },

  createCategoryEquipment: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: masterTypes.ADD_CATEGORY_EQUIPMENT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },

  getHistoryEquipmentDetail: {
    request: (requestData: any) => ({
      ...requestData,
      type: masterTypes.HISTORY_EQUIPMENT_DETAIL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: masterTypes.HISTORY_EQUIPMENT_DETAIL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: masterTypes.HISTORY_EQUIPMENT_DETAIL_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};

// action without redux

// ++++ Equipment ++++

export async function getDetailEquipmentAction(
  id: string
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_DETAIL_EQUIPMENT);
  try {
    const response = await http.get(id || "");
    const successResponse = {
      success: true,
      data: response?.data?.equipment,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function addEquipmentAction(
  payload: object
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.CREATE_DATA_EQUIPMENT);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response?.data?.equipment,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function addChildEquipment(
  payload: object
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.CREATE_CHILD_EQUIPMENT);
  try {
    const response = await http.post(payload);
    let successResponse: { error: boolean; success: boolean; data: any } = {
      error: true,
      success: false,
      data: null,
    };
    if (response?.result) {
      const successResponse = {
        success: true,
        error: false,
        data: response?.data,
      };
      return successResponse;
    }

    successResponse = {
      error: true,
      success: false,
      data: {
        message: response?.message?.replace("code", "serial number") ?? "Failed to add data.",
      },
    };
    return successResponse;
  } catch (err) {
    console.error(err);
    const errorResponse = {
      error: true,
      success: false,
      data: { message: "Failed to save data" },
    };
    return errorResponse;
  }
}
export async function updateChildEquipment(
  payload: object
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.UPDATE_CHILD_EQUIPMENT);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function deleteChildEquipment(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_CHILD_EQUIPMENT);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.data?.equipment,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateEquipmentAction(
  payload: any
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");

  try {
    const response = await http.custom({
      url: urlsEndpoint.UPDATE_DATA_EQUIPMENT,
      method: "post",
      data: {
        id: payload?.id,
        type: payload?.type,
        brand: payload?.brand,
        code: payload?.code,
        status: payload?.status,
        remarks: payload?.remarks,
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    const successResponse = {
      success: true,
      data: response?.data?.equipment,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function deleteEquipmentAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_DATA_EQUIPMENT);
  try {
    const response = await http.get(id);
    const successResponse = {
      success: true,
      data: response?.data?.equipment,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

// -----------------------------------------------------
export async function actionEquipmenType(
  payload: object,
  type: string
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const pathUrl =
    type === "add" ? urlsEndpoint.CREATE_EQUIPMENT_TYPE : urlsEndpoint.UPDATE_EQUIPMENT_TYPE;
  const http = new HttpService(pathUrl);
  try {
    const res = await http.post(payload);
    const successResponse = { success: true, data: res?.data?.equipmentType };
    return successResponse;
  } catch (err) {
    const errorResponse = { error: true, data: err };
    return errorResponse;
  }
}

export async function deleteEquipmenType(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_EQUIPMENT_TYPE);
  try {
    const res = await http.delete(id);
    const successResponse = { success: true, data: res?.data?.equipmentType };
    return successResponse;
  } catch (err) {
    const errorResponse = { error: true, data: err };
    return errorResponse;
  }
}

export async function deleteLocAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_DATA_LOCATION);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.data?.location,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function deleteKeyAction(
  subCategoryId: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(`${urlsEndpoint.DELETE_MASTER_KEY}/${subCategoryId}`);
  try {
    // const response = await http.custom({
    //   url: `${urlsEndpoint.DELETE_MASTER_KEY}/{id}`,
    //   method: "post",
    // });
    const response = await http.post();
    const successResponse = {
      success: true,
      data: response?.data?.datamaster,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
export async function restoreKeyAction(
  subCategoryId: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(`${urlsEndpoint.RESTORE_MASTER_KEY}/${subCategoryId}`);
  try {
    // const response = await http.custom({
    //   url: `${urlsEndpoint.DELETE_MASTER_KEY}/{id}`,
    //   method: "post",
    // });
    const response = await http.post();
    const successResponse = {
      success: true,
      data: response?.data?.datamaster,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
export async function deleteKeyActionValid(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(`${urlsEndpoint.DELETE_MASTER_KEY_VALID}/${id}`);
  try {
    // const response = await http.custom({
    //   url: `${urlsEndpoint.DELETE_MASTER_KEY}/{id}`,
    //   method: "post",
    // });
    const response = await http.post();
    const successResponse = {
      success: true,
      data: response?.data?.datamaster,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function delMasAttAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_MASTER_ATT);
  try {
    const response = await http.get(id);
    const successResponse = {
      success: true,
      data: response?.data?.attdata,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

// ++++ locationType ++++

export async function addLocationTypeAction(
  payload: object
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.CREATE_LOCATION_TYPE);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response?.data?.locationType,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function deleteLocationTypeAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_LOCATION_TYPE);
  try {
    const response = await http.get(id);
    const successResponse = {
      success: true,
      data: response?.data?.locationType,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getDetailLocationType(
  id: string
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.GET_DETAIL_LOCATION_TYPE);
  try {
    const response = await http.get(id || "");
    const successResponse = {
      success: true,
      data: response?.data?.locationType,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateLocationTypeAction(
  payload: IMasterLocationTypeRequestData
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");

  try {
    const response = await http.custom({
      url: urlsEndpoint.UPDATE_DETAIL_LOCATION_TYPE,
      method: "post",
      data: {
        id: payload?.id,
        locationTypeName: payload?.locationTypeName,
        locationTypeDescription: payload?.locationTypeDescription,
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    const successResponse = {
      success: true,
      data: response?.data?.equipment,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

// -----------------------

// **** SUBLOCATION
// export async function deleteSubLocationAction(id: number):
//   Promise<{ success?: boolean, error?: boolean, data: any }> {
//   const http = new HttpService(urlsEndpoint.DELETE_SUBLOCATION);
//   try {
//     const response = await http.deleteById(id)
//     const successResponse = {
//       success: true,
//       data: response?.data?.subLocation,
//     };
//     return successResponse;
//   } catch (err) {
//     const errorResponse = {
//       error: true,
//       data: err,
//     };
//     return errorResponse
//   }
// }
