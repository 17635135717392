import * as urlsName from "../../helpers/urlsName";
export const types = {
  GET_MYTASK_COUNTER_REQUEST: "sidebar/GET_MYTASK_COUNTER_REQUEST",
  GET_MYTASK_COUNTER_SUCCESS: "sidebar/GET_MYTASK_COUNTER_SUCCESS",
  GET_MYTASK_COUNTER_FAILURE: "sidebar/GET_MYTASK_COUNTER_FAILURE",
};

export const taskCounterNameMapping = [
  {
    title: "Medical Center",
    path: urlsName.MY_TASK,
    search: "health",
  },
  {
    title: "SHE",
    path: urlsName.MY_TASK,
    search: "safety",
  },
  // {
  //   title: "Reassign",
  //   path: urlsName.REASSIGN,
  //   search: null,
  // },
  // {
  //   title: "Undo Status",
  //   path: urlsName.UNDO_STATUS,
  //   search: null,
  // },
];

export type TypeFlow = "HEALTH" | "SAFETY";

export interface IMyTaskReqParams {
  typeFlow: TypeFlow;
  dateFilter?: "TODAY" | "WTD" | "MTD" | "YTD" | "CUSTOM" | "";
}

export interface IMyTask {
  myTask: number;
  typeFlow: TypeFlow;
}
