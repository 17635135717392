import clsx from "clsx";
import { FC } from "react";
import styles from "./styles.module.less";

interface SegmentedProps {
  data: Array<string>;
  value?: string;
  onSelect: (e: string) => void;
}

const Segmented: FC<SegmentedProps> = ({ data, value, onSelect }) => {
  return (
    <div className={styles.segmentedWrap}>
      {data &&
        data.map((item, i) => (
          <div
            key={i}
            onClick={() => onSelect(item)}
            className={clsx(styles.segmentedItem, {
              [styles.segmentedItem__active]: item === value,
              [styles.segmentedItem__nonActive]: item !== value,
            })}
          >
            {item}
          </div>
        ))}
    </div>
  );
};

export default Segmented;
