import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";

interface Props {
  children: ReactNode;
  className: string;
}

const Swiper: FC<Props> = ({ children, className }) => {
  const ref: any = useRef(null);
  const startX = useRef<number>(0);
  const [startScrollLeft, setStartScrollLeft] = useState<number>(0);
  const [myMouseDown, setMyMouseDown] = useState<boolean>(false);

  const handleDown = useCallback((e: any) => {
    setMyMouseDown(true); // <=============================== This makes it true
    if (!ref.current?.contains(e.target)) return;
    startX.current = e.pageX - ref.current.offsetLeft;
    setStartScrollLeft(ref.current.scrollLeft);
  }, []);

  const handleMove = useCallback(
    (e: any) => {
      e.preventDefault();
      if (!ref.current?.contains(e.target)) return;
      const mouseX = e.pageX - ref.current.offsetLeft;
      const moveX = mouseX - startX.current;
      if (myMouseDown) {
        ref.current.scrollLeft = startScrollLeft - moveX;
      }
    },
    [myMouseDown, startScrollLeft, startX]
  );

  const handleUp = () => {
    setMyMouseDown(false); // <=========================== This makes it false
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleUp);
    document.addEventListener("mousedown", handleDown);
    document.addEventListener("mousemove", handleMove);
    return () => {
      document.removeEventListener("mouseup", handleUp);
      document.removeEventListener("mousedown", handleDown);
      document.removeEventListener("mousemove", handleMove);
    };
  }, [handleDown, handleMove]);

  return (
    <div ref={ref} className={className}>
      {children}
    </div>
  );
};

export default Swiper;
