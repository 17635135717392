import { types as masterTypes } from "./_types";

const initialState = {
  bulkUploadDataUser: [],
  bulkUploadDataMedicine: [],
  paginationMedicine: null,
  listRole: [],
  pagination: null,
  actionStatus: "initial",
  moreActionStatus: {
    addUser: "initial",
  },
  archiveData: null,
  isLoading: false,
  isLoadingMedicine: false,
  dataFailedUser: [],
  dataFailedMedicine: [],
  isLoadingFailedUser: false,
  isLoadingFailedMedicine: false,
};

export const bulkuploadReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case masterTypes.GET_BULK_UPLOAD_MEDICINE_LIST_REQUEST:
      return {
        ...state,
        isLoadingMedicine: true,
      };
    case masterTypes.GET_BULK_UPLOAD_MEDICINE_LIST_FAILED:
      return {
        ...state,
        isLoadingMedicine: false,
        bulkUploadDataMedicine: [],
        actionStatus: action.status,
      };
    case masterTypes.GET_BULK_UPLOAD_MEDICINE_LIST_SUCCESS:
      return {
        ...state,
        bulkUploadDataMedicine: Array.isArray(action.data.data.bulkUploadMedicine)
          ? action.data.data.bulkUploadMedicine
          : [],
        paginationMedicine: action.data.pagination,
        actionStatus: action.status,
        isLoadingMedicine: false,
      };
    case masterTypes.GET_BULK_UPLOAD_USER_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.GET_BULK_UPLOAD_USER_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.GET_BULK_UPLOAD_USER_LIST_SUCCESS:
      return {
        ...state,
        bulkUploadDataUser: Array.isArray(action.data.data.logUploadFiles)
          ? action.data.data.logUploadFiles
          : [],
        pagination: action.data.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case masterTypes.GET_BULK_UPLOAD_MEDICINE_FAILED_LIST_REQUEST:
      return {
        ...state,
        isLoadingFailedMedicine: true,
      };
    case masterTypes.GET_BULK_UPLOAD_MEDICINE_FAILED_LIST_FAILED:
      return {
        ...state,
        isLoadingFailedMedicine: false,
        dataFailedMedicine: [],
      };
    case masterTypes.GET_BULK_UPLOAD_MEDICINE_FAILED_LIST_SUCCESS:
      return {
        ...state,
        dataFailedMedicine: Array.isArray(action.data.data.logUploadDetail)
          ? action.data.data.logUploadDetail
          : [],
        isLoadingFailedMedicine: false,
      };
    case masterTypes.GET_BULK_UPLOAD_USER_FAILED_LIST_REQUEST:
      return {
        ...state,
        isLoadingFailedUser: true,
      };
    case masterTypes.GET_BULK_UPLOAD_USER_FAILED_LIST_FAILED:
      return {
        ...state,
        isLoadingFailedUser: false,
        dataFailedUser: [],
        actionStatus: action.status,
      };
    case masterTypes.GET_BULK_UPLOAD_USER_FAILED_LIST_SUCCESS:
      return {
        ...state,
        dataFailedUser: Array.isArray(action.data.dataList) ? action.data.dataList : [],
        actionStatus: action.status,
        isLoadingFailedUser: false,
      };
    default:
      return state;
  }
};
