import { call, put, takeLatest } from "redux-saga/effects";

import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";

import { actions } from "./_actions";
import { types } from "./_types";

export const watcherNotification = () => [
  takeLatest(types.GET_LIST_NOTIF_REQUEST, workerListNotif),
  takeLatest(types.READ_NOTIF_REQUEST, workerReadNotif),
  takeLatest(types.READ_BADGE_REQUEST, workerReadBadge),
];

function* workerListNotif({ payload, onFailure, onSuccess }: any): any {
  const http = new HttpService(urlsEndpoint.GET_LIST_NOTIF);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerReadNotif({ payload, onFailure, onSuccess }: any): any {
  const http = new HttpService(urlsEndpoint.READ_ID_NOTIF);
  try {
    const response = yield call(http.put, payload, "");
    yield put(actions.readNotif.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.readNotif.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerReadBadge({ payload, onFailure, onSuccess }: any): any {
  const http = new HttpService(urlsEndpoint.READ_BADGE_NOTIF);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}
