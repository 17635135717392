import { object, ref, SchemaOf, string } from "yup";
import { decrypt } from "../../helpers/aes";
import { MY_PIN } from "../../store/defaultTypes";
import { IChangePassword } from "./_types";

export const validateChangePassword: SchemaOf<IChangePassword> = object().shape({
  currentPassword: string()
    .required("This field is required")
    .trim()
    .test({
      name: "current-test",
      test: function (value: any) {
        if (value !== decrypt(localStorage[MY_PIN])) {
          return this.createError({
            message: "Password incorrect",
          });
        }
        return true;
      },
    }),
  newPassword: string()
    .required("This field is required")
    .trim()
    .test({
      name: "password-test",
      test: function (value: any) {
        var regex = new RegExp(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,}$/
        );
        if (value === decrypt(localStorage[MY_PIN])) {
          return this.createError({
            message: "Your new password must be different from previously used password",
          });
        }
        if (regex.test(value) === false) {
          return this.createError({
            message:
              "Use 8 or more characters with combination of uppercase & lowercase, numbers and special characters",
          });
        }
        return true;
      },
    }),
  confirmPassword: string()
    .required("This field is required")
    .oneOf([ref("newPassword"), null], "Those passwords didn’t match"),
});
