import { types as masterTypes } from "./_types";

const initialState = {
  actionStatus: "initial",
  moreActionStatus: {
    getForm: "initial",
    addData: "initial",
    updateData: "initial",
    getLoc: "initial",
    getHierarchy: "initial",
  },
  data: [],
  isLoading: true,
  dataSub: [],
};

export const hierarchyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case masterTypes.GET_LIST_HIERARCHY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.GET_LIST_HIERARCHY_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.GET_LIST_HIERARCHY_SUCCESS:
      return {
        ...state,
        data: action.data.data.modelList,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.SUB_HIERARCHY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.SUB_HIERARCHY_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case masterTypes.SUB_HIERARCHY_SUCCESS:
      return {
        ...state,
        dataSub: action.data.data.model,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    default:
      return state;
  }
};
