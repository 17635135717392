export const types = {
  GET_MY_TASK_KEYS_REQUEST: "my-task/GET_MY_TASK_KEYS_REQUEST",
  GET_MY_TASK_KEYS_SUCCESS: "my-task/GET_MY_TASK_KEYS_SUCCESS",
  GET_MY_TASK_KEYS_FAILED: "my-task/GET_MY_TASK_KEYS_FAILED",

  GET_HISTORY_KEYS_REQUEST: "my-task/GET_HISTORY_KEYS_REQUEST",
  GET_HISTORY_KEYS_SUCCESS: "my-task/GET_HISTORY_KEYS_SUCCESS",
  GET_HISTORY_KEYS_FAILED: "my-task/GET_HISTORY_KEYS_FAILED",

  CREATE_MY_TASK_REQUEST: "my-task/CREATE_MY_TASK_REQUEST",
  CREATE_MY_TASK_SUCCESS: "my-task/CREATE_MY_TASK_SUCCESS",
  CREATE_MY_TASK_FAILED: "my-task/CREATE_MY_TASK_FAILED",

  GET_TIMELINE_STATUS: "my-task/GET_TIMELINE_STATUS",
  GET_TIMELINE_STATUS_SUCCESS: "my-task/GET_TIMELINE_STATUS_SUCCESS",
  GET_TIMELINE_STATUS_FAILED: "my-task/GET_TIMELINE_STATUS_FAILED",

  REJECTED_HEALTH_REQUEST: "my-task/REJECTED_HEALTH_REQUEST",
  REJECTED_HEALTH_SUCCESS: "my-task/REJECTED_HEALTH_SUCCESS",
  REJECTED_HEALTH_FAILED: "my-task/REJECTED_HEALTH_FAILED",

  REJECTED_SAFETY_REQUEST: "my-task/REJECTED_SAFETY_REQUEST",
  REJECTED_SAFETY_SUCCESS: "my-task/REJECTED_SAFETY_SUCCESS",
  REJECTED_SAFETY_FAILED: "my-task/REJECTED_SAFETY_FAILED",

  REASSIGN_TASK_REQUEST: "my-task/REASSIGN_TASK_REQUEST",
  REASSIGN_TASK_SUCCESS: "my-task/REASSIGN_TASK_SUCCESS",
  REASSIGN_TASK_FAILED: "my-task/REASSIGN_TASK_FAILED",
};

export interface IGetReservasiKeyData {
  token: string;
  start: number;
  limit: number;
  filter: {
    filter: string;
  };
  orderBy: string[];
  direction: string;
}

export interface IGETMyTaskRequest {
  token?: string;
  patientId?: string;
  time?: string;
  flowData: {
    leadAuditor?: string;
    assigmentNumber?: string;
    submitionDate?: string | Date;
    location?: string | null;
    generalInfo?: string | null;
    action?: string;
    formFlow?: any;
  };
}

export interface PayloadReasign {
  assignmentNumber: any;
  username: string;
  comment?: string;
  fromFlow?: any;
}

export interface PayloadGetMyTask {
  start: number;
  limit: number;
  page: number;
  keyword: string;
  filter: Filter;
  orderBy: string[];
  direction: string;
}

export interface Filter {
  typeFlow: string;
  description: any[];
  assignmentNumber: string;
  assignmentDate: string;
  status: string;
  name: string;
}
