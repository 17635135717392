// Auth
export const ROOT = "/";
export const FORGET_PASSWORD = "/forget-password";
export const REGISTER = "/register";
export const REGISTER_STEP_2 = "/register-step-2";
export const OTP = "/otp";
export const UPDATE_PASSWORD = "/update-password";

//Home
export const HOME_PAGE = "/home";

//health
export const RESERVATION_LIST = "/reservation";
export const MEDICAL_CENTER = "/medical-center";
export const CREATE_RESERVATION = "/reservation/add";
export const DETAIL_RESERVATION = "/reservation/detail";

// Dashboard
export const DASHBOARD = "/dashboard";
export const DASHBOARD_PIC = "/dashboard/pic";
export const DASHBOARD_EMPLOYEE = "/dashboard/employee";

//Company profile
export const COMPANY_PROFILE = "/company-profile";
export const ADD_COMPANY_PROFILE = "/company-profile/add";

//Hierarchy Company profile
export const HIERARCHY_LIST = "/hierarchy_list";
export const ADD_HIERARCHY_LIST = "/hierarchy_list/add";
export const SUB_HIERARCHY_LIST = "/subhierarchy_list";
export const ADD_SUB_HIERARCHY_LIST = "/subhierarchy_list/add";

// Greeting
export const GREETING = "/greeting";
export const GREETING_ADD = "/greeting/add";

// User
export const USER = "/user";
export const ARCHIVE_USER = "/archive-user";
export const USER_UPDATE = "/user/add";
export const USER_INQUIRY = "/user/medical-record";

//Site
export const SITE = "/site";
export const ADD_SITE = "/site/add-new";

// Role
export const ROLE = "/role";
export const ARCHIVE_ROLE = "/archive-role";
export const ROLE_ADD = "/role/add";
export const ROLE_UPDATE = "/role/update";

// GENERAL
export const GENERAL_CORPORATE = "/general-corporate";
export const GENERAL_GROUP = "/general-group";
export const GENERAL_ROLE = "/general-role";
export const GENERAL_THEME = "/general-theme";
export const GENERAL_FORM = "/general-form";
export const GENERAL_FORM_ADD = "/general-form/add";
export const GENERAL_FORM_UPDATE = "/general-form/update";
export const GENERAL_FORM_DRAFT = "/general-form-draft";
export const GENERAL_FORM_ARCHIVE = "/general-formflow-archive";
export const GENERAL_REMINDER = "/general-reminder";

//Report
export const REPORT_SUBMISION = "/report-submision";

// Master
export const MASTER_KEY = "/master-key";
export const MASTER_KEY_CATEGORI = "/master-categori";
export const MASTER_KEY_ARCHIVE = "/master-archive";
export const MASTER_KEY_ADD = "/master-key/add";
export const MASTER_KEY_ADD_CATEGORY = "/sub-Category/add";
export const MASTER_KEY_UPDATE = "/master-key/update";
export const MASTER_KEY_HEATH = "/master-health";
export const MASTER_KEY_SAFETY = "/master-safety";

export const MASTER_ATT = "/master-attendance";
export const MASTER_ATT_ADD = "/master-attendance/add";
export const MASTER_ATT_UPDATE = "/master-attendance/update";

export const DETAIL_ATT = "/detail-attendance";
export const DETAIL_ATT_ADD = "/detail-attendance/add";
export const DETAIL_ATT_UPDATE = "/detail-attendance/update";

export const MASTER_LOCATION = "/master-location";
export const MASTER_LOCATION_ADD = "/master-location/action";

export const MASTER_LIST_SITE = "/master-site";
export const MASTER_ADD_SITE = "/master-site/add";
export const MASTER_EDIT_SITE = "/master-site/edit";

export const MASTER_LIST_RISK = "/master-risk";
export const MASTER_ADD_RISK = "/master-risk/add";
export const MASTER_EDIT_RISK = "/master-risk/edit";

export const MASTER_LIST_SHIFT = "/master-shift";
export const MASTER_ADD_SHIFT = "/master-shift/add";
export const MASTER_EDIT_SHIFT = "/master-shift/edit";

export const MASTER_LIST_EQUIPMENT = "/master-equipment";
export const MASTER_ADD_EQUIPMENT = "/master-equipment/add";
export const MASTER_EDIT_EQUIPMENT = "/master-equipment/edit";

export const MASTER_LIST_CHILD_EQUIPMENT = "/master-child-equipment";
export const MASTER_ADD_CHILD_EQUIPMENT = "/master-child-equipment/add";
export const MASTER_EDIT_CHILD_EQUIPMENT = "/master-child-equipment/edit";
export const MASTER_LOG_CHILD_EQUIPMENT = "/master-child-equipment/log";

export const MASTER_LOCATION_TYPE = "/master-location-type";
export const MASTER_LOCATION_ADD_TYPE = "/master-location-type/add";
export const MASTER_LOCATION_UPDATE_TYPE = "/master-location-type/update";

export const MASTER_LIST_EQUIPMENT_CATEGORY = "/master-equipment-category";
export const MASTER_LIST_EQUIPMENT_TYPE = "/master-equipment-type";
export const MASTER_ADD_EQUIPMENT_TYPE = "/master-equipment-type/add";
export const MASTER_EDIT_EQUIPMENT_TYPE = "/master-equipment-type/edit";

export const FORM_FLOW_LIST = "/form-flow";
export const FORM_FLOW_ADD = "/form-flow/add";

export const TOOLS_LIST = "/tools";
export const TOOLS_ADD = "/tools/add";

export const MASTER_LIST_SUB_LOCATION = "/master-sub-location";
export const MASTER_ADD_SUB_LOCATION = "/master-sub-location/action";

export const MASTER_FIRE_EQUIPMENT = "/master-fire";
export const MASTER_FIRE_EUIPMQNT_ADD = MASTER_FIRE_EQUIPMENT + "/add";
export const MASTER_FIRE_EQUIPMENT_CHILD = MASTER_FIRE_EQUIPMENT + "/child";
export const MASTER_FIRE_EQUIPMENT_CHILD_ADD = MASTER_FIRE_EQUIPMENT_CHILD + "/add";
export const MASTER_FIRE_EQUIPMENT_CHILD_LOG = MASTER_FIRE_EQUIPMENT_CHILD + "/log";

// Rank Configuration
export const RANK_CONFIGURATION = "/rank-configuration";
export const RANK_CONFIGURATION_CREATE = "/rank-configuration/add";
export const RANK_CONFIGURATION_UPDATE = "/rank-configuration/edit";

export const RANK_DETAIL = "/rank-detail";
export const RANK_DETAIL_CREATE = "/rank-detail/add";
export const RANK_DETAIL_UPDATE = "/rank-detail/edit";

export const TYPE_OF_WORK = "/typeof-work";
export const TYPE_OF_WORK_CREATE = "/typeof-work/add";
export const TYPE_OF_WORK_UPDATE = "/typeof-work/edit";

// Reservation
export const MY_TASK = "/my-task";
export const MY_TASK_DETAIL = "/my-task/detail";
export const MY_TASK_HISTORY_DETAIL = "/task-detail-history";

export const MEDICAL_RECORD = "/medical/record";
export const MEDICAL_PREVIEW = "/medical/preview";
export const MEDICAL_LIST = "/medical-list";
export const MEDICINE_LIST = "/medicine/detail";

// Safety
export const PERMIT_TO_WORK = "/permit-to-work";
export const CREATE_PERMIT_TO_WORK = "/permit-to-work/action";
export const SPESIAL_PERMIT_TO_WORK = "/spesial-work-permit";
export const HOT_PERMIK = "/spesial-hot-permik";
export const HOT_WORK_PERMIK = "/spesial-hot-work";
export const HOT_CONFIRM_PERMIK = "/spesial-hot-confirm";

export const BULK_UPLOAD_MEDICINE = "/bulk-upload-medicine";
export const BULK_UPLOAD_USER = "/bulk-upload-user";
// Medicine
export const LIST_MEDICINE = "/medicine";
export const LOG_MEDICINE = "/log-medicine";
// Medical Inventory
export const INVENTORY = "/category-inventory";
export const DETAIL_INVENTORY = "/list-inventory";

// Article
export const ARTICLE_LIST = "/home/article-list";
// Articles
export const LIST_ARTICLES = "/articles";
export const LIST_ARTICLES_CREATE = "/articles/add";
export const LIST_ARTICLES_EDIT = "/articles/edit";
export const ARTICLE_DETAIL = "/home/article-detail";

// Reassign
export const REASSIGN = "/reassign";
// Workflow
export const WORKFLOW_CATEGORIZATION = "/workflow-categorization";

// undo status
export const UNDO_STATUS = "/undo-status";

// Follow up action
export const DETAIL_FOLLOW_UP_ACTION = "/detail-follow-up-action";

// Procedure Config
export const PROCEDURE_CONFIG = "/procedure-config";
export const ADD_PROCEDURE_CONFIG = "/procedure-config/add";

//Safety Voice Scoring

export const SAFETY_VOICE_SCORING = "/safety-voice-scoring";
export const SAFETY_VOICE_SCORING_DETAIL = "/safety-voice-scoring/detail";
// Hiradc
export const LIST_HIRADC = "/hiradc-submission";
export const ADD_HIRADC = "/add-hiradc";
export const DETAIL_HIRADC = "/detail-hiradc";
export const ARCHIVED_HIRADC = "/archived-hiradc";
export const HIRADC_LIST = "/list-hiradc";
//Dashboard Dynamic
export const DASHBOARD_DYNAMIC = "/dashboard-list";
export const DASHBOARD_PAGE = "/dashboard-page";
export const DASHBOARD_CREATE = "/dashboard-list/add";
export const DASHBOARD_EDIT = "/dashboard-list/edit";
