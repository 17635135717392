import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import {
  IGetMedicalDetailRequestData,
  IGETMedicalRequest,
  IGetListMedical,
  types as healthTypes,
} from "./_types";

export const actions = {
  getMedicalRecord: {
    request: (requestData: IDefaultRequestParams<IGetMedicalDetailRequestData>) => ({
      ...requestData,
      type: healthTypes.GET_MEDICAL_RECORD_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_MEDICAL_RECORD_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_MEDICAL_RECORD_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getMedicalList: {
    request: (requestData: IDefaultRequestParams<IGetListMedical>) => ({
      ...requestData,
      type: healthTypes.MEDICAL_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.MEDICAL_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.MEDICAL_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getDetail: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.MEDICAL_DETAIL_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.MEDICAL_DETAIL_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.MEDICAL_DETAIL_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getUser: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_USERS_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_USERS_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_USERS_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getUserDetail: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GET_DETAIL_USER_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.GET_DETAIL_USER_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.GET_DETAIL_USER_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getList: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.LIST_MEDICAL_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.LIST_MEDICAL_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.LIST_MEDICAL_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getMedicine: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.LIST_MEDICINE_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.LIST_MEDICINE_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.LIST_MEDICINE_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  submitMedical: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.SUBMIT_MEDICAL_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.SUBMIT_MEDICAL_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.SUBMIT_MEDICAL_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  submitMedicine: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.SUBMIT_MEDICINE_KEYS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.SUBMIT_MEDICINE_KEYS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.SUBMIT_MEDICINE_KEYS_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createMedical: {
    request: (requestData: IDefaultRequestParams<IGETMedicalRequest>) => ({
      ...requestData,
      type: healthTypes.CREATE_MY_TASK_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: healthTypes.CREATE_MY_TASK_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: healthTypes.CREATE_MY_TASK_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  healthLetter: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.HEALTH_LETTER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  sickLetter: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.SICK_LETTER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  referralLetter: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.REFERRAL_LETTER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  antigenResult: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.ANTIGEN_RESULT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  guaranteeLetter: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.GUARANTEE_LETTER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  reimbursement: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: healthTypes.REIMBURSEMENT_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
};

export async function getUsersDetail(
  username: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(`${urlsEndpoint.GET_DETAIL_USER}/${username}`);
  try {
    const response = await http.custom({
      url: `${urlsEndpoint.GET_DETAIL_USER}/{username}`,
      method: "get",
    });
    // const response = await http.get();
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
