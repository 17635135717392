import { types as detailTypes } from "./_types";

const initialState = {
  attdata: [],
  attMasterDetail: {},
  attDetailData: {},
  actionStatus: "initial",
  moreActionStatus: {
    getDetailAtt: "initial",
    getDetailDataAtt: "initial",
    addDetailAtt: "initial",
    updateDetailAtt: "initial",
  },
  data: null,
  isLoading: true,
};

export const detailReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case detailTypes.GET_ALL_DETAIL_ATT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case detailTypes.GET_ALL_DETAIL_ATT_FAILED:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case detailTypes.GET_ALL_DETAIL_ATT_SUCCESS:
      return {
        ...state,
        data: action.data.data.attdata,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case detailTypes.GET_DETAIL_ATT_DATA_REQUEST:
    case detailTypes.GET_DETAIL_ATT_DATA_FAILED:
    case detailTypes.GET_DETAIL_ATT_DATA_SUCCESS:
      return {
        ...state,
        attDetailData: {
          ...state.attDetailData,
          [action.data?.data?.attdata?.detailAttId]: action.data?.data?.attdata,
        },
        moreActionStatus: {
          ...state.moreActionStatus,
          getDetailDataAtt: action.actionStatus,
        },
        actionStatus: action.actionStatus,
      };
    case detailTypes.CREATE_DETAIL_ATT_REQUEST:
    case detailTypes.CREATE_DETAIL_ATT_FAILED:
    case detailTypes.CREATE_DETAIL_ATT_SUCCESS:
      return {
        ...state,
        actionStatus: action.actionStatus,
        moreActionStatus: {
          ...state.moreActionStatus,
          addDetailAtt: action.actionStatus,
        },
      };
    case detailTypes.UPDATE_DETAIL_ATT_REQUEST:
    case detailTypes.UPDATE_DETAIL_ATT_FAILED:
    case detailTypes.UPDATE_DETAIL_ATT_SUCCESS:
      return {
        ...state,
        moreActionStatus: {
          ...state.moreActionStatus,
          updateDetailAtt: action.actionStatus,
        },
        actionStatus: action.actionStatus,
      };
    default:
      return state;
  }
};
