export const types = {
  GET_PROFILE_REQUEST: "changePassword/CHANGE_PASSWORD_REQUEST",
};

export interface IPayloadUpdatePass {
  nik: string;
  password: string;
  isReset: boolean;
}

export interface IChangePassword {
  currentPassword: string;
  newPassword: string;
  confirmPassword?: string;
}
