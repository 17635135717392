import { DefaultActionStatus } from "../../store/defaultTypes";
import { types as sidebarTypes, IMyTask } from "./_types";

export const actions = {
  getMyTaskCounter: {
    request: () => ({
      type: sidebarTypes.GET_MYTASK_COUNTER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: IMyTask[]) => ({
      type: sidebarTypes.GET_MYTASK_COUNTER_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (data: any) => ({
      type: sidebarTypes.GET_MYTASK_COUNTER_FAILURE,
      data,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};
