import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./store/configureStore";
import RoutePage from "./_router";
import i18en from "./_i18n";
import "./index.css";

import "./assets/styles/app.less";

ReactDOM.render(
  <div className="App">
    <I18nextProvider i18n={i18en}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <React.StrictMode>
            <BrowserRouter>
              <RoutePage />
            </BrowserRouter>
          </React.StrictMode>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </div>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
