import { types } from "./_types";

const initialState = {
  sites: [],
  actionStatus: "initial",
  moreActionStatus: {
    addSite: "initial",
  },
};

export const siteReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_SITE_REQUEST:
    case types.GET_SITE_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
      };
    case types.GET_SITE_SUCCESS:
      return {
        ...state,
        sites: Array.isArray(action.data.data.site) ? action.data.data.site : [],
        actionStatus: action.actionStatus,
      };
    default:
      return state;
  }
};
