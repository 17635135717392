export const types = {
  GET_DASHBOARD_LIST_REQUEST: "dashboard/GET_DASHBOARD_LIST_REQUEST",
  GET_DASHBOARD_LIST_SUCCESS: "dashboard/GET_DASHBOARD_LIST_SUCCESS",
  GET_DASHBOARD_LIST_FAILURE: "dashboard/GET_DASHBOARD_LIST_FAILURE",

  CREATE_DASHBOARD_REQUEST: "dashboard/CREATE_DASHBOARD_REQUEST",
  CREATE_DASHBOARD_SUCCESS: "dashboard/CREATE_DASHBOARD_SUCCESS",
  CREATE_DASHBOARD_FAILURE: "dashboard/CREATE_DASHBOARD_FAILURE",

  DELETE_DASHBOARD_REQUEST: "dashboard/DELETE_DASHBOARD_REQUEST",
  DELETE_DASHBOARD_SUCCESS: "dashboard/DELETE_DASHBOARD_SUCCESS",
  DELETE_DASHBOARD_FAILURE: "dashboard/DELETE_DASHBOARD_FAILURE",

  UPDATE_DASHBOARD_REQUEST: "dashboard/UPDATE_DASHBOARD_REQUEST",
  UPDATE_DASHBOARD_SUCCESS: "dashboard/UPDATE_DASHBOARD_SUCCESS",
  UPDATE_DASHBOARD_FAILURE: "dashboard/UPDATE_DASHBOARD_FAILURE",

  UPDATE_PRIORITY_DASHBOARD_REQUEST: "dashboard/UPDATE_PRIORITY_DASHBOARD_REQUEST",
  UPDATE_PRIORITY_DASHBOARD_SUCCESS: "dashboard/UPDATE_PRIORITY_DASHBOARD_SUCCESS",
  UPDATE_PRIORITY_DASHBOARD_FAILURE: "dashboard/UPDATE_PRIORITY_DASHBOARD_FAILURE",

  DETAIL_DASHBOARD_REQUEST: "dashboard/DETAIL_DASHBOARD_REQUEST",
  DETAIL_DASHBOARD_SUCCESS: "dashboard/DETAIL_DASHBOARD_SUCCESS",
  DETAIL_DASHBOARD_FAILURE: "dashboard/DETAIL_DASHBOARD_FAILURE",

  GET_ALL_DASHBOARD_LIST_REQUEST: "dashboard/GET_ALL_DASHBOARD_LIST_REQUEST",
  GET_ALL_DASHBOARD_LIST_SUCCESS: "dashboard/GET_ALL_DASHBOARD_LIST_SUCCESS",
  GET_ALL_DASHBOARD_LIST_FAILURE: "dashboard/GET_ALL_DASHBOARD_LIST_FAILURE",
};

export interface IGetFormData {
  page: number;
  limit: number;
  filter: {
    keyword: string;
  };
  orderBy: any[];
  direction: string;
}

export interface IFormDashboard {
  id?: number;
  title: string;
  description: string;
  roles: string;
  tabs: any[];
}
