import { IDetailAttValues } from "./DetailAttendance/types";

export const types = {
  GET_ALL_DETAIL_ATT_REQUEST: "detail/GET_ALL_DETAIL_ATT_REQUEST",
  GET_ALL_DETAIL_ATT_SUCCESS: "detail/GET_ALL_DETAIL_ATT_SUCCESS",
  GET_ALL_DETAIL_ATT_FAILED: "detail/GET_ALL_DETAIL_ATT_FAILED",

  GET_DETAIL_ATT_DATA_REQUEST: "detail/GET_DETAIL_ATT_DATA_REQUEST",
  GET_DETAIL_ATT_DATA_SUCCESS: "detail/GET_DETAIL_ATT_DATA_SUCCESS",
  GET_DETAIL_ATT_DATA_FAILED: "detail/GET_DETAIL_ATT_DATA_FAILED",

  CREATE_DETAIL_ATT_REQUEST: "detail/CREATE_DETAIL_ATT_REQUEST",
  CREATE_DETAIL_ATT_SUCCESS: "detail/CREATE_DETAIL_ATT_SUCCESS",
  CREATE_DETAIL_ATT_FAILED: "detail/CREATE_DETAIL_ATT_FAILED",

  UPDATE_DETAIL_ATT_REQUEST: "detail/UPDATE_DETAIL_ATT_REQUEST",
  UPDATE_DETAIL_ATT_SUCCESS: "detail/UPDATE_DETAIL_ATT_SUCCESS",
  UPDATE_DETAIL_ATT_FAILED: "detail/UPDATE_DETAIL_ATT_FAILED",
};

export interface IGetDetailAttData {
  token: string;
  attId: number;
}

export interface IGetListDetailAttData {
  attId: number;
  detailAttId: number;
}

export interface ICreateDetailAttData extends IDetailAttValues {
  attId: number;
}

export interface IUpdateDetailAttData extends IDetailAttValues {
  detailAttId: number;
}
