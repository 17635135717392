export const types = {
  GET_MEDICINE_KEYS_REQUEST: "medicine/GET_MEDICINE_KEYS_REQUEST",
  GET_MEDICINE_KEYS_SUCCESS: "medicine/GET_MEDICINE_KEYS_SUCCESS",
  GET_MEDICINE_KEYS_FAILED: "medicine/GET_MEDICINE_KEYS_FAILED",

  GET_MEDICINE_LOG_REQUEST: "medicine/GET_MEDICINE_LOG_REQUEST",
  GET_MEDICINE_LOG_SUCCESS: "medicine/GET_MEDICINE_LOG_SUCCESS",
  GET_MEDICINE_LOG_FAILED: "medicine/GET_MEDICINE_LOG_FAILED",

  GET_DETAIL_MEDICINE_REQUEST: "medicine/GET_DETAIL_MEDICINE_REQUEST",
  GET_DETAIL_MEDICINE_SUCCESS: "medicine/GET_DETAIL_MEDICINE_SUCCESS",
  GET_DETAIL_MEDICINE_FAILED: "medicine/GET_DETAIL_MEDICINE_FAILED",

  GET_MEDICINE_CODE_REQUEST: "medicine/GET_MEDICINE_CODE_REQUEST",
  GET_MEDICINE_CODE_SUCCESS: "medicine/GET_MEDICINE_CODE_SUCCESS",
  GET_MEDICINE_CODE_FAILED: "medicine/GET_MEDICINE_CODE_FAILED",

  EXPORT_MEDICINE_REQUEST: "medicine/EXPORT_MEDICINE_REQUEST",
  EXPORT_MEDICINE_SUCCESS: "medicine/EXPORT_MEDICINE_SUCCESS",
  EXPORT_MEDICINE_FAILED: "medicine/EXPORT_MEDICINE_FAILED",

  EXPORT_MEDICAL_RECORD_REQUEST: "medicine/EXPORT_MEDICAL_RECORD_REQUEST",
  EXPORT_MEDICAL_RECORD_SUCCESS: "medicine/EXPORT_MEDICAL_RECORD_SUCCESS",
  EXPORT_MEDICAL_RECORD_FAILED: "medicine/EXPORT_MEDICAL_RECORD_FAILED",
};

export interface IGetMedicineRequestData {
  page: number;
  limit: number;
  start: number;
  requestForm: string;
  keyword: string | null;
  orderBy: {
    column: string[];
    direction: string | null;
  };
  filter: object;
}

export interface IGetLogMedicineRequestData {
  start: number;
  limit: number;
  page: number;
  filter: object;
  orderBy: {
    column: string[];
    direction: string | null;
  };
}

export interface IGetDetailMedicine {
  medicineId: number;
  medicineCode: string;
  medicineName: string;
  description: string;
  minimumStock: number;
  type: string;
  price: number;
  action: string;
  qty: number;
  expiredDate: string;
  notes: string;
  isActive: boolean;
}

export interface IPayloadUpdateMedicine {
  medicineId: number;
  action: string;
  qty: number;
  expiredDate: string;
  notes: string;
}

export interface IPayloadTypeList {
  start: number;
  limit: number;
}

export interface IPayloadExportMedicine {
  start: number;
  limit: number;
  page: number;
  filter: object;
  orderBy: {
    column: string[];
    direction: string | null;
  };
}

export interface IPayloadCreateMedicine {
  medicineId: number;
  medicineCode: string;
  medicineName: string;
  description: string;
  qty: number;
  minimumStock: number;
  type: string;
  price: number;
  expiredDate: string;
  notes: string;
  patientId: number;
  isActive: boolean;
}
