import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import {
  ICreateFormRequestData,
  IGetFlowDataSearch,
  IGetFormData,
  IGetFormDataArchive,
  IGetFormDataSearch,
  IGetFormDetailRequestData,
  IGETFormFlowRequest,
  IGetFormsRequestData,
  IUpdateFormRequestData,
  IGetFormDataMasters,
  IGetFormDataCompany,
  types as generalTypes,
} from "./_types";

export const actions = {
  getForms: {
    request: (requestData: IDefaultRequestParams<IGetFormsRequestData>) => ({
      ...requestData,
      type: generalTypes.GET_FORMS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_FORMS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_FORMS_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getFormDraft: {
    request: (requestData: IDefaultRequestParams<IGetFormDataArchive>) => ({
      type: generalTypes.GET_FORMS_DRAFT_REQUEST,
      ...requestData,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_FORMS_DRAFT_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_FORMS_DRAFT_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getFormPublish: {
    request: (requestData: IDefaultRequestParams<IGetFormData>) => ({
      type: generalTypes.GET_FORMS_PUBLISH_REQUEST,
      ...requestData,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_FORMS_PUBLISH_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_FORMS_PUBLISH_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getFormSearch: {
    request: (requestData: IDefaultRequestParams<IGetFormDataSearch>) => ({
      type: generalTypes.GET_FORMS_SEARCH_REQUEST,
      ...requestData,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_FORMS_SEARCH_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_FORMS_SEARCH_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getFlowSearch: {
    request: (requestData: IDefaultRequestParams<IGetFlowDataSearch>) => ({
      type: generalTypes.GET_FLOW_SEARCH_REQUEST,
      ...requestData,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_FLOW_SEARCH_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_FLOW_SEARCH_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getFormMaster: {
    request: (requestData: IDefaultRequestParams<IGetFormsRequestData>) => ({
      ...requestData,
      type: generalTypes.GET_FORMS_MASTER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_FORMS_MASTER_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_FORMS_MASTER_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getMasterDatas: {
    request: (requestData: IDefaultRequestParams<IGetFormDataMasters>) => ({
      ...requestData,
      type: generalTypes.GET_MASTER_DATAS_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_MASTER_DATAS_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_MASTER_DATAS_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getMasterDataSafety: {
    request: (requestData: IDefaultRequestParams<IGetFormDataMasters>) => ({
      ...requestData,
      type: generalTypes.GET_MASTER_SAFETY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_MASTER_SAFETY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_MASTER_SAFETY_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListEquip: {
    request: (requestData: IDefaultRequestParams<null>) => ({
      ...requestData,
      type: generalTypes.GET_LIST_EQUIP_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_LIST_EQUIP_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_LIST_EQUIP_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getFormDetail: {
    request: (requestData: IDefaultRequestParams<IGetFormDetailRequestData>) => ({
      ...requestData,
      type: generalTypes.GET_FORM_DETAIL_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_FORM_DETAIL_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_FORM_DETAIL_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  createForm: {
    request: (requestData: IDefaultRequestParams<ICreateFormRequestData>) => ({
      ...requestData,
      type: generalTypes.CREATE_FORM_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    failure: (data: any) => ({
      type: generalTypes.CREATE_FORM_FAILURE,
      data,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
    success: (data: any) => ({
      type: generalTypes.CREATE_FORM_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
  },
  updateForm: {
    request: (requestData: IDefaultRequestParams<IUpdateFormRequestData>) => ({
      ...requestData,
      type: generalTypes.UPDATE_FORM_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.UPDATE_FORM_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.UPDATE_FORM_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListFormFlow: {
    request: (requestData: IDefaultRequestParams<IGETFormFlowRequest>) => ({
      ...requestData,
      type: generalTypes.GET_LIST_FORM_FLOW_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_LIST_FORM_FLOW_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_LIST_FORM_FLOW_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListCompany: {
    request: (requestData: IDefaultRequestParams<IGetFormDataCompany>) => ({
      ...requestData,
      type: generalTypes.GET_COMPANY_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_COMPANY_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_COMPANY_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getAllFormMaster: {
    request: (requestData: IDefaultRequestParams<IGetFormsRequestData>) => ({
      ...requestData,
      type: generalTypes.GET_ALL_FORMS_MASTER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: generalTypes.GET_ALL_FORMS_MASTER_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: generalTypes.GET_ALL_FORMS_MASTER_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};

// action without redux //
// ++++++++++ flow form  ++++++++

export async function deleteFormFlowAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_FORM_FLOW);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response?.data?.formFlow,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function addFormFlowAction(
  payload: IGETFormFlowRequest
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await http.custom({
      url: urlsEndpoint.CREATE_FORM_FLOW,
      method: "post",
      data: {
        flowName: payload?.flowName,
        description: payload?.description,
        isRepetitiveFlow: payload?.isRepetitiveFlow,
        flowFrequency: payload?.flowFrequency,
        flowRepetition: payload?.flowRepetition,
        flowDate: payload?.flowDate,
        isActive: payload?.isActive,
        typeFlow: payload?.typeFlow,
        icon: payload?.icon,
        forms: payload?.datas ?? [],
        code: payload?.code,
        categoryId: payload?.categoryId,
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateFormFlowAction(
  payload: IGETFormFlowRequest
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await http.custom({
      url: urlsEndpoint.UPDATE_FORM_FLOW,
      method: "post",
      data: {
        id: payload?.id,
        flowName: payload?.flowName,
        description: payload?.description,
        isRepetitiveFlow: payload?.isRepetitiveFlow,
        flowFrequency: payload?.flowFrequency,
        flowRepetition: payload?.flowRepetition,
        flowDate: payload?.flowDate,
        isActive: payload?.isActive,
        typeFlow: payload?.typeFlow,
        icon: payload?.icon,
        forms: payload?.datas ?? [],
        code: payload?.code,
        categoryId: payload?.categoryId,
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getDetailFormFlow(
  payload: IGETFormFlowRequest
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");

  try {
    const response = await http.custom({
      url: urlsEndpoint.GET_DETAIL_FORM_FLOW,
      method: "post",
      data: {
        id: payload?.id,
        detailId: payload?.detailId,
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    const successResponse = {
      success: true,
      data: response?.data?.formList,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

// +++++++++++++++++++++++++++++++++

export async function deleteFormAction(
  formVersionId: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_FORM);
  //  const http = new HttpService(`${urlsEndpoint.DELETE_MASTER_KEY_VALID}/${formVersionId}`);
  try {
    const response = await http.delete(formVersionId);
    const successResponse = {
      success: true,
      data: response?.data?.formVers,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
export async function deleteFormArchive(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(`${urlsEndpoint.RESTORE_FORM}/${id}`);
  //  const http = new HttpService(`${urlsEndpoint.DELETE_MASTER_KEY_VALID}/${formVersionId}`);
  try {
    const response = await http.post();
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
export async function deleteCompany(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_COMPANY);
  //  const http = new HttpService(`${urlsEndpoint.DELETE_MASTER_KEY_VALID}/${formVersionId}`);
  try {
    const response = await http.delete(id);
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
export async function restoreFormAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  // const http = new HttpService(urlsEndpoint.RESTORE_FORM_FLOW);
  const http = new HttpService(`${urlsEndpoint.RESTORE_FORM_FLOW}/${id}`);
  try {
    const response = await http.post();
    const successResponse = {
      success: true,
      data: response,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}
export async function addCompanyAction(
  payload: any
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await http.custom({
      url: urlsEndpoint.CREATE_COMPANY,
      method: "post",
      data: {
        companyName: payload?.companyName,
        subcont: payload?.subcont,
        companyEmail: payload.companyEmail,
        companyPhone: payload.companyPhone,
        companyAddress: payload.companyAddress,
        companyType: payload.companyType,
        status: payload.status,
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateCompanyAction(
  payload: any
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await http.custom({
      url: urlsEndpoint.UPDATE_COMPANY,
      method: "post",
      data: {
        companyDetailId: payload?.id,
        companyName: payload?.companyName,
        username: payload?.username,
        password: payload.password,
        companyEmail: payload.companyEmail,
        companyPhone: payload.companyPhone,
        companyAddress: payload.companyAddress,
        companyType: payload.companyType,
        status: payload.status,
        subcont: payload?.subcont,
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });

    const successResponse = {
      success: true,
      data: response?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

interface IGetFormDetails {
  formId: number;
  formVersionId: number | null;
}

export async function getFormDetails(
  formIds: IGetFormDetails[]
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await Promise.all(
      formIds.map((item) =>
        http.custom({
          url: urlsEndpoint.GET_FORM_DETAIL,
          method: "POST",
          data: {
            formId: item.formId,
            formVersionId: item.formVersionId,
          },
        })
      )
    );

    const successResponse = {
      success: true,
      error: false,
      data: response,
    };

    return successResponse;
  } catch (err) {
    console.error(err);
    const errorResponse = {
      success: false,
      error: true,
      data: err,
    };

    return errorResponse;
  }
}
