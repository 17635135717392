import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { IPayloadGetProfile, IPayloadUpdateProfile, types as profileTypes } from "./_types";

export async function getDetailUser(
  userName: string,
  token: string
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await http.custom({
      url: `${urlsEndpoint.GET_DETAIL_AUTH_USERS}/${userName}`,
      method: "get",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const successResponse = {
      success: true,
      data: response?.user,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateDetailUser(
  payload: IPayloadUpdateProfile
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.EDIT_USER);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response?.message,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function uploadPhotoProfile(
  payload: any
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await http.upload({
      url: urlsEndpoint.UPLOAD_FILE,
      method: "post",
      data: payload,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const successResponse = {
      success: true,
      data: response?.data?.data,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export const profileActions = {
  getProfile: {
    request: (requestData: IDefaultRequestParams<IPayloadGetProfile>) => ({
      ...requestData,
      type: profileTypes.GET_PROFILE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: profileTypes.GET_PROFILE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: profileTypes.GET_PROFILE_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};
