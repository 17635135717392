import { types } from "./_types";
import { IMyTask } from "./_types";

interface IINITIAL_STATE {
  tasksCounter: IMyTask[];
  isLoading: boolean;
  actionStatus: string;
}

const INITIAL_STATE: IINITIAL_STATE = {
  tasksCounter: [],
  isLoading: false,
  actionStatus: "initial",
};

export const sidebarReducers = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case types.GET_MYTASK_COUNTER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_MYTASK_COUNTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tasksCounter: action.data,
        actionStatus: action.actionStatus,
      };
    case types.GET_MYTASK_COUNTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    default:
      return state;
  }
};
