import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { actions as userActions } from "./_actions";
import {
  IGetMasterKey,
  ICreateMasterKeyCategory,
  ICreateMasterAttData,
  ICreateMasterKeyData,
  ICreateMasterLocData,
  IGetAllLocData,
  IGetAllSiteData,
  IGetLocationDetailData,
  IGetMasterAttData,
  IGetMasterAttDetailData,
  IGetMasterKeyData,
  IGetMasterKeyDetailData,
  IMasterEquipmentRequestData,
  IMasterLocationTypeRequestData,
  IMasterSiteRequestData,
  IMasterSubLocationData,
  IUpdateMasterAttData,
  IUpdateMasterKeyData,
  IUpdateMasterLocData,
  IGetMasterArchive,
  IGetMasterEquipmentDetailLog,
  types as masterTypes,
} from "./_types";

export const watcherMaster = () => [
  takeLatest(masterTypes.GET_MASTER_KEYS_REQUEST, workerGetForms),
  takeLatest(masterTypes.GET_MASTER_KEYS_CATEGORY_REQUEST, workerGetFormsCategori),
  takeLatest(masterTypes.GET_MASTER_KEYS_ARCHIVE_REQUEST, workerGetArchiveCategori),
  takeLatest(masterTypes.GET_MASTER_KEY_DETAIL_REQUEST, workerGetFormDetail),
  takeLatest(masterTypes.CREATE_MASTER_KEY_REQUEST, workerCreateForm),
  takeLatest(masterTypes.CREATE_MASTER_CATEGORY_REQUEST, workerCreateCategory),
  takeLatest(masterTypes.UPDATE_MASTER_KEY_REQUEST, workerUpdateForm),
  //------------------
  takeLatest(masterTypes.GET_ALL_MASTER_ATT_REQUEST, workerGetMasterAtt),
  takeLatest(masterTypes.GET_MASTER_ATT_DETAIL_REQUEST, workerGetMasterAttDetail),
  takeLatest(masterTypes.CREATE_MASTER_ATT_REQUEST, workerCreateMasterAtt),
  takeLatest(masterTypes.UPDATE_MASTER_ATT_REQUEST, workerUpdateMasterAtt),
  //------------------
  takeLatest(masterTypes.GET_ALL_LOCATION_REQUEST, workerGetAllLoc),
  takeLatest(masterTypes.GET_LOCATION_BY_ID_REQUEST, workerGetLocDetail),
  takeLatest(masterTypes.CREATE_DATA_LOCATION_REQUEST, workerCreateLoc),
  takeLatest(masterTypes.UPDATE_DATA_LOCATION_REQUEST, workerUpdateLoc),
  takeLatest(masterTypes.GET_LIST_LOC_REQUEST, workerListLoc),

  takeLatest(masterTypes.SITE_LIST_REQUEST, workerSiteList),
  takeLatest(masterTypes.RISK_LIST_REQUEST, workerRiskList),
  takeLatest(masterTypes.SHIFT_LIST_REQUEST, workerShiftList),
  takeLatest(masterTypes.EQUIPMENT_LIST_REQUEST, workerEquipmentList),
  takeLatest(masterTypes.EQUIPMENT_TYPE_LIST_REQUEST, workerEquipmentType),
  takeLatest(masterTypes.EQUIPMENT_ADD_REQUEST, workerAddEquipmentType),
  takeLatest(masterTypes.EQUIPMENT_UPDATE_REQUEST, workerEditEquipmentType),
  takeLatest(masterTypes.EQUIPMENT_DELETE_REQUEST, workerDeleteEquipmentType),
  takeLatest(masterTypes.EQUIPMENT_DETAIL_REQUEST, workerDetailEquipment),
  takeLatest(masterTypes.EQUIPMENT_CHILD_REQUEST, workerChildEquipment),

  takeLatest(masterTypes.LOCATION_LIST_REQUEST, workerLocationList),
  takeLatest(masterTypes.LOCATION_TYPE_LIST_REQUEST, workerLocationType),
  takeLatest(masterTypes.GET_SITE_REQUEST, workerGetAllSite),

  takeEvery(masterTypes.SUBLOCATION_LIST_REQUEST, workerSubLocation),
  takeLatest(masterTypes.SUBLOCATION_BY_ID_REQUEST, workerSubLocationById),
  takeLatest(masterTypes.CREATE_SUBLOCATION_REQUEST, workerCreateSubLocation),
  takeLatest(masterTypes.DELETE_SUBLOCATION_REQUEST, workerDeleteSubLocation),
  takeLatest(masterTypes.UPDATE_SUBLOCATION_REQUEST, workerUpdateSubLocation),

  //Tools
  takeLatest(masterTypes.MASTER_TOOLS_LIST_REQUEST, workerGetMasterTools),
  takeLatest(masterTypes.MASTER_TOOLS_ADD_REQUEST, workerAddMasterTools),
  takeLatest(masterTypes.MASTER_TOOLS_EDIT_REQUEST, workerEditMasterTools),
  takeLatest(masterTypes.MASTER_TOOLS_DELETE_REQUEST, workerDeleteMasterTools),
  takeLatest(masterTypes.MASTER_TYPE_DATAS_LIST_REQUEST, worketGetMasterTypesDataList),

  takeLatest(masterTypes.EQUIPMENT_CHILD_LIST_REQUEST, worketGetMasterEquipmentDataList),
  takeLatest(masterTypes.EQUIPMENT_CATEGORY_LIST_REQUEST, worketGetEquipmentCategoryDataList),
  takeLatest(masterTypes.ADD_CATEGORY_EQUIPMENT_REQUEST, workerCreateCategoryEquipment),
  takeLatest(masterTypes.HISTORY_EQUIPMENT_DETAIL_REQUEST, worketGetMasterEquipmentLogChildList),
];

function* workerGetForms({ payload }: IDefaultRequestAction<IGetMasterKeyData>): any {
  const http = new HttpService(urlsEndpoint.GET_MASTER_KEYS);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getForms.success(response));
  } catch (err) {
    yield put(userActions.getForms.failure(err));
  }
}
function* workerGetFormsCategori({ payload }: IDefaultRequestAction<IGetMasterKey>): any {
  const http = new HttpService(urlsEndpoint.GET_MASTER_KEYS_CATEGORY);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getForms.success(response));
  } catch (err) {
    yield put(userActions.getForms.failure(err));
  }
}

function* workerGetArchiveCategori({ payload }: IDefaultRequestAction<IGetMasterArchive>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_MASTER_KEYS_CATEGORY,
      method: "post",
      data: {
        start: payload?.start,
        limit: payload?.limit,
        search: payload?.search,
        status: payload?.status,
        categoryId: payload?.categoryId,
        page: 1,
        requestForm: "mobile",
        orderBy: {
          column: ["value"],
          direction: "ASC",
        },
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    yield put(userActions.getForms.success(response));
  } catch (err) {
    yield put(userActions.getForms.failure(err));
  }
}

function* workerGetFormDetail({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetMasterKeyDetailData>): any {
  const http = new HttpService(urlsEndpoint.GET_MASTER_KEY_DETAIL);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getFormDetail.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getFormDetail.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerCreateForm({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<ICreateMasterKeyData>): any {
  const http = new HttpService(urlsEndpoint.CREATE_MASTER_KEYS);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.createForm.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.createForm.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerCreateCategory({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<ICreateMasterKeyCategory>): any {
  const http = new HttpService(urlsEndpoint.CREATE_MASTER_KEY);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.createForm.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.createForm.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerUpdateForm({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IUpdateMasterKeyData>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_MASTER_KEY);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.updateForm.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.updateForm.failure(err));
    yield callback(onFailure, err);
  }
}
//--------------------
//batas
//--------------------
function* workerGetMasterAtt({ payload }: IDefaultRequestAction<IGetMasterAttData>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_ALL_MASTER_ATT,
      method: "post",
      data: {
        start: payload?.start,
        limit: payload?.limit,
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    yield put(userActions.getMasterAtt.success(response));
  } catch (err) {
    yield put(userActions.getMasterAtt.failure(err));
  }
}
function* workerGetMasterAttDetail({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetMasterAttDetailData>): any {
  const http = new HttpService(urlsEndpoint.GET_MASTER_ATT_DETAIL);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getMasterAttDetail.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getMasterAttDetail.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetMasterTools({ payload, onFailure, onSuccess }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.GET_ALL_TOOLS);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getListTools.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getListTools.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerAddMasterTools({ payload, onFailure, onSuccess }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.CREATE_DATA_TOOLS);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerEditMasterTools({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_DATA_TOOLS);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerDeleteMasterTools({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.DELETE_DATA_TOOLS);
  try {
    const response = yield call(http.delete, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerCreateMasterAtt({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<ICreateMasterAttData>): any {
  const http = new HttpService(urlsEndpoint.CREATE_MASTER_ATT);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.createMasterAtt.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.createMasterAtt.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerUpdateMasterAtt({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IUpdateMasterAttData>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_MASTER_ATT);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.updateMasterAtt.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.updateMasterAtt.failure(err));
    yield callback(onFailure, err);
  }
}
//--------------------
//batas
//--------------------
function* workerGetAllLoc({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetAllLocData>): any {
  const http = new HttpService(urlsEndpoint.GET_ALL_LOCATION);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetAllSite({ payload }: IDefaultRequestAction<IGetAllSiteData>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_ALL_SITES,
      method: "post",
      data: {
        start: payload?.start,
        limit: payload?.limit,
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });

    yield put(userActions.getMasterSite.success(response));
  } catch (err) {
    yield put(userActions.getMasterSite.failure(err));
  }
}
function* workerGetLocDetail({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetLocationDetailData>): any {
  const http = new HttpService(urlsEndpoint.GET_LOCATION_BY_ID);
  try {
    // const response = yield call(http.post, payload);
    const response = yield call(http.get, payload?.id || "");

    yield put(userActions.getLocDetail.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getLocDetail.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerCreateLoc({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<ICreateMasterLocData>): any {
  const http = new HttpService(urlsEndpoint.CREATE_DATA_LOCATION);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.createLoc.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.createLoc.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerUpdateLoc({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IUpdateMasterLocData>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_DATA_LOCATION);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.updateLoc.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.updateLoc.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerListLoc({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_LIST_LOC);
  try {
    const response = yield call(http.find, payload);
    yield put(userActions.getListLoc.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getListLoc.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerSiteList({ payload }: IDefaultRequestAction<IMasterSiteRequestData>): any {
  const http = new HttpService("https://private-cb7e1-sentinel12.apiary-mock.com/Site/list");
  try {
    const response = yield call(http.find, payload);
    yield put(userActions.getSiteList.success(response));
  } catch (err) {
    yield put(userActions.getSiteList.failure(err));
  }
}

function* workerRiskList({ payload }: IDefaultRequestAction<IMasterSiteRequestData>): any {
  const http = new HttpService("https://private-cb7e1-sentinel12.apiary-mock.com/Risk/list");
  try {
    const response = yield call(http.find, payload);
    yield put(userActions.getRiskList.success(response));
  } catch (err) {
    yield put(userActions.getRiskList.failure(err));
  }
}

function* workerShiftList({ payload }: IDefaultRequestAction<IMasterSiteRequestData>): any {
  const http = new HttpService("https://private-cb7e1-sentinel12.apiary-mock.com/shift/list");
  try {
    const response = yield call(http.find, payload);
    yield put(userActions.getShiftList.success(response));
  } catch (err) {
    yield put(userActions.getShiftList.failure(err));
  }
}

function* workerLocationList({ payload }: IDefaultRequestAction<IMasterSiteRequestData>): any {
  const http = new HttpService("https://private-cb7e1-sentinel12.apiary-mock.com/location/list");
  try {
    const response = yield call(http.find, payload);
    yield put(userActions.getLocationList.success(response));
  } catch (err) {
    yield put(userActions.getLocationList.failure(err));
  }
}

// equipment worker

function* workerEquipmentList({
  payload,
}: IDefaultRequestAction<IMasterEquipmentRequestData>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_ALL_EQUIPMENT,
      method: "post",
      data: {
        start: payload?.start,
        limit: payload?.limit,
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    yield put(userActions.getEquipmentList.success(response));
  } catch (err) {
    yield put(userActions.getEquipmentList.failure(err));
  }
}

function* workerEquipmentType({
  payload,
  onSuccess,
}: IDefaultRequestAction<IMasterEquipmentRequestData>): any {
  const http = new HttpService(urlsEndpoint.GET_ALL_EQUIPMENT);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getEquipmentType.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getEquipmentType.failure(err));
  }
}

function* workerAddEquipmentType({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.CREATE_EQUIPMENT_TYPE);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerEditEquipmentType({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_EQUIPMENT_TYPE);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerDeleteEquipmentType({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.DELETE_EQUIPMENT_TYPE);
  try {
    const response = yield call(http.delete, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerDetailEquipment({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.GET_DETAIL_CHILD_EQUIPMENT);
  try {
    const response = yield call(http.get, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerChildEquipment({ payload, onSuccess, onFailure }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.GET_CHILD_EQUIPMENT);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerLocationType({
  payload,
}: IDefaultRequestAction<IMasterLocationTypeRequestData>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_ALL_LOCATION_TYPE,
      method: "post",
      data: {
        start: payload?.start,
        limit: payload?.limit,
      },
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    yield put(userActions.getLocationTypeList.success(response));
  } catch (err) {
    yield put(userActions.getLocationTypeList.failure(err));
  }
}

function* workerSubLocation({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IMasterSubLocationData>): any {
  const http = new HttpService(urlsEndpoint.GET_ALL_SUBLOCATION);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerSubLocationById({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService("");
  try {
    const response = yield call(http.custom, {
      url: urlsEndpoint.GET_All_MASTER,
      method: "get",
      headers: {
        Authorization: `Bearer ${payload?.token}`,
      },
    });
    yield put(userActions.getSubLocationById.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerCreateSubLocation({
  payload,
  onSuccess,
  onFailure,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.CREATE_SUBLOCATION);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerDeleteSubLocation({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.DELETE_SUBLOCATION);
  try {
    const response = yield call(http.delete, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerUpdateSubLocation({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_SUBLOCATION);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* worketGetMasterTypesDataList({ onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService("");

  try {
    const response = yield call(http.custom, {
      method: "get",
      baseURL: process.env.REACT_APP_API_URL,
      url: urlsEndpoint.GET_All_MASTER,
    });
    yield put(userActions.getMasterTypesDataList.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getMasterTypesDataList.failure(err));
    yield callback(onFailure, err);
  }
}

function* worketGetMasterEquipmentDataList({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.LIST_CHILD_EQUIPMENT);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getChildEquipment.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getChildEquipment.failure(err));
    yield callback(onFailure, err);
  }
}

function* worketGetMasterEquipmentLogChildList({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IGetMasterEquipmentDetailLog>): any {
  const http = new HttpService(urlsEndpoint.GET_LOG_DETAIL_EQUIPMENT);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getHistoryEquipmentDetail.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getHistoryEquipmentDetail.failure(err));
    yield callback(onFailure, err);
  }
}

function* worketGetEquipmentCategoryDataList({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.LIST_CATEGORY_QUIPMENT);
  try {
    const response = yield call(http.post, payload);
    yield put(userActions.getCategory.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(userActions.getCategory.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerCreateCategoryEquipment({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.CREATE_CATEGORY_EQUIPMENT);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}
