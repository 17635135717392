import {
  Reservation,
  MyTaskDetail,
  FormFlow,
  Deviation,
  DeviationFormObject,
  ParentData,
} from "../Health/_types";

export const types = {
  GET_SAFETY_VOICE_SCORE_LIST_REQUEST: "safetyVoiceScoring/GET_SAFETY_VOICE_SCORE_LIST_REQUEST",
  GET_SAFETY_VOICE_SCORE_LIST_SUCCESS: "safetyVoiceScoring/GET_SAFETY_VOICE_SCORE_LIST_SUCCESS",
  GET_SAFETY_VOICE_SCORE_LIST_FAILED: "safetyVoiceScoring/GET_SAFETY_VOICE_SCORE_LIST_FAILED",
  GET_SAFETY_VOICE_SCORE_DETAIL_REQUEST: "safetyVoiceScoring/GET_SAFETY_VOICE_SCORE_DETAIL_REQUEST",
  GET_SAFETY_VOICE_SCORE_DETAIL_SUCCESS: "safetyVoiceScoring/GET_SAFETY_VOICE_SCORE_DETAIL_SUCCESS",
  GET_SAFETY_VOICE_SCORE_DETAIL_FAILED: "safetyVoiceScoring/GET_SAFETY_VOICE_SCORE_DETAIL_FAILED",
};

export interface IGetsafetyVoiceScoringRequestData {
  start: number;
  limit: number;
  page: number;
  keyword: string;
  filter: any;
  orderBy: any;
}

export interface IGetsafetyVoiceScoringFailedRequestData {
  bulkUploadId: number;
}

export interface Score {
  totalScore: number;
  [key: string]: any;
}

export interface FormListSafetyVoice extends FormFlow {
  score: Record<string, Score>;
}

export interface FormFlowSafetyVoice extends Omit<MyTaskDetail, "formList"> {
  formList: FormListSafetyVoice[];
  riskLocationName: string;
  riskSubLocationName: string;
}

export interface ReservationSafetyVoice extends Reservation {
  patientName: string;
  totalScore: number;
  totalScoreA: number;
  totalScoreB: number;
}

export interface SafetyVoiceDataSource {
  formFlow: FormFlowSafetyVoice;
  reservation: ReservationSafetyVoice;
  listDeviation: Deviation[];
  deviationForm: DeviationFormObject;
  fieldId: string | null;
  parentData: ParentData;
}
