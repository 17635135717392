import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { actions as actionForm } from "./_actions";
import { types as masterTypes } from "./_types";

export const watcherArticles = () => [
  takeLatest(masterTypes.UPDATE_PIN_ARTICLES_REQUEST, workerUpdatePin),
  takeLatest(masterTypes.GET_PIN_ARTICLES_REQUEST, workArticles),
  takeLatest(masterTypes.GET_CATEGORY_ARTICLES_REQUEST, workArticlesCategory),
  takeLatest(masterTypes.GET_LIST_ARTICLES_REQUEST, workArticlesGetList),
  takeLatest(masterTypes.GET_LIST_CATEGORY_ARTICLES_REQUEST, workCategoryArticles),
  takeLatest(masterTypes.GET_PIN_ARTICLES_LIST_REQUEST, workerGetPinArticles),
  takeLatest(masterTypes.DELETE_ARTICLES_REQUEST, workerDeleteArticles),
  takeLatest(masterTypes.CREATE_ARTICLES_REQUEST, workerCreateArticle),
  takeLatest(masterTypes.EDIT_ARTICLES_REQUEST, workerUpdateArticle),
  takeLatest(masterTypes.ADD_CATEGORY_ARTICLES_REQUEST, workerCreateCategory),
];

function* workArticles({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_LIST_ARTICLES);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.getPinArticles.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getPinArticles.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerUpdatePin({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.UPDATE_PIN_ARTICLES);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.getUpdatePin.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getUpdatePin.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerUpdateArticle({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.EDIT_ARTICLES);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.getUpdateArticle.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getUpdateArticle.failure(err));
    yield callback(onFailure, err);
  }
}

function* workArticlesCategory({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_CATEGORY_ARTICLES);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.getCatgeoryArticles.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getCatgeoryArticles.failure(err));
    yield callback(onFailure, err);
  }
}
function* workCategoryArticles({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_CATEGORY_ARTICLES);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.getCategory.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getCategory.failure(err));
    yield callback(onFailure, err);
  }
}

function* workArticlesGetList({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_LIST_ARTICLES);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.getListArticles.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getListArticles.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetPinArticles({ onSuccess, onFailure }: any): any {
  const http = new HttpService(urlsEndpoint.PIN_ARTICLES);
  try {
    const response = yield call(http.get, "");
    yield put(actionForm.getArticlePin.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.getArticlePin.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerDeleteArticles({ payload, onSuccess, onFailure }: any): any {
  const http = new HttpService(urlsEndpoint.DELETE_LIST_ARTICLES);
  try {
    const response = yield call(http.delete, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerCreateArticle({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.CREATE_ARTICLES);
  try {
    const response = yield call(http.post, payload);
    yield put(actionForm.createArticle.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actionForm.createArticle.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerCreateCategory({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.ADD_CATEGORY_ARTICLE);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}
