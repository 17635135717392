import { combineReducers, Reducer } from "redux";

import { authReducers } from "../pages/Auth/_reducers";
import { types as authTypes } from "../pages/Auth/_types";
import { userReducers } from "../pages/User/_reducers";
import { generalReducers } from "../pages/General/_reducers";
import { masterReducer } from "../pages/MasterData/_reducers";
import { reportReducers } from "../pages/Report/_reducers";
import { detailReducer } from "../pages/DetailData/_reducers";
import { roleReducers } from "../pages/Role/_reducers";
import { siteReducers } from "../pages/Site/_reducers";
import { reservationReducers } from "../pages/Reservation/_reducers";
import { healthReducers } from "../pages/Health/_reducers";
import { medicalReducers } from "../pages/MedicalRecord/_reducers";
import { notificationReducers } from "../pages/Notification/_reducers";
import { rankConfigurationReducers } from "../pages/RankConfiguration/_reducers";
import { hierarchyReducer } from "../pages/HierarchyCompany/_reducers";
import { ptwReducers } from "../pages/Safety/_reducers";
import { profileReducers } from "../pages/Profile/_reducers";
import { medicineReducers } from "../pages/Medicine/_reducers";
import { inquiryReducers } from "../pages/UserInquiry/_reducers";
import { medicalInventoryReducers } from "../pages/MedicalInvetory/Inventory/_reducers";
import { bulkuploadReducer } from "../pages/BulkUpload/_reducers";
import { articlesReducers } from "../pages/Articles/_reducers";
import { homeArticleReducers } from "../pages/Home/_reducers";
import { sidebarReducers } from "../components/Sidebar/_reducers";
import { workflowCategorizationReducers } from "../pages/WorkflowCategorization/_reducers";
import { procedureConfigurationReducers } from "../pages/ProcedureConfig/_reducers";
import { safetyVoiceScoreReducer } from "../pages/SafetyVoiceScoring/_reducers";
import { hiradcReducers } from "../pages/Hiradc/_reducers";
import { dashboardRecuder } from "../pages/DynamicDashboard/_reducers";

const allReducers = combineReducers({
  auth: authReducers,
  general: generalReducers,
  master: masterReducer,
  user: userReducers,
  report: reportReducers,
  detail: detailReducer,
  role: roleReducers,
  site: siteReducers,
  reservation: reservationReducers,
  health: healthReducers,
  medical: medicalReducers,
  rankConfig: rankConfigurationReducers,
  notification: notificationReducers,
  hierarchy: hierarchyReducer,
  ptw: ptwReducers,
  profile: profileReducers,
  medicine: medicineReducers,
  inquiry: inquiryReducers,
  medicalInventory: medicalInventoryReducers,
  bulkupload: bulkuploadReducer,
  articles: articlesReducers,
  homeArticle: homeArticleReducers,
  sidebar: sidebarReducers,
  workflowCategorization: workflowCategorizationReducers,
  procedureConfig: procedureConfigurationReducers,
  safetyVoiceScore: safetyVoiceScoreReducer,
  hiradc: hiradcReducers,
  dashbboard: dashboardRecuder,
});

const rootReducers = (state: RootState, action: any) => {
  if (action.type === authTypes.SIGNOUT) {
    // const {app} = state;
    // state = {app};
    state = {} as RootState;
  }

  return allReducers(state, action);
};

export default rootReducers as Reducer;

export type RootState = ReturnType<typeof allReducers>;
