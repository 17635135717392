export const types = {
  GET_CATEGORY_REQUEST: "workflowCategorization/GET_CATEGORY_REQUEST",
  GET_CATEGORY_SUCCESS: "workflowCategorization/SIGNIN_SUCCESS",
  GET_CATEGORY_FAILED: "workflowCategorization/GET_CATEGORY_FAILED",
};

export interface IPayloadUpdateCategory {
  id?: string | number;
  name: string;
  description: string;
  image?: string | null;
}

export interface IPayloadGetProfile {
  userName: string;
  token: string;
}
