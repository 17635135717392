import { call, put, takeLatest } from "redux-saga/effects";
import { debug } from "../../helpers/logger";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";

import { actions } from "./_actions";
import { types } from "./_types";

const currentFlow = localStorage.getItem("CURRENT_TYPEFLOW");

export const watcherHealth = () => [
  takeLatest(types.GET_RESERVATION_REQUEST, workerGetReservation),
  takeLatest(types.GET_RESERVATION_TYPE_REQUEST, workerGetReservationType),
  takeLatest(types.GET_LIST_PATIENT_REQUEST, workerGetListPatient),
  takeLatest(types.GET_LIST_TIME_REQUEST, workerGetListTime),
  takeLatest(types.GET_LIST_MEDICAL_REQUEST, workerGetListMedical),
  takeLatest(types.GET_DETAIL_MEDICAL_REQUEST, workerGetDetailMedical),
  takeLatest(types.GET_HISTORY_MEDICAL_REQUEST, workerGetListHistoryMedical),
  takeLatest(types.CREATE_RESERVATION_REQUEST, workerCreateReservation),
  takeLatest(types.GET_RESERVATION_HISTORY_REQUEST, workerGetReservationHistory),
  takeLatest(types.GET_RESERVATION_DETAIL_REQUEST, workerGetReservationDetail),
  takeLatest(types.GET_RESERVATION_DETAIL_HISTORY_REQUEST, workerGetReservationDetailHistory),
  takeLatest(types.GET_TIMELINE_STATUS_REQUEST, workerGetTimeLineStatus),
  takeLatest(types.GET_DETAIL_RISK_REQUEST, workerGetDetailRisk),
  takeLatest(types.GET_LIST_INVENTORY_REQUEST, workerGetInventory),
  takeLatest(types.GET_RESERVATION_DETAIL_SHE_REQUEST, workerGetReservationDetailSafety),
  takeLatest(types.GET_RESERVATION_DETAIL_HEALTH_REQUEST, workerGetReservationDetailHealth),
];

function* workerGetReservation({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  //* DEVIATION MARK SWITCHER
  const http = new HttpService(
    currentFlow && currentFlow === "safety"
      ? urlsEndpoint.SAFETY_RESERVATION_LIST
      : urlsEndpoint.RESERVATION_LIST
  );
  // const http = new HttpService(urlsEndpoint.DUMMY_RESERVATION_LIST);
  try {
    const response = yield call(http.post, payload);
    // const response = yield call(http.find);
    yield put(actions.getReservationList.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getReservationList.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetReservationHistory({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(
    currentFlow && currentFlow === "safety"
      ? urlsEndpoint.SAFETY_RESERVATION_HISTORY
      : urlsEndpoint.RESERVATION_HISTORY
  );
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getReservationHistory.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getReservationHistory.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetReservationType({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.RESERVATION_TYPE);
  try {
    const response = yield call(http.find, payload);
    yield put(actions.getReservationType.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getReservationType.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerCreateReservation({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(
    currentFlow && currentFlow === "safety"
      ? urlsEndpoint.CREATE_SAFETY_RESERVATION
      : urlsEndpoint.CREATE_RESERVATION
  );
  try {
    const response = yield call(http.post, payload);
    debug(response);
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.createReservation.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetReservationDetailHealth({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<{ assignmentNumber: string }>): any {
  const http = new HttpService(urlsEndpoint.DETAIL_RESERVATION);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetReservationDetailSafety({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<{ assignmentNumber: string }>): any {
  const http = new HttpService(urlsEndpoint.DETAIL_SAFETY_RESERVATION);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetReservationDetail({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<{ assignmentNumber: string; selectedFlow?: "safety" | "health" }>): any {
  //* DEVIATION MARK SWITCHER

  const currentEndpoint = (() => {
    if (payload?.selectedFlow) {
      return payload.selectedFlow.toLowerCase() === "safety"
        ? urlsEndpoint.DETAIL_SAFETY_RESERVATION
        : urlsEndpoint.DETAIL_RESERVATION;
    }

    if (!currentFlow) return urlsEndpoint.DETAIL_RESERVATION;

    return currentFlow === "safety"
      ? urlsEndpoint.DETAIL_SAFETY_RESERVATION
      : urlsEndpoint.DETAIL_RESERVATION;
  })();

  const http = new HttpService(currentEndpoint);
  // const prepareAssignmentNumber = payload?.assignmentNumber
  //   ? payload.assignmentNumber.replaceAll("/", "-")
  //   : "";
  // const http = new HttpService(
  //   `${urlsEndpoint.DUMMY_RESERVATION_DETAIL}/${prepareAssignmentNumber}`
  // );
  try {
    const response = yield call(http.post, { assignmentNumber: payload?.assignmentNumber ?? "" });
    // const response = yield call(http.find);
    yield put(actions.getReservationDetail.success(response));
    // yield put(actions.getReservationDetail.success(response.data));
    yield callback(onSuccess, response);
    // yield callback(onSuccess, response.data);
  } catch (err) {
    yield put(actions.getReservationDetail.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetReservationDetailHistory({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<{ assignmentNumber: string; selectedFlow?: string }>): any {
  const currentEndpoint = (() => {
    if (payload?.selectedFlow) {
      return payload.selectedFlow.toLowerCase() === "safety"
        ? urlsEndpoint.DETAIL_SAFETY_HISTORY
        : urlsEndpoint.DETAIL_HISTORY_RESERVATION;
    }

    if (!currentFlow) return urlsEndpoint.DETAIL_HISTORY_RESERVATION;

    return currentFlow === "safety"
      ? urlsEndpoint.DETAIL_SAFETY_HISTORY
      : urlsEndpoint.DETAIL_HISTORY_RESERVATION;
  })();

  const http = new HttpService(currentEndpoint);

  try {
    const response = yield call(http.post, { assignmentNumber: payload?.assignmentNumber });
    yield put(actions.getReservationDetailHistory.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getReservationDetailHistory.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetListPatient({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.LIST_PATIENT);
  try {
    const response = yield call(http.find, payload);
    yield put(actions.getListPatient.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListPatient.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetListTime({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.LIST_TIME);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerGetListMedical({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.LIST_MEDICAL_CENTER);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getListMedicalCenter.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListMedicalCenter.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetListHistoryMedical({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.LIST_HISTORY_MEDICAL);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getHistoryMedicalCenter.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getHistoryMedicalCenter.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetDetailMedical({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.DETAIL_MEDICAL_CENTER);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getMedicalCenterDetail.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getMedicalCenterDetail.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetTimeLineStatus({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.TIMELINE_STATUS);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getTimelineStatus.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getTimelineStatus.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerGetDetailRisk({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.DETAIL_RISK);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getDetailRisk.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getDetailRisk.failure(err));
    yield callback(onFailure, err);
  }
}
function* workerGetInventory({ payload, onFailure, onSuccess }: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_MASTER_KEYS_CATEGORY);
  try {
    const response = yield call(http.post, payload);
    yield put(actions.getListInventory.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getListInventory.failure(err));
    yield callback(onFailure, err);
  }
}
