import { types } from "./_types";

const initialState = {
  formList: [],
  formDetail: {},
  actionStatus: "initial",
  moreActionStatus: {
    getForm: "initial",
    updateForm: "initial",
  },
};

export const reportReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_FORMS_REQUEST:
    case types.GET_FORMS_FAILURE:
    case types.GET_FORM_DETAIL_REQUEST:
    case types.GET_FORM_DETAIL_FAILURE:
    case types.GET_FORMS_SUCCESS:
      return {
        ...state,
        formList: action.data?.data?.formDataList || [],
        actionStatus: action.actionStatus,
      };
    case types.GET_FORM_DETAIL_SUCCESS:
      return {
        ...state,
        formDetail: {
          ...state.formDetail,
          [action.data?.data?.datareport?.formId]: action.data?.data?.datareport?.fieldData,
        },
        actionStatus: action.actionStatus,
      };

    default:
      return state;
  }
};
