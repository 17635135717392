// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import pdfjsLib from "pdfjs-dist/webpack";

// const blobToBase64 = (blob: Blob) => {
//   return new Promise((resolve, _) => {
//     const reader = new FileReader();
//     reader.onloadend = () => resolve(reader.result);
//     reader.readAsDataURL(blob);
//   });
// };

// const convertPdfToImages = async (file: Blob) => {
//   let images;
//   const data = (await blobToBase64(file)) as string;
//   const pdf = await pdfjsLib.getDocument(data).promise;
//   const canvas = document.createElement("canvas");
//   const page = await pdf.getPage(1);
//   const viewport = page.getViewport({ scale: 3 });
//   const context = canvas.getContext("2d");
//   canvas.height = viewport.height;
//   canvas.width = viewport.width;
//   await page.render({ canvasContext: context as any, viewport: viewport }).promise;
//   images = canvas.toDataURL();
//   canvas.remove();
//   return images;
// };

// generate PDF & QRCode
export const generatePDF = async (blob: Blob, ttd: boolean = false, pcr: boolean = false) => {
  // // Convert div to canvas
  // let yQr = ttd ? 210 : 235;
  // let yTtd = pcr ? 195 : 230;

  // // Element
  // const ttdElement = document.getElementById("ttd_letter") as HTMLElement;
  // const qrElement = document.getElementById("qrcode") as HTMLCanvasElement;

  // const pdfImg = (await convertPdfToImages(blob)) as string;
  // const pdf: jsPDF = new jsPDF({ orientation: "portrait", unit: "mm", format: "a4" });
  // const qrImage: string = qrElement.toDataURL();

  // // Adds the image to the pdf
  // html2canvas(ttdElement).then((canvas: any) => {
  //   pdf.addImage(pdfImg, "png", 0, 0, 210, 297);
  //   pdf.addImage(qrImage, "png", 175, yQr, 25, 25);
  //   ttd && pdf.addImage(canvas.toDataURL(), "png", 5, yTtd, 55, 35);
  //   // Downloads the pdf
  //   const blobURL: string = URL.createObjectURL(pdf.output("blob"));
  //   window.open(blobURL);
  // });
  const blobURL: string = URL.createObjectURL(blob);
  window.open(blobURL);
};
