import { IValues } from "./types";

export const types = {
  GET_ALL_ROLE_REQUEST: "role/GET_ALL_ROLE_REQUEST",
  GET_ALL_ROLE_SUCCESS: "role/GET_ALL_ROLE_SUCCESS",
  GET_ALL_ROLE_FAILURE: "role/GET_ALL_ROLE_FAILURE",

  GET_VIEW_ROLE_REQUEST: "role/GET_VIEW_ROLE_REQUEST",
  GET_VIEW_ROLE_SUCCESS: "role/GET_VIEW_ROLE_SUCCESS",
  GET_VIEW_ROLE_FAILURE: "role/GET_VIEW_ROLE_FAILURE",

  GET_ALL_ARCHIVE_ROLE_REQUEST: "role/GET_ALL_ARCHIVE_ROLE_REQUEST",
  GET_ALL_ARCHIVE_ROLE_SUCCESS: "role/GET_ALL_ARCHIVE_ROLE_SUCCESS",
  GET_ALL_ARCHIVE_ROLE_FAILURE: "role/GET_ALL_ARCHIVE_ROLE_FAILURE",

  GET_ROLE_BY_ID_REQUEST: "role/GET_ROLE_BY_ID_REQUEST",
  GET_ROLE_BY_ID_SUCCESS: "role/GET_ROLE_BY_ID_SUCCESS",
  GET_ROLE_BY_ID_FAILURE: "role/GET_ROLE_BY_ID_FAILURE",

  CREATE_ROLE_REQUEST: "role/CREATE_ROLE_REQUEST",
  CREATE_ROLE_SUCCESS: "role/CREATE_ROLE_SUCCESS",
  CREATE_ROLE_FAILURE: "role/CREATE_ROLE_FAILURE",

  UPDATE_ROLE_REQUEST: "role/UPDATE_ROLE_REQUEST",
  UPDATE_ROLE_SUCCESS: "role/UPDATE_ROLE_SUCCESS",
  UPDATE_ROLE_FAILURE: "role/UPDATE_ROLE_FAILURE",
};

export interface IGetRoleRequestData {
  token?: string;
  start?: number;
  limit?: number;
  page?: number;
  keyword?: string | "";
  orderBy?: {
    field: string;
    sort: string;
  };
}

export interface IGetRoleDetailRequestData {
  id: number;
}

export interface ICreateRoleData extends IValues {}

export interface IUpdateRoleData extends IValues {
  id: number;
}

export interface Role {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
}
