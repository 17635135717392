// import { debug } from "../../helpers/logger";
import { types } from "./_types";

const initialState = {
  formDraftedList: [],
  formPublishedList: [],
  masterDataList: [],
  masterDataSafety: [],
  formMasterDataList: [],
  formDetail: {},
  dataEquip: [],
  formList: [],
  companyList: [],
  actionStatus: "initial",
  moreActionStatus: {
    getForm: "initial",
    updateForm: "initial",
  },
  formFlowList: [],
  data: null,
  dataRole: null,
  isLoading: true,
  pagination: null,
};

export const generalReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_FORMS_REQUEST:
    case types.GET_FORMS_FAILURE:
    case types.GET_FORMS_SUCCESS:
      return {
        ...state,
        ...(action.data?.formStatus === "PUBLISH"
          ? { formPublishedList: action.data?.data?.formList || [] }
          : { formDraftedList: action.data?.data?.formList || [] }),
        actionStatus: action.actionStatus,
      };
    case types.GET_FORMS_PUBLISH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_FORMS_PUBLISH_FAILURE:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_FORMS_PUBLISH_SUCCESS:
      return {
        ...state,
        data: action.data.data.formList,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_FORMS_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_FORMS_SEARCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_FORMS_SEARCH_SUCCESS:
      return {
        ...state,
        data: action.data.data.formList,
        pagination: action.data?.pagination,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_FLOW_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_FLOW_SEARCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_FLOW_SEARCH_SUCCESS:
      return {
        ...state,
        data: action.data.data.formFlow,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_FORMS_DRAFT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_FORMS_DRAFT_FAILURE:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_FORMS_DRAFT_SUCCESS:
      return {
        ...state,
        data: action.data.data.formList,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.CREATE_FORM_REQUEST:
    case types.CREATE_FORM_FAILURE:
    case types.CREATE_FORM_SUCCESS:
    case types.GET_MASTER_DATAS_REQUEST:
    case types.GET_MASTER_DATAS_FAILURE:
    case types.GET_LIST_FORM_FLOW_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_LIST_FORM_FLOW_FAILURE:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_MASTER_DATAS_SUCCESS:
      return {
        ...state,
        masterDataList:
          action?.data?.data?.datamaster?.map((item: any) => ({
            id: item.categoryId,
            label: item.name,
            value: item.name,
          })) || [],
        actionStatus: action.actionStatus,
      };
    case types.GET_MASTER_SAFETY_SUCCESS:
      return {
        ...state,
        masterDataSafety:
          action?.data?.data?.datamaster?.map((item: any) => ({
            id: item.categoryId,
            label: item.name,
            value: item.name,
          })) || [],
        actionStatus: action.actionStatus,
      };
    case types.GET_FORMS_MASTER_SUCCESS:
      return {
        ...state,
        formMasterDataList:
          action.data.data?.formList?.map((item: any) => ({
            label: item.formName,
            value: item.formVersionId,
          })) || [],
        actionStatus: action.actionStatus,
      };

    case types.GET_FORM_DETAIL_REQUEST:
    case types.GET_FORM_DETAIL_FAILURE:
      return {
        ...state,
        moreActionStatus: {
          ...state.moreActionStatus,
          getForm: action.actionStatus,
        },
        actionStatus: action.actionStatus,
      };
    case types.GET_FORM_DETAIL_SUCCESS:
      return {
        ...state,
        formDetail: {
          ...state.formDetail,
          [action.data?.data?.form?.formId]: action.data?.data?.form,
        },
        moreActionStatus: {
          ...state.moreActionStatus,
          getForm: action.actionStatus,
        },
        actionStatus: action.actionStatus,
      };
    case types.UPDATE_FORM_REQUEST:
    case types.UPDATE_FORM_FAILURE:
    case types.UPDATE_FORM_SUCCESS:
      return {
        ...state,
        moreActionStatus: {
          ...state.moreActionStatus,
          updateForm: action.actionStatus,
        },
        actionStatus: action.actionStatus,
      };
    case types.GET_LIST_EQUIP_REQUEST:
    case types.GET_LIST_EQUIP_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
      };
    case types.GET_LIST_EQUIP_SUCCESS:
      return {
        ...state,
        dataEquip:
          action.data.data?.equipmentList?.map((item: any) => ({
            label: item.name,
            value: item.id,
          })) || [],
        actionStatus: action.actionStatus,
      };
    case types.GET_LIST_FORM_FLOW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        formFlowList: action.data?.data?.formFlow || [],
        pagination: action.data?.pagination,
        actionStatus: action.actionStatus,
      };
    case types.GET_COMPANY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_COMPANY_FAILURE:
      return {
        ...state,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_COMPANY_SUCCESS:
      return {
        ...state,
        companyList: action.data?.data?.companyList || [],
        pagination: action.data?.pagination,
        actionStatus: action.actionStatus,
        isLoading: false,
      };
    case types.GET_ALL_FORMS_MASTER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_ALL_FORMS_MASTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    case types.GET_ALL_FORMS_MASTER_SUCCESS:
      return {
        ...state,
        data: action.data.data.formList,
        pagination: action.data?.pagination,
        isLoading: false,
        actionStatus: action.actionStatus,
      };
    default:
      return state;
  }
};
