import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { IAddSiteRequestData, types as siteTypes } from "./_types";

export const actions = {
  getSites: {
    request: (requestData: IDefaultRequestParams<null>) => ({
      ...requestData,
      type: siteTypes.GET_SITE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: siteTypes.GET_SITE_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: siteTypes.GET_SITE_FAILURE,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  addSite: {
    request: (requestData: IDefaultRequestParams<IAddSiteRequestData>) => ({
      ...requestData,
      type: siteTypes.ADD_SITE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  editSite: {
    request: (requestData: IDefaultRequestParams<IAddSiteRequestData>) => ({
      ...requestData,
      type: siteTypes.EDIT_SITE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  deleteSite: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: siteTypes.DELETE_SITE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
};
