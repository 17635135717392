import { Modal, notification } from "antd";
import { Formik, FormikValues } from "formik";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as urlsName from "../../helpers/urlsName";
import { actions as authActions } from "../../pages/Auth/_actions";
import { selectors } from "../Profile/_selectors";
import { validateChangePassword } from "./validationSchema";
import View from "./view";
import { actions } from "./_actions";

interface ModalProfileProps {
  isModalOpen: boolean;
  onClick: Function;
}

const ChangePassword: FC<ModalProfileProps> = ({ isModalOpen, onClick }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const dataProfile = useSelector(selectors.getUserProfile);
  const initialValues: FormikValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [isLoading, setLoading] = useState(false);

  const onFinish = (values: FormikValues) => {
    setLoading(true);
    dispatch(
      actions.updatePassword.request({
        payload: {
          nik: dataProfile?.nik,
          password: values.newPassword,
          isReset: false,
        },
        onSuccess: (res: any) => {
          setLoading(false);
          dispatch(authActions.signout());
          localStorage.clear();
          history.push(urlsName.ROOT);
        },
        onFailure: (err: any) => {
          setLoading(false);
          notification.error({
            placement: "topRight",
            message: "Failed to Change Password",
            duration: 3,
          });
        },
      })
    );
  };

  return (
    <Modal visible={isModalOpen} closable={false} onCancel={() => onClick()} footer={false}>
      <Formik
        enableReinitialize
        onSubmit={onFinish}
        initialValues={initialValues}
        validationSchema={validateChangePassword}
      >
        {(formikProps) => <View onClick={onClick} isLoading={isLoading} {...formikProps} />}
      </Formik>
    </Modal>
  );
};

export default ChangePassword;
