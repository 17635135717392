import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";

import { actions } from "./_actions";
import { IAddSiteRequestData, types } from "./_types";

export const watcherSite = () => [
  takeLatest(types.GET_SITE_REQUEST, workerGetSites),
  takeLatest(types.ADD_SITE_REQUEST, workerAddSite),
  takeLatest(types.EDIT_SITE_REQUEST, workerEditSite),
  takeLatest(types.DELETE_SITE_REQUEST, workerDeleteSite),
];

function* workerGetSites({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IAddSiteRequestData>): any {
  const http = new HttpService(urlsEndpoint.GET_ALL_SITE);
  try {
    const response = yield call(http.post, {
      start: 0,
      limit: 8,
    });
    yield put(actions.getSites.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(actions.getSites.failure(err));
    yield callback(onFailure, err);
  }
}

function* workerAddSite({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IAddSiteRequestData>): any {
  const http = new HttpService(urlsEndpoint.ADD_SITE);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerEditSite({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<IAddSiteRequestData>): any {
  const http = new HttpService(urlsEndpoint.EDIT_SITE);
  try {
    const response = yield call(http.post, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}

function* workerDeleteSite({ payload, onFailure, onSuccess }: IDefaultRequestAction<any>): any {
  const http = new HttpService(urlsEndpoint.DELETE_SITE);
  try {
    const response = yield call(http.deleteById, payload);
    yield callback(onSuccess, response);
  } catch (err) {
    yield callback(onFailure, err);
  }
}
