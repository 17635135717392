export function bmiCalculate(height: number, weight: number) {
  let result;
  let bmi = weight / ((height * height) / 10000);

  // Dividing as per the bmi conditions
  if (bmi < 18.6) result = "Underweight";
  else if (bmi >= 18.6 && bmi < 24.9) result = "Normal";
  else if (bmi >= 25.0 && bmi < 29.9) result = `Overweight`;
  else result = "Obesity";
  result = {
    result: isNaN(bmi) ? "-" : result,
    score: isNaN(bmi) ? "-" : bmi.toFixed(1),
  };
  return result;
}
