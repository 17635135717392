export const types = {
  GET_FORMS_REQUEST: "report/GET_FORMS_REQUEST",
  GET_FORMS_SUCCESS: "report/GET_FORMS_SUCCESS",
  GET_FORMS_FAILURE: "report/GET_FORMS_FAILURE",

  GET_FORM_DETAIL_REQUEST: "report/GET_FORM_DETAIL_REQUEST",
  GET_FORM_DETAIL_SUCCESS: "report/GET_FORM_DETAIL_SUCCESS",
  GET_FORM_DETAIL_FAILURE: "report/GET_FORM_DETAIL_FAILURE",
};

export interface IGetFormsRequestData {
  start: number;
  limit: number;
}

export interface IGetFormDetailRequestData {
  formId: string;
}
