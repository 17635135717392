import { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";

import Loader from "../components/Loader";
import { selectors as authSelectors } from "../pages/Auth/_selectors";
import i18n from "../_i18n";

import AppAuthenticatedPage from "./AppAuthenticated";

const AppUnauthenticatedPage = lazy(() => import("./AppUnauthenticated"));

const RoutePage = () => {
  const userData = useSelector(authSelectors.getUserData);

  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);

  if (!userData) {
    return (
      <Suspense fallback={<Loader />}>
        <AppUnauthenticatedPage />
      </Suspense>
    );
  }

  return <AppAuthenticatedPage />;
};

export default RoutePage;
