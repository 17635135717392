import {
  Button,
  Card,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Modal,
  Select,
  Space,
  Upload,
} from "antd";
import moment from "moment";
import { createRef, FC, useEffect, useState } from "react";
import { bmiCalculate } from "../../helpers/bmiCalculate";
import styles from "./styles.module.less";

interface ViewProps {
  isModalOpen: boolean;
  imageUrl?: string;
  uploadButton: any;
  dummyRequest: any;
  beforeUpload: any;
  handleChange: any;
  onFinish: any;
  modalClose: Function;
  userData?: {
    fullName: string;
    nik: string;
    nikPassport: string;
    height: number;
    weight: number;
    photoProfile: any;
    gender: string;
    dateOfBirth: string;
    bloodType: string;
    email: string;
    phone: string;
  };
  isLoading: boolean;
  loadingPicture: boolean;
}

const View: FC<ViewProps> = ({
  isModalOpen,
  imageUrl,
  uploadButton,
  dummyRequest,
  beforeUpload,
  handleChange,
  onFinish,
  modalClose,
  userData,
  isLoading,
  loadingPicture,
}) => {
  const formRef = createRef<FormInstance>();
  const [form] = Form.useForm();
  const [bmi, setBmi] = useState({ height: userData?.height ?? 0, weight: userData?.weight ?? 0 });
  const dob = moment(userData?.dateOfBirth) ?? null;

  useEffect(() => {
    form.setFieldsValue({ ...userData, dateOfBirth: moment(userData?.dateOfBirth) });
  }, [form, userData]);

  const onValuechange = (values: any) => {
    if (`${Object?.keys(values)}` === "height") {
      setBmi({ ...bmi, height: parseInt(values.height) });
    }
    if (`${Object?.keys(values)}` === "weight") {
      setBmi({ ...bmi, weight: parseInt(values.weight) });
    }
  };

  const handleRemoveText = (event: any) => {
    return event.target.value.replace(/[^\d]/g, "");
  };

  const handleChangeNumber = (event: any) => {
    let value = handleRemoveText(event);
    if (value > 0) {
      return `${value}`.replace(/^0+/, "");
    }
    return 0;
  };

  useEffect(() => {
    form.setFieldsValue({ BMI: bmiCalculate(bmi?.height ?? 0, bmi?.weight ?? 0).score });
  }, [bmi, form]);

  useEffect(() => {
    setBmi({ height: userData?.height ?? 0, weight: userData?.weight ?? 0 });
  }, [userData?.height, userData?.weight]);

  const setDob = (value: any) => {
    form.setFieldsValue({ dateOfBirth: moment(value) });
  };

  return (
    <Modal
      visible={isModalOpen}
      cancelText="Batal"
      okText="Simpan"
      closable={false}
      maskClosable={false}
      footer={false}
    >
      <Card loading={isLoading} className={styles.container}>
        <h1 className={styles.headTitle}>Update Profile</h1>
        <Upload
          name="avatar"
          className={styles.uploadPhoto}
          showUploadList={false}
          action={""}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl && !loadingPicture ? (
            <img src={imageUrl} alt="avatar" className={styles.img__wrap} />
          ) : (
            uploadButton
          )}
        </Upload>
        <h3 className={styles.titleSection}>General Information</h3>
        <Form
          ref={formRef}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            fullName: userData?.fullName,
            nik: userData?.nik,
            height: userData?.height,
            weight: userData?.weight,
            nikPassport: userData?.nikPassport || null,
            email: userData?.email,
            phone: userData?.phone,
          }}
          onValuesChange={onValuechange}
        >
          <Form.Item className={styles.labelForm} label="Full Name" name={"fullName"}>
            <Input disabled className={styles.form} value={userData?.fullName ?? ""} />
          </Form.Item>
          <Form.Item className={styles.labelForm} label="Employee ID" name={"nik"}>
            <Input disabled className={styles.form} value={userData?.nik ?? ""} />
          </Form.Item>
          <Form.Item
            className={styles.labelForm}
            label="Nomor Induk Kewarganegaraan (NIK) / No Passport"
            name={"nikPassport"}
          >
            <Input className={styles.form} value={userData?.nikPassport ?? ""} />
          </Form.Item>
          <Form.Item className={styles.labelForm} label="Gender" name={"gender"}>
            <Select
              defaultValue={userData?.gender ?? ""}
              size={"large"}
              allowClear
              options={[
                {
                  value: "Male",
                  label: "Male",
                },
                {
                  value: "Female",
                  label: "Female",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            className={styles.labelForm}
            label="Date Of Birth"
            name="dateOfBirth"
            initialValue={dob}
          >
            <DatePicker
              name="dateOfBirth"
              clearIcon={false}
              placeholder="Please input your data!"
              format="YYYY-MM-DD"
              onChange={(date, dateString) => setDob(dateString)}
              style={{ width: "100%", borderRadius: "5px", height: "40px" }}
            />
          </Form.Item>
          <Form.Item className={styles.labelForm} label="Email" name={"email"}>
            <Input className={styles.form} value={userData?.email ?? ""} />
          </Form.Item>
          <Form.Item
            className={styles.labelForm}
            label="Phone Number"
            name={"phone"}
            getValueFromEvent={handleRemoveText}
          >
            <Input className={styles.form} value={userData?.phone ?? ""} />
          </Form.Item>
          <h3 className={styles.titleSection}>Additional Information</h3>
          <Form.Item className={styles.labelForm} label="Blood Type" name={"bloodType"}>
            <Select
              defaultValue={userData?.bloodType ?? ""}
              size={"large"}
              allowClear
              options={[
                {
                  value: "A",
                  label: "A",
                },
                {
                  value: "AB",
                  label: "AB",
                },
                {
                  value: "B",
                  label: "B",
                },
                {
                  value: "O",
                  label: "O",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            className={styles.labelForm}
            label="Height"
            name={"height"}
            getValueFromEvent={handleChangeNumber}
          >
            <Input className={styles.form} value={userData?.height ?? ""} suffix="cm" />
          </Form.Item>
          <Form.Item
            className={styles.labelForm}
            label="Weight"
            name={"weight"}
            getValueFromEvent={handleChangeNumber}
          >
            <Input className={styles.form} value={userData?.weight ?? ""} suffix="kg" />
          </Form.Item>
          <Form.Item className={styles.labelForm} label="BMI" name={"BMI"}>
            <Input
              disabled
              className={styles.form}
              value={
                userData?.weight && userData?.height
                  ? bmiCalculate(userData?.height ?? 0, userData?.weight ?? 0).score
                  : ""
              }
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Space size={"large"}>
              <Button
                key="cancel"
                // loading={this.state.confirmLoading}
                onClick={() => modalClose()}
                size={"large"}
              >
                Cancel
              </Button>
              <Button
                key="submit"
                type="primary"
                // loading={this.state.confirmLoading}
                htmlType="submit"
                size={"large"}
              >
                Submit
              </Button>
            </Space>
          </div>
        </Form>
      </Card>
    </Modal>
  );
};

export default View;
