import { call, put, takeLatest } from "redux-saga/effects";
import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { callback } from "../../store/defaultSagas";
import { IDefaultRequestAction } from "../../store/defaultTypes";
import { workflowCategorizationAction } from "./_actions";
import { types } from "./_types";

function* workerGetWorkflowCategory({
  payload,
  onFailure,
  onSuccess,
}: IDefaultRequestAction<null>): any {
  const http = new HttpService(urlsEndpoint.GET_WORKFLOW_CATEGORIZATION);
  try {
    const response = yield call(http.post, payload);
    yield put(workflowCategorizationAction.getCategoryList.success(response));
    yield callback(onSuccess, response);
  } catch (err) {
    yield put(workflowCategorizationAction.getCategoryList.failure(err));
    yield callback(onFailure, err);
  }
}

export const watcherWorkflowCategorization = () => [
  takeLatest(types.GET_CATEGORY_REQUEST, workerGetWorkflowCategory),
];
