// import { IFormikValues } from "./Medicine/types";

export const types = {
  GET_MEDICAL_RECORD_KEYS_REQUEST: "my-task/GET_MEDICAL_RECORD_KEYS_REQUEST",
  GET_MEDICAL_RECORD_KEYS_SUCCESS: "my-task/GET_MEDICAL_RECORD_KEYS_SUCCESS",
  GET_MEDICAL_RECORD_KEYS_FAILED: "my-task/GET_MEDICAL_RECORD_KEYS_FAILED",

  SUBMIT_MEDICAL_KEYS_REQUEST: "my-task/SUBMIT_MEDICAL_KEYS_REQUEST",
  SUBMIT_MEDICAL_KEYS_SUCCESS: "my-task/SUBMIT_MEDICAL_KEYS_SUCCESS",
  SUBMIT_MEDICAL_KEYS_FAILED: "my-task/SUBMIT_MEDICAL_KEYS_FAILED",

  LIST_MEDICAL_KEYS_REQUEST: "my-task/LIST_MEDICAL_KEYS_REQUEST",
  LIST_MEDICAL_KEYS_SUCCESS: "my-task/LIST_MEDICAL_KEYS_SUCCESS",
  LIST_MEDICAL_KEYS_FAILED: "my-task/LIST_MEDICAL_KEYS_FAILED",

  LIST_MEDICINE_KEYS_REQUEST: "my-task/LIST_MEDICINE_KEYS_REQUEST",
  LIST_MEDICINE_KEYS_SUCCESS: "my-task/LIST_MEDICINE_KEYS_SUCCESS",
  LIST_MEDICINE_KEYS_FAILED: "my-task/LIST_MEDICINE_KEYS_FAILED",

  MEDICAL_DETAIL_KEYS_REQUEST: "my-task/MEDICAL_DETAIL_KEYS_REQUEST",
  MEDICAL_DETAIL_KEYS_SUCCESS: "my-task/MEDICAL_DETAIL_KEYS_SUCCESS",
  MEDICAL_DETAIL_KEYS_FAILED: "my-task/MEDICAL_DETAIL_KEYS_FAILED",

  SUBMIT_MEDICINE_KEYS_REQUEST: "my-task/SUBMIT_MEDICINE_KEYS_REQUEST",
  SUBMIT_MEDICINE_KEYS_SUCCESS: "my-task/SUBMIT_MEDICINE_KEYS_SUCCESS",
  SUBMIT_MEDICINE_KEYS_FAILED: "my-task/SUBMIT_MEDICINE_KEYS_FAILED",

  GET_USERS_KEYS_REQUEST: "my-task/GET_USERS_KEYS_REQUEST",
  GET_USERS_KEYS_SUCCESS: "my-task/GET_USERS_KEYS_SUCCESS",
  GET_USERS_KEYS_FAILED: "my-task/GET_USERS_KEYS_FAILED",

  GET_DETAIL_USER_KEYS_REQUEST: "my-task/GET_DETAIL_USER_KEYS_REQUEST",
  GET_DETAIL_USER_KEYS_SUCCESS: "my-task/GET_DETAIL_USER_KEYS_SUCCESS",
  GET_DETAIL_USER_KEYS_FAILED: "my-task/GET_DETAIL_USER_KEYS_FAILED",

  CREATE_MY_TASK_REQUEST: "my-task/CREATE_MY_TASK_REQUEST",
  CREATE_MY_TASK_SUCCESS: "my-task/CREATE_MY_TASK_SUCCESS",
  CREATE_MY_TASK_FAILED: "my-task/CREATE_MY_TASK_FAILED",
  // LETTER
  HEALTH_LETTER_REQUEST: "my-task/HEALTH_LETTER_REQUEST",
  SICK_LETTER_REQUEST: "my-task/SICK_LETTER_REQUEST",
  REFERRAL_LETTER_REQUEST: "my-task/REFERRAL_LETTER_REQUEST",
  ANTIGEN_RESULT_REQUEST: "my-task/ANTIGEN_RESULT_REQUEST",
  GUARANTEE_LETTER_REQUEST: "my-task/GUARANTEE_LETTER_REQUEST",
  REIMBURSEMENT_REQUEST: "my-task/REIMBURSEMENT_REQUEST",

  MEDICAL_LIST_REQUEST: "my-task/MEDICAL_LIST_REQUEST",
  MEDICAL_LIST_SUCCESS: "my-task/MEDICAL_LIST_SUCCESS",
  MEDICAL_LIST_FAILED: "my-task/MEDICAL_LIST_FAILED",
};

export interface IGetReservasiKeyData {
  token: string;
  start: number;
  limit: number;
  filter: {
    filter: string;
  };
  orderBy: string[];
  direction: string;
}

export interface IGetListMedical {
  start: number;
  limit: number;
  page: 1;
  keyword: "";
  orderBy: {
    column: string[];
    direction: string;
  };
}

export interface IGetMedicalList {
  token: string;
  start: number;
  limit: number;
  filter: {
    filter: string;
  };
  orderBy: string[];
  direction: string;
}

export interface IGetDetailUserRequestData {
  username: string | null;
}
export interface IGetMedicalDetailRequestData {
  assignmentNumber: string;
  treatmentId: number | null;
}
export interface IGetDetailMedicine {
  id: number | null;
}

export interface ICreateSubmitMedicineKeyData {
  token?: string;
  treatmentId: number | null;
  treatment: string;
  diagnose: string;
  note: string;
  reservationId: number | null;
  isConfirm: boolean;
  medicine: any[] | {};
}
export interface IGETMedicalRequest {
  token?: string;
  patientId: string;
  time: string;
  flowData: {
    leadAuditor: string | null;
    assigmentNumber: string;
    submitionDate: string | Date;
    location: string | null;
    generalInfo: string | null;
    action: string;
    formFlow: {
      flowName: string;
      formVersionId: number | null;
      description: string;
      id: number | null;
      accessByUser: string | null;
      accessByRole: string | null;
      formFlowId: number | null;
      isDraft: boolean | null;
      formList: any[];
      // field: any[];
    };
  };
}
