// import { debug } from "../../helpers/logger";
import { types as masterTypes } from "./_types";

const initialState = {
  data: null,
  isLoading: true,
  pagination: null,
  dataSublocation: [],
  dataPrequalification: [],
  dataDetailType: [],
  dataListPtw: [],
  dataListSpecialPtw: [],
  dataDetailPermit: [],
  dataSpesialPermit: [],
  dataSpesialPermitChecklist: [],
  dataListSpesial: [],
};

export const ptwReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case masterTypes.GET_ALL_LOCATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.GET_ALL_LOCATION_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case masterTypes.GET_ALL_LOCATION_SUCCESS:
      return {
        ...state,
        data: action.data.data.Location,
        isLoading: false,
      };
    case masterTypes.CREATE_PERMIT_REQUEST:
    case masterTypes.UPDATE_PERMIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.CREATE_PERMIT_FAILED:
    case masterTypes.CREATE_PERMIT_SUCCESS:
    case masterTypes.UPDATE_PERMIT_FAILED:
    case masterTypes.UPDATE_PERMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    // ptw
    case masterTypes.LIST_PERMIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.LIST_PERMIT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case masterTypes.LIST_PERMIT_SUCCESS:
      return {
        ...state,
        data: action.data.data.permitToWork ? action.data.data.permitToWork : [],
        pagination: action.data.data.pagination,
        isLoading: false,
      };
    // get list project ptw
    case masterTypes.LIST_PROJECT_PERMIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.LIST_PROJECT_PERMIT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case masterTypes.LIST_PROJECT_PERMIT_SUCCESS:
      return {
        ...state,
        data: action.data.data.permitToWork ? action.data.data.permitToWork : [],
        isLoading: false,
      };

    case masterTypes.DELETE_PERMIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.DELETE_PERMIT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case masterTypes.DELETE_PERMIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    // tow
    case masterTypes.GET_DETAIL_TYPE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.GET_DETAIL_TYPE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case masterTypes.GET_DETAIL_TYPE_SUCCESS:
      return {
        ...state,
        data: action.data.data.rankDetail,
        isLoading: false,
      };
    // prequalification
    case masterTypes.GET_PREQUALIFICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.GET_PREQUALIFICATION_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case masterTypes.GET_PREQUALIFICATION_SUCCESS:
      return {
        ...state,
        data: action.data.data.preQualificationFromContractor,
        isLoading: false,
      };
    // GET PERMIT
    case masterTypes.GET_SPESIAL_PERMIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.GET_SPESIAL_PERMIT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case masterTypes.GET_SPESIAL_PERMIT_SUCCESS:
      return {
        ...state,
        dataSpesialPermit: action.data.dataList,
        isLoading: false,
      };
    case masterTypes.GET_SPESIAL_PERMIT_CHECKLIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.GET_SPESIAL_PERMIT_CHECKLIST_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case masterTypes.GET_SPESIAL_PERMIT_CHECKLIST_SUCCESS:
      return {
        ...state,
        dataSpesialPermitChecklist: action.data.data,
        isLoading: false,
      };
    case masterTypes.GET_BY_DAY_PERMIT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case masterTypes.GET_BY_DAY_PERMIT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case masterTypes.GET_BY_DAY_PERMIT_SUCCESS:
      return {
        ...state,
        dataListSpesial: action.data.dataList,
        isLoading: false,
      };
    default:
      return state;
  }
};
