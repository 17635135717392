export const types = {
  GET_LIST_HIERARCHY_REQUEST: "hierarchy/GET_LIST_HIERARCHY_REQUEST",
  GET_LIST_HIERARCHY_SUCCESS: "hierarchy/GET_LIST_HIERARCHY_SUCCESS",
  GET_LIST_HIERARCHY_FAILED: "hierarchy/GET_LIST_HIERARCHY_FAILED",

  ADD_HIERARCHY_REQUEST: "hierarchy/ADD_HIERARCHY_REQUEST",
  ADD_HIERARCHY_SUCCESS: "hierarchy/ADD_HIERARCHY_SUCCESS",
  ADD_HIERARCHY_FAILED: "hierarchy/ADD_HIERARCHY_FAILED",

  UPDATE_HIERARCHY_REQUEST: "hierarchy/UPDATE_HIERARCHY_REQUEST",
  UPDATE_HIERARCHY_SUCCESS: "hierarchy/UPDATE_HIERARCHY_SUCCESS",
  UPDATE_HIERARCHY_FAILED: "hierarchy/UPDATE_HIERARCHY_FAILED",

  DELETE_HIERARCHY_REQUEST: "hierarchy/DELETE_HIERARCHY_REQUEST",
  DELETE_HIERARCHY_SUCCESS: "hierarchy/DELETE_HIERARCHY_SUCCESS",
  DELETE_HIERARCHY_FAILED: "hierarchy/DELETE_HIERARCHY_FAILED",

  SUB_HIERARCHY_REQUEST: "hierarchy/SUB_HIERARCHY_REQUEST",
  SUB_HIERARCHY_SUCCESS: "hierarchy/SUB_HIERARCHY_SUCCESS",
  SUB_HIERARCHY_FAILED: "hierarchy/SUB_HIERARCHY_FAILED",

  ADD_SUB_HIERARCHY_REQUEST: "hierarchy/ADD_SUB_HIERARCHY_REQUEST",
  UPDATE_SUB_HIERARCHY_REQUEST: "hierarchy/UPDATE_SUB_HIERARCHY_REQUEST",
  DELETE_SUB_HIERARCHY_REQUEST: "hierarchy/DELETE_SUB_HIERARCHY_REQUEST",
};
