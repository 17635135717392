import HttpService from "../../services/http";
import urlsEndpoint from "../../services/urlsEndpoint";
import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import { IPayloadUpdateCategory, types as procedureConfiguration } from "./_types";

export async function createProcedureConfigAction(
  payload: IPayloadUpdateCategory
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.ADD_PROCEDURE_CONFIG);
  try {
    const response = await http.post({
      id: null,
      documentNumber: payload?.documentNumber,
      formFlow: payload?.formFlow,
      isActive: payload?.isActive,
      releaseDate: payload?.releaseDate,
      revision: payload?.revision,
    });
    const successResponse = {
      error: true,
      data: undefined,
    };
    if (response.result) {
      const successResponse = {
        success: true,
        data: response?.message,
      };
      return successResponse;
    }
    if (!response.result && response.message === "The name already exists") {
      const successResponse = {
        error: true,
        success: false,
        data: { message: response?.message },
      };
      return successResponse;
    }
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getDetailProcedureConfigAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService("");
  try {
    const response = await http.custom({
      url: `${urlsEndpoint.GET_PROCEDURE_CONFIG_DETAIL}/${id}`,
      method: "get",
    });
    const successResponse = {
      success: true,
      data: response.data?.procedure,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function updateProcedureConfigAction(
  payload: IPayloadUpdateCategory
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.UPDATE_PROCEDURE_CONFIG);
  try {
    const response = await http.post(payload);
    const successResponse = {
      error: true,
      data: undefined,
    };
    if (response.result) {
      const successResponse = {
        success: true,
        data: response?.message,
      };
      return successResponse;
    }
    if (!response.result && response.message === "The name already exists") {
      const successResponse = {
        error: true,
        success: false,
        data: { message: response?.message },
      };
      return successResponse;
    }
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function deleteProdecureConfigAction(
  id: number
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.DELETE_PROCEDURE_CONFIG_LIST);
  try {
    const response = await http.delete(id);
    const successResponse = {
      error: true,
      data: undefined,
    };
    if (response.result) {
      const successResponse = {
        success: true,
        data: response?.message,
      };
      return successResponse;
    }
    if (!response.result) {
      const successResponse = {
        error: true,
        success: false,
        data: { message: response?.message },
      };
      return successResponse;
    }
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export async function getFormListAction(
  payload: any
): Promise<{ success?: boolean; error?: boolean; data: any }> {
  const http = new HttpService(urlsEndpoint.SEARCH_FORM_FLOW);
  try {
    const response = await http.post(payload);
    const successResponse = {
      success: true,
      data: response?.data?.formFlow,
    };
    return successResponse;
  } catch (err) {
    const errorResponse = {
      error: true,
      data: err,
    };
    return errorResponse;
  }
}

export const procedureConfigAction = {
  getProcedureConfigList: {
    request: (requestData: IDefaultRequestParams<any>) => ({
      ...requestData,
      type: procedureConfiguration.GET_PROCEDURE_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: procedureConfiguration.GET_PROCEDURE_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: procedureConfiguration.GET_PROCEDURE_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};
