import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { actions } from "../../src/pages/Auth/_actions";
import { debug } from "../helpers/logger";
import { actions as authActions } from "../pages/Auth/_actions";
import { store } from "../store/configureStore";
import { RootState } from "../store/rootReducers";

const ax = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
});

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  debug("<<< request: ", config);
  const rootState: RootState = store.getState();
  const userData = rootState.auth.data;
  return {
    ...config,
    headers: {
      ...config.headers,
      ...(userData ? { Authorization: `Bearer ${userData.access_token}` } : {}),
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
    },
  };
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponse = (response: AxiosResponse): AxiosResponse => {
  debug("<< Response : ", response);
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosResponse> => {
  debug("<<< error: ", error);
  const rootState: RootState = store.getState();
  const userData = rootState.auth.data;
  if (error.response?.status === 401) {
    store.dispatch(
      actions.refreshToken.request({
        payload: userData?.refresh_token,
        onFailure: () => {
          store.dispatch(authActions.signout());
          localStorage.clear();
        },
      })
    );
  }
  return Promise.reject(error);
};

ax.interceptors.request.use(onRequest, onRequestError);

ax.interceptors.response.use(onResponse, onResponseError);

export default ax;
