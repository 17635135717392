import { DefaultActionStatus, IDefaultRequestParams } from "../../store/defaultTypes";
import {
  types as bulkUploadType,
  IGetBulkUploadRequestData,
  IGetBulkUploadFailedRequestData,
} from "./_types";

export const actions = {
  getListMedicine: {
    request: (requestData: any) => ({
      ...requestData,
      type: bulkUploadType.GET_BULK_UPLOAD_MEDICINE_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: bulkUploadType.GET_BULK_UPLOAD_MEDICINE_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: bulkUploadType.GET_BULK_UPLOAD_MEDICINE_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListUser: {
    request: (requestData: IDefaultRequestParams<IGetBulkUploadRequestData>) => ({
      ...requestData,
      type: bulkUploadType.GET_BULK_UPLOAD_USER_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: bulkUploadType.GET_BULK_UPLOAD_USER_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: bulkUploadType.GET_BULK_UPLOAD_USER_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  uploadFileUser: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: bulkUploadType.BULK_UPLOAD_USER_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  uploadFileMedicine: {
    request: (payload: IDefaultRequestParams<any>) => ({
      ...payload,
      type: bulkUploadType.BULK_UPLOAD_MEDICINE_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
  },
  getListFailedMedicine: {
    request: (requestData: IDefaultRequestParams<IGetBulkUploadFailedRequestData>) => ({
      ...requestData,
      type: bulkUploadType.GET_BULK_UPLOAD_MEDICINE_FAILED_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: bulkUploadType.GET_BULK_UPLOAD_MEDICINE_FAILED_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: bulkUploadType.GET_BULK_UPLOAD_MEDICINE_FAILED_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
  getListFailedUser: {
    request: (requestData: IDefaultRequestParams<IGetBulkUploadFailedRequestData>) => ({
      ...requestData,
      type: bulkUploadType.GET_BULK_UPLOAD_USER_FAILED_LIST_REQUEST,
      actionStatus: DefaultActionStatus.REQUEST,
    }),
    success: (data: any) => ({
      type: bulkUploadType.GET_BULK_UPLOAD_USER_FAILED_LIST_SUCCESS,
      data,
      actionStatus: DefaultActionStatus.SUCCESS,
    }),
    failure: (error: any) => ({
      type: bulkUploadType.GET_BULK_UPLOAD_USER_FAILED_LIST_FAILED,
      error,
      actionStatus: DefaultActionStatus.FAILURE,
    }),
  },
};
