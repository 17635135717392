import { IFormikValues } from "./MasterKey/types";
import { IFormikNames } from "./SubCategory/types";
import { IAttValues } from "./MasterAttendance/types";
import { ILocValues } from "./MasterLocation/types";

export const types = {
  GET_MASTER_KEYS_REQUEST: "master/GET_MASTER_KEYS_REQUEST",
  GET_MASTER_KEYS_SUCCESS: "master/GET_MASTER_KEYS_SUCCESS",
  GET_MASTER_KEYS_FAILED: "master/GET_MASTER_KEYS_FAILED",

  GET_MASTER_KEYS_CATEGORY_REQUEST: "master/GET_MASTER_KEYS_CATEGORY_REQUEST",
  GET_MASTER_KEYS_CATEGORY_SUCCESS: "master/GET_MASTER_KEYS_CATEGORY_SUCCESS",
  GET_MASTER_KEYS_CATEGORY_FAILED: "master/GET_MASTER_KEYS_CATEGORY_FAILED",

  GET_MASTER_KEYS_ARCHIVE_REQUEST: "master/GET_MASTER_KEYS_ARCHIVE_REQUEST",
  GET_MASTER_KEYS_ARCHIVE_SUCCESS: "master/GET_MASTER_KEYS_ARCHIVE_SUCCESS",
  GET_MASTER_KEYS_ARCHIVE_FAILED: "master/GET_MASTER_KEYS_ARCHIVE_FAILED",

  GET_MASTER_KEY_DETAIL_REQUEST: "master/GET_MASTER_KEY_DETAIL_REQUEST",
  GET_MASTER_KEY_DETAIL_SUCCESS: "master/GET_MASTER_KEY_DETAIL_SUCCESS",
  GET_MASTER_KEY_DETAIL_FAILED: "master/GET_MASTER_KEY_DETAIL_FAILED",

  CREATE_MASTER_KEY_REQUEST: "master/CREATE_MASTER_KEY_REQUEST",
  CREATE_MASTER_KEY_SUCCESS: "master/CREATE_MASTER_KEY_SUCCESS",
  CREATE_MASTER_KEY_FAILED: "master/CREATE_MASTER_KEY_FAILED",

  CREATE_MASTER_CATEGORY_REQUEST: "master/CREATE_MASTER_CATEGORY_REQUEST",
  CREATE_MASTER_CATEGORY_SUCCESS: "master/CREATE_MASTER_CATEGORY_SUCCESS",
  CREATE_MASTER_CATEGORY_FAILED: "master/CREATE_MASTER_CATEGORY_FAILED",

  UPDATE_MASTER_KEY_REQUEST: "master/UPDATE_MASTER_KEY_REQUEST",
  UPDATE_MASTER_KEY_SUCCESS: "master/UPDATE_MASTER_KEY_SUCCESS",
  UPDATE_MASTER_KEY_FAILED: "master/UPDATE_MASTER_KEY_FAILED",

  GET_ALL_MASTER_ATT_REQUEST: "master/GET_ALL_MASTER_ATT_REQUEST",
  GET_ALL_MASTER_ATT_SUCCESS: "master/GET_ALL_MASTER_ATT_SUCCESS",
  GET_ALL_MASTER_ATT_FAILED: "master/GET_ALL_MASTER_ATT_FAILED",

  GET_MASTER_ATT_DETAIL_REQUEST: "master/GET_MASTER_ATT_DETAIL_REQUEST",
  GET_MASTER_ATT_DETAIL_SUCCESS: "master/GET_MASTER_ATT_DETAIL_SUCCESS",
  GET_MASTER_ATT_DETAIL_FAILED: "master/GET_MASTER_ATT_DETAIL_FAILED",

  GET_LIST_LOC_REQUEST: "master/GET_LIST_LOC_REQUEST",
  GET_LIST_LOC_SUCCESS: "master/GET_LIST_LOC_SUCCESS",
  GET_LIST_LOC_FAILED: "master/GET_LIST_LOC_FAILED",

  CREATE_MASTER_ATT_REQUEST: "master/CREATE_MASTER_ATT_REQUEST",
  CREATE_MASTER_ATT_SUCCESS: "master/CREATE_MASTER_ATT_SUCCESS",
  CREATE_MASTER_ATT_FAILED: "master/CREATE_MASTER_ATT_FAILED",

  UPDATE_MASTER_ATT_REQUEST: "master/UPDATE_MASTER_ATT_REQUEST",
  UPDATE_MASTER_ATT_SUCCESS: "master/UPDATE_MASTER_ATT_SUCCESS",
  UPDATE_MASTER_ATT_FAILED: "master/UPDATE_MASTER_ATT_FAILED",

  GET_ALL_LOCATION_REQUEST: "master/GET_ALL_LOCATION_REQUEST",
  GET_ALL_LOCATION_SUCCESS: "master/GET_ALL_LOCATION_SUCCESS",
  GET_ALL_LOCATION_FAILED: "master/GET_ALL_LOCATION_FAILED",

  GET_LOCATION_BY_ID_REQUEST: "master/GET_LOCATION_BY_ID_REQUEST",
  GET_LOCATION_BY_ID_SUCCESS: "master/GET_LOCATION_BY_ID_SUCCESS",
  GET_LOCATION_BY_ID_FAILED: "master/GET_LOCATION_BY_ID_FAILED",

  CREATE_DATA_LOCATION_REQUEST: "master/CREATE_DATA_LOCATION_REQUEST",
  CREATE_DATA_LOCATION_SUCCESS: "master/CREATE_DATA_LOCATION_SUCCESS",
  CREATE_DATA_LOCATION_FAILED: "master/CREATE_DATA_LOCATION_FAILED",

  UPDATE_DATA_LOCATION_REQUEST: "master/UPDATE_DATA_LOCATION_REQUEST",
  UPDATE_DATA_LOCATION_SUCCESS: "master/UPDATE_DATA_LOCATION_SUCCESS",
  UPDATE_DATA_LOCATION_FAILED: "master/UPDATE_DATA_LOCATION_FAILED",

  SITE_LIST_REQUEST: "master/SITE_LIST_REQUEST",
  SITE_LIST_SUCCESS: "master/SITE_LIST_SUCCESS",
  SITE_LIST_FAILED: "master/SITE_LIST_FAILED",

  GET_SITE_REQUEST: "GET_SITE_REQUEST",
  GET_SITE_SUCCESS: "GET_SITE_SUCCESS",
  GET_SITE_FAILED: "GET_SITE_FAILED",

  RISK_LIST_REQUEST: "master/RISK_LIST_REQUEST",
  RISK_LIST_SUCCESS: "master/RISK_LIST_SUCCESS",
  RISK_LIST_FAILED: "master/RISK_LIST_FAILED",

  SHIFT_LIST_REQUEST: "master/SHIFT_LIST_REQUEST",
  SHIFT_LIST_SUCCESS: "master/SHIFT_LIST_SUCCESS",
  SHIFT_LIST_FAILED: "master/SHIFT_LIST_FAILED",

  EQUIPMENT_LIST_REQUEST: "master/EQUIPMENT_LIST_REQUEST",
  EQUIPMENT_LIST_SUCCESS: "master/EQUIPMENT_LIST_SUCCESS",
  EQUIPMENT_LIST_FAILED: "master/EQUIPMENT_LIST_FAILED",

  EQUIPMENT_TYPE_LIST_REQUEST: "master/EQUIPMENT_TYPE_LIST_REQUEST",
  EQUIPMENT_TYPE_LIST_SUCCESS: "master/EQUIPMENT_TYPE_LIST_SUCCESS",
  EQUIPMENT_TYPE_LIST_FAILED: "master/EQUIPMENT_TYPE_LIST_FAILED",

  LOCATION_LIST_REQUEST: "master/LOCATION_LIST_REQUEST",
  LOCATION_LIST_SUCCESS: "master/LOCATION_LIST_SUCCESS",
  LOCATION_LIST_FAILED: "master/LOCATION_LIST_FAILED",

  LOCATION_TYPE_LIST_REQUEST: "master/LOCATION_TYPE_LIST_REQUEST",
  LOCATION_TYPE_LIST_SUCCESS: "master/LOCATION_TYPE_LIST_SUCCESS",
  LOCATION_TYPE_LIST_FAILED: "master/LOCATION_TYPE_LIST_FAILED",

  SUBLOCATION_LIST_REQUEST: "master/SUBLOCATION_LIST_REQUEST",

  SUBLOCATION_BY_ID_REQUEST: "master/SUBLOCATION_BY_ID_REQUEST",
  SUBLOCATION_BY_ID_SUCCESS: "master/SUBLOCATION_BY_ID_SUCCESS",
  SUBLOCATION_BY_ID_FAILED: "master/SUBLOCATION_BY_ID_FAILED",

  CREATE_SUBLOCATION_REQUEST: "master/CREATE_SUBLOCATION_REQUEST",
  CREATE_SUBLOCATION_SUCCESS: "master/CREATE_SUBLOCATION_SUCCESS",
  CREATE_SUBLOCATION_FAILED: "master/CREATE_SUBLOCATION_FAILED",

  DELETE_SUBLOCATION_REQUEST: "site/DELETE_SUBLOCATION_REQUEST",
  UPDATE_SUBLOCATION_REQUEST: "site/UPDATE_SUBLOCATION_REQUEST",
  UPDATE_SUBLOCATION_SUCCESS: "site/UPDATE_SUBLOCATION_SUCCESS",
  UPDATE_SUBLOCATION_FAILED: "site/UPDATE_SUBLOCATION_FAILED",

  MASTER_TYPE_DATAS_LIST_REQUEST: "site/MASTER_TYPE_DATAS_LIST_REQUEST",
  MASTER_TYPE_DATAS_LIST_SUCCESS: "site/MASTER_TYPE_DATAS_LIST_SUCCESS",
  MASTER_TYPE_DATAS_LIST_FAILURE: "MASTER_TYPE_DATAS_LIST_FAILURE",

  MASTER_TOOLS_LIST_REQUEST: "master/MASTER_TOOLS_LIST_REQUEST",
  MASTER_TOOLS_LIST_SUCCESS: "master/MASTER_TOOLS_LIST_SUCCESS",
  MASTER_TOOLS_LIST_FAILURE: "master/MASTER_TOOLS_LIST_FAILURE",

  MASTER_TOOLS_ADD_REQUEST: "master/MASTER_TOOLS_ADD_REQUEST",
  MASTER_TOOLS_EDIT_REQUEST: "master/MASTER_TOOLS_EDIT_REQUEST",
  MASTER_TOOLS_DELETE_REQUEST: "master/MASTER_TOOLS_DELETE_REQUEST",

  EQUIPMENT_ADD_REQUEST: "master/EQUIPMENT_ADD_REQUEST",
  EQUIPMENT_UPDATE_REQUEST: "master/EQUIPMENT_UPDATE_REQUEST",
  EQUIPMENT_DELETE_REQUEST: "master/EQUIPMENT_DELETE_REQUEST",
  EQUIPMENT_DETAIL_REQUEST: "master/EQUIPMENT_DETAIL_REQUEST",
  EQUIPMENT_CHILD_REQUEST: "master/EQUIPMENT_CHILD_REQUEST",

  EQUIPMENT_CHILD_LIST_REQUEST: "master/EQUIPMENT_CHILD_LIST_REQUEST",
  EQUIPMENT_CHILD_LIST_SUCCESS: "master/EQUIPMENT_CHILD_LIST_SUCCESS",
  EQUIPMENT_CHILD_LIST_FAILED: "master/EQUIPMENT_CHILD_LIST_FAILED",

  // Category
  ADD_CATEGORY_EQUIPMENT_REQUEST: "master/ADD_CATEGORY_EQUIPMENT_REQUEST",
  EQUIPMENT_CATEGORY_LIST_REQUEST: "master/EQUIPMENT_CATEGORY_LIST_REQUEST",
  EQUIPMENT_CATEGORY_LIST_SUCCESS: "master/EQUIPMENT_CATEGORY_LIST_SUCCESS",
  EQUIPMENT_CATEGORY_LIST_FAILED: "master/EQUIPMENT_CATEGORY_LIST_FAILED",

  HISTORY_EQUIPMENT_DETAIL_REQUEST: "master/HISTORY_EQUIPMENT_DETAIL_REQUEST",
  HISTORY_EQUIPMENT_DETAIL_FAILED: "master/HISTORY_EQUIPMENT_DETAIL_FAILED",
  HISTORY_EQUIPMENT_DETAIL_SUCCESS: "master/HISTORY_EQUIPMENT_DETAIL_SUCCESS",
};

export interface IGetMasterKeyData {
  token: string;
  start: number;
  limit: number;
  page: number;
  search: string;
  typeId: number;
  orderBy: {
    column: string[];
    direction: string;
  };
}
export interface IGetMasterKey {
  token: string;
  start: number;
  limit: number;
  search: string;
  page: number;
  categoryId: number;
  orderBy: {
    column: string[];
    direction: string;
  };
}
export interface IGetMasterArchive {
  token: string;
  start: number;
  limit: number;
  status: string;
  search: string;
  page: number;
  categoryId: number;
  orderBy: {
    column: string[];
    direction: string;
  };
}

export interface IGetMasterKeyDatas {
  token: string;
  start: number;
  limit: number;
  page: number;
  type: string;
  keyword: string;
  name: string;
  orderBy: {
    column: string[];
    direction: string;
  };
}

export interface IGetMasterEquipmentDetailLog {
  start: number;
  limit: number;
  page: number;
  keyword: string;
  name: string;
  filter: {
    id: string | null;
    identificationNumber: string | null;
    code: string | null;
  };
  orderBy: {
    column: string[];
    direction: string;
  };
}

export interface IGetMasterKeyDetailData {
  subCategoryId: number;
}
export interface ICreateMasterKeyData extends IFormikValues {
  categoryId: number;
}

export interface ICreateMasterKeyCategory extends IFormikNames {
  typeId: number;
}
export interface IUpdateMasterKeyData extends IFormikValues {
  // subCategoryId: number;
  id: number;
}
//--------------------
//batas
//--------------------
export interface IGetMasterAttData {
  token: string;
  start: number;
  limit: number;
}
export interface IGetMasterAttDetailData {
  attId: number;
}
export interface ICreateMasterAttData extends IAttValues {}
export interface IUpdateMasterAttData extends IAttValues {
  attId: number;
}
//--------------------
//batas
//--------------------
export interface IGetAllLocData {
  token: string;
  start: number;
  limit: number;
}

export interface IGetAllSiteData {
  token: string;
  start: number;
  limit: number;
}
export interface IGetLocationDetailData {
  id: number;
}
export interface ICreateMasterLocData extends ILocValues {}
export interface IUpdateMasterLocData extends ILocValues {
  id: number;
}
export interface IMasterSiteRequestData {}

export interface IMasterRiskRequestData {}

export interface IMasterShiftRequestData {}

export interface IMasterEquipmentRequestData {
  token: string;
  start: number;
  limit: number;
}

export interface IMasterLocationRequestData {}

export interface IMasterLocationTypeRequestData {
  token: string;
  start: number;
  limit: number;
  id: number;
  locationTypeName: string;
  locationTypeDescription: string;
}

export interface IMasterSubLocationData {
  token: string;
  start: number;
  limit: number;
}

export interface Location {
  locationName: string;
  locationDescription: string;
  id: number;
}

export interface SubLocation {
  subLocationName: string;
  subLocationDescription: string;
  locationId: number;
  id: number;
  isDangerousArea: boolean;
}
