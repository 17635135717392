import { Layout } from "antd";
import { FC, ReactNode, Suspense } from "react";
import { ContentLoader } from "../Loader";

const ContentComponent: FC<{ children: ReactNode }> = ({ children }) => {
  const { Content } = Layout;
  return (
    <Suspense fallback={<ContentLoader />}>
      <Content style={{ backgroundColor: "white" }}>{children}</Content>
    </Suspense>
  );
};

export default ContentComponent;
