export const types = {
  GET_MASTER_ACTION_KEYS_REQUEST: "hiradc/GET_MASTER_ACTION_KEYS_REQUEST",
  GET_MASTER_ACTION_KEYS_SUCCESS: "hiradc/GET_MASTER_ACTION_KEYS_SUCCESS",
  GET_MASTER_ACTION_KEYS_FAILED: "hiradc/GET_MASTER_ACTION_KEYS_FAILED",

  GET_LIST_HIRADC_KEYS_REQUEST: "hiradc/GET_LIST_HIRADC_KEYS_REQUEST",
  GET_LIST_HIRADC_KEYS_SUCCESS: "hiradc/GET_LIST_HIRADC_KEYS_SUCCESS",
  GET_LIST_HIRADC_KEYS_FAILED: "hiradc/GET_LIST_HIRADC_KEYS_FAILED",

  GET_DETAIL_HIRADC_KEYS_REQUEST: "hiradc/GET_DETAIL_HIRADC_KEYS_REQUEST",
  GET_DETAIL_HIRADC_KEYS_SUCCESS: "hiradc/GET_DETAIL_HIRADC_KEYS_SUCCESS",
  GET_DETAIL_HIRADC_KEYS_FAILED: "hiradc/GET_DETAIL_HIRADC_KEYS_FAILED",

  GET_ARCHIVED_HIRADC_KEYS_REQUEST: "hiradc/GET_ARCHIVED_HIRADC_KEYS_REQUEST",
  GET_ARCHIVED_HIRADC_KEYS_SUCCESS: "hiradc/GET_ARCHIVED_HIRADC_KEYS_SUCCESS",
  GET_ARCHIVED_HIRADC_KEYS_FAILED: "hiradc/GET_DETAIL_HIRADC_KEYS_FAILED",

  EXPORT_HIRADC_REQUEST: "hiradc/EXPORT_HIRADC_REQUEST",
  EXPORT_HIRADC_SUCCESS: "hiradc/EXPORT_HIRADC_SUCCESS",
  EXPORT_HIRADC_FAILED: "hiradc/EXPORT_HIRADC_FAILED",

  LOG_HIRADC_REQUEST: "hiradc/LOG_HIRADC_REQUEST",
  LOG_HIRADC_SUCCESS: "hiradc/LOG_HIRADC_SUCCESS",
  LOG_HIRADC_FAILED: "hiradc/LOG_HIRADC_FAILED",

  LIST_APROVED_HIRADC_REQUEST: "hiradc/LIST_APROVED_HIRADC_REQUEST",
  LIST_APROVED_HIRADC_SUCCESS: "hiradc/LIST_APROVED_HIRADC_SUCCESS",
  LIST_APROVED_HIRADC_FAILED: "hiradc/LIST_APROVED_HIRADC_FAILED",
};
export interface IGetHiradcRequestData {
  page: number;
  limit: number;
  start: number;
  keyword: string;
  orderBy: string[];
  direction: string | null;
  filter: object;
}

export interface IGetHiradcListRequest {
  page: number;
  limit: number;
  start: number;
  keyword: string;
  orderBy: string[];
  direction: string | null;
  filter: object;
}
export interface IGetDetailHiradcRequestData {
  id?: number | null;
}

export interface IPayloadExportHiradc {
  start: number;
  limit: number;
  page: number;
  filter: object;
  keyword: "";
  orderBy: string[];
  direction: string | null;
}

export interface IPayloadLogHiradc {
  start: number;
  limit: number;
  page: number;
  filter: object;
  keyword: string;
  orderBy: string[];
  direction: string | null;
}
