import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "../../pages/Reservation/_actions";
import { manualGetMyTask } from "../../pages/Reservation/_actions";
import { MyTaskDetail } from "../../pages/Health/_types";

interface useGetMyTaskProps {
  assignmentNumber?: string | undefined;
  assignmentNumbers?: string[] | undefined;
}

interface useGetMyTaskOptions {
  skip?: boolean;
  multiple?: boolean;
}

type AllSettledType = "fulfilled" | "rejected";

interface Response {
  status: AllSettledType;
  value?: {
    error: boolean;
    data?: { formFlow: MyTaskDetail[] };
  };
}

export const makeMyTaskPayload = (assignmentNumber: string | undefined) => {
  return {
    start: 0,
    limit: 1,
    page: 1,
    keyword: "",
    filter: {
      typeFlow: "Safety",
      description: [],
      assignmentNumber: assignmentNumber,
      assignmentDate: "",
      status: "",
      name: "",
    },
    orderBy: ["assignmentNumber"],
    direction: "DESC",
  };
};

const useGetMyTaskDetail = (
  { assignmentNumber, assignmentNumbers }: useGetMyTaskProps,
  options?: useGetMyTaskOptions
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [task, setTask] = useState<MyTaskDetail | undefined>(undefined);
  const [childTasks, setChildTasks] = useState<Record<string, MyTaskDetail> | undefined>(undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    if (options?.skip || !assignmentNumber) return;

    setIsLoading(true);

    dispatch(
      actions.getMyTaskList.request({
        payload: makeMyTaskPayload(assignmentNumber),
        onSuccess: (res) => {
          if (res?.data && res.data.formFlow) {
            setTask(res.data.formFlow[0]);
          } else {
            setTask(undefined);
          }
        },
        onFailure: (err) => {
          console.error(err);
          setIsLoading(false);
          setIsError(true);
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentNumber, options?.skip]);

  useEffect(() => {
    if (!assignmentNumbers) {
      setIsError(true);
      setIsLoading(false);
      return;
    }

    const onPageLoad = async () => {
      try {
        const results: Awaited<Response[]> = await Promise.allSettled(
          assignmentNumbers.map((item) => manualGetMyTask(item))
        );
        const childTasksPlaceholder: Record<string, MyTaskDetail> = {};
        for (let result of results) {
          if (result.status === "rejected" || !result.value || !result.value.data) continue;

          childTasksPlaceholder[result.value.data.formFlow[0].assignmentNumber] =
            result.value.data.formFlow[0];
        }
        setChildTasks(childTasksPlaceholder);
      } catch (err) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    onPageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(assignmentNumbers)]);

  return {
    isLoading,
    isError,
    task,
    childTasks,
  };
};

export default useGetMyTaskDetail;
